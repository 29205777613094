import grey from "@material-ui/core/colors/grey";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/styles';
import React from "react";
import Skeleton from 'react-loading-skeleton';
import * as Constants from '../Constants';
import EventListItem from './EventListItem';
import './styles.scss';

const primary800 = grey["400"];
// import useStyles from "./styles";


const useStyles = makeStyles(({ palette }) => ({
  card: {
    borderRadius: 12,
    // minWidth: 150,
    textAlign: 'left',
    margin: 2
  },
  cardHovered: {
    borderRadius: 12,
    minWidth: 150,
    textAlign: 'left',
    margin: 5,
    backgroundColor: primary800
  },
  heading: {
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: '0.5px',
    marginBottom: -5,
    marginTop: -1,
    marginLeft: 4,
  },
  headingKey: {
    fontSize: 16,
    color: palette.grey[500],
    fontWeight: 500,
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    margin: 0,
  },
  subheader: {
    fontSize: 14,
    color: palette.grey[500],
    marginBottom: -3,
    marginTop: -3,
    marginLeft: 4,
  },
  boxStyle: {
    marginTop: -3,
  },
  boxStyleRow: {
    flex: "auto",
    flexDirection: "row"
  },
  eventCard: {
    borderRadius: 12,
    width: '100%',
    textAlign: 'left',
  },

  liHeading: {
    fontSize: 14,
  },
  liSubheader: {
    fontSize: 12,
  },
  liStatLabel: {
    fontSize: 14,
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  },
  liStatValue: {
    fontSize: 14,
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  },
  statLabel: {
    fontSize: 12,
    color: palette.grey[500],
    fontWeight: 500,
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    marginBottom: -5,
    marginTop: -5,
  },
  statValue: {
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: '1px',
  },
}));

function EventList({ renderedEventData, handleUiClick, onMouseEnter, onMouseLeave }) {
  let styles = useStyles();
  let itemWidth = "100%";
  let itemHeight = 60;

  if (renderedEventData === undefined) {
    return (<>
      <Skeleton duration={2} height={itemHeight} width={itemWidth} />
      <Skeleton duration={2} height={itemHeight} width={itemWidth} marginLeft='.5rem' />
      <Skeleton duration={2} height={itemHeight} width={itemWidth} />
      <Skeleton duration={2} height={itemHeight} width={itemWidth} />
    </>);
  } else if (renderedEventData.length <= 0) {
  return (
    <>
        <div className="card-header">
          <div className="stats is-gray">
            <h3>{"No events!"}</h3>
          </div>
        </div>
        <div className="card-header">
          <div className="stats is-white">
          <Skeleton count={8} duration={2} height={itemHeight} width={itemWidth} />
            </div>
        </div>
        
      </>);
  } else {
    return (
      <>

        <div className="card-header"
          id={Constants.TRCK_GRAPH_UI_INTERACTIVE_IDs.BT_SHOW_ALL_EVENTS}
          onClick={(e) => handleUiClick(e, undefined)} >
          <div className="stats is-blue" >
            <h3>{"All Events!"}</h3>
          </div>
        </div>
        <List dense={true} style={{ maxHeight: 600, overflowY: 'scroll', overflowX: 'hidden' }}>
          {renderedEventData.map(logicalEvent => (
            <ListItem button alignItems="center"  key={logicalEvent.id}
            id={Constants.TRCK_GRAPH_UI_INTERACTIVE_IDs.BT_EVENT_LIST_ITEM}
            onClick={(e) => handleUiClick(e, logicalEvent)}
            onMouseEnter={(e) => onMouseEnter(e, logicalEvent)}
            onMouseLeave={(e) => onMouseLeave(e, logicalEvent)}
          >
            <EventListItem logicalEvent={logicalEvent} styles={styles} />
          </ListItem>))}
      </List>
    </>
  );
}
}


export default (EventList);