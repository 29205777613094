import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Divider } from '@material-ui/core';
import { useGutterBorderedGridStyles } from '@mui-treasury/styles/grid/gutterBordered';
import { useFadedShadowStyles } from '@mui-treasury/styles/shadow/faded';
import React from "react";


function EventHeaderItem({ entity, styles, isEntityHidden }) {
  const shadowStyles = useFadedShadowStyles();
  const borderedGridStyles = useGutterBorderedGridStyles({
    borderColor: 'rgba(0, 0, 0, 0.08)',
    height: '60%',
  });

  let name = "- - -";
  let description = "- - -";
  let health = "- - -";
  let feedEnd = "- - -";
  let relayEnd = "- - -";
  if (entity !== undefined) {
    if (entity.name !== undefined) name = entity.name;
  }
  let entityClassName;
  if (isEntityHidden) {
    entityClassName = styles.cardHidden;
  } else {
    entityClassName = styles.card;
  }
  return (
    <Card raised className={styles.eventCard} >
      <Box m={1} flex={'auto'} >
        <div className={styles.liHeading}>{name}</div>
      </Box>
    </Card>
  );
}
export default (EventHeaderItem);