import { Button, Grid } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import 'rc-checkbox/assets/index.css';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import * as ApiGlobal from '../../api/ApiGlobal';
// components
import { dateAndHhMmSsToDateAndHh0000, dateCompact, getTodayAt23h59m59s, timestampToLocaleHhmmss } from '../../commonjs/CustomUtils';
import NavigateHistory from '../../components/NavigateHistory/NavigateHistory';
import { LOG_TAG, TIME_WINDOWS_IN_MILLIS } from '../../GlobalConstants';
import { ApiHelper } from './api/ApiHelper';
// components
import GraphSummary from "./components/GraphSummary";
import LBoxGraph from "./components/LBoxGraph";
import * as Constants from './Constants';
// styles
var selfRef
export default class LBoxClass extends React.Component {
  constructor(props) {
    super(props);
    console.log('TrckCircuitID: ' + this.props.entityId);
    //ForeGround
    this.handleUiClick = this.handleUiClick.bind(this);
    this.onClickSwitchTrckView = this.onClickSwitchTrckView.bind(this);

    this.onClickBefore = this.onClickBefore.bind(this);
    this.onClickAfter = this.onClickAfter.bind(this)
    this.onClick30MinBefore = this.onClick30MinBefore.bind(this);
    this.onClick05MinBefore = this.onClick05MinBefore.bind(this);
    this.onClick05MinAfter = this.onClick05MinAfter.bind(this);
    this.onClick30MinAfter = this.onClick30MinAfter.bind(this);
    this.onSelectDateTime = this.onSelectDateTime.bind(this);
    this.onClickNavigateHistory = this.onClickNavigateHistory.bind(this);

    //BackGround
    this.getStaleEventIndex = this.getStaleEventIndex.bind(this);
    this.sliceEventsInLiveWindow = this.sliceEventsInLiveWindow.bind(this);
    this.sliceTsData = this.sliceTsData.bind(this);
    this.sliceUniqueEvents = this.sliceUniqueEvents.bind(this);
    this.switchToHistory = this.switchToHistory.bind(this);
    this.updateHistoryData = this.updateHistoryData.bind(this);
    this.loadHistoryDefault = this.loadHistoryDefault.bind(this);

    this.onLiveApiErrorCb = this.onLiveApiErrorCb.bind(this);
    this.onLiveApiSuccessCb = this.onLiveApiSuccessCb.bind(this);
    this.onHistoryApiErrorCb = this.onHistoryApiErrorCb.bind(this);
    this.onHistoryApiSuccessCb = this.onHistoryApiSuccessCb.bind(this);

    this.onReqInterceptSuccessCb = this.onReqInterceptSuccessCb.bind(this);
    this.onReqInterceptFailureCb = this.onReqInterceptFailureCb.bind(this);
    this.onRespInterceptSuccessCb = this.onRespInterceptSuccessCb.bind(this);
    this.onRespInterceptFailureCb = this.onRespInterceptFailureCb.bind(this);


    this.resetState = this.resetState.bind(this);
    this.resetHistoryState = this.resetHistoryState.bind(this);
    this.resetLiveState = this.resetLiveState.bind(this);

    this.pollStart = this.pollStart.bind(this);
    this.pollStop = this.pollStop.bind(this);
    this.polling = this.polling.bind(this);
    // this.periodicRefresh = this.periodicRefresh.bind(this);
    this._pollingInitTimer = {};
    this._pollingTimer = {};
    this._pollingInterval = TIME_WINDOWS_IN_MILLIS.TEN_SEC;
    this._pollingInitTimeout = TIME_WINDOWS_IN_MILLIS.ONE_SEC;

    //Live View class variables

    this._liveViewWindowSize = TIME_WINDOWS_IN_MILLIS.A_DAY;
    this._liveApiHelper = new ApiHelper();

    this.liveMetaData = ApiGlobal.emptyMetaData;
    this.liveEventData = [];//ApiGlobal.emptyEventData;
    this.liveTSData = ApiGlobal.emptyTSData;
    this.liveLatestData = ApiGlobal.emptyLatestData;
    this.liveDataTs = 0;
    this.liveDataTe = 0;
    this.liveSummary = {};
    //History View class variables
    this._historyViewWindowSize = TIME_WINDOWS_IN_MILLIS.A_DAY;
    this._historyApiHelper = new ApiHelper();
    this._apiTokenSource = axios.CancelToken.source();
    this.historySummary = {};
    this.hasHistory = false;
    this.graphSummary = {};

    this._isPolling = false;
    //TODO remove summary cards and add a summary state obj
    this.state = {
      trckViewState: Constants.TRCK_HOME_VIEW_STATE_IDs.LIVE,

      selectedDateAndHour: dateAndHhMmSsToDateAndHh0000(),
      graphSummary: {},
      isApiReqOngoing: false,

      liveMetaData: ApiGlobal.emptyMetaData,
      liveEventData: [],//ApiGlobal.emptyEventData,
      liveTSData: ApiGlobal.emptyTSData,
      liveLatestData: ApiGlobal.emptyLatestData,
      liveDataTs: 0,
      liveDataTe: 0,
      liveWindowTs: 0,
      liveWindowTe: 0,
      liveSummary: {},

      historyMetaData: ApiGlobal.emptyMetaData,
      historyEventData: [],
      historySubEventData: [],
      historyTSData: ApiGlobal.emptyTSData,
      historyLatestData: ApiGlobal.emptyLatestData,
      historyDataTs: 0,
      historyDataTe: 0,
      historyWindowTs: 0,
      historyWindowTe: 0,
      historySummary: {},
      historyRenderedTsData: [],
      historyRenderedEventData: [],
      historyReqTimeSlice: 0,
      historyReqTypeId: Constants.API_REQ_TYPE_IDs.MAX_API_REQ_TYPE_ID
    };
    selfRef = this;
    console.log(LOG_TAG.INFO, "constructor()" + selfRef.state.trckViewState);
  }
  //TODO reset states functions must cancel all the ongoing API requests

  pollStart() {
    console.log(LOG_TAG.INFO, "pollStart Current State[" + selfRef.state.trckViewState + "]");
    try {
      if (selfRef._isPolling === true) return;
      selfRef._pollingInitTimer = setTimeout(function () {
        selfRef.polling(); // do it once and then start it up every 2 seconds
        selfRef._pollingTimer = setInterval(selfRef.polling.bind(selfRef), selfRef._pollingInterval);
      }, selfRef._pollingInitTimeout);
      // console.log('pollStart done');
      selfRef._isPolling = true;
    } catch (err) {
      console.log(LOG_TAG.ERR_CATCH, err.message);
    }
  }
  pollStop() {
    console.log(LOG_TAG.INFO, "pollStop");
    try {
      if (selfRef._pollingInitTimer) {
        clearInterval(selfRef._pollingInitTimer);
        selfRef._pollingInitTimer = null;
      }
      if (selfRef._pollingTimer) {
        clearTimeout(selfRef._pollingTimer);
        selfRef._pollingTimer = null;
      }
      selfRef._isPolling = false;
    } catch (err) {
      console.log(LOG_TAG.ERR_CATCH, err.message);
    }
  }

  polling() { //prep and send API request
    try {
      console.log(LOG_TAG.INFO, "Polling!");
      if (selfRef.state.trckViewState === Constants.TRCK_HOME_VIEW_STATE_IDs.HISTORICAL) {
        console.log(LOG_TAG.ERR_STATE, "Cannot poll in history View");
        return;
      }
      let tempTe = selfRef.state.liveDataTe;
      if (tempTe === 0) {
        tempTe = getTodayAt23h59m59s();
        let tempTs = tempTe - selfRef._liveViewWindowSize;
        selfRef._liveApiHelper.prepReq(selfRef.props.entityId, tempTs, tempTe, Constants.API_REQ_TYPE_IDs.GET_LIVE)
          .sendReq(selfRef._apiTokenSource,
            selfRef.onLiveApiSuccessCb,
            selfRef.onLiveApiErrorCb,
            selfRef.onReqInterceptSuccessCb,
            selfRef.onReqInterceptFailureCb,
            selfRef.onRespInterceptSuccessCb,
            selfRef.onRespInterceptFailureCb);
      } else {
        //TODO check for date change here.
        selfRef._liveApiHelper.prepReq(selfRef.props.entityId, tempTe + 1, (new Date()).getTime(), Constants.API_REQ_TYPE_IDs.GET_LIVE)
          .sendReq(selfRef._apiTokenSource,
            selfRef.onLiveApiSuccessCb,
            selfRef.onLiveApiErrorCb,
            selfRef.onReqInterceptSuccessCb,
            selfRef.onReqInterceptFailureCb,
            selfRef.onRespInterceptSuccessCb,
            selfRef.onRespInterceptFailureCb);
      }
    } catch (err) {
      console.log(LOG_TAG.ERR_CATCH, err.message);
    }
  }

  getStaleEventIndex(existingEventList, newEvent) {
    if (existingEventList !== undefined && existingEventList.length > 0 && newEvent !== undefined) {
      for (let eventCtr = 0; eventCtr < existingEventList.length; eventCtr++) {
        if (existingEventList[eventCtr].id === newEvent.id)
          return eventCtr;
      }
    }
    return -1;
  }
  sliceTsData(tsData, windowTs, windowTe) {
    //TODO add validity of history window ts and te
    //find the start and end timstamp according to the event and set the data accordingnly
    try {
      let renderStartIndex = 0;
      let renderStopIndex = tsData.length - 1;
      let tsDataLength = tsData.length;
      if (windowTs < tsData[0].t) {
        renderStartIndex = 0;
      } else {
        for (let ctr1 = 0; ctr1 < tsDataLength; ctr1++) {
          if (windowTs < tsData[ctr1].t) {
            renderStartIndex = ctr1;
            break;
          }
        }
      }
      if (windowTe > tsData[tsDataLength - 1].t) {
        renderStopIndex = tsDataLength - 1;
      } else {
        for (let ctr = tsDataLength - 1; ctr >= 0; ctr--) {
          if (windowTe > tsData[ctr].t) {
            renderStopIndex = ctr;
            break;
          }
        }
      }
      if (renderStartIndex > renderStopIndex) {
        console.log("Invalid history window!")
        //show the message on UI
        return [];
      }
      return tsData.slice(renderStartIndex, renderStopIndex);
    } catch (err) {
      console.log("sliceTsData Error-" + err);
    }
  }
  sliceEventsInLiveWindow(tempEventData, sliceStart, sliceEnd) {
    console.log(LOG_TAG.INFO, "sliceEventsInLiveWindow [" + timestampToLocaleHhmmss(sliceStart) + " => " + timestampToLocaleHhmmss(sliceEnd) + "]");
    let slicedEventData = []
    if (tempEventData !== undefined && tempEventData.length > 0) {
      for (let i = 0; i < tempEventData.length; i++)
        if ((tempEventData[i].ts >= sliceStart && tempEventData[i].ts <= sliceEnd)
          || (tempEventData[i].te >= sliceStart && tempEventData[i].te <= sliceEnd)) {
          slicedEventData.push(tempEventData[i]);
        }
      console.log("Event before[" + tempEventData.length + "] after[" + slicedEventData.length + "]");
      return slicedEventData;
    }
  }
  sliceUniqueEvents(eventList) {
    try {
      let uniqueEventList = eventList.filter((eventList, index, self) => index === self.findIndex((t) => (t.id === eventList.id)))
      if (uniqueEventList !== undefined) {
        uniqueEventList.sort(function (a, b) {
          return a.id - b.id;
        });
        return uniqueEventList;
      }
      else if (eventList !== undefined) {
        eventList.sort(function (a, b) {
          return a.id - b.id;
        });
        return eventList
      }
    } catch (err) {
      console.log(err);
      return eventList;
    }
  }
  sortEventList(eventList) {
    try {
      if (eventList !== undefined && eventList.length > 0) {
        eventList.sort(function (a, b) {
          return a.id - b.id;
        });
        return eventList
      }
    } catch (err) {
      console.log(err);
      return eventList;
    }
  }
  sliceHistoryEventData(eventData, windowTs, windowTe) {
    //TODO add validity of history window ts and te
    try {
      let tempEventData = [];
      let length = eventData.length;
      for (let ctr = length - 1; ctr >= 0; ctr--) {
        if ((windowTs <= eventData[ctr].ts && windowTe >= eventData[ctr].ts) || (windowTs <= eventData[ctr].te && windowTe >= eventData[ctr].te)) {
          let eventItem = cloneDeep(eventData[ctr]);
          tempEventData.push(eventItem);
        }
      }
      // let uniqueEventList = tempEventData.filter((tempEventData, index, self) =>
      //   index === self.findIndex((t) => (t.id === tempEventData.id)))

      // console.log(uniqueEventList)
      console.log(LOG_TAG.INFO, "Events[" + eventData.length + "] Sliced[" + tempEventData.length + "] [" + dateCompact(windowTs) + " => " + dateCompact(windowTe) + "]")
      return tempEventData;
    } catch (err) {
      console.log("sliceHistoryEventData Error-" + err);
    }
  }

  updateHistoryData(metaData, eventData, tSData, latestData, summary, windowTs, windowTe) {
    let maxIndex = tSData.length - 1;
    console.log(LOG_TAG.INFO, "updateHistoryData");
    console.log(LOG_TAG.INFO, "data [" + ((tSData[maxIndex].t - tSData[0].t) / 1000 / 60).toFixed(2) + " mins] Ts[" + dateCompact(tSData[0].t) + "] Te[" + dateCompact(tSData[maxIndex].t) + "]")
    console.log(LOG_TAG.INFO, "Window [" + ((windowTe - windowTs) / 1000 / 60).toFixed(2) + " mins] Ts[" + dateCompact(windowTs) + "] Te[" + dateCompact(windowTe) + "]")
    let windowSlice = selfRef.sliceTsData(tSData, windowTs, windowTe);
    let eventSlice = selfRef.sliceHistoryEventData(eventData, windowTs, windowTe);
    let eventCountInWindow;
    if (eventSlice !== undefined) eventCountInWindow = eventSlice.length;
    let tempGraphSummary = { "from": windowTs, "to": windowTe, "events": eventCountInWindow }
    selfRef.setState({
      historySummary: summary,
      historyMetaData: metaData,
      historyEventData: eventData,
      historySubEventData: [],//ApiGlobal.emptyEventData,
      historyTSData: { ts: tSData[0].t, te: tSData[maxIndex].t, tSeries: tSData },
      historyLatestData: latestData,
      historyDataTs: tSData[0].t,
      historyDataTe: tSData[maxIndex].t,
      historyWindowTs: windowTs,
      historyWindowTe: windowTe,
      historyRenderedTsData: windowSlice,
      historyRenderedEventData: eventSlice,
      graphSummary: tempGraphSummary,
      // historyReqTypeId: Constants.API_REQ_TYPE_IDs.MAX_API_REQ_TYPE_ID,
      // historyReqTimeSlice: 0,
      trckViewState: Constants.TRCK_HOME_VIEW_STATE_IDs.HISTORICAL,
    });
  }

  onLiveApiSuccessCb(metaData, eventData, tSData, latestData, summary, reqType) {
    //TODO set state on new Live API Data
    try {
      console.log(LOG_TAG.INFO, "onLiveApiSuccessCb");
      if (selfRef.state.trckViewState !== Constants.TRCK_HOME_VIEW_STATE_IDs.LIVE) {
        console.log(LOG_TAG.ERR_API, "Wrong view state. Cannot updated View[" + selfRef.state.trckViewState + "]");
        return;
      }
      if (tSData !== undefined && eventData !== undefined) {
        console.log(LOG_TAG.INFO, "Received TSData[" + tSData.length + "] - events[" + (eventData.length) + "]");
      }

      let tempTSeries = [...(selfRef.state.liveTSData.tSeries), ...tSData];
      let tempEventData;
      let copyLiveEventData = cloneDeep(selfRef.state.liveEventData);
      if (eventData !== undefined && eventData.length > 0) {
        eventData = selfRef.sortEventList(eventData);
        if ((copyLiveEventData === undefined) ||
          (copyLiveEventData !== undefined && copyLiveEventData.length === 0)) {
          tempEventData = [...eventData];
        } else {
          for (let eventCtr = 0; eventCtr < eventData.length; eventCtr++) {
            let staleEventIndex = selfRef.getStaleEventIndex(copyLiveEventData, eventData[eventCtr])
            if (staleEventIndex !== -1) {
              // console.log("staleEventId[" + copyLiveEventData[staleEventIndex].id + "]")
              copyLiveEventData.splice(staleEventIndex, 1);
            } else {
              // console.log("new uniqueEventId[" + eventData[eventCtr].id + "]")
            }
          }
          tempEventData = [...copyLiveEventData, ...eventData];
        }
      } else {
        tempEventData = copyLiveEventData;
      }

      let maxIndex = tempTSeries.length - 1;
      if ((tempTSeries[maxIndex].t - tempTSeries[0].t) > (selfRef._liveViewWindowSize + Constants.FIVE_MINS_IN_MILLIS)) {
        let sliceEnd = tempTSeries[maxIndex].t;
        let sliceStart = sliceEnd - selfRef._liveViewWindowSize;
        tempTSeries = selfRef.sliceTsData(tempTSeries, sliceStart, sliceEnd)
        maxIndex = tempTSeries.length - 1;
        tempEventData = selfRef.sliceEventsInLiveWindow(tempEventData, sliceStart, sliceEnd);
      }

      let lastEventTime = undefined;
      let lastUpdateTime = undefined;
      let allEvents = undefined;
      if (tempEventData !== undefined && tempEventData.length > 0) {
        lastEventTime = tempEventData[tempEventData.length - 1].ts;
        allEvents = tempEventData.length;
      }
      if (tempTSeries !== undefined && tempTSeries.length > 0) {
        lastUpdateTime = tempTSeries[tempTSeries.length - 1].t;
      }
      // console.log("lastEventTime[" + lastEventTime + "] lastUpdateTime[" + lastUpdateTime + "]");
      summary["lastEvent"] = lastEventTime;
      summary["lastUpdate"] = lastUpdateTime;
      summary["allEvents"] = allEvents;

      // let maxIndex = tempTSeries.length - 1;
      if (maxIndex >= 0) {
        console.log(LOG_TAG.INFO, "updated TSData[" + tempTSeries.length + "] [" + (timestampToLocaleHhmmss(tempTSeries[0].t)) +
          " => " + (timestampToLocaleHhmmss(tempTSeries[maxIndex].t)) + "] - events[" + (tempEventData.length) + "]");
      }
      selfRef.setState({
        liveMetaData: metaData,
        liveEventData: tempEventData,
        liveTSData: { ts: tempTSeries[0].t, te: tempTSeries[maxIndex].t, tSeries: tempTSeries },
        liveLatestData: latestData,
        liveDataTs: tempTSeries[0].t,
        liveDataTe: tempTSeries[maxIndex].t,
        liveSummary: summary,
        graphSummary: { "from": tempTSeries[0].t, "to": tempTSeries[maxIndex].t, "events": tempEventData.length },
      });
      // selfRef.periodicRefresh();
    } catch (err) {
      console.log(LOG_TAG.ERR_CATCH, err);
    }
  }

  onHistoryApiSuccessCb(metaData, eventData, tSData, latestData, summary, reqType, needReset) {
    //TODO update history resp success to state
    if (selfRef._isMounted === false) return;
    console.log("onHistoryApiSuccessCb");
    try {
      console.log(LOG_TAG.INFO, "onHistoryApiSuccessCb");

      let copyMetaData;
      let copyEventData;
      let copyTSData;
      let copyLatestData;
      let copySummary;
      let copyWindowTs = selfRef.state.historyWindowTs;
      let copyWindowTe = selfRef.state.historyWindowTe;
      let maxIndex;

      if (metaData === undefined || tSData === undefined || latestData === undefined) {
        //TODO Hourly data not available for 
        console.log(LOG_TAG.ERR_API, "Data not available for[" + selfRef.state.selectedDateAndHour + "]");
        //TODO update UI
        return;
      }
      if (metaData !== undefined && metaData === {}) {
        console.log(LOG_TAG.ERR_API, "empty metaData received!")
        //TODO update UI
        return;

      }
      if (latestData !== undefined && latestData === {}) {
        console.log(LOG_TAG.ERR_API, "empty latestData received!")
        //TODO update UI
        return;

      }
      if (tSData !== undefined && tSData.length === 0) {
        console.log(LOG_TAG.ERR_API, "empty tSData received!")
        //TODO update UI
        return;
      }
      if (eventData !== undefined && eventData.length === 0) {
        console.log(LOG_TAG.ERR_API, "Empty events received!")
      }

      summary.normal = (+summary.normal);
      summary.warning = (+summary.warning);
      summary.error = (+summary.error);
      summary.critical = (+summary.critical);
      switch (reqType) {
        case Constants.API_REQ_TYPE_IDs.GET_HISTORY_HOUR:
          copyTSData = [...tSData];
          maxIndex = tSData.length - 1;
          copyWindowTs = tSData[0].t;
          copyWindowTe = tSData[maxIndex].t;
          copyEventData = [...eventData];
          break;
        case Constants.API_REQ_TYPE_IDs.GET_HISTORY_30MIN_BEFORE:
          copyTSData = [...tSData, ...(selfRef.state.historyTSData.tSeries)];
          maxIndex = tSData.length - 1;
          copyWindowTs = tSData[0].t;
          copyWindowTe = copyWindowTe - Constants.THIRTY_MINS_IN_MILLIS;
          if (tSData[maxIndex].t - tSData[0].t > selfRef._historyViewWindowSize) {
            let currentWindowSize = copyWindowTe - copyWindowTs;
            if (currentWindowSize < selfRef._historyViewWindowSize) {
              copyWindowTe = copyWindowTe + (selfRef._historyViewWindowSize - currentWindowSize);
            }
          }
          copyEventData = [...eventData, ...selfRef.state.historyEventData];
          break;
        case Constants.API_REQ_TYPE_IDs.GET_HISTORY_05MIN_BEFORE:
          copyTSData = [...tSData, ...(selfRef.state.historyTSData.tSeries)];
          maxIndex = tSData.length - 1;
          copyWindowTs = tSData[0].t;
          copyWindowTe = copyWindowTe - Constants.FIVE_MINS_IN_MILLIS;
          if (tSData[maxIndex].t - tSData[0].t > selfRef._historyViewWindowSize) {
            let currentWindowSize = copyWindowTe - copyWindowTs;
            if (currentWindowSize < selfRef._historyViewWindowSize) {
              copyWindowTe = copyWindowTe + (selfRef._historyViewWindowSize - currentWindowSize);
            }
          }
          copyEventData = [...eventData, ...selfRef.state.historyEventData];
          break;
        case Constants.API_REQ_TYPE_IDs.GET_HISTORY_05MIN_AFTER:
          copyTSData = [...(selfRef.state.historyTSData.tSeries), ...tSData];
          maxIndex = tSData.length - 1;
          copyWindowTs = copyWindowTs + Constants.FIVE_MINS_IN_MILLIS;
          copyWindowTe = tSData[maxIndex].t;
          if (tSData[maxIndex].t - tSData[0].t > selfRef._historyViewWindowSize) {
            let currentWindowSize = copyWindowTe - copyWindowTs;
            if (copyWindowTe - copyWindowTs < selfRef._historyViewWindowSize) {
              copyWindowTs = copyWindowTs - (selfRef._historyViewWindowSize - currentWindowSize);
            }
          }
          copyEventData = [...selfRef.state.historyEventData, ...eventData];
          break;
        case Constants.API_REQ_TYPE_IDs.GET_HISTORY_30MIN_AFTER:
          copyTSData = [...(selfRef.state.historyTSData.tSeries), ...tSData];
          maxIndex = tSData.length - 1;
          copyWindowTs = copyWindowTs + Constants.THIRTY_MINS_IN_MILLIS;
          copyWindowTe = tSData[maxIndex].t;
          if (tSData[maxIndex].t - tSData[0].t > selfRef._historyViewWindowSize) {
            let currentWindowSize = copyWindowTe - copyWindowTs;
            if (copyWindowTe - copyWindowTs < selfRef._historyViewWindowSize) {
              copyWindowTs = copyWindowTs - (selfRef._historyViewWindowSize - currentWindowSize);
            }
          }
          copyEventData = [...selfRef.state.historyEventData, ...eventData];
          break;
        case Constants.API_REQ_TYPE_IDs.GET_EVENT:
          copyTSData = [...tSData];
          maxIndex = tSData.length - 1;
          copyWindowTs = tSData[0].t;
          copyWindowTe = tSData[maxIndex].t;
          copyEventData = [...eventData];
          break;
        default:
          break;
      }

      copyEventData = selfRef.sliceUniqueEvents(copyEventData);
      if (selfRef.hasHistory === false) selfRef.hasHistory = true;
      selfRef.updateHistoryData(metaData, copyEventData, copyTSData, latestData, summary, copyWindowTs, copyWindowTe);
    } catch (err) {
      console.log(LOG_TAG.ERR_CATCH, err);
    }
  }

  onLiveApiErrorCb(error) {
    //TODO Live API error 
  }
  onHistoryApiErrorCb(error, reqType) {
    //TODO update history resp error to state
    if(selfRef._isMounted=== false) return;
    switch (reqType) {
      case Constants.API_REQ_TYPE_IDs.GET_HISTORY_HOUR:
        break;
      case Constants.API_REQ_TYPE_IDs.GET_HISTORY_30MIN_BEFORE:
        break;
      case Constants.API_REQ_TYPE_IDs.GET_HISTORY_05MIN_BEFORE:
        break;
      case Constants.API_REQ_TYPE_IDs.GET_HISTORY_05MIN_AFTER:
        break;
      case Constants.API_REQ_TYPE_IDs.GET_HISTORY_30MIN_AFTER:
        break;
      default:
        break;
    }
  }


  loadHistoryDefault(copyDefaultFromLiveData) {
    console.log(LOG_TAG.INFO, "loadHistoryDefault");
    if (copyDefaultFromLiveData === true) {
      // selfRef.resetHistoryState();
      selfRef.onHistoryApiSuccessCb(selfRef.state.liveMetaData,
        selfRef.state.liveEventData,
        selfRef.state.liveTSData.tSeries,
        selfRef.state.liveLatestData,
        selfRef.state.liveSummary,
        Constants.API_REQ_TYPE_IDs.GET_HISTORY_HOUR, true);
    } else {
    let tempTe = (new Date()).getTime();
    let tempTs = tempTe - selfRef._historyViewWindowSize;
    selfRef._historyApiHelper.prepReq(selfRef.props.entityId, tempTs, tempTe, Constants.API_REQ_TYPE_IDs.GET_HISTORY_HOUR)
        .sendReq(selfRef._apiTokenSource,
          selfRef.onHistoryApiSuccessCb,
          selfRef.onHistoryApiErrorCb,
          selfRef.onReqInterceptSuccessCb,
          selfRef.onReqInterceptFailureCb,
          selfRef.onRespInterceptSuccessCb,
          selfRef.onRespInterceptFailureCb);
    }
  }
  componentDidMount() {
    try {
      console.log(LOG_TAG.LIFE_CYCLE, "DidMount LBoxClass");
      if (selfRef.props.ts !== undefined && selfRef.props.te !== undefined) {// start historical view and no polling
        let eventStartTStamp = selfRef.props.ts - Constants.EVENT_TIME_PADDING_IN_MILLIS;
        let eventEndTStamp = +(selfRef.props.te) + +(Constants.EVENT_TIME_PADDING_IN_MILLIS);

        selfRef.setState({
          trckViewState: Constants.TRCK_HOME_VIEW_STATE_IDs.HISTORICAL
        })
        selfRef._historyApiHelper.prepReq(selfRef.props.entityId, eventStartTStamp, eventEndTStamp, Constants.API_REQ_TYPE_IDs.GET_EVENT)
          .sendReq(selfRef._apiTokenSource,
            selfRef.onHistoryApiSuccessCb,
            selfRef.onHistoryApiErrorCb,
            selfRef.onReqInterceptSuccessCb,
            selfRef.onReqInterceptFailureCb,
            selfRef.onRespInterceptSuccessCb,
            selfRef.onRespInterceptFailureCb);
      } else {//start live view and polling
        selfRef.pollStart();
      }
    } catch (err) {
      console.log(err.message)
    }
    selfRef._isMounted= true;
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    try {
      console.log(LOG_TAG.LIFE_CYCLE, "DidUpdate LBoxClasss");
      if (selfRef.props.entityId !== prevProps.entityId) {
        console.log(LOG_TAG.LIFE_CYCLE, "Received new entityId");
        selfRef.pollStop();
        selfRef.resetState();
        selfRef.pollStart();
      }
      if (prevState.trckViewState === Constants.TRCK_HOME_VIEW_STATE_IDs.HISTORICAL &&
        selfRef.state.trckViewState === Constants.TRCK_HOME_VIEW_STATE_IDs.LIVE) {
        selfRef.pollStart();
      }
      if (selfRef.state.trckViewState === Constants.TRCK_HOME_VIEW_STATE_IDs.HISTORICAL) {
        console.log(LOG_TAG.INFO, "History TimeSlice [" + prevState.historyReqTimeSlice + " => " + selfRef.state.historyReqTimeSlice + "]")
        console.log(LOG_TAG.INFO, "History ReqType [" + prevState.historyReqTypeId + " => " + selfRef.state.historyReqTypeId + "]")

        if (selfRef.state.historyReqTimeSlice !== 0 && selfRef.state.historyReqTypeId !== Constants.API_REQ_TYPE_IDs.MAX_API_REQ_TYPE_ID) {
          selfRef.onClickNavigateHistory(selfRef.state.historyReqTimeSlice, selfRef.state.historyReqTypeId);
        }
      }
    } catch (err) {
      console.log(LOG_TAG.ERR_CATCH, "DidUpdate LBoxClass err[" + err + "]")
    }
  }

  componentWillUnmount() {
    try {
      selfRef.pollStop();
      selfRef._apiTokenSource.cancel('apiRequest cancelled: unmounted!');
      selfRef._isMounted= false;
    } catch (err) {
      console.log(err.message)
    }
  }
  switchToHistory() {
    console.log(LOG_TAG.INFO, "switchToHistory")
    if (selfRef.state.trckViewState === Constants.TRCK_HOME_VIEW_STATE_IDs.HISTORICAL) return;
    selfRef.pollStop();
    selfRef.setState({
      trckViewState: Constants.TRCK_HOME_VIEW_STATE_IDs.HISTORICAL
    })
  }
  onClickSwitchTrckView() {
    console.log(LOG_TAG.INFO, "View state[" + selfRef.state.trckViewState + "]");
    switch (selfRef.state.trckViewState) {
      case Constants.TRCK_HOME_VIEW_STATE_IDs.LIVE:
        console.log(LOG_TAG.INFO, "View is Currently Live!");
        // selfRef.switchToHistory();
        // selfRef.loadHistoryDefault();
        break;
      case Constants.TRCK_HOME_VIEW_STATE_IDs.HISTORICAL:
        console.log(LOG_TAG.INFO, "View is Currently Historical!");
        selfRef.resetState();
        break;
      default:
        break;
    }
  }


  render() {
    const { classes, theme } = selfRef.props;
    let btTxtSwitchTrckView;
    let graphViewHolder;
    let navigateHistory;
    let pageTitleSubtext;
    let summary;
    let locationBoxes;
    console.log(LOG_TAG.LIFE_CYCLE, "LBoxClass render");
    switch (selfRef.state.trckViewState) {
      case Constants.TRCK_HOME_VIEW_STATE_IDs.LIVE:
        console.log(LOG_TAG.INFO, "State LIVE");
        pageTitleSubtext = Constants.LIVE;
        summary = selfRef.state.liveSummary;
        locationBoxes= selfRef.state.liveMetaData.locationBoxes;
        btTxtSwitchTrckView = Constants.GO_LIVE;
        navigateHistory = <NavigateHistory
          btLabels={Constants.NAVIGATE_HISTORY_BT_LABELS}
          btIds={Constants.NAVIGATE_HISTORY_BT_IDs}
          btCb={selfRef.handleUiClick} onSelectDateTime={selfRef.onSelectDateTime} isApiReqOngoing={selfRef.state.isApiReqOngoing} />
        graphViewHolder = <>
          <GraphSummary summary={selfRef.state.graphSummary} />
          <LBoxGraph
            classes={selfRef.props.classes}
            theme={selfRef.props.theme}
            metaData={selfRef.state.liveMetaData}
            tsData={selfRef.state.liveTSData.tSeries}
            eventData={selfRef.state.liveEventData}
          /></>
        break;
      case Constants.TRCK_HOME_VIEW_STATE_IDs.HISTORICAL:
        console.log(LOG_TAG.INFO, "State HISTORICAL");
        pageTitleSubtext = Constants.HISTORICAL;
        summary = selfRef.state.historySummary;
        locationBoxes= selfRef.state.historyMetaData.locationBoxes;
        btTxtSwitchTrckView = Constants.GO_LIVE;
        navigateHistory = <NavigateHistory
          btLabels={Constants.NAVIGATE_HISTORY_BT_LABELS}
          btIds={Constants.NAVIGATE_HISTORY_BT_IDs}
          btCb={selfRef.handleUiClick} onSelectDateTime={selfRef.onSelectDateTime} isApiReqOngoing={selfRef.state.isApiReqOngoing} />
        graphViewHolder = <>
          <GraphSummary selectedDateAndHour={selfRef.state.selectedDateAndHour} summary={selfRef.state.graphSummary} />
          <LBoxGraph
            classes={selfRef.props.classes}
            theme={selfRef.props.theme}
            metaData={selfRef.state.historyMetaData}
            tsData={selfRef.state.historyRenderedTsData}
            eventData={selfRef.state.historyRenderedEventData}
          />
        </>
        break;
      default:
        break;
    }
    return (
      <>
        <div className={classes.pageHeading}>
          <div className={classes.pageTitleSubtext}>
            <h3 className={classes.headingTextBig}> {Constants.PAGE_TITLE_TEXT} {'-'} {selfRef.state.liveMetaData.name}</h3>
            <h3 className={classes.headingTextSmall}> {'-'} {pageTitleSubtext}</h3>
          </div>
          <Button classes={{ root: classes.button }} variant="contained" size="large" color="primary"
            id={Constants.TRCK_HOME_UI_INTERACTIVE_IDs.BT_TRCK_VIEW_SWITCH} onClick={(e) => selfRef.handleUiClick(e)}>
            {btTxtSwitchTrckView}
          </Button>
        </div>
        {/* <Grid container spacing={1} style={{ overflow: 'hidden' }}>
          <Grid item sm={4} xs={12}>
            <HealthSummary summary={summary} />
          </Grid>
          <Grid item sm={4} xs={12}>
            <EventSummary summary={summary} />
          </Grid>
          <Grid item sm={4} xs={12}>
            <LocationSummary locationBoxes={locationBoxes} />
          </Grid>
        </Grid> */}
        <Grid container spacing={1} direction='column'>
          <Grid item sm={12} xs={12}>
            {navigateHistory}
          </Grid>
          <Grid item sm={12} xs={12}>
            <Card className={classes.card}>
              {graphViewHolder}
            </Card>
          </Grid>
        </Grid>
      </>);
  }

  handleUiClick(e) {
    console.log("")
    console.log(LOG_TAG.INFO, "***USER INPUT***")
    console.log(LOG_TAG.INFO, "onClick Id[" + e.currentTarget.id + "]");
    let clickableId = +(e.currentTarget.id);
    switch (clickableId) {
      case Constants.TRCK_HOME_UI_INTERACTIVE_IDs.BT_TRCK_VIEW_SWITCH:
        selfRef.onClickSwitchTrckView();
        break;
      case Constants.TRCK_HOME_UI_INTERACTIVE_IDs.BT_NAVIGATE_HISTORY_30MIN_BEFORE:
        selfRef.switchToHistory();
        if (selfRef.hasHistory === false) selfRef.loadHistoryDefault(true);
        selfRef.onClick30MinBefore()
        break;
      case Constants.TRCK_HOME_UI_INTERACTIVE_IDs.BT_NAVIGATE_HISTORY_05MIN_BEFORE:
        selfRef.switchToHistory();
        if (selfRef.hasHistory === false) selfRef.loadHistoryDefault(true);
        selfRef.onClick05MinBefore()
        break;
      case Constants.TRCK_HOME_UI_INTERACTIVE_IDs.BT_NAVIGATE_HISTORY_05MIN_AFTER:
        selfRef.switchToHistory();
        if (selfRef.hasHistory === false) selfRef.loadHistoryDefault(true);
        selfRef.onClick05MinAfter()
        break;
      case Constants.TRCK_HOME_UI_INTERACTIVE_IDs.BT_NAVIGATE_HISTORY_30MIN_AFTER:
        selfRef.switchToHistory();
        if (selfRef.hasHistory === false) selfRef.loadHistoryDefault(true);
        selfRef.onClick30MinAfter()
        break;
      default:
        break;
    }
  }
  onSelectDateTime(dateTime) {
    try {
      let tempTs = undefined;
      let tempTe = undefined;
      if (dateTime === undefined) {
        //TODO show error message on UI
        console.log(LOG_TAG.ERR_LOGIC, "selectedDateTime:[" + dateTime + "]")
        return;
        // tempTe = (new Date()).getTime();
        // tempTs = tempTe - selfRef._historyViewWindowSize;
      } else {
        console.log("")
        console.log(LOG_TAG.INFO, "***Load Data for  Selected Date and Hour:[" + dateTime + "] ***")
        selfRef.setState({ selectedDateAndHour: dateTime })
        //At this stage Polling is off may still test if polling is off or not
        //TODO get an hour long data
        let selectedDateAndHour = new Date(dateTime);
        selectedDateAndHour.setHours(0);
        selectedDateAndHour.setMinutes(0);
        selectedDateAndHour.setSeconds(0);
        tempTs = selectedDateAndHour.getTime();
        tempTe = tempTs + TIME_WINDOWS_IN_MILLIS.A_DAY;
        if (tempTe > getTodayAt23h59m59s()) {
          console.log(LOG_TAG.ERR_LOGIC, "selectedDateTime:[" + dateTime + "]")
          return;
        }
      }
      selfRef.resetHistoryState();
      selfRef._historyApiHelper.prepReq(selfRef.props.entityId, tempTs, tempTe, Constants.API_REQ_TYPE_IDs.GET_HISTORY_HOUR)
        .sendReq(selfRef._apiTokenSource,
          selfRef.onHistoryApiSuccessCb,
          selfRef.onHistoryApiErrorCb,
          selfRef.onReqInterceptSuccessCb,
          selfRef.onReqInterceptFailureCb,
          selfRef.onRespInterceptSuccessCb,
          selfRef.onRespInterceptFailureCb);
    } catch (error) {
      console.log(error);
    }
  }
  onClick30MinBefore() {
    selfRef.setState({
      historyReqTimeSlice: Constants.THIRTY_MINS_IN_MILLIS,
      historyReqTypeId: Constants.API_REQ_TYPE_IDs.GET_HISTORY_30MIN_BEFORE
    })
    // selfRef.onClickBefore(Constants.THIRTY_MINS_IN_MILLIS, Constants.API_REQ_TYPE_IDs.GET_HISTORY_30MIN_BEFORE);
  }
  onClick05MinBefore() {
    selfRef.setState({
      historyReqTimeSlice: Constants.FIVE_MINS_IN_MILLIS,
      historyReqTypeId: Constants.API_REQ_TYPE_IDs.GET_HISTORY_05MIN_BEFORE
    })
    // selfRef.onClickBefore(Constants.FIVE_MINS_IN_MILLIS, Constants.API_REQ_TYPE_IDs.GET_HISTORY_05MIN_BEFORE);
  }
  onClick05MinAfter() {
    selfRef.setState({
      historyReqTimeSlice: Constants.FIVE_MINS_IN_MILLIS,
      historyReqTypeId: Constants.API_REQ_TYPE_IDs.GET_HISTORY_05MIN_AFTER
    })
    // selfRef.onClickAfter(Constants.FIVE_MINS_IN_MILLIS, Constants.API_REQ_TYPE_IDs.GET_HISTORY_05MIN_AFTER);
  }
  onClick30MinAfter() {
    selfRef.setState({
      historyReqTimeSlice: Constants.THIRTY_MINS_IN_MILLIS,
      historyReqTypeId: Constants.API_REQ_TYPE_IDs.GET_HISTORY_30MIN_AFTER
    })
    // selfRef.onClickAfter(Constants.THIRTY_MINS_IN_MILLIS, Constants.API_REQ_TYPE_IDs.GET_HISTORY_30MIN_AFTER);
  }

  onClickBefore(timeSize, apiReqTypeId) {
    try {
      console.log(LOG_TAG.INFO, "onClickBefore[" + (timeSize / 1000 / 60).toFixed(2) + " mins]");

      console.log(LOG_TAG.INFO,
        "Avaialble data [" + ((selfRef.state.historyDataTe - selfRef.state.historyDataTs) / 1000 / 60).toFixed(2)
        + " mins] window[" + ((selfRef.state.historyWindowTe - selfRef.state.historyWindowTs) / 1000 / 60).toFixed(2) + " mins]")
      console.log(LOG_TAG.INFO,
        "[" + dateCompact(selfRef.state.historyDataTs) + " => " + dateCompact(selfRef.state.historyWindowTs) +
        " <=> " + dateCompact(selfRef.state.historyWindowTe) + " <= " + dateCompact(selfRef.state.historyDataTe) + "]")

      let windowTs = selfRef.state.historyWindowTs;
      let windowTe = selfRef.state.historyWindowTe;
      if (windowTs - timeSize >= selfRef.state.historyDataTs) {//data is available locally
        let tempTs = windowTs - timeSize;
        let tempTe = windowTe - timeSize;

        console.log(LOG_TAG.INFO, "Data available locally ["
          + ((selfRef.state.historyDataTe - selfRef.state.historyDataTs) / 1000 / 60).toFixed(2) + " mins] ["
          + dateCompact(selfRef.state.historyDataTs) + " => " + dateCompact(selfRef.state.historyDataTe) + "]")

        selfRef.updateHistoryData(
          selfRef.state.historyMetaData,
          selfRef.state.historyEventData,
          selfRef.state.historyTSData.tSeries,
          selfRef.state.historyLatestData,
          selfRef.state.historySummary,
          tempTs,
          tempTe);
        return
      }

      let tillTs = selfRef.state.historyDataTs - 1
      if (tillTs <= 0) {
        //TODO show erro on UI
        console.log(LOG_TAG.ERR_LOGIC, "onClickBefore invalid end time")
        return;
      }
      let fromTs = tillTs - timeSize;
      console.log(LOG_TAG.INFO, "Get from[" + dateCompact(fromTs) + "] till[" + dateCompact(tillTs) + "]")
      selfRef._historyApiHelper.prepReq(selfRef.props.entityId, fromTs, tillTs, apiReqTypeId)
        .sendReq(selfRef._apiTokenSource,
          selfRef.onHistoryApiSuccessCb,
          selfRef.onHistoryApiErrorCb,
          selfRef.onReqInterceptSuccessCb,
          selfRef.onReqInterceptFailureCb,
          selfRef.onRespInterceptSuccessCb,
          selfRef.onRespInterceptFailureCb);
    } catch (error) {
      console.log(error);
    }
  }

  onClickNavigateHistory(timeSize, apiReqTypeId) {

    selfRef.setState({
      historyReqTimeSlice: 0,
      historyReqTypeId: Constants.API_REQ_TYPE_IDs.MAX_API_REQ_TYPE_ID
    })
    console.log(LOG_TAG.INFO, "onClickNavigateHistory");
    console.log(LOG_TAG.INFO,
      "Avaialble data [" + ((selfRef.state.historyDataTe - selfRef.state.historyDataTs) / 1000 / 60).toFixed(2)
      + " mins] window[" + ((selfRef.state.historyWindowTe - selfRef.state.historyWindowTs) / 1000 / 60).toFixed(2) + " mins]")
    console.log(LOG_TAG.INFO,
      "[" + dateCompact(selfRef.state.historyDataTs) + " => " + dateCompact(selfRef.state.historyWindowTs) +
      " <=> " + dateCompact(selfRef.state.historyWindowTe) + " <= " + dateCompact(selfRef.state.historyDataTe) + "]")

    let fromTs, tillTs;
    let windowTs = selfRef.state.historyWindowTs;
    let windowTe = selfRef.state.historyWindowTe;

    switch (apiReqTypeId) {
      case Constants.API_REQ_TYPE_IDs.GET_HISTORY_30MIN_BEFORE:
      case Constants.API_REQ_TYPE_IDs.GET_HISTORY_05MIN_BEFORE:
        console.log(LOG_TAG.INFO, "onClickNavigateBackwards[" + (timeSize / 1000 / 60).toFixed(2) + " mins]");
        if (windowTs - timeSize >= selfRef.state.historyDataTs) {//data is available locally
          let tempTs = windowTs - timeSize;
          let tempTe = windowTe - timeSize;

          console.log(LOG_TAG.INFO, "Data available locally ["
            + ((selfRef.state.historyDataTe - selfRef.state.historyDataTs) / 1000 / 60).toFixed(2) + " mins] ["
            + dateCompact(selfRef.state.historyDataTs) + " => " + dateCompact(selfRef.state.historyDataTe) + "]")

          selfRef.updateHistoryData(
            selfRef.state.historyMetaData,
            selfRef.state.historyEventData,
            selfRef.state.historyTSData.tSeries,
            selfRef.state.historyLatestData,
            selfRef.state.historySummary,
            tempTs,
            tempTe);
          return
        }

        tillTs = selfRef.state.historyDataTs - 1
        if (tillTs <= 0) {
          //TODO show erro on UI
          console.log(LOG_TAG.ERR_LOGIC, "onClickBefore invalid end time")
          return;
        }
        fromTs = tillTs - timeSize;
        console.log(LOG_TAG.INFO, "Get from[" + dateCompact(fromTs) + "] till[" + dateCompact(tillTs) + "]")
        selfRef._historyApiHelper.prepReq(selfRef.props.entityId, fromTs, tillTs, apiReqTypeId)
          .sendReq(selfRef._apiTokenSource,
            selfRef.onHistoryApiSuccessCb,
            selfRef.onHistoryApiErrorCb,
            selfRef.onReqInterceptSuccessCb,
            selfRef.onReqInterceptFailureCb,
            selfRef.onRespInterceptSuccessCb,
            selfRef.onRespInterceptFailureCb);
        break;

      case Constants.API_REQ_TYPE_IDs.GET_HISTORY_05MIN_AFTER:
      case Constants.API_REQ_TYPE_IDs.GET_HISTORY_30MIN_AFTER:
        console.log(LOG_TAG.INFO, "onClickNavigateForwards[" + (timeSize / 1000 / 60).toFixed(2) + " mins]");
        let newTe = selfRef.state.historyWindowTe + timeSize;
        if (newTe > (new Date()).getTime()) {
          console.log(LOG_TAG.ERR_LOGIC, "onClickAfter End Time is invalid[" + new Date(newTe) + "]");
          return;
        }

        if (windowTe + timeSize <= selfRef.state.historyDataTe) {//data is available locally
          let tempTs = windowTs + timeSize;
          let tempTe = windowTe + timeSize;

          console.log(LOG_TAG.INFO, "Data available locally ["
            + ((selfRef.state.historyDataTe - selfRef.state.historyDataTs) / 1000 / 60).toFixed(2) + " mins] ["
            + dateCompact(selfRef.state.historyDataTs) + " => " + dateCompact(selfRef.state.historyDataTe) + "]")

          selfRef.updateHistoryData(
            selfRef.state.historyMetaData,
            selfRef.state.historyEventData,
            selfRef.state.historyTSData.tSeries,
            selfRef.state.historyLatestData,
            selfRef.state.historySummary,
            tempTs,
            tempTe);
          return;
        }
        fromTs = selfRef.state.historyDataTe + 1
        if (fromTs <= 0) {
          //TODO show erro on UI
          console.log(LOG_TAG.ERR_LOGIC, "onClickAfter invalid start time")
          return;
        }
        tillTs = fromTs + timeSize;
        console.log(LOG_TAG.INFO, " Get from[" + dateCompact(fromTs) + "] till [" + dateCompact(tillTs) + "]")
        selfRef._historyApiHelper.prepReq(selfRef._apiTokenSource, selfRef.props.entityId, fromTs, tillTs, apiReqTypeId)
          .sendReq(selfRef._apiTokenSource,
            selfRef.onHistoryApiSuccessCb,
            selfRef.onHistoryApiErrorCb,
            selfRef.onReqInterceptSuccessCb,
            selfRef.onReqInterceptFailureCb,
            selfRef.onRespInterceptSuccessCb,
            selfRef.onRespInterceptFailureCb);
        break;
      default:
        break;
    }
  }
  onClickAfter(timeSize, apiReqTypeId) {
    try {
      console.log(LOG_TAG.INFO, "onClickAfter[" + (timeSize / 1000 / 60).toFixed(2) + " mins]");
      let newTe = selfRef.state.historyWindowTe + timeSize;
      if (newTe > (new Date()).getTime()) {
        console.log(LOG_TAG.ERR_LOGIC, "onClickAfter End Time is invalid[" + new Date(newTe) + "]");
        return;
      }

      console.log(LOG_TAG.INFO,
        "Avaialble data [" + ((selfRef.state.historyDataTe - selfRef.state.historyDataTs) / 1000 / 60).toFixed(2)
        + " mins] window[" + ((selfRef.state.historyWindowTe - selfRef.state.historyWindowTs) / 1000 / 60).toFixed(2) + " mins]")
      console.log(LOG_TAG.INFO,
        "[" + dateCompact(selfRef.state.historyDataTs) + " => " + dateCompact(selfRef.state.historyWindowTs) +
        " <=> " + dateCompact(selfRef.state.historyWindowTe) + " <= " + dateCompact(selfRef.state.historyDataTe) + "]")
      let windowTs = selfRef.state.historyWindowTs;
      let windowTe = selfRef.state.historyWindowTe;

      if (windowTe + timeSize <= selfRef.state.historyDataTe) {//data is available locally
        let tempTs = windowTs + timeSize;
        let tempTe = windowTe + timeSize;

        console.log(LOG_TAG.INFO, "Data available locally ["
          + ((selfRef.state.historyDataTe - selfRef.state.historyDataTs) / 1000 / 60).toFixed(2) + " mins] ["
          + dateCompact(selfRef.state.historyDataTs) + " => " + dateCompact(selfRef.state.historyDataTe) + "]")

        selfRef.updateHistoryData(
          selfRef.state.historyMetaData,
          selfRef.state.historyEventData,
          selfRef.state.historyTSData.tSeries,
          selfRef.state.historyLatestData,
          selfRef.state.historySummary,
          tempTs,
          tempTe);
        return
      }
      let fromTs = selfRef.state.historyDataTe + 1
      if (fromTs <= 0) {
        //TODO show erro on UI
        console.log(LOG_TAG.ERR_LOGIC, "onClickAfter invalid start time")
        return;
      }
      let tillTs = fromTs + timeSize;
      console.log(LOG_TAG.INFO, " Get from[" + dateCompact(fromTs) + "] till [" + dateCompact(tillTs) + "]")
      selfRef._historyApiHelper.prepReq(selfRef._apiTokenSource, selfRef.props.entityId, fromTs, tillTs, apiReqTypeId)
        .sendReq(selfRef._apiTokenSource,
          selfRef.onHistoryApiSuccessCb,
          selfRef.onHistoryApiErrorCb,
          selfRef.onReqInterceptSuccessCb,
          selfRef.onReqInterceptFailureCb,
          selfRef.onRespInterceptSuccessCb,
          selfRef.onRespInterceptFailureCb);
    } catch (error) {
      console.log(error);
    }
  }

  resetState() {
    console.log(LOG_TAG.INFO, "resetState");
    try {
      selfRef.hasHistory = false;
      selfRef.setState({
        trckViewState: Constants.TRCK_HOME_VIEW_STATE_IDs.LIVE,

        selectedDateAndHour: dateAndHhMmSsToDateAndHh0000(),
        graphSummary: {},
        isApiReqOngoing: false,

        liveMetaData: ApiGlobal.emptyMetaData,
        liveEventData: [],
        liveTSData: ApiGlobal.emptyTSData,
        liveLatestData: ApiGlobal.emptyLatestData,
        liveDataTs: 0,
        liveDataTe: 0,
        liveWindowTs: 0,
        liveWindowTe: 0,
        liveSummary: {},

        historyMetaData: ApiGlobal.emptyMetaData,
        historyEventData: [],
        historySubEventData: [],
        historyTSData: ApiGlobal.emptyTSData,
        historyLatestData: ApiGlobal.emptyLatestData,
        historyDataTs: 0,
        historyDataTe: 0,
        historyWindowTs: 0,
        historyWindowTe: 0,
        historySummary: {},
        historyRenderedTsData: [],
        historyRenderedEventData: [],
        historyReqTimeSlice: 0,
        historyReqTypeId: Constants.API_REQ_TYPE_IDs.MAX_API_REQ_TYPE_ID
      });
    } catch (err) {
      console.log(LOG_TAG.ERR_CATCH, "resetState failure")
    }
  }
  resetLiveState() {
    console.log("resetLiveState");
    try {
      selfRef.setState({
        trckViewState: Constants.TRCK_HOME_VIEW_STATE_IDs.LIVE,

        selectedDateAndHour: dateAndHhMmSsToDateAndHh0000(),
        graphSummary: {},
        isApiReqOngoing: false,
        liveMetaData: ApiGlobal.emptyMetaData,
        liveEventData: [],
        liveTSData: ApiGlobal.emptyTSData,
        liveLatestData: ApiGlobal.emptyLatestData,
        liveDataTs: 0,
        liveDataTe: 0,
        liveWindowTs: 0,
        liveWindowTe: 0,
        liveSummary: {},

      });
    } catch (err) {
      console.log("resetLiveState failure")
    }
  }
  resetHistoryState() {
    console.log("resetHistoryState");
    try {
      selfRef.setState({
        trckViewState: Constants.TRCK_HOME_VIEW_STATE_IDs.HISTORICAL,

        selectedDateAndHour: dateAndHhMmSsToDateAndHh0000(),
        graphSummary: {},
        isApiReqOngoing: false,
        historyMetaData: ApiGlobal.emptyMetaData,
        historyEventData: [],
        historySubEventData: [],
        historyTSData: ApiGlobal.emptyTSData,
        historyLatestData: ApiGlobal.emptyLatestData,
        historyDataTs: 0,
        historyDataTe: 0,
        historyWindowTs: 0,
        historyWindowTe: 0,
        historySummary: {},
        historyRenderedTsData: [],
        historyRenderedEventData: [],
      });
    } catch (err) {
      console.log("resetHistoryState failure")
    }
  }

  onReqInterceptSuccessCb(config, reqType) {
    // console.log(LOG_TAG.INFO, "onReqInterceptSuccessCb");
    switch (reqType) {
      case Constants.API_REQ_TYPE_IDs.GET_LIVE:
        break;
      case Constants.API_REQ_TYPE_IDs.GET_HISTORY_HOUR:
      case Constants.API_REQ_TYPE_IDs.GET_HISTORY_30MIN_BEFORE:
      case Constants.API_REQ_TYPE_IDs.GET_HISTORY_05MIN_BEFORE:
      case Constants.API_REQ_TYPE_IDs.GET_HISTORY_05MIN_AFTER:
      case Constants.API_REQ_TYPE_IDs.GET_HISTORY_30MIN_AFTER:
        selfRef.setState({ isApiReqOngoing: true });
        break;
      default:
        break;
    }
  }
  onReqInterceptFailureCb(error, reqType) {
    console.log(LOG_TAG.INFO, "onReqInterceptFailureCb");
    switch (reqType) {
      case Constants.API_REQ_TYPE_IDs.GET_LIVE:
        break;
      case Constants.API_REQ_TYPE_IDs.GET_HISTORY_HOUR:
        break;
      case Constants.API_REQ_TYPE_IDs.GET_HISTORY_30MIN_BEFORE:
        break;
      case Constants.API_REQ_TYPE_IDs.GET_HISTORY_05MIN_BEFORE:
        break;
      case Constants.API_REQ_TYPE_IDs.GET_HISTORY_05MIN_AFTER:
        break;
      case Constants.API_REQ_TYPE_IDs.GET_HISTORY_30MIN_AFTER:
        break;
      default:
        break;
    }
  }
  onRespInterceptSuccessCb(response, reqType) {
    // console.log(LOG_TAG.INFO, "onRespInterceptSuccessCb");
    switch (reqType) {
      case Constants.API_REQ_TYPE_IDs.GET_LIVE:
        break;
      case Constants.API_REQ_TYPE_IDs.GET_HISTORY_HOUR:
      case Constants.API_REQ_TYPE_IDs.GET_HISTORY_30MIN_BEFORE:
      case Constants.API_REQ_TYPE_IDs.GET_HISTORY_05MIN_BEFORE:
      case Constants.API_REQ_TYPE_IDs.GET_HISTORY_05MIN_AFTER:
      case Constants.API_REQ_TYPE_IDs.GET_HISTORY_30MIN_AFTER:
        selfRef.setState({ isApiReqOngoing: false });
        break;
      default:
        break;
    }
  }
  onRespInterceptFailureCb(error, reqType) {
    console.log(LOG_TAG.INFO, "onRespInterceptFailureCb");
    switch (reqType) {
      case Constants.API_REQ_TYPE_IDs.GET_LIVE:
        break;
      case Constants.API_REQ_TYPE_IDs.GET_HISTORY_HOUR:

      case Constants.API_REQ_TYPE_IDs.GET_HISTORY_30MIN_BEFORE:

      case Constants.API_REQ_TYPE_IDs.GET_HISTORY_05MIN_BEFORE:

      case Constants.API_REQ_TYPE_IDs.GET_HISTORY_05MIN_AFTER:

      case Constants.API_REQ_TYPE_IDs.GET_HISTORY_30MIN_AFTER:
        selfRef.setState({ isApiReqOngoing: false });
        break;
      default:
        break;
    }
  }
}
