import { AN_HOUR_IN_MILLIS } from "../pages/trck/Constants";

export const timestampToLocaleHhmmss = (timestamp) => {
    //Do something with the input
    let dateObj = new Date(timestamp);
    let hours = dateObj.getHours();
    let minutes = dateObj.getMinutes();
    let seconds = dateObj.getSeconds();
    let tsPostfix = (hours >= 12) ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    let localeHhmmss = hours.toString().padStart(2, '0') + ':' +
        minutes.toString().padStart(2, '0') + ':' +
        seconds.toString().padStart(2, '0') + " " + tsPostfix;
    return localeHhmmss;
};

export const timestampToLocaleHhmmss24 = (timestamp) => {
    //Do something with the input
    let dateObj = new Date(timestamp);
    let hours = dateObj.getHours();
    let minutes = dateObj.getMinutes();
    let seconds = dateObj.getSeconds();
    // let tsPostfix = (hours >= 12) ? "PM" : "AM";
    // hours = hours % 12;
    // hours = hours ? hours : 12; // the hour '0' should be '12'
    let localeHhmmss = hours.toString().padStart(2, '0') + ':' +
        minutes.toString().padStart(2, '0') + ':' +
        seconds.toString().padStart(2, '0');
    return localeHhmmss;
};


export const tStampToLocaleHhmm24 = (timestamp) => {
    //Do something with the input
    let dateObj = new Date(timestamp);
    let hours = dateObj.getHours();
    let minutes = dateObj.getMinutes();
    let seconds = dateObj.getSeconds();
    let tsPostfix = (hours >= 12) ? "PM" : "AM";
    // hours = hours % 12;
    // hours = hours ? hours : 12; // the hour '0' should be '12'
    let localeHhmm = hours.toString().padStart(2, '0') + ':' +
        minutes.toString().padStart(2, '0');
    return localeHhmm;
};
export const timestampToLocaleDateAndHhmmss = (timestamp) => {
    //Do something with the input
    let dateObj = new Date(timestamp);
    // let dayOfMonth= dateObj.toLocaletempString();

    let hours = dateObj.getHours();
    let minutes = dateObj.getMinutes();
    let seconds = dateObj.getSeconds();
    let localeHhmmss = hours.toString().padStart(2, '0') + ':' +
        minutes.toString().padStart(2, '0') + ':' +
        seconds.toString().padStart(2, '0');
    return localeHhmmss;
};

export const dateAndHhMmSsToDateAndHh0000 = (argDateObj) => {
    if(argDateObj!==undefined){
        argDateObj.setMinutes(0);
        argDateObj.setSeconds(0);
    }else{
        argDateObj= new Date();
        argDateObj.setMinutes(0);
        argDateObj.setSeconds(0);
    }
    return argDateObj;
};
export const timestampToDayName = (timestamp) => {
    let dateObj = new Date(timestamp);
    let dayOfWeek = dateObj.getDay()
    let dayName = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
    return dayName[dayOfWeek];
};

export const timestampToDayOfweek = (timestamp) => {
    let dateObj = new Date(timestamp);
    return dateObj.getDay();
};

export const getClosestDisplayableTick = (lessThan, timestamp, multipleOf) => {
    let usTicks = multipleOf;
    let tempUsTicks = timestamp % usTicks;
    if (lessThan) {
        let value = timestamp - tempUsTicks;
        // console.log("actual[" + timestamp + "][" + timestampToLocaleHhmmss(timestamp) + "] less[" +
        //     value + "][" + timestampToLocaleHhmmss(value) + "]");
        return value;
    } else {
        let value = timestamp + (usTicks - tempUsTicks);
        // console.log("actual[" + timestamp + "][" + timestampToLocaleHhmmss(timestamp) + "] more[" +
        //     value + "][" + timestampToLocaleHhmmss(value) + "]");
        return value;
    }

};
export const dateCompact = (val) => {
    if (val === undefined) return "";
    let tempString = new Date(val);
    let tempObj =
        tempString.getFullYear() + "-" +
        ("0" + (tempString.getMonth() + 1)).slice(-2) + "-" +
        ("0" + tempString.getDate()).slice(-2) + " " +
        ("0" + tempString.getHours()).slice(-2) + ":" +
        ("0" + tempString.getMinutes()).slice(-2) + ":" +
        ("0" + tempString.getSeconds()).slice(-2);
    return tempObj;
}
export const shortDateTime = (val) => {

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];
    if (val === undefined) return "";
    let tempString = new Date(val);
    let tempObj =
        ("" +( tempString.getDate())+" "+ (monthNames[tempString.getUTCMonth()])) + ", "+
        ("0" + tempString.getHours()).slice(-2)  +":"+
        ("0" + tempString.getMinutes()).slice(-2);
    return tempObj;
}
export const timeCompact = (val) => {
    if (val === undefined) return "";
    let tempString = new Date(val);
    let tempObj =
        ("0" + tempString.getHours()).slice(-2) + ":" +
        ("0" + tempString.getMinutes()).slice(-2) + ":" +
        ("0" + tempString.getSeconds()).slice(-2);
    return tempObj;
}
export const justAnAlert = () => {
    alert('hello');
};

export const getDate = (val) => {
    if (val === undefined) return "";
    let tempString = new Date(val);
    let tempObj =
        tempString.getFullYear() + "-" +
        ("0" + (tempString.getMonth() + 1)).slice(-2) + "-" +
        ("0" + tempString.getDate()).slice(-2);
    return tempObj;
}

export const getTime = (val) => {
    if (val === undefined) return "";
    let tempString = new Date(val);
    let tempObj =
        ("0" + tempString.getHours()).slice(-2) + ":" +
        ("0" + tempString.getMinutes()).slice(-2) + ":" +
        ("0" + tempString.getSeconds()).slice(-2);
    return tempObj;
}

export const getDurationInHhMmSs = (val) => {
    if (val === undefined) return "";
    let valInSec =Math.floor( val / 1000);
    let s = valInSec % 60;
    let m = Math.floor((valInSec / 60) % 60);
    let h = Math.floor((valInSec / (60 * 60)) % 24);
    let tempObj = "";
    if(h>=1)
        tempObj = tempObj+(h)+ "h ";
    if(m>=1)
        tempObj = tempObj+  (m)+ "m ";
    if(s>=1)
        tempObj = tempObj+ (s)+ "s";
    return tempObj;
}
export const getTodayAt0H0M0S = () =>{
    let todayAt0H0M0S= new Date();
    todayAt0H0M0S.setHours(0);
    todayAt0H0M0S.setMinutes(0);
    todayAt0H0M0S.setSeconds(0);
    return todayAt0H0M0S.getTime();
}
export const getTodayAt23h59m59s = () =>{
    let todayAt0H0M0S= new Date();
    todayAt0H0M0S.setHours(23);
    todayAt0H0M0S.setMinutes(59);
    todayAt0H0M0S.setSeconds(59);
    return todayAt0H0M0S.getTime();
}