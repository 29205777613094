import React from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import SidebarFunctional from "../Sidebar/SidebarFunctional";

// pages
import TrckFunctional from "../../pages/trck/TrckFunctional";
import DashboardFunctional from "../../pages/dashboard/DashboardFunctional";
import LBoxFunctional from '../../pages/lbox/LBoxFunctional'
import UnderConstruction from '../../pages/underconstruction/UnderConstruction'
// context
import { useLayoutState } from "../../context/LayoutContext";

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <SidebarFunctional />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <Switch>
            <Route path="/app/dashboard" component={DashboardFunctional} />
            <Route path="/app/trck/:id/:ts/:te" render={() => <TrckFunctional />} />
            <Route path="/app/trck/:id" render={() => <TrckFunctional />} />
            <Route path="/app/ptmc/:id" render={() => <UnderConstruction />}/>
            <Route path="/app/sgnl/:id" render={() => <UnderConstruction />}/>
            <Route path="/app/lbox/:id/:ts/:te" render={() => <LBoxFunctional />} />
            <Route path="/app/lbox/:id" render={() => <LBoxFunctional />} />} />
          </Switch>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
