import { Divider } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { useGutterBorderedGridStyles } from '@mui-treasury/styles/grid/gutterBordered';
import React from "react";
import useStyles from "../components/styles";
function CardTitle({ trck }) {
    return <>
        <div style={{ backgroundColor: "red" }}>
        </div>
        {trck.name}
    </>
}
function EnvSummary({ summary }) {
    let classes = useStyles();
    const borderedGridStyles = useGutterBorderedGridStyles({
        borderColor: 'rgba(0, 0, 0, 0.08)',
        height: '50%',
    });
    let tempRange = "26-53 °C"//todo: populate from data
    let humiRange = "21-60 %"
    if (summary !== undefined) {
        if (summary.tempMin !== undefined && summary.tempMax !== undefined) tempRange = summary.tempMin + " - " + summary.tempMax;

        if (summary.humiMin !== undefined && summary.humiMax !== undefined) humiRange = summary.humiMin + " - " + summary.humiMax;
    }
    return (
        <Card className={classes.card}>
            <CardContent>
                <h3 className={classes.heading}>{"Environment"}</h3>
                <span className={classes.subheader}>Live Summary</span>
            </CardContent>
            <Divider light />
            <Box display={'flex'}>
                <Box p={2} flex={'auto'} className={borderedGridStyles.item}>
                    <p className={classes.statLabel}>Temperature Range</p>
                    <p className={classes.statValue}>{tempRange}</p>
                </Box>
                <Box p={2} flex={'auto'} className={borderedGridStyles.item}>
                    <p className={classes.statLabel}>Humidity Range</p>
                    <p className={classes.statValue}>{humiRange}</p>
                </Box>
            </Box>
        </Card>
    )
}
export default (EnvSummary);