
import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import * as ApiGlobal from '../../../api/ApiGlobal';
import { dateCompact, getDate, getTime, getDurationInHhMmSs, shortDateTime } from '../../../commonjs/CustomUtils';
import * as Constants from '../Constants';
import { LOG_TAG } from '../../../GlobalConstants';
let selfRef;

const axiosInstanceLive = axios.create({
  baseURL: ApiGlobal.API_BASE_URL,
  timeout: Constants.API_REQ_TIMEOUT,
  headers: { 'Content-Type': 'application/json' },
});

const axiosInstanceHistorical = axios.create({
  baseURL: ApiGlobal.API_BASE_URL,
  timeout: Constants.API_REQ_TIMEOUT,
  headers: { 'Content-Type': 'application/json' },
});


axiosInstanceLive.interceptors.request.use(function (config) {
  // console.log(config);
  selfRef.onReqInterceptSuccessCb(config, config.data.reqType);
  return config;
}, function (error) {
  let data = JSON.parse(error.config.data);
  selfRef.onReqInterceptFailureCb(error,data.reqType);
  return Promise.reject(error);
});

axiosInstanceLive.interceptors.response.use(function (response) {
  let data = JSON.parse(response.config.data);
  selfRef.onRespInterceptSuccessCb(response, data.reqType);
  return response;
}, function (error) {
  let data = JSON.parse(error.config.data);
  selfRef.onRespInterceptFailureCb(error,data.reqType);
  return Promise.reject(error);
});

axiosInstanceHistorical.interceptors.request.use(function (config) {
  selfRef.onReqInterceptSuccessCb(config, config.data.reqType);
  return config;
}, function (error) {
  let data = JSON.parse(error.config.data);
  selfRef.onReqInterceptFailureCb(error,data.reqType);
  return Promise.reject(error);
});

axiosInstanceHistorical.interceptors.response.use(function (response) {
  let data = JSON.parse(response.config.data);
  selfRef.onRespInterceptSuccessCb(response, data.reqType);
  return response;
}, function (error) {
  let data = JSON.parse(error.config.data);
  selfRef.onRespInterceptFailureCb(error,data.reqType);
  return Promise.reject(error);
});
export class ApiHelper {
  constructor() {
    this.req = undefined;
    this.data = undefined;
    this.reqType = Constants.API_REQ_TYPE_IDs.MAX_API_REQ_TYPE_ID;
    this.sendReq = this.sendReq.bind(this);
    this.prepReq = this.prepReq.bind(this);

    this.onApiRespSuccess = this.onApiRespSuccess.bind(this);
    this.onApiRespError = this.onApiRespError.bind(this);
    this.parseApiResponse = this.parseApiResponse.bind(this);
    this.onReqInterceptSuccessCb = undefined;
    this.onReqInterceptFailureCb = undefined;
    this.onRespInterceptSuccessCb = undefined;
    this.onRespInterceptFailureCb = undefined;
    selfRef = this;

  }
  sendReq(apiTokenSource, onSuccess, onFailure,
    onReqInterceptSuccessCb, onReqInterceptFailureCb,
    onRespInterceptSuccessCb, onRespInterceptFailureCb) {
    selfRef.onSuccessCb = onSuccess
    selfRef.onFailureCb = onFailure;
    selfRef.onReqInterceptSuccessCb = onReqInterceptSuccessCb;
    selfRef.onReqInterceptFailureCb = onReqInterceptFailureCb;
    selfRef.onRespInterceptSuccessCb = onRespInterceptSuccessCb;
    selfRef.onRespInterceptFailureCb = onRespInterceptFailureCb;

    try {
      if (selfRef.reqType === Constants.API_REQ_TYPE_IDs.GET_LIVE) {
        axiosInstanceLive.post(ApiGlobal.GET_LOGICAL_ENTITY_DATA, selfRef.data,
          { cancelToken: apiTokenSource.token }).then(response => selfRef.onApiRespSuccess(response))
          .catch(error => selfRef.onApiRespError(error));
      } else {
        axiosInstanceHistorical.post(ApiGlobal.GET_LOGICAL_ENTITY_DATA, selfRef.data,
          apiTokenSource.token).then(response => selfRef.onApiRespSuccess(response))
          .catch(error => selfRef.onApiRespError(error));
      }
    } catch (err) {
      console.log(err.message)
    }
    return selfRef;
  }
  prepReq(entityId, ts, te, reqType) {
    console.log(LOG_TAG.INFO,"***API Call For data***");
    console.log(LOG_TAG.INFO,"ApiHelper prepReq reqType[" + reqType + "] call [" + dateCompact(ts) + " => " + dateCompact(te) + "]");
    selfRef.reqType = reqType;
    selfRef.data = { 'id': entityId, 'ts': ts, 'te': te, 'filter': [0, 1, 2, 3, 4, 5], 'reqType': reqType };
    return selfRef;
  }

  parseApiResponse(apiRespObj) {
    // add latest items at the top of the list
    try {
      if (apiRespObj !== undefined) {
        let tempMetaData = cloneDeep(ApiGlobal.emptyMetaData);
        tempMetaData.id = apiRespObj.id;
        tempMetaData.name = apiRespObj.name;
        tempMetaData.description = apiRespObj.description;
        tempMetaData.entityType = apiRespObj.entityType;
        tempMetaData.signals = apiRespObj.signals.slice();
        tempMetaData.locationBoxes= apiRespObj.locationBoxes;
        let rawTSList = apiRespObj.v.slice();
        if (rawTSList.length > 0) {
          let conditionedTSList = [];
          for (let ctr = rawTSList.length - 1; ctr >= 0; ctr--) {
            let rawTSItem = rawTSList[ctr];
            if (rawTSItem !== undefined) {
              let conditionedTSItem = {};
              conditionedTSItem["t"] = rawTSItem.t;
              for (let signalCtr = 0; signalCtr < tempMetaData.signals.length; signalCtr++) {
                conditionedTSItem[tempMetaData.signals[signalCtr].name] = rawTSItem.v[signalCtr];
              }
              conditionedTSList.push(conditionedTSItem);
            }
          }
          // for (let ctr = conditionedTSList.length - 1; ctr >= 1; ctr--) {
          //   let item = conditionedTSList[ctr];
          //   let nextItem = conditionedTSList[ctr - 1];
          //   for (let signalCtr = 0; signalCtr < tempMetaData.signals.length; signalCtr++) {
          //     let signalName = tempMetaData.signals[signalCtr].name;
          //     if (nextItem[signalName] == null)
          //       nextItem[signalName] = item[signalName];
          //   }
          // }
                    let startTime = (new Date()).getTime();
            for (let signalCtr = 0; signalCtr < tempMetaData.signals.length; signalCtr++) {
              let signalName = tempMetaData.signals[signalCtr].name;
              for (let ctr = 0; ctr <conditionedTSList.length ; ctr++) {
              if(conditionedTSList[ctr][signalName] != null)
                continue;
              let prevCtr=ctr-1;
              let nextCtr=ctr+1;
              while(prevCtr>=0 && conditionedTSList[prevCtr][signalName] == null )
                prevCtr--;
              while(nextCtr<conditionedTSList.length  && conditionedTSList[nextCtr][signalName] == null )
                nextCtr++;
              if(prevCtr<0 && nextCtr>=conditionedTSList.length)
                break;
                if(prevCtr<0)
                conditionedTSList[ctr][signalName]= conditionedTSList[nextCtr][signalName];
              else if( nextCtr>=conditionedTSList.length)
              conditionedTSList[ctr][signalName]= conditionedTSList[prevCtr][signalName];
            else {
// console.log("" +prevCtr+"  "+ conditionedTSList[prevCtr] + "  "  +nextCtr+"  "+ conditionedTSList[nextCtr]);
if(conditionedTSList[nextCtr]== null)
continue;
              let t1=conditionedTSList[prevCtr]["t"];
              let v1=conditionedTSList[prevCtr][signalName];
              let t2=conditionedTSList[nextCtr]["t"];
              let v2=conditionedTSList[nextCtr][signalName];
              let t=conditionedTSList[ctr]["t"];
              let v= v1 + (t-t1) * (v2-v1)/ (t2-t1);
              conditionedTSList[ctr][signalName] = parseFloat(v).toFixed(3);
              // console.log(v1+"\t"+t1+"\t"+v2+"\t"+t2+"\t"+v+"\t"+t);
            }
            }
          }
let endTime = (new Date()).getTime();
// console.log("Filling missing values took "+ (endTime-startTime) + "milliseconds");
          let tempEventData = apiRespObj.events.slice();
          let normal = 0, warning = 0, error = 0, critical = 0;
          if (apiRespObj.events !== undefined && apiRespObj.events.length > 0) {
            for (let eventCtr = 0; eventCtr < tempEventData.length; eventCtr++) {
              let gearEvent = tempEventData[eventCtr];
              gearEvent.ts=gearEvent.ts-5000;
              if (gearEvent.severity === 1) {
                gearEvent["fillColour"] = "green";
                normal = normal + 1;
              } else if (gearEvent.severity === 2) {
                gearEvent["fillColour"] = "yellow";
                warning = warning + 1;
              } else if (gearEvent.severity === 3) {
                gearEvent["fillColour"] = "orange";
                error = error + 1;
              } else if (gearEvent.severity === 4) {
                gearEvent["fillColour"] = "red";
                critical = critical + 1;
              }
              gearEvent["fillOpacity"] = .1;
              if (gearEvent.ts > 0) {
                gearEvent["date"] = shortDateTime(gearEvent.ts);
                // gearEvent["time"] = getTime(gearEvent.ts);
              } else {
                gearEvent["date"] = "_ _ _";
                // gearEvent["time"] = "_ _ _";
              }
              if (gearEvent.te >= gearEvent.ts) {
                gearEvent["duration"] = getDurationInHhMmSs(gearEvent.te - gearEvent.ts);
              }
            }
          }
          let tempLatestData = {};
          for (let signalCtr = 0; signalCtr < tempMetaData.signals.length; signalCtr++) {
            tempLatestData[tempMetaData.signals[signalCtr].name] = apiRespObj.latest.v[signalCtr];
          }
          let summary = {
            "normal": normal,
            "warning": warning,
            "error": error,
            "critical": critical,
            "health": apiRespObj.health,
            "stn": apiRespObj.station, 
            "params": apiRespObj.params
          };
          selfRef.onSuccessCb(tempMetaData, tempEventData, conditionedTSList, tempLatestData, summary, selfRef.reqType);
        }
      }
    } catch (err) {
      console.log(LOG_TAG.ERR_CATCH,err.message);
      //TODO Inform the caller about the error in parsing respinse
      selfRef.onFailureCb(err);
      //selfRef.onSuccessCb(tempMetaData, tempEventData, conditionedTSList, tempLatestData, summary,selfRef.reqType);
    }
  }
  onApiRespSuccess(response) {
    selfRef.parseApiResponse(response.data);
  }

  onApiRespError(error) {
    if (axios.isCancel(error)) {
      console.log(LOG_TAG.ERR_CATCH,'apiRequest cancelled!');
    }
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(LOG_TAG.ERR_CATCH,error.response.data);
      console.log(LOG_TAG.ERR_CATCH,error.response.status);
      console.log(LOG_TAG.ERR_CATCH,error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(LOG_TAG.ERR_CATCH,error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(LOG_TAG.ERR_CATCH, error.message);
    }
    console.log(LOG_TAG.ERR_CATCH,error.config);
    selfRef.onFailureCb(error, selfRef.reqType);
  }

}