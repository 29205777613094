import MomentUtils from '@date-io/moment';
//without the css import the DatePicker doesnt open.
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { dateAndHhMmSsToDateAndHh0000 } from '../../commonjs/CustomUtils';
var onChangeDateTimeParent = undefined;

// var selectedDateTimeLocal = new Date();
export default function PickDate({onChangeDateTime }) {
  //   var classes = useStyles();
  //   var theme = useTheme();

  const [selectedDateTimeLocal, setSelectedDateTimeLocal] = useState(dateAndHhMmSsToDateAndHh0000())
  // if (resetNavigateHistory === true) setSelectedDateTimeLocal(dateAndHhMmSsToDateAndHh0000());
  // console.log("PickDateTime selectedDateTimeLocal["+selectedDateTimeLocal+"]");
  onChangeDateTimeParent = onChangeDateTime;
  //TODO use the useCallbackHook for optimization
  const onChangeDateTimeLocal = (dateTime) => {
    try {
      if (onChangeDateTimeParent === undefined) {
        console.log("onChangeDateTimeParent is undefine dateTime[" + dateTime + "]")
      } else {
        let dateObj = new Date(dateTime);
        if (dateObj.getDate() !== selectedDateTimeLocal.getDate()) {
          console.log("new date selected[" + dateTime + "]")
          setSelectedDateTimeLocal(dateObj);
          onChangeDateTimeParent(dateTime);
        } else {
          setSelectedDateTimeLocal(dateObj);
          // console.log("date selected[" + dateTime + "]")
        }
      }

    } catch (err) {
      console.log("onChangeDateTimeParent is undefined!");
    }
  }

  // console.log('PickDateTime Functional');

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DatePicker
        label="Select a Date"
        inputVariant="filled"
        // value={selectedDateTimeLocal}
        onChange={onChangeDateTimeLocal}
      />
    </MuiPickersUtilsProvider>
  );

  // const [startDate, setStartDate] = useState(new Date());
  // return (
  //   <DatePicker
  //     selected={startDate}
  //     onChange={date => setStartDate(date)}
  //     showTimeSelect
  //     timeFormat="HH:mm"
  //     timeIntervals={60}
  //     timeCaption="time"
  //     dateFormat="MMMM d, yyyy h:mm aa"
  //   />
  // );

}
