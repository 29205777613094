export const PAGE_TITLE_TEXT = "Track Circuit"
export const SUMMERY_CARD_TITLE1 = "Normal"
export const SUMMERY_CARD_TITLE2 = "Warning"
export const SUMMERY_CARD_TITLE3 = "Error"
export const SUMMERY_CARD_TITLE4 = "Critial"
export const GO_LIVE = "Go Live"
export const GO_HISTORICAL = "Go Historical"
export const GRAPHICAL = "Graphical"
export const EVENTS = "Events"
export const HISTORICAL_EVENT_LIST = "List"
export const HISTORICAL_EVENT_GRAPHICAL = "Graphical"
export const LIVE = "Live"
export const HISTORICAL = "Historical"

export const TRCK="trck";
export const PTMC="ptmc";
export const SGNL="sgnl";
let viewStateIdCtr = 0;



// export const ENTITY_TAB_STATE_IDs = {
//   IS_TRCK: (viewStateIdCtr++),
//   IS_PTMC:(viewStateIdCtr++),
//   IS_SGNL:(viewStateIdCtr++),
// }

export const TRCK_HOME_VIEW_STATE_IDs = {
  LIVE: (viewStateIdCtr++),
  HISTORICAL: {
    GRAPHICAL: (viewStateIdCtr++),
    LIST: (viewStateIdCtr++)
  },
}

export const TRCK_GRAPH_STATE_IDs = {
  FULL_RANGE: (viewStateIdCtr++),
  EVENT_RANGE:(viewStateIdCtr++),

}

export const ENTITY_TAB_STATE_IDs = {
  IS_TRCK: (viewStateIdCtr++),
  IS_PTMC:(viewStateIdCtr++),
  IS_SGNL:(viewStateIdCtr++),
}

export const ONGOING_EVENTS_TAB_STATE_IDs = {
  IS_NORMAL: (viewStateIdCtr++),
  IS_WARNING:(viewStateIdCtr++),
  IS_ERROR:(viewStateIdCtr++),
}

export const RESOLVED_EVENTS_TAB_STATE_IDs = {
  IS_NORMAL: (viewStateIdCtr++),
  IS_WARNING:(viewStateIdCtr++),
  IS_ERROR:(viewStateIdCtr++),
}

let uiInteractiveIdCtr = 0;
export const TRCK_HOME_UI_INTERACTIVE_IDs = {
  BT_TRCK_VIEW_SWITCH: (uiInteractiveIdCtr++),
  BT_HISTORICAL_VIEW_SWITCH: (uiInteractiveIdCtr++),
  TRCK_HOME_MAX_INTERACTIVE_ID: (uiInteractiveIdCtr++)
};

export const TRCK_GRAPH_UI_INTERACTIVE_IDs = {
  BT_SHOW_ALL_EVENTS: (uiInteractiveIdCtr++),
  BT_EVENT_LIST_ITEM: (uiInteractiveIdCtr++),
  TRCK_GRAPH_MAX_INTERACTIVE_ID: (uiInteractiveIdCtr++)
};

let apiReqTypeIdCtr = 0;
export const API_REQ_TYPE_IDs = {
  GET_DASHBOARD:(apiReqTypeIdCtr++),
  MAX_API_REQ_TYPE_ID:(apiReqTypeIdCtr++)
};


export const EVENT_TABLE_COLUMNS= [
  { title: 'Name', field: 'name' },
  // { title: 'Description', field: 'description' },
  { title: 'Date', field: 'date' },
  { title: 'Time', field: 'time' },
  { title: 'Duration', field: 'duration' },
  { title: 'Gear', field: 'entityType' },
  // { title: 'Severity', field: 'severity' },
];