import React from 'react';
import useStyle from './styles';
import { Bar, BarChart, CartesianGrid, Label, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

const renderCustomizedLabel = (props) => {
  const { x, y, width, height, value } = props;
  const radius = 10;
  return (
    <g>
      <text fontSize="16" x={x + width / 2} y={y - radius} fill="#666" textAnchor="middle" dominantBaseline="middle" >
        {value}
      </text>
    </g>
  );
};

//'https://jsfiddle.net/alidingling/a5Leskck/'

export default function DoubleBarChart({ height, dataKeys, data }) {
  let styles = useStyle();
  return (
    <ResponsiveContainer width="100%" height={height}>
      <BarChart data={data} margin={{ top: 5, right: 5, left: 5, bottom: 5 }}>
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis dataKey={dataKeys.xKey}
          tick={{ fontSize: 16, fill: "#444" }}
        > </XAxis>
        <Legend verticalAlign="top" align="right" iconSize={20} wrapperStyle={{top: -15}}  />
        <YAxis type="number" allowDecimals={false} tick={{ fontSize: 16, fill: "#444" }} >
          <Label position="insideLeft" angle={-90} dy={0} dx={0} style={{ textAnchor: 'middle', fontSize: 16, fill: "#444" }}>{dataKeys.yLabel} </Label>
        </YAxis>

        <Tooltip
          wrapperStyle={{ backgroundColor: '#FFF' }}
          contentStyle={{ backgroundColor: '#FFF' }}
          itemStyle={{ backgroundColor: '#FFF', fontSize: 16 }}
          labelStyle={{ fill: '#444', fontSize: 16, marginBottom: '0.5rem' }}
        />

        <Bar name={dataKeys.col1Label} dataKey={dataKeys.col1} fill={dataKeys.colors[0]} minPointSize={5} >
          <LabelList dataKey={dataKeys.col1} content={renderCustomizedLabel} />
        </Bar>

        <Bar name={dataKeys.col2Label} dataKey={dataKeys.col2} fill={dataKeys.colors[1]} minPointSize={5} minPointSize={10}>
          <LabelList dataKey={dataKeys.col2} content={renderCustomizedLabel} />
        </Bar>

      </BarChart>
    </ResponsiveContainer>
  );
}
