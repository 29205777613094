export const PAGE_TITLE_TEXT = "Location Box"
export const SUMMARY_CARD_TITLE1 = "Normal"
export const SUMMARY_CARD_TITLE2 = "Warning"
export const SUMMARY_CARD_TITLE3 = "Error"
export const SUMMARY_CARD_TITLE4 = "Critial"
export const GO_LIVE = "Go Live"
export const GO_HISTORICAL = "Go Historical"
export const GRAPHICAL = "Graphical"
export const EVENTS = "Events"
export const HISTORICAL_EVENT_LIST = "List"
export const HISTORICAL_EVENT_GRAPHICAL = "Graphical"
export const LIVE = "Live"
export const HISTORICAL = "Historical"
export const EVENT_TIME_PADDING_IN_MINUTES = 2
export const EVENT_TIME_PADDING_IN_MILLIS = (EVENT_TIME_PADDING_IN_MINUTES* 60 * 1000)

export const FIVE_MINS_IN_MILLIS = 300000;
export const THIRTY_MINS_IN_MILLIS = 1800000;
export const AN_HOUR_IN_MILLIS=3600000;

export const NAVIGATE_HISTORY_BT_LABELS = ["<< 30 Mins", "< 05 Mins", "05 Mins >", "30 Mins >>"];
export const API_REQ_TIMEOUT= 30000;
let viewStateIdCtr = 0;
export const TRCK_HOME_VIEW_STATE_IDs = {
  LIVE: (viewStateIdCtr++),
  HISTORICAL:(viewStateIdCtr++),
  MAX_TRCK_HOME_VIEW_STATE_ID: (viewStateIdCtr++)
}

export const TRCK_GRAPH_STATE_IDs = {
  FULL_RANGE: (viewStateIdCtr++),
  EVENT_RANGE: (viewStateIdCtr++),
  MAX_TRCK_GRPAH_STATE_ID: (viewStateIdCtr++)
}

let uiInteractiveIdCtr = 0;
export const TRCK_HOME_UI_INTERACTIVE_IDs = {
  BT_TRCK_VIEW_SWITCH: (uiInteractiveIdCtr++),
  BT_HISTORICAL_VIEW_SWITCH: (uiInteractiveIdCtr++),
  BT_NAVIGATE_HISTORY_30MIN_BEFORE: (uiInteractiveIdCtr++),
  BT_NAVIGATE_HISTORY_05MIN_BEFORE: (uiInteractiveIdCtr++),
  BT_NAVIGATE_HISTORY_05MIN_AFTER: (uiInteractiveIdCtr++),
  BT_NAVIGATE_HISTORY_30MIN_AFTER: (uiInteractiveIdCtr++),
  MAX_TRCK_HOME_INTERACTIVE_ID: (uiInteractiveIdCtr++)
};

export const NAVIGATE_HISTORY_BT_IDs = [
  TRCK_HOME_UI_INTERACTIVE_IDs.BT_NAVIGATE_HISTORY_30MIN_BEFORE,
  TRCK_HOME_UI_INTERACTIVE_IDs.BT_NAVIGATE_HISTORY_05MIN_BEFORE,
  TRCK_HOME_UI_INTERACTIVE_IDs.BT_NAVIGATE_HISTORY_05MIN_AFTER,
  TRCK_HOME_UI_INTERACTIVE_IDs.BT_NAVIGATE_HISTORY_30MIN_AFTER];

export const TRCK_GRAPH_UI_INTERACTIVE_IDs = {
  BT_SHOW_ALL_EVENTS: (uiInteractiveIdCtr++),
  BT_EVENT_LIST_ITEM: (uiInteractiveIdCtr++),
  MAX_TRCK_GRAPH_INTERACTIVE_ID: (uiInteractiveIdCtr++)
};

let apiReqTypeIdCtr = 0;
export const API_REQ_TYPE_IDs = {
  GET_LIVE: (apiReqTypeIdCtr++),
  GET_HISTORY_HOUR: (apiReqTypeIdCtr++),
  GET_HISTORY_30MIN_BEFORE: (apiReqTypeIdCtr++),
  GET_HISTORY_05MIN_BEFORE: (apiReqTypeIdCtr++),
  GET_HISTORY_05MIN_AFTER: (apiReqTypeIdCtr++),
  GET_HISTORY_30MIN_AFTER: (apiReqTypeIdCtr++),
  GET_EVENT: (apiReqTypeIdCtr++),
  MAX_API_REQ_TYPE_ID: (apiReqTypeIdCtr++)
};
