
import { getDurationInHhMmSs, getDate, getTime, timestampToDayName } from '../../../commonjs/CustomUtils'
import axios from 'axios';
import * as ApiGlobal from '../../../api/ApiGlobal';
import {GEAR_TYPE, SEVERITY}  from '../../../GlobalConstants';

import * as Constants from '../Constants';
import cloneDeep from 'lodash/cloneDeep';
let selfRef;
export class ApiHelper {
  constructor() {
    this.req = undefined;
    this.reqType = Constants.API_REQ_TYPE_IDs.MAX_API_REQ_TYPE_ID;
    this.sendReq = this.sendReq.bind(this);
    this.prepReq = this.prepReq.bind(this);
    this.onApiRespSuccess = this.onApiRespSuccess.bind(this);
    this.onApiRespError = this.onApiRespError.bind(this);
    this.parseApiResponse = this.parseApiResponse.bind(this);
    this.conditionDailyEventData = this.conditionDailyEventData.bind(this);
    this.inflateEventHistory = this.inflateEventHistory.bind(this);
    this.getAggregateDaily = this.getAggregateDaily.bind(this);
    this.getEntityType = this.getEntityType.bind(this);
    this.getDummySummaryObj = this.getDummySummaryObj.bind(this);
    selfRef = this;
  }
  sendReq(onSuccess, onFailure) {
    selfRef.onSuccessCb = onSuccess
    selfRef.onFailureCb = onFailure;
    try {
      selfRef.req.then(response => selfRef.onApiRespSuccess(response))
        .catch(error => selfRef.onApiRespError(error));
    } catch (err) {
      console.log(err.message)
    }
    return selfRef;
  }
  prepReq(apiTokenSource, reqType) {
    console.log("Api reqType[" + reqType + "]");

    selfRef.reqType = reqType;
    selfRef.req = axios({
      method: `${ApiGlobal.AXIOS_METHOD_TYPE_GET}`,
      url: `${ApiGlobal.GET_LOGICAL_DASHBOARD_DATA}`,
      baseURL: `${ApiGlobal.API_BASE_URL}`,
      headers: {
        'Content-Type': 'application/json'
      },
      canceltoken: apiTokenSource.token,
    });
    return selfRef;
  }

  getEntityType(entityList, entityId) {
    let entityType = "";
    for (let ctr = 0; ctr < entityList.length; ctr++) {
      if (entityList[ctr].id === entityId) {
        return entityList[ctr].entityType;
      }
    }
  }

  conditionDailyEventData(weeklyData, dayCtr) {
    let dayInMs = 24 * 60 * 60 * 1000;
    let dailyData = { "day": "", "no": 0, "nc": 0, "wo": 0, "wc": 0, "eo": 0, "ec": 0 };
    dailyData.day = timestampToDayName(weeklyData.date - (dayCtr * dayInMs));
    dailyData.no = weeklyData.normalOngoing[dayCtr];
    dailyData.nc = weeklyData.normalClosed[dayCtr];
    dailyData.wo = weeklyData.warningOngoing[dayCtr];
    dailyData.wc = weeklyData.warningClosed[dayCtr];
    dailyData.eo = weeklyData.errorOngoing[dayCtr];
    dailyData.ec = weeklyData.errorClosed[dayCtr];
    return dailyData;
  }
  getAggregateDaily(trckWeekly, ptmcWeekly, sgnlWeekly) {
    let tempAggregateWeekly = [];
    for (let dayCtr = 0; dayCtr < 7; dayCtr++) {
      let dailyData = { "day": "", "no": 0, "nc": 0, "wo": 0, "wc": 0, "eo": 0, "ec": 0 };
      dailyData.day = trckWeekly[dayCtr].day;
      dailyData.no = trckWeekly[dayCtr].no + ptmcWeekly[dayCtr].no + sgnlWeekly[dayCtr].no;
      dailyData.nc = trckWeekly[dayCtr].nc + ptmcWeekly[dayCtr].nc + sgnlWeekly[dayCtr].nc;
      dailyData.wo = trckWeekly[dayCtr].wo + ptmcWeekly[dayCtr].wo + sgnlWeekly[dayCtr].wo;
      dailyData.wc = trckWeekly[dayCtr].wc + ptmcWeekly[dayCtr].wc + sgnlWeekly[dayCtr].wc;
      dailyData.eo = trckWeekly[dayCtr].eo + ptmcWeekly[dayCtr].eo + sgnlWeekly[dayCtr].eo;
      dailyData.ec = trckWeekly[dayCtr].ec + ptmcWeekly[dayCtr].ec + sgnlWeekly[dayCtr].ec;
      tempAggregateWeekly.push(dailyData);
    }
    return tempAggregateWeekly;
  }
  getAggregateWeekly(eventsWeekly) {
    let aggregate = { "no": 0, "nc": 0, "wo": 0, "wc": 0, "eo": 0, "ec": 0 };
    for (let dayCtr = 0; dayCtr < 7; dayCtr++) {
      aggregate.no = eventsWeekly[dayCtr].no + aggregate.no;
      aggregate.nc = eventsWeekly[dayCtr].nc + aggregate.nc;
      aggregate.wo = eventsWeekly[dayCtr].wo + aggregate.wo;
      aggregate.wc = eventsWeekly[dayCtr].wc + aggregate.wc;
      aggregate.eo = eventsWeekly[dayCtr].eo + aggregate.eo;
      aggregate.ec = eventsWeekly[dayCtr].ec + aggregate.ec;
    }
    return aggregate;
  }
  inflateEventHistory(apiRespObj) {
    try {
      let tempEvents = apiRespObj.eventHistory;
      let apiTrckWeekly = {};
      let apiPtmcWeekly = {};
      let apiSgnlWeekly = {};
      if (tempEvents !== undefined && tempEvents.length > 0) {
        for (let i = 0; i < tempEvents.length; i++) {
          if (tempEvents[i].type === GEAR_TYPE.TRCK) {
            apiTrckWeekly = cloneDeep(tempEvents[i])
          } else if (tempEvents[i].type === GEAR_TYPE.PTMC) {
            apiPtmcWeekly = cloneDeep(tempEvents[i]);
          } else {
            apiSgnlWeekly = cloneDeep(tempEvents[i]);
          }
        }
      }
      let trckWeekly = [];
      let ptmcWeekly = [];
      let sgnlWeekly = [];
      for (let i = 6; i >= 0; i--) {
        trckWeekly.push(selfRef.conditionDailyEventData(apiTrckWeekly, i));
        ptmcWeekly.push(selfRef.conditionDailyEventData(apiPtmcWeekly, i));
        sgnlWeekly.push(selfRef.conditionDailyEventData(apiSgnlWeekly, i));
      }
      let eventsWeekly = {};
      let trck = {};
      trck["aggregate"] = this.getAggregateWeekly(trckWeekly);
      trck["week"] = trckWeekly;
      let ptmc = {};
      ptmc["aggregate"] = this.getAggregateWeekly(ptmcWeekly);
      ptmc["week"] = ptmcWeekly;
      let sgnl = {};
      sgnl["aggregate"] = this.getAggregateWeekly(sgnlWeekly);
      sgnl["week"] = sgnlWeekly;
      let aggregate = selfRef.getAggregateDaily(trck.week, ptmc.week, sgnl.week);
      eventsWeekly[GEAR_TYPE.TRCK] = trck;
      eventsWeekly[GEAR_TYPE.PTMC] = ptmc;
      eventsWeekly[GEAR_TYPE.SGNL] = sgnl;
      eventsWeekly[GEAR_TYPE.ALL] = aggregate;
      return eventsWeekly;
    } catch (err) {
      console.log(err.message);
      return;
    }
  }

  getDummySummaryObj() {
    let summary = {};
    summary[GEAR_TYPE.TRCK] = { "name":"Track Circuits","n": 0, "w": 0, "e": 0 };
    summary[GEAR_TYPE.PTMC] = { "name":"Point Machines","n": 0, "w": 0, "e": 0 };
    summary[GEAR_TYPE.SGNL] = { "name":"Signals","n": 0, "w": 0, "e": 0 };
    return summary;
  }
  parseApiResponse(apiRespObj) {
    // add latest items at the top of the list
    try {
      let entities = apiRespObj.entities;
      let tempTrckList = [];
      let tempPtmcList = [];
      let tempSgnlList = [];
      let entityLinkTrck = "/app/trck/"
      let entityLinkPtmc = "/app/ptmc/"
      let entityLinkSgnl = "/app/sgnl/"
      let entityLinkLbox = "/app/lbox/"

      for (let entityCtr = 0; entityCtr < entities.length; entityCtr++) {
        if (entities[entityCtr].entityType === GEAR_TYPE.TRCK) {
          let tempObj = { ...entities[entityCtr], entityLink: entityLinkTrck + entities[entityCtr].id }
          tempTrckList.push(tempObj);
        } else if (entities[entityCtr].entityType === GEAR_TYPE.PTMC) {
          let tempObj = { ...entities[entityCtr], entityLink: entityLinkPtmc + entities[entityCtr].id }
          tempPtmcList.push(tempObj);
        } else if (entities[entityCtr].entityType === GEAR_TYPE.SGNL) {
          let tempObj = { ...entities[entityCtr], entityLink: entityLinkSgnl + entities[entityCtr].id }
          tempSgnlList.push(tempObj);
        }
      }

      let tempOngoingNormal = [];
      let tempOngoingWarning = [];
      let tempOngoingError = [];

      let tempResolvedNormal = [];
      let tempResolvedWarning = [];
      let tempResolvedError = [];
      let events = apiRespObj.events;
      let nowTs = Date.now();
      let summary = selfRef.getDummySummaryObj();
      for (let eventCtr = 0; eventCtr < events.length; eventCtr++) {
        let tempObj = events[eventCtr];
        tempObj.entityType = selfRef.getEntityType(entities, tempObj.logicalEntityId)
        let gearEvent;
        if(tempObj.te==null)tempObj.te=tempObj.ts+1000000;
        let duration=tempObj.te-tempObj.ts;
        if(duration<2000000){//less than 10 minutes
          duration= 2000000;
        }
        let mid=(tempObj.te+tempObj.ts)/2;
        let start = tempObj.ts - duration/2;
        let end = tempObj.te + duration/2;
        if (tempObj.entityType === GEAR_TYPE.TRCK) {
          gearEvent = { ...tempObj, entityLink: entityLinkTrck + tempObj.logicalEntityId + "/" + start + "/" + end }
        } else if (tempObj.entityType === GEAR_TYPE.PTMC) {
          gearEvent = { ...tempObj, entityLink: entityLinkPtmc + tempObj.logicalEntityId + "/" + start + "/" + end }
        } else if (tempObj.entityType === GEAR_TYPE.SGNL) {
          gearEvent = { ...tempObj, entityLink: entityLinkSgnl + tempObj.logicalEntityId + "/" + start + "/" + end }
        } else if (tempObj.entityType === GEAR_TYPE.LBOX){
          gearEvent = { ...tempObj, entityLink: entityLinkLbox + tempObj.logicalEntityId + "/" + start + "/" + end }
        }

        if (gearEvent.severity === SEVERITY.NORMAL) {
          if (gearEvent.te > nowTs) {//ongoing
            tempOngoingNormal.push(gearEvent);
          } else {
            tempResolvedNormal.push(gearEvent);
          }
        } else if (gearEvent.severity === SEVERITY.WARNING) {
          if (gearEvent.te > nowTs) {//ongoing
            tempOngoingWarning.push(gearEvent);
          } else {
            tempResolvedWarning.push(gearEvent);
          }
        } else if (gearEvent.severity === SEVERITY.ERROR) {
          if (gearEvent.te > nowTs) {//ongoing
            tempOngoingError.push(gearEvent);
          } else {
            tempResolvedError.push(gearEvent);
          }
        }
        if (gearEvent.ts > 0) {
          gearEvent["date"] = getDate(gearEvent.ts);
          gearEvent["time"] = getTime(gearEvent.ts);
        } else {
          gearEvent["date"] = "_ _ _";
          gearEvent["time"] = "_ _ _";
        }
        if (gearEvent.te >= gearEvent.ts) {
          gearEvent["duration"] = getDurationInHhMmSs(gearEvent.te - gearEvent.ts);
        }

        switch (gearEvent.severity) {
          case SEVERITY.NORMAL:
            if (gearEvent.entityType === GEAR_TYPE.TRCK)
              summary[GEAR_TYPE.TRCK].n = (summary[GEAR_TYPE.TRCK].n) + 1;
            else if (gearEvent.entityType === GEAR_TYPE.TRCK)
              summary[GEAR_TYPE.SGNL].n = (summary[GEAR_TYPE.SGNL].n) + 1;
            else if (gearEvent.entityType === GEAR_TYPE.TRCK)
              summary[GEAR_TYPE.PTMC].n = (summary[GEAR_TYPE.PTMC].n) + 1;

            break;
          case SEVERITY.WARNING:
            if (gearEvent.entityType === GEAR_TYPE.TRCK)
              summary[GEAR_TYPE.TRCK].w = (summary[GEAR_TYPE.TRCK].w) + 1;
            else if (gearEvent.entityType === GEAR_TYPE.TRCK)
              summary[GEAR_TYPE.SGNL].w = (summary[GEAR_TYPE.SGNL].w) + 1;
            else if (gearEvent.entityType === GEAR_TYPE.TRCK)
              summary[GEAR_TYPE.PTMC].w = (summary[GEAR_TYPE.PTMC].w) + 1;
            break;
          case SEVERITY.ERROR:
            if (gearEvent.entityType === GEAR_TYPE.TRCK)
              summary[GEAR_TYPE.TRCK].e = (summary[GEAR_TYPE.TRCK].e) + 1;
            else if (gearEvent.entityType === GEAR_TYPE.TRCK)
              summary[GEAR_TYPE.SGNL].e = (summary[GEAR_TYPE.SGNL].e) + 1;
            else if (gearEvent.entityType === GEAR_TYPE.TRCK)
              summary[GEAR_TYPE.PTMC].e = (summary[GEAR_TYPE.PTMC].e) + 1;
            break;
          default:
            break;
        }
      }

      let eventsWeekly = selfRef.inflateEventHistory(apiRespObj);
      selfRef.onSuccessCb(tempTrckList,
        tempPtmcList,
        tempSgnlList,
        tempOngoingNormal,
        tempOngoingWarning,
        tempOngoingError,
        tempResolvedNormal,
        tempResolvedWarning,
        tempResolvedError,
        eventsWeekly,
        summary,
        selfRef.reqType);
    } catch (err) {

      console.log(err.message);
      //TODO Inform the caller about the error in parsing respinse
      selfRef.onFailureCb(err, selfRef.reqType);
      //selfRef.onSuccessCb(tempMetaData, tempEventData, conditionedTSList, tempLatestData, summary,selfRef.reqType);
    }
  }
  onApiRespSuccess(response) {
    selfRef.parseApiResponse(response.data);
  }

  onApiRespError(error) {
    if (axios.isCancel(error)) {
      console.log('apiRequest cancelled!');
    }
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }
    console.log(error.config);
    selfRef.onFailureCb(error, selfRef.reqType);
  }

}