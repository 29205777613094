import { Divider } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import 'rc-checkbox/assets/index.css';
import React from 'react';
import { withRouter } from "react-router-dom";
// components
import * as Constants from '../Constants';
import EventList from '../events/EventList';

var selfRef
class EventTabs extends React.Component {
  constructor(props) {
    super(props);
    this.onSelectResolvedEventTab = this.onSelectResolvedEventTab.bind(this);
    this.state = {
      resolvedEventsTabState: Constants.RESOLVED_EVENTS_TAB_STATE_IDs.IS_NORMAL,
    };
    selfRef = this;
  }

  onSelectResolvedEventTab(state) {
    console.log("setResolvedEventTab[" + state + "]")
    selfRef.setState({ resolvedEventsTabState: state });
  }

  render() {
    let resolvedEventListView
    switch (this.state.resolvedEventsTabState) {
      case Constants.RESOLVED_EVENTS_TAB_STATE_IDs.IS_NORMAL:
        resolvedEventListView = <EventList eventList={this.props.resolvedNormalList} />
        break;
      case Constants.RESOLVED_EVENTS_TAB_STATE_IDs.IS_WARNING:
        resolvedEventListView = <EventList eventList={this.props.resolvedWarningList} />
        break;
      case Constants.RESOLVED_EVENTS_TAB_STATE_IDs.IS_ERROR:
        resolvedEventListView = <EventList eventList={this.props.resolvedErrorList} />
        break;
    }
    console.log("ResolvedEvents render!");
    return (<>

      <Card className={selfRef.props.classes.card}>
        <CardContent>
          <Box display={'flex'} alignItems="left">
            <Box p={1} flex={'auto'} >
              <h3 className={selfRef.props.classes.heading}>{"Events"}</h3>
            </Box>
            <Box p={1} flex={'auto'} >
              {
                <Tabs
                  classes={selfRef.props.muiTabsStyles}
                  value={selfRef.state.resolvedEventsTabState}
                  onChange={(e, value) => this.onSelectResolvedEventTab(value)}
                >
                  <Tab value={Constants.RESOLVED_EVENTS_TAB_STATE_IDs.IS_NORMAL} classes={selfRef.props.muitTabsItemStyles} label={'Normal'} />
                  <Tab value={Constants.RESOLVED_EVENTS_TAB_STATE_IDs.IS_WARNING} classes={selfRef.props.muitTabsItemStyles} label={'Warning'} />
                  <Tab value={Constants.RESOLVED_EVENTS_TAB_STATE_IDs.IS_ERROR} classes={selfRef.props.muitTabsItemStyles} label={'Error'} />
                </Tabs>
              }
            </Box>
          </Box>
        </CardContent>
        <Divider light />
        <CardContent>

          {resolvedEventListView}
        </CardContent>
      </Card>
    </>);
  }
}
export default withRouter(EventTabs);