import React from "react";
import { Divider } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Paginate from "./Paginate";
import * as Constants from '../Constants';
import TrckCard from "./TrckCard.js";
import PtmcCard from "./PtmcCard.js";
import SgnlCard from "./SgnlCard.js";
const styles = theme => ({
  card: {
    borderRadius: 12,
    minWidth: 150,
    textAlign: 'left',
    margin: 5
  },
  heading: {
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: '0.5px',
    marginTop: 4,
    marginBottom: 0,
  },
  root: {
    justifyContent: "center",
    height: "100%",
    width: "100%",
    display: "flex"
  },
  mapDiv: {
    height: "85vh",
    width: "65%",
    display: "inline-block",
    position: "sticky",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  listGridDiv: {
    overflow: "hidden",
    height: "75vh"
  },
  paginageGridDiv: {
    overflow: "hidden",
    height: "10vh"
  },
  parentDiv: {
    display: "center",
    justifyContent: "center",
    height: "100%",
    overflow: "hidden"
  },
  paginationSection: {
    // height: "30px",
    margin: 20
  }
});

const getEntityTypeName = (entityType) => {
  if (entityType === Constants.TRCK) return "Track Circuits"
  if (entityType === Constants.PTMC) return "Point Machines"
  if (entityType === Constants.SGNL) return "Signals"
}
const EntityCard = (entity,entityType) => {
  if (entityType === Constants.TRCK) return <TrckCard entity={entity} />
  if (entityType === Constants.PTMC) return <PtmcCard entity={entity} />
  if (entityType === Constants.SGNL) return <SgnlCard entity={entity} />
}

class EntityGrid extends React.Component {
  state = {
    currentPage: 1,
    hoveredCardId: ""
  };

  handlePageChange = page => {
    this.setState({
      currentPage: page
    });
  };

  setCardMarkerHover = trck => {
    //console.log(trck)
    this.setState({
      hoveredCardId: trck.pageid
    });
  };

  resetCardMarkerHover = () => {
    this.setState({
      hoveredCardId: ""
    });
  };

  render() {
    const { entities, entityType, classes } = this.props;
    const { currentPage } = this.state;
    const resultsPerPage = 6;
    const pageCount = Math.ceil(entities.length / resultsPerPage);
    const total = Math.ceil(entities.length);
    const offset = (currentPage - 1) * resultsPerPage;
    const entitiesSlicedPerPage = entities.slice(
      offset,
      offset + resultsPerPage
    );

    const entityTypeName = getEntityTypeName(entityType)
    return (

      <Card className={classes.card}>
        <CardContent>
          <h3 className={classes.heading}>{entityTypeName}</h3>
        </CardContent>
        <Divider light />
        <CardContent>

          <div className={classes.root}>
            <div className={classes.parentDiv}>
              <Grid
                container
                className={classes.listGridDiv}
                justify="space-evenly"
                spacing={1}
              >
                {entitiesSlicedPerPage.map((entity, index) => (
                  <Grid key={index} item>
                    <TrckCard entity={entity} />
                  </Grid>
                ))}
              </Grid>
              <Grid
                container
                justify="flex-end"
                spacing={1}
              >
                <div className={classes.paginationSection}>
                  <Paginate
                    total={total}
                    resultsPerPage={resultsPerPage}
                    pageCount={pageCount}
                    currentPage={currentPage}
                    handlePageChange={this.handlePageChange}
                  />
                </div>
              </Grid>
            </div>
          </div>
        </CardContent>
      </Card>

    );
  }
}

EntityGrid.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(EntityGrid);