import { Button } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import { useTheme } from "@material-ui/styles";
import { useGutterBorderedGridStyles } from '@mui-treasury/styles/grid/gutterBordered';
import { useFadedShadowStyles } from '@mui-treasury/styles/shadow/faded';
import 'rc-checkbox/assets/index.css';
import React from 'react';
import PickDate from '../PickDateTime/PickDate';
import useStyles from "./styles";
import Skeleton from 'react-loading-skeleton';

function onClickBtCb(e) {
    console.log("default onClickBtCb; please add a callback")
}
function onChangeDateTimeSoft(dateTime) {
    console.log("default onChange; please add Callback. DateTime:[" + dateTime + "]")
}


export default function NavigateHistory({  resetNavigateHistory, btLabels, btIds, btCb, onSelectDateTime, isApiReqOngoing }) {
    var styles = useStyles();
    var theme = useTheme();
    const shadowStyles = useFadedShadowStyles();
    const borderedGridStyles = useGutterBorderedGridStyles({
        borderColor: 'rgba(0, 0, 0, 0.08)',
        height: '30%',
    });
    if (btIds === undefined) btIds = [1, 2, 3, 4];
    if (btLabels === undefined) btLabels = ["btLabel1", "btLabel2", "btLabel3", "btLabel4"];
    if (btCb === undefined) btCb = onClickBtCb;
    if (onSelectDateTime === undefined) onSelectDateTime = onChangeDateTimeSoft;

    let bt1, bt2, bt3, bt4, pickDataTime;
    // console.log('NavigateHistory [' + isApiReqOngoing + "]");

    if (isApiReqOngoing === true) {
        bt1 = <Skeleton duration={2} height={40} width={"50%"}/>
        bt2 = <Skeleton duration={2} height={40} width={"50%"}/>
        bt3 = <Skeleton duration={2} height={40} width={"50%"}/>
        bt4 = <Skeleton duration={2} height={40} width={"50%"}/>
        pickDataTime = <Skeleton duration={2} height={40} width={"60%"}/>
    } else {
        bt1 = <Button classes={{ root: styles.button }}
            variant="contained" size="medium" color="primary"
            id={btIds[0]} onClick={(e) => btCb(e)}
        > {btLabels[0]}
        </Button>

        bt2 = <Button classes={{ root: styles.button }}
            variant="contained" size="medium" color="primary"
            id={btIds[1]} onClick={(e) => btCb(e)}
        > {btLabels[1]}
        </Button>
        bt3 = <Button classes={{ root: styles.button }}
            variant="contained" size="medium" color="primary"
            id={btIds[2]} onClick={(e) => btCb(e)}
        > {btLabels[2]}
        </Button>
        bt4 = <Button classes={{ root: styles.button }}
            variant="contained" size="medium" color="primary"
            id={btIds[3]} onClick={(e) => btCb(e)}
        >{btLabels[3]}
        </Button>
        pickDataTime = <PickDate  resetNavigateHistory={resetNavigateHistory} onChangeDateTime={onSelectDateTime} />
    }
    return (
        <Card className={styles.card}>
            <Box display={'flex'} >
                <Box p={2} flex={'auto'} >
                    {bt1}
                </Box>
                <Box p={2} flex={'auto'} >
                    {bt2}
                </Box>
                <Box p={2} flex={'auto'} >
                    {pickDataTime}
                </Box>
                <Box p={2} flex={'auto'} >
                    {bt3}
                </Box>
                <Box p={2} flex={'auto'} >
                    {bt4}
                </Box>
            </Box>
        </Card>
    );
}