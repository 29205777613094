import React from 'react';
import { useTheme } from "@material-ui/styles";
// components
import SidebarClass from "./SidebarClass";
// styles
import useStyles from "./styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

export default function SidebarFunctional({location}) {
  let classes = useStyles();
  let theme = useTheme();
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  console.log('sidebar function');
  return (
    <>
      <SidebarClass 
        classes={classes}
        theme={theme}
        isSidebarOpened={isSidebarOpened}
        layoutDispatch={layoutDispatch}
        toggleSidebar={toggleSidebar}
        location={location} >
      </SidebarClass>
    </>
  );
}

// #######################################################################
