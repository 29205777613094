export const GREEN = "#8BC34A"//"#4CAF50"
export const YELLOW = "#FFEB3B"//"#FFEB3B"
export const ORANGE = "#FF5722"//"#FF9800"
export const RED = "#ff7043"//"#F44336"
export const OUR_BLUE = "#424572"
export const OUR_RED = "#b70003"
export const OUR_SILVER_GREY = "#EEF5DB"


export const GEAR_TYPE = { "TRCK": "TRACK_CIRCUIT_DC", "PTMC": "POINT_MACHINE", "SGNL": "SIGNAL","LBOX":"LOCATION_BOX","ALL":"ALL" }
export const SEVERITY = { "NORMAL":1, "WARNING":2,"ERROR":3}

export const LOG_TAG = {
    "ERR_STATE": "ERR_STATE",
    "ERR_API": "ERR_API",
    "INFO": "INFO",
    "ERR_CATCH":"ERR_CATCH",
    "LIFE_CYCLE":"LIFE_CYCLE",
    "HOOK":"HOOK",
    "ERR_LOGIC":"ERR_LOGIC"
}

export const TIME_WINDOWS_IN_MILLIS ={
    ONE_SEC: 1000,
    FIVE_SEC: 5000,
    TEN_SEC:10000,
    THIRTY_SEC:30000,
    ONE_MIN : 60000,
    TWO_MIN : 120000,
    FIVE_MINS : 300000,
    THIRTY_MINS: 1800000,
    AN_HOUR: 3600000,
    FOUR_HOURS: 3600000*4,
    SIX_HOURS:3600000*6,
    TWELVE_HOURS:3600000*12,
    A_DAY:3600000*24
  }