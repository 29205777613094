import { Divider } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Launch from "@material-ui/icons/Launch";
import { makeStyles } from '@material-ui/styles';
import { useGutterBorderedGridStyles } from '@mui-treasury/styles/grid/gutterBordered';
import { useFadedShadowStyles } from '@mui-treasury/styles/shadow/faded';
import React from "react";
import { Link as ReactLink } from "react-router-dom";

const useStyles = makeStyles(({ palette }) => ({
  card: {
    borderRadius: 12,
    minWidth: 150,
    textAlign: 'left',
    margin: 5
  },
  heading: {
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: '0.5px',
    marginTop: 4,
    marginBottom: 0,
  },
  headingKey: {
    fontSize: 16,
    color: palette.grey[500],
    fontWeight: 500,
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    margin: 0,
  },
  subheader: {
    fontSize: 14,
    color: palette.grey[500],
    marginBottom: '0.875em',
  },
  statLabel: {
    fontSize: 12,
    color: palette.grey[500],
    fontWeight: 500,
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    margin: 0,
  },
  statValue: {
    fontSize: 20,
    fontWeight: 'bold',
    letterSpacing: '1px',
  },
}));
function TrckCard({ entity }) {
  const styles = useStyles();
  const shadowStyles = useFadedShadowStyles();
  const borderedGridStyles = useGutterBorderedGridStyles({
    borderColor: 'rgba(0, 0, 0, 0.08)',
    height: '30%',
  });
  
  return (
    <Card raised className={styles.card} >
        <Box m={1} flex={'auto'} >
        <div className={styles.heading}>{entity.name}</div>
      </Box>
      <Divider light />
      <Box display={'flex'} alignItems="center">
        <Box p={2} flex={'auto'} className={borderedGridStyles.item} >
          <h3 className={styles.heading}>{entity.health} %</h3>
        </Box>
        <Box p={2} flex={'auto'} className={borderedGridStyles.item}  >
          <p className={styles.statValue}>
            <IconButton aria-label="link" component={entity.entityLink && ReactLink}
              to={entity.entityLink} color="primary" >
              <Launch />
            </IconButton>
          </p>
        </Box>
      </Box>
    </Card>
  )
}
export default (TrckCard);