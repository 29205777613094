import { green, red, yellow } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Launch from '@material-ui/icons/Launch';
import Remove from '@material-ui/icons/Remove';
import Save from '@material-ui/icons/Save';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { useTheme } from "@material-ui/styles";
import { containedTabsStylesHook } from '@mui-treasury/styles/tabs';
import MaterialTable from "material-table";
import React, { forwardRef } from 'react';
import { Link as ReactLink } from "react-router-dom";
import useStyles from "../components/styles";
import * as Constants from '../Constants';

import { SEVERITY}  from '../../../GlobalConstants';


const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  Save: forwardRef((props, ref) => <Save {...props} ref={ref} />),
  Launch: forwardRef((props, ref) => <Launch {...props} ref={ref} />)
};

const EventTabs = ({ classes, value, onSelectResolvedEventTab }) => {
  return (<Tabs
    classes={classes}
    value={value}
    onChange={(e, value) => onSelectResolvedEventTab(value)}
  >
    <Tab value={Constants.RESOLVED_EVENTS_TAB_STATE_IDs.IS_NORMAL} classes={classes.muitTabsItemStyles} label={'Normal'} />
    <Tab value={Constants.RESOLVED_EVENTS_TAB_STATE_IDs.IS_WARNING} classes={classes.muitTabsItemStyles} label={'Warning'} />
    <Tab value={Constants.RESOLVED_EVENTS_TAB_STATE_IDs.IS_ERROR} classes={classes.muitTabsItemStyles} label={'Error'} />
  </Tabs>
  );
}

export default function EventList({ eventList, classes, value, onSelectResolvedEventTab }) {
  var classes = useStyles();
  var theme = useTheme();
  const tabsStyles = containedTabsStylesHook.useTabs();
  const tabItemStyles = containedTabsStylesHook.useTabItem();
  console.log('ResolvedEventList render');
  let isLinkActive = false;
  const tableComponents = {
    Action: props => {
      if (props.data.severity === SEVERITY.NORMAL) {
        return (<IconButton aria-label="link" component={props.data.entityLink && ReactLink}
          to={props.data.entityLink} style={{ color: green[500] }} >
          <Launch />
        </IconButton>)
      }
      if (props.data.severity === SEVERITY.WARNING) {
        return (<IconButton aria-label="link" component={props.data.entityLink && ReactLink}
          to={props.data.entityLink} style={{ color: yellow[500] }} >
          <Launch />
        </IconButton>)
      }
      if (props.data.severity === SEVERITY.ERROR) {
        return (<IconButton aria-label="link" component={props.data.entityLink && ReactLink}
          to={props.data.entityLink} style={{ color: red[500] }} >
          <Launch />
        </IconButton>)
      }
    }
  }
  if (eventList instanceof Array && eventList.length > 0) {
    return (
      <MaterialTable
        title={""}
        icons={tableIcons}
        components={tableComponents}
        columns={Constants.EVENT_TABLE_COLUMNS}
        data={eventList}
        actions={[{
          icon: () => <Launch />,
          tooltip: 'View Event',
          onClick: (event, rowData) => alert("You saved " + rowData.name)
        }]}
      />
    );
  } else {
    return (
      <div className={classes.linearProgress}>
        <LinearProgress variant="query" color="primary" />
      </div>
    );
  }
}
