export const API_BASE_URL = "https://sensegear.3point3labs.com:8003"
// export const API_BASE_URL = "https://54.180.155.86:8003"
// export const API_BASE_URL = "http://192.168.29.21:8003"
//  export const API_BASE_URL = "http://192.168.29.100:8003"
// export const API_BASE_URL = "http://192.168.29.2:8003"

export const GET_PHYSICAL_ENTITY_DATA = "/get_physical_entity_data"
export const GET_PHYSICAL_ENTITIES = "/get_physical_entities"
export const GET_LOGICAL_ENTITY_DATA = "/get_logical_entity_data"
export const GET_LOGICAL_ENTITIES = "/get_logical_entities"
export const GET_LOGICAL_DASHBOARD_DATA = "/get_logical_dashboard_data"

export const GET_PHYSICAL_ENTITY_RAW_PACKETS = "/get_physical_entity_raw_packets"
export const AXIOS_METHOD_TYPE_POST = "post"
export const AXIOS_METHOD_TYPE_GET = "get"

//export default {
export const showFullGraphView = {
  "id": 99999999,
  "name": "See all events",
  "ts": -1,
  "te": -1,
  "details": "Click to see all events",
  "severity": 0,
  "logicalSignalId": -1,
  "logicalTriggerId": -1,
  "logicalEntityId": -1
};

/***************Historical event data *********************** */
export const mockEntityHistoricalEventList = [{
  "id": 8,
  "name": "MinCheckFail",
  "ts": 1577287893000,
  "te": 1577288828000,
  "details": "Event Details",
  "severity": 1,
  "logicalSignalId": 1,
  "logicalTriggerId": 1,
  "logicalEntityId": 1,
  "eventData": {
    "id": 1,
    "name": "TC1",
    "description": "Track Circuit 1 Mathura",
    "entityType": "TRACK_CIRCUIT_DC",
    "status": 1,
    "signals": [
      {
        "id": 1,
        "idx": 0,
        "name": "Vf",
        "dscr": "Feed Voltage"
      },
      {
        "id": 2,
        "idx": 1,
        "name": "Vr",
        "dscr": "Relay Voltage"
      },
      {
        "id": 3,
        "idx": 2,
        "name": "Vt",
        "dscr": "Feed Resistance Drop"
      },
      {
        "id": 4,
        "idx": 3,
        "name": "If",
        "dscr": "Feed Current"
      },
      {
        "id": 5,
        "idx": 4,
        "name": "Ir",
        "dscr": "Relay Current"
      },
      {
        "id": 6,
        "idx": 5,
        "name": "Il",
        "dscr": "Leakage Current"
      }
    ],
    "events": [{
      "id": 8,
      "name": "MinCheckFail",
      "ts": 1577287893000,
      "te": 1577288828000,
      "details": "Event Details",
      "severity": 1,
      "logicalSignalId": 1,
      "logicalTriggerId": 1,
      "logicalEntityId": 1
    },
    ],
    "ts": 1577287893000,
    "te": 1577288828000,
    "v": [

      {
        "t": 1577288828000,
        "v": [
          4.836,
          4.757,
          4.837,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288823000,
        "v": [
          4.836,
          4.759,
          4.838,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288818000,
        "v": [
          4.838,
          4.761,
          4.84,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288814000,
        "v": [
          4.845,
          4.76,
          4.839,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288808000,
        "v": [
          4.837,
          4.761,
          4.84,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288803000,
        "v": [
          4.84,
          4.759,
          4.84,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288798000,
        "v": [
          4.838,
          4.76,
          4.839,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288793000,
        "v": [
          4.839,
          4.758,
          4.838,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288788000,
        "v": [
          4.836,
          4.761,
          4.841,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288783000,
        "v": [
          4.838,
          4.76,
          4.841,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288778000,
        "v": [
          4.836,
          4.763,
          4.842,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288773000,
        "v": [
          4.84,
          4.76,
          4.839,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288768000,
        "v": [
          4.837,
          4.764,
          4.843,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288763000,
        "v": [
          4.838,
          4.763,
          4.844,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288758000,
        "v": [
          4.839,
          4.762,
          4.841,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288754000,
        "v": [
          4.848,
          4.761,
          4.841,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288748000,
        "v": [
          4.84,
          4.761,
          4.842,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288743000,
        "v": [
          4.838,
          4.764,
          4.844,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288738000,
        "v": [
          4.837,
          4.764,
          4.842,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288733000,
        "v": [
          4.836,
          4.76,
          4.841,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288728000,
        "v": [
          4.836,
          4.759,
          4.84,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288723000,
        "v": [
          4.836,
          4.76,
          4.839,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288718000,
        "v": [
          4.836,
          4.76,
          4.843,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288713000,
        "v": [
          4.836,
          4.759,
          4.84,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288708000,
        "v": [
          4.836,
          4.763,
          4.841,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288703000,
        "v": [
          4.837,
          4.762,
          4.842,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288699000,
        "v": [
          4.843,
          4.748,
          4.84,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288694000,
        "v": [
          4.84,
          4.775,
          4.839,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288688000,
        "v": [
          4.84,
          4.761,
          4.84,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288683000,
        "v": [
          4.839,
          4.759,
          4.838,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288678000,
        "v": [
          4.836,
          4.763,
          4.842,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288673000,
        "v": [
          4.838,
          4.762,
          4.842,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288668000,
        "v": [
          4.835,
          4.763,
          4.839,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288663000,
        "v": [
          4.836,
          4.761,
          4.841,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288658000,
        "v": [
          4.837,
          4.761,
          4.84,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288653000,
        "v": [
          4.833,
          4.759,
          4.84,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288648000,
        "v": [
          4.834,
          4.759,
          4.838,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288643000,
        "v": [
          4.834,
          4.759,
          4.84,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288638000,
        "v": [
          4.835,
          4.76,
          4.841,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288634000,
        "v": [
          4.84,
          4.759,
          4.84,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288628000,
        "v": [
          4.836,
          4.762,
          4.84,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288623000,
        "v": [
          4.834,
          4.759,
          4.837,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288618000,
        "v": [
          4.832,
          4.76,
          4.838,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288613000,
        "v": [
          4.834,
          4.757,
          4.836,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288608000,
        "v": [
          4.831,
          4.758,
          4.837,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288603000,
        "v": [
          4.836,
          4.758,
          4.835,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288598000,
        "v": [
          4.834,
          4.762,
          4.841,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288593000,
        "v": [
          4.836,
          4.759,
          4.837,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288588000,
        "v": [
          4.835,
          4.759,
          4.839,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288583000,
        "v": [
          4.838,
          4.76,
          4.84,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288578000,
        "v": [
          4.84,
          4.762,
          4.845,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288574000,
        "v": [
          4.847,
          4.762,
          4.84,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288568000,
        "v": [
          4.841,
          4.761,
          4.842,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288563000,
        "v": [
          4.837,
          4.766,
          4.845,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288558000,
        "v": [
          4.837,
          4.763,
          4.844,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288553000,
        "v": [
          4.838,
          4.766,
          4.846,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288548000,
        "v": [
          4.841,
          4.762,
          4.842,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288543000,
        "v": [
          4.837,
          4.766,
          4.844,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288538000,
        "v": [
          4.842,
          4.762,
          4.842,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288533000,
        "v": [
          4.838,
          4.767,
          4.842,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288528000,
        "v": [
          4.838,
          4.761,
          4.84,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288523000,
        "v": [
          4.837,
          4.764,
          4.842,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288518000,
        "v": [
          4.838,
          4.761,
          4.843,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288514000,
        "v": [
          4.843,
          4.761,
          4.845,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288509000,
        "v": [
          4.84,
          4.763,
          4.843,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288504000,
        "v": [
          4.839,
          4.762,
          4.845,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288498000,
        "v": [
          4.839,
          4.761,
          4.843,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288493000,
        "v": [
          4.839,
          4.761,
          4.841,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288488000,
        "v": [
          4.836,
          4.763,
          4.843,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288483000,
        "v": [
          4.837,
          4.766,
          4.843,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288478000,
        "v": [
          4.839,
          4.761,
          4.84,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288473000,
        "v": [
          4.838,
          4.759,
          4.841,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288468000,
        "v": [
          4.839,
          4.762,
          4.841,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288463000,
        "v": [
          4.838,
          4.762,
          4.841,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288458000,
        "v": [
          4.838,
          4.761,
          4.841,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288454000,
        "v": [
          4.845,
          4.761,
          4.841,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288448000,
        "v": [
          4.837,
          4.76,
          4.843,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288443000,
        "v": [
          4.84,
          4.761,
          4.839,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288438000,
        "v": [
          4.837,
          4.762,
          4.842,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288433000,
        "v": [
          4.838,
          4.761,
          4.842,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288428000,
        "v": [
          4.837,
          4.759,
          4.839,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288423000,
        "v": [
          4.837,
          4.761,
          4.84,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288418000,
        "v": [
          4.837,
          4.762,
          4.842,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288413000,
        "v": [
          4.835,
          4.76,
          4.841,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288408000,
        "v": [
          4.836,
          4.76,
          4.84,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288403000,
        "v": [
          4.838,
          4.759,
          4.84,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288398000,
        "v": [
          4.841,
          4.762,
          4.842,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288393000,
        "v": [
          4.845,
          4.771,
          4.844,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288388000,
        "v": [
          4.837,
          4.765,
          4.843,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288383000,
        "v": [
          4.834,
          4.762,
          4.839,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288378000,
        "v": [
          4.838,
          4.764,
          4.843,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288373000,
        "v": [
          4.841,
          4.764,
          4.843,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288368000,
        "v": [
          4.839,
          4.767,
          4.847,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288363000,
        "v": [
          4.84,
          4.764,
          4.843,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288358000,
        "v": [
          4.843,
          4.766,
          4.846,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288353000,
        "v": [
          4.842,
          4.767,
          4.849,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288348000,
        "v": [
          4.844,
          4.766,
          4.845,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288343000,
        "v": [
          4.844,
          4.767,
          4.847,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288338000,
        "v": [
          4.839,
          4.765,
          4.845,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288334000,
        "v": [
          4.851,
          4.762,
          4.844,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288328000,
        "v": [
          4.839,
          4.765,
          4.844,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288323000,
        "v": [
          4.84,
          4.762,
          4.842,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288318000,
        "v": [
          4.838,
          4.763,
          4.841,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288313000,
        "v": [
          4.839,
          4.76,
          4.84,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288308000,
        "v": [
          4.839,
          4.761,
          4.841,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288303000,
        "v": [
          4.842,
          4.762,
          4.842,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288298000,
        "v": [
          4.841,
          4.76,
          4.841,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288293000,
        "v": [
          4.839,
          4.763,
          4.843,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288288000,
        "v": [
          4.837,
          4.762,
          4.844,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288283000,
        "v": [
          4.839,
          4.762,
          4.84,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288278000,
        "v": [
          4.838,
          4.759,
          4.839,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288274000,
        "v": [
          4.845,
          4.761,
          4.841,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288268000,
        "v": [
          4.838,
          4.76,
          4.842,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288263000,
        "v": [
          4.837,
          4.762,
          4.843,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288258000,
        "v": [
          4.835,
          4.759,
          4.839,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288253000,
        "v": [
          4.834,
          4.76,
          4.84,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288248000,
        "v": [
          4.833,
          4.758,
          4.84,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288243000,
        "v": [
          4.835,
          4.756,
          4.836,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288238000,
        "v": [
          4.837,
          4.758,
          4.837,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288233000,
        "v": [
          4.83,
          4.757,
          4.837,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288228000,
        "v": [
          4.832,
          4.756,
          4.836,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288223000,
        "v": [
          4.832,
          4.76,
          4.839,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288218000,
        "v": [
          4.836,
          4.757,
          4.838,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288213000,
        "v": [
          4.838,
          4.759,
          4.836,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288208000,
        "v": [
          4.834,
          4.758,
          4.836,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288203000,
        "v": [
          4.833,
          4.755,
          4.834,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288198000,
        "v": [
          4.834,
          4.755,
          4.835,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288193000,
        "v": [
          4.833,
          4.756,
          4.836,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288188000,
        "v": [
          4.833,
          4.757,
          4.836,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288183000,
        "v": [
          4.832,
          4.757,
          4.839,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288178000,
        "v": [
          4.831,
          4.758,
          4.838,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288173000,
        "v": [
          4.831,
          4.758,
          4.837,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288168000,
        "v": [
          4.835,
          4.756,
          4.835,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288163000,
        "v": [
          4.834,
          4.757,
          4.837,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288158000,
        "v": [
          4.83,
          4.758,
          4.839,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288154000,
        "v": [
          4.839,
          4.76,
          4.837,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288148000,
        "v": [
          4.833,
          4.759,
          4.842,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288143000,
        "v": [
          4.833,
          4.759,
          4.84,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288138000,
        "v": [
          4.835,
          4.757,
          4.838,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288133000,
        "v": [
          4.83,
          4.756,
          4.838,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288128000,
        "v": [
          4.835,
          4.759,
          4.838,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288123000,
        "v": [
          4.833,
          4.759,
          4.841,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288118000,
        "v": [
          4.833,
          4.758,
          4.836,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288113000,
        "v": [
          4.833,
          4.756,
          4.835,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288108000,
        "v": [
          4.833,
          4.756,
          4.837,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288103000,
        "v": [
          4.831,
          4.757,
          4.836,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288098000,
        "v": [
          4.837,
          4.748,
          4.839,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288093000,
        "v": [
          4.838,
          4.774,
          4.839,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288088000,
        "v": [
          4.837,
          4.759,
          4.839,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288083000,
        "v": [
          4.834,
          4.76,
          4.84,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288078000,
        "v": [
          4.833,
          4.76,
          4.84,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288073000,
        "v": [
          4.837,
          4.759,
          4.838,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288068000,
        "v": [
          4.835,
          4.761,
          4.84,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288063000,
        "v": [
          4.834,
          4.762,
          4.839,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288058000,
        "v": [
          4.835,
          4.762,
          4.841,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288053000,
        "v": [
          4.833,
          4.757,
          4.837,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288048000,
        "v": [
          4.834,
          4.755,
          4.835,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288043000,
        "v": [
          4.834,
          4.762,
          4.839,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288038000,
        "v": [
          4.833,
          4.758,
          4.838,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288034000,
        "v": [
          4.842,
          4.754,
          4.833,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288027000,
        "v": [
          4.833,
          4.754,
          4.833,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288022000,
        "v": [
          4.834,
          4.755,
          4.834,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288018000,
        "v": [
          4.833,
          4.755,
          4.835,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288013000,
        "v": [
          4.835,
          4.756,
          4.835,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288008000,
        "v": [
          4.835,
          4.755,
          4.835,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288003000,
        "v": [
          4.832,
          4.755,
          4.835,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577287998000,
        "v": [
          4.833,
          4.758,
          4.835,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577287993000,
        "v": [
          4.835,
          4.756,
          4.835,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577287988000,
        "v": [
          4.83,
          4.757,
          4.836,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577287983000,
        "v": [
          4.829,
          4.755,
          4.835,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577287978000,
        "v": [
          4.83,
          4.757,
          4.838,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577287974000,
        "v": [
          4.838,
          4.751,
          4.834,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577287968000,
        "v": [
          4.831,
          4.755,
          4.833,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577287963000,
        "v": [
          4.829,
          4.756,
          4.836,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577287958000,
        "v": [
          4.832,
          4.754,
          4.835,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577287953000,
        "v": [
          4.832,
          4.758,
          4.836,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577287948000,
        "v": [
          4.833,
          4.755,
          4.834,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577287943000,
        "v": [
          4.831,
          4.756,
          4.836,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577287938000,
        "v": [
          4.828,
          4.754,
          4.836,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577287933000,
        "v": [
          4.83,
          4.752,
          4.831,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577287928000,
        "v": [
          4.83,
          4.752,
          4.831,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577287923000,
        "v": [
          4.827,
          4.754,
          4.832,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577287918000,
        "v": [
          4.832,
          4.754,
          4.833,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577287914000,
        "v": [
          4.835,
          4.753,
          4.832,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577287908000,
        "v": [
          4.826,
          4.753,
          4.831,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577287903000,
        "v": [
          4.829,
          4.751,
          4.831,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577287898000,
        "v": [
          4.828,
          4.757,
          4.838,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577287893000,
        "v": [
          4.828,
          4.753,
          4.832,
          0.029,
          0.0,
          null
        ]
      },

    ],
    "latest": {
      "t": null,
      "v": [
        4.835,
        4.758,
        4.837,
        0.028,
        0.0,
        null
      ]
    }
  }
},
{
  "id": 7,
  "name": "MinCheckFail",
  "ts": 1577287893000,
  "te": 1577288658000,
  "details": "Event Details",
  "severity": 1,
  "logicalSignalId": 1,
  "logicalTriggerId": 1,
  "logicalEntityId": 1,
  eventData: {
    "id": 1,
    "name": "TC1",
    "description": "Track Circuit 1 Mathura",
    "entityType": "TRACK_CIRCUIT_DC",
    "status": 1,
    "signals": [
      {
        "id": 1,
        "idx": 0,
        "name": "Vf",
        "dscr": "Feed Voltage"
      },
      {
        "id": 2,
        "idx": 1,
        "name": "Vr",
        "dscr": "Relay Voltage"
      },
      {
        "id": 3,
        "idx": 2,
        "name": "Vt",
        "dscr": "Feed Resistance Drop"
      },
      {
        "id": 4,
        "idx": 3,
        "name": "If",
        "dscr": "Feed Current"
      },
      {
        "id": 5,
        "idx": 4,
        "name": "Ir",
        "dscr": "Relay Current"
      },
      {
        "id": 6,
        "idx": 5,
        "name": "Il",
        "dscr": "Leakage Current"
      }
    ],
    "events": [
      {
        "id": 7,
        "name": "MinCheckFail",
        "ts": 1577288658000,
        "te": 1577288758000,
        "details": "Event Details",
        "severity": 1,
        "logicalSignalId": 1,
        "logicalTriggerId": 1,
        "logicalEntityId": 1
      }],
    "ts": 1577287893000,
    "te": 1577288658000,
    "v": [
      {
        "t": 1577288658000,
        "v": [
          4.837,
          4.761,
          4.84,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288653000,
        "v": [
          4.833,
          4.759,
          4.84,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288648000,
        "v": [
          4.834,
          4.759,
          4.838,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288643000,
        "v": [
          4.834,
          4.759,
          4.84,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288638000,
        "v": [
          4.835,
          4.76,
          4.841,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288634000,
        "v": [
          4.84,
          4.759,
          4.84,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288628000,
        "v": [
          4.836,
          4.762,
          4.84,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288623000,
        "v": [
          4.834,
          4.759,
          4.837,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288618000,
        "v": [
          4.832,
          4.76,
          4.838,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288613000,
        "v": [
          4.834,
          4.757,
          4.836,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288608000,
        "v": [
          4.831,
          4.758,
          4.837,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288603000,
        "v": [
          4.836,
          4.758,
          4.835,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288598000,
        "v": [
          4.834,
          4.762,
          4.841,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288593000,
        "v": [
          4.836,
          4.759,
          4.837,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288588000,
        "v": [
          4.835,
          4.759,
          4.839,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288583000,
        "v": [
          4.838,
          4.76,
          4.84,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288578000,
        "v": [
          4.84,
          4.762,
          4.845,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288574000,
        "v": [
          4.847,
          4.762,
          4.84,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288568000,
        "v": [
          4.841,
          4.761,
          4.842,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288563000,
        "v": [
          4.837,
          4.766,
          4.845,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288558000,
        "v": [
          4.837,
          4.763,
          4.844,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288553000,
        "v": [
          4.838,
          4.766,
          4.846,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288548000,
        "v": [
          4.841,
          4.762,
          4.842,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288543000,
        "v": [
          4.837,
          4.766,
          4.844,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288538000,
        "v": [
          4.842,
          4.762,
          4.842,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288533000,
        "v": [
          4.838,
          4.767,
          4.842,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288528000,
        "v": [
          4.838,
          4.761,
          4.84,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288523000,
        "v": [
          4.837,
          4.764,
          4.842,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288518000,
        "v": [
          4.838,
          4.761,
          4.843,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288514000,
        "v": [
          4.843,
          4.761,
          4.845,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288509000,
        "v": [
          4.84,
          4.763,
          4.843,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288504000,
        "v": [
          4.839,
          4.762,
          4.845,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288498000,
        "v": [
          4.839,
          4.761,
          4.843,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288493000,
        "v": [
          4.839,
          4.761,
          4.841,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288488000,
        "v": [
          4.836,
          4.763,
          4.843,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288483000,
        "v": [
          4.837,
          4.766,
          4.843,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288478000,
        "v": [
          4.839,
          4.761,
          4.84,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288473000,
        "v": [
          4.838,
          4.759,
          4.841,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288468000,
        "v": [
          4.839,
          4.762,
          4.841,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288463000,
        "v": [
          4.838,
          4.762,
          4.841,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288458000,
        "v": [
          4.838,
          4.761,
          4.841,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288454000,
        "v": [
          4.845,
          4.761,
          4.841,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288448000,
        "v": [
          4.837,
          4.76,
          4.843,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288443000,
        "v": [
          4.84,
          4.761,
          4.839,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288438000,
        "v": [
          4.837,
          4.762,
          4.842,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288433000,
        "v": [
          4.838,
          4.761,
          4.842,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288428000,
        "v": [
          4.837,
          4.759,
          4.839,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288423000,
        "v": [
          4.837,
          4.761,
          4.84,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288418000,
        "v": [
          4.837,
          4.762,
          4.842,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288413000,
        "v": [
          4.835,
          4.76,
          4.841,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288408000,
        "v": [
          4.836,
          4.76,
          4.84,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288403000,
        "v": [
          4.838,
          4.759,
          4.84,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288398000,
        "v": [
          4.841,
          4.762,
          4.842,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288393000,
        "v": [
          4.845,
          4.771,
          4.844,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288388000,
        "v": [
          4.837,
          4.765,
          4.843,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288383000,
        "v": [
          4.834,
          4.762,
          4.839,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288378000,
        "v": [
          4.838,
          4.764,
          4.843,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288373000,
        "v": [
          4.841,
          4.764,
          4.843,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288368000,
        "v": [
          4.839,
          4.767,
          4.847,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288363000,
        "v": [
          4.84,
          4.764,
          4.843,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288358000,
        "v": [
          4.843,
          4.766,
          4.846,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288353000,
        "v": [
          4.842,
          4.767,
          4.849,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288348000,
        "v": [
          4.844,
          4.766,
          4.845,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288343000,
        "v": [
          4.844,
          4.767,
          4.847,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288338000,
        "v": [
          4.839,
          4.765,
          4.845,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288334000,
        "v": [
          4.851,
          4.762,
          4.844,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288328000,
        "v": [
          4.839,
          4.765,
          4.844,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288323000,
        "v": [
          4.84,
          4.762,
          4.842,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288318000,
        "v": [
          4.838,
          4.763,
          4.841,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288313000,
        "v": [
          4.839,
          4.76,
          4.84,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288308000,
        "v": [
          4.839,
          4.761,
          4.841,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288303000,
        "v": [
          4.842,
          4.762,
          4.842,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288298000,
        "v": [
          4.841,
          4.76,
          4.841,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288293000,
        "v": [
          4.839,
          4.763,
          4.843,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288288000,
        "v": [
          4.837,
          4.762,
          4.844,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288283000,
        "v": [
          4.839,
          4.762,
          4.84,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288278000,
        "v": [
          4.838,
          4.759,
          4.839,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288274000,
        "v": [
          4.845,
          4.761,
          4.841,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288268000,
        "v": [
          4.838,
          4.76,
          4.842,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288263000,
        "v": [
          4.837,
          4.762,
          4.843,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288258000,
        "v": [
          4.835,
          4.759,
          4.839,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288253000,
        "v": [
          4.834,
          4.76,
          4.84,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288248000,
        "v": [
          4.833,
          4.758,
          4.84,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288243000,
        "v": [
          4.835,
          4.756,
          4.836,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288238000,
        "v": [
          4.837,
          4.758,
          4.837,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288233000,
        "v": [
          4.83,
          4.757,
          4.837,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288228000,
        "v": [
          4.832,
          4.756,
          4.836,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288223000,
        "v": [
          4.832,
          4.76,
          4.839,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288218000,
        "v": [
          4.836,
          4.757,
          4.838,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288213000,
        "v": [
          4.838,
          4.759,
          4.836,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288208000,
        "v": [
          4.834,
          4.758,
          4.836,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288203000,
        "v": [
          4.833,
          4.755,
          4.834,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288198000,
        "v": [
          4.834,
          4.755,
          4.835,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288193000,
        "v": [
          4.833,
          4.756,
          4.836,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288188000,
        "v": [
          4.833,
          4.757,
          4.836,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288183000,
        "v": [
          4.832,
          4.757,
          4.839,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288178000,
        "v": [
          4.831,
          4.758,
          4.838,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288173000,
        "v": [
          4.831,
          4.758,
          4.837,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288168000,
        "v": [
          4.835,
          4.756,
          4.835,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288163000,
        "v": [
          4.834,
          4.757,
          4.837,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288158000,
        "v": [
          4.83,
          4.758,
          4.839,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288154000,
        "v": [
          4.839,
          4.76,
          4.837,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288148000,
        "v": [
          4.833,
          4.759,
          4.842,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288143000,
        "v": [
          4.833,
          4.759,
          4.84,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288138000,
        "v": [
          4.835,
          4.757,
          4.838,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288133000,
        "v": [
          4.83,
          4.756,
          4.838,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288128000,
        "v": [
          4.835,
          4.759,
          4.838,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288123000,
        "v": [
          4.833,
          4.759,
          4.841,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288118000,
        "v": [
          4.833,
          4.758,
          4.836,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288113000,
        "v": [
          4.833,
          4.756,
          4.835,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288108000,
        "v": [
          4.833,
          4.756,
          4.837,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288103000,
        "v": [
          4.831,
          4.757,
          4.836,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288098000,
        "v": [
          4.837,
          4.748,
          4.839,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288093000,
        "v": [
          4.838,
          4.774,
          4.839,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288088000,
        "v": [
          4.837,
          4.759,
          4.839,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288083000,
        "v": [
          4.834,
          4.76,
          4.84,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288078000,
        "v": [
          4.833,
          4.76,
          4.84,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288073000,
        "v": [
          4.837,
          4.759,
          4.838,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288068000,
        "v": [
          4.835,
          4.761,
          4.84,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288063000,
        "v": [
          4.834,
          4.762,
          4.839,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288058000,
        "v": [
          4.835,
          4.762,
          4.841,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288053000,
        "v": [
          4.833,
          4.757,
          4.837,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288048000,
        "v": [
          4.834,
          4.755,
          4.835,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288043000,
        "v": [
          4.834,
          4.762,
          4.839,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288038000,
        "v": [
          4.833,
          4.758,
          4.838,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288034000,
        "v": [
          4.842,
          4.754,
          4.833,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288027000,
        "v": [
          4.833,
          4.754,
          4.833,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288022000,
        "v": [
          4.834,
          4.755,
          4.834,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288018000,
        "v": [
          4.833,
          4.755,
          4.835,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288013000,
        "v": [
          4.835,
          4.756,
          4.835,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577288008000,
        "v": [
          4.835,
          4.755,
          4.835,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577288003000,
        "v": [
          4.832,
          4.755,
          4.835,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577287998000,
        "v": [
          4.833,
          4.758,
          4.835,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577287993000,
        "v": [
          4.835,
          4.756,
          4.835,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577287988000,
        "v": [
          4.83,
          4.757,
          4.836,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577287983000,
        "v": [
          4.829,
          4.755,
          4.835,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577287978000,
        "v": [
          4.83,
          4.757,
          4.838,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577287974000,
        "v": [
          4.838,
          4.751,
          4.834,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577287968000,
        "v": [
          4.831,
          4.755,
          4.833,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577287963000,
        "v": [
          4.829,
          4.756,
          4.836,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577287958000,
        "v": [
          4.832,
          4.754,
          4.835,
          0.028,
          0.0,
          null
        ]
      },
      {
        "t": 1577287953000,
        "v": [
          4.832,
          4.758,
          4.836,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577287948000,
        "v": [
          4.833,
          4.755,
          4.834,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577287943000,
        "v": [
          4.831,
          4.756,
          4.836,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577287938000,
        "v": [
          4.828,
          4.754,
          4.836,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577287933000,
        "v": [
          4.83,
          4.752,
          4.831,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577287928000,
        "v": [
          4.83,
          4.752,
          4.831,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577287923000,
        "v": [
          4.827,
          4.754,
          4.832,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577287918000,
        "v": [
          4.832,
          4.754,
          4.833,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577287914000,
        "v": [
          4.835,
          4.753,
          4.832,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577287908000,
        "v": [
          4.826,
          4.753,
          4.831,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577287903000,
        "v": [
          4.829,
          4.751,
          4.831,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577287898000,
        "v": [
          4.828,
          4.757,
          4.838,
          0.029,
          0.0,
          null
        ]
      },
      {
        "t": 1577287893000,
        "v": [
          4.828,
          4.753,
          4.832,
          0.029,
          0.0,
          null
        ]
      }
    ],
    "latest": {
      "t": null,
      "v": [
        4.835,
        4.758,
        4.837,
        0.028,
        0.0,
        null
      ]
    }

  }
}];


/************************************************************ */


export const emptyTrck = {
  id: -1, name: "Track Circuit Empty", entityLink: "", entityIcon: "",
  entityChildren: []
};
export const emptyPtmc = {
  id: -1, name: "Point machines Empty", entityLink: "", entityIcon: "",
  entityChildren: []
};
export const emptySgnl = {
  id: 1, name: "Signals Empty", entityLink: "", entityIcon: "",
  entityChildren: []
};
export const mockEventHistory = [
  {
    "date": 1581273000000,
    "normalClosed": 20,
    "normalOngoing": 30,
    "errorClosed": 5,
    "errorOngoing": 8,
    "warningClosed": 6,
    "warningOngoing": 3
  },
  {
    "date": 1581186600000,
    "normalClosed": 10,
    "normalOngoing": 20,
    "errorClosed": 8,
    "errorOngoing": 5,
    "warningClosed": 2,
    "warningOngoing": 4
  },
  {
    "date": 1581100200000,
    "normalClosed": 27,
    "normalOngoing": 32,
    "errorClosed": 7,
    "errorOngoing": 23,
    "warningClosed": 33,
    "warningOngoing": 56
  },
  {
    "date": 1581013800000,
    "normalClosed": 324,
    "normalOngoing": 454,
    "errorClosed": 44,
    "errorOngoing": 5,
    "warningClosed": 23,
    "warningOngoing": 26
  }, {
    "date": 1580927400000,
    "normalClosed": 324,
    "normalOngoing": 454,
    "errorClosed": 44,
    "errorOngoing": 5,
    "warningClosed": 23,
    "warningOngoing": 26
  }, {
    "date": 1580841000000,
    "normalClosed": 324,
    "normalOngoing": 454,
    "errorClosed": 44,
    "errorOngoing": 5,
    "warningClosed": 23,
    "warningOngoing": 26
  }, {
    "date": 1580754600000,
    "normalClosed": 324,
    "normalOngoing": 454,
    "errorClosed": 44,
    "errorOngoing": 5,
    "warningClosed": 23,
    "warningOngoing": 26
  }
];
export const mockEntityDataResponse = {
  "id": 1,
  "name": "TC1",
  "description": "Track Circuit 1 Mathura",
  "entityType": "TRACK_CIRCUIT_DC",
  "status": 1,
  "signals": [
    {
      "id": 1,
      "idx": 0,
      "name": "Vf",
      "dscr": "Feed Voltage"
    },
    {
      "id": 2,
      "idx": 1,
      "name": "Vr",
      "dscr": "Relay Voltage"
    },
    {
      "id": 3,
      "idx": 2,
      "name": "Vt",
      "dscr": "Feed Resistance Drop"
    },
    {
      "id": 4,
      "idx": 3,
      "name": "If",
      "dscr": "Feed Current"
    },
    {
      "id": 5,
      "idx": 4,
      "name": "Ir",
      "dscr": "Relay Current"
    },
    {
      "id": 6,
      "idx": 5,
      "name": "Il",
      "dscr": "Leakage Current"
    }
  ],
  "events": [{
    "id": 8,
    "name": "MinCheckFail",
    "ts": 1577288828000,
    "te": 1577288923000,
    "details": "Event Details",
    "severity": 1,
    "logicalSignalId": 1,
    "logicalTriggerId": 1,
    "logicalEntityId": 1
  },
  {
    "id": 7,
    "name": "MinCheckFail",
    "ts": 1577288658000,
    "te": 1577288758000,
    "details": "Event Details",
    "severity": 1,
    "logicalSignalId": 1,
    "logicalTriggerId": 1,
    "logicalEntityId": 1
  }],
  "ts": 1577287513000,
  "te": 1577289009000,
  "v": [
    {
      "t": 1577289009000,
      "v": [
        4.835,
        4.758,
        4.837,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577289004000,
      "v": [
        4.837,
        4.756,
        4.837,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288999000,
      "v": [
        4.836,
        4.747,
        4.837,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288994000,
      "v": [
        4.836,
        4.77,
        4.836,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288989000,
      "v": [
        4.833,
        4.755,
        4.834,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288984000,
      "v": [
        4.832,
        4.757,
        4.836,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288979000,
      "v": [
        4.831,
        4.758,
        4.835,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288973000,
      "v": [
        4.832,
        4.754,
        4.833,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288968000,
      "v": [
        4.831,
        4.755,
        4.833,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288963000,
      "v": [
        4.828,
        4.754,
        4.832,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288958000,
      "v": [
        4.828,
        4.757,
        4.834,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288953000,
      "v": [
        4.83,
        4.757,
        4.838,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288948000,
      "v": [
        4.827,
        4.752,
        4.83,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288943000,
      "v": [
        4.833,
        4.754,
        4.833,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288938000,
      "v": [
        4.831,
        4.752,
        4.831,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288934000,
      "v": [
        4.836,
        4.753,
        4.836,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288928000,
      "v": [
        4.832,
        4.756,
        4.834,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288923000,
      "v": [
        4.828,
        4.753,
        4.835,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288918000,
      "v": [
        4.829,
        4.756,
        4.836,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288913000,
      "v": [
        4.83,
        4.752,
        4.833,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288908000,
      "v": [
        4.829,
        4.754,
        4.836,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288903000,
      "v": [
        4.829,
        4.755,
        4.834,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288898000,
      "v": [
        4.831,
        4.752,
        4.832,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288893000,
      "v": [
        4.829,
        4.756,
        4.837,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288888000,
      "v": [
        4.829,
        4.755,
        4.834,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288883000,
      "v": [
        4.832,
        4.754,
        4.834,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288878000,
      "v": [
        4.827,
        4.755,
        4.836,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288874000,
      "v": [
        4.835,
        4.754,
        4.836,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288869000,
      "v": [
        4.829,
        4.751,
        4.831,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288864000,
      "v": [
        4.829,
        4.752,
        4.833,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288859000,
      "v": [
        4.829,
        4.754,
        4.834,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288854000,
      "v": [
        4.832,
        4.756,
        4.834,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288849000,
      "v": [
        4.834,
        4.759,
        4.838,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288844000,
      "v": [
        4.833,
        4.762,
        4.84,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288839000,
      "v": [
        4.836,
        4.759,
        4.84,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288834000,
      "v": [
        4.835,
        4.758,
        4.839,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288828000,
      "v": [
        4.836,
        4.757,
        4.837,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288823000,
      "v": [
        4.836,
        4.759,
        4.838,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288818000,
      "v": [
        4.838,
        4.761,
        4.84,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288814000,
      "v": [
        4.845,
        4.76,
        4.839,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288808000,
      "v": [
        4.837,
        4.761,
        4.84,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288803000,
      "v": [
        4.84,
        4.759,
        4.84,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288798000,
      "v": [
        4.838,
        4.76,
        4.839,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288793000,
      "v": [
        4.839,
        4.758,
        4.838,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288788000,
      "v": [
        4.836,
        4.761,
        4.841,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288783000,
      "v": [
        4.838,
        4.76,
        4.841,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288778000,
      "v": [
        4.836,
        4.763,
        4.842,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288773000,
      "v": [
        4.84,
        4.76,
        4.839,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288768000,
      "v": [
        4.837,
        4.764,
        4.843,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288763000,
      "v": [
        4.838,
        4.763,
        4.844,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288758000,
      "v": [
        4.839,
        4.762,
        4.841,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288754000,
      "v": [
        4.848,
        4.761,
        4.841,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288748000,
      "v": [
        4.84,
        4.761,
        4.842,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288743000,
      "v": [
        4.838,
        4.764,
        4.844,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288738000,
      "v": [
        4.837,
        4.764,
        4.842,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288733000,
      "v": [
        4.836,
        4.76,
        4.841,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288728000,
      "v": [
        4.836,
        4.759,
        4.84,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288723000,
      "v": [
        4.836,
        4.76,
        4.839,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288718000,
      "v": [
        4.836,
        4.76,
        4.843,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288713000,
      "v": [
        4.836,
        4.759,
        4.84,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288708000,
      "v": [
        4.836,
        4.763,
        4.841,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288703000,
      "v": [
        4.837,
        4.762,
        4.842,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288699000,
      "v": [
        4.843,
        4.748,
        4.84,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288694000,
      "v": [
        4.84,
        4.775,
        4.839,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288688000,
      "v": [
        4.84,
        4.761,
        4.84,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288683000,
      "v": [
        4.839,
        4.759,
        4.838,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288678000,
      "v": [
        4.836,
        4.763,
        4.842,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288673000,
      "v": [
        4.838,
        4.762,
        4.842,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288668000,
      "v": [
        4.835,
        4.763,
        4.839,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288663000,
      "v": [
        4.836,
        4.761,
        4.841,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288658000,
      "v": [
        4.837,
        4.761,
        4.84,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288653000,
      "v": [
        4.833,
        4.759,
        4.84,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288648000,
      "v": [
        4.834,
        4.759,
        4.838,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288643000,
      "v": [
        4.834,
        4.759,
        4.84,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288638000,
      "v": [
        4.835,
        4.76,
        4.841,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288634000,
      "v": [
        4.84,
        4.759,
        4.84,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288628000,
      "v": [
        4.836,
        4.762,
        4.84,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288623000,
      "v": [
        4.834,
        4.759,
        4.837,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288618000,
      "v": [
        4.832,
        4.76,
        4.838,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288613000,
      "v": [
        4.834,
        4.757,
        4.836,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288608000,
      "v": [
        4.831,
        4.758,
        4.837,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288603000,
      "v": [
        4.836,
        4.758,
        4.835,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288598000,
      "v": [
        4.834,
        4.762,
        4.841,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288593000,
      "v": [
        4.836,
        4.759,
        4.837,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288588000,
      "v": [
        4.835,
        4.759,
        4.839,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288583000,
      "v": [
        4.838,
        4.76,
        4.84,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288578000,
      "v": [
        4.84,
        4.762,
        4.845,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288574000,
      "v": [
        4.847,
        4.762,
        4.84,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288568000,
      "v": [
        4.841,
        4.761,
        4.842,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288563000,
      "v": [
        4.837,
        4.766,
        4.845,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288558000,
      "v": [
        4.837,
        4.763,
        4.844,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288553000,
      "v": [
        4.838,
        4.766,
        4.846,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288548000,
      "v": [
        4.841,
        4.762,
        4.842,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288543000,
      "v": [
        4.837,
        4.766,
        4.844,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288538000,
      "v": [
        4.842,
        4.762,
        4.842,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288533000,
      "v": [
        4.838,
        4.767,
        4.842,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288528000,
      "v": [
        4.838,
        4.761,
        4.84,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288523000,
      "v": [
        4.837,
        4.764,
        4.842,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288518000,
      "v": [
        4.838,
        4.761,
        4.843,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288514000,
      "v": [
        4.843,
        4.761,
        4.845,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288509000,
      "v": [
        4.84,
        4.763,
        4.843,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288504000,
      "v": [
        4.839,
        4.762,
        4.845,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288498000,
      "v": [
        4.839,
        4.761,
        4.843,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288493000,
      "v": [
        4.839,
        4.761,
        4.841,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288488000,
      "v": [
        4.836,
        4.763,
        4.843,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288483000,
      "v": [
        4.837,
        4.766,
        4.843,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288478000,
      "v": [
        4.839,
        4.761,
        4.84,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288473000,
      "v": [
        4.838,
        4.759,
        4.841,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288468000,
      "v": [
        4.839,
        4.762,
        4.841,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288463000,
      "v": [
        4.838,
        4.762,
        4.841,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288458000,
      "v": [
        4.838,
        4.761,
        4.841,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288454000,
      "v": [
        4.845,
        4.761,
        4.841,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288448000,
      "v": [
        4.837,
        4.76,
        4.843,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288443000,
      "v": [
        4.84,
        4.761,
        4.839,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288438000,
      "v": [
        4.837,
        4.762,
        4.842,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288433000,
      "v": [
        4.838,
        4.761,
        4.842,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288428000,
      "v": [
        4.837,
        4.759,
        4.839,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288423000,
      "v": [
        4.837,
        4.761,
        4.84,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288418000,
      "v": [
        4.837,
        4.762,
        4.842,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288413000,
      "v": [
        4.835,
        4.76,
        4.841,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288408000,
      "v": [
        4.836,
        4.76,
        4.84,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288403000,
      "v": [
        4.838,
        4.759,
        4.84,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288398000,
      "v": [
        4.841,
        4.762,
        4.842,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288393000,
      "v": [
        4.845,
        4.771,
        4.844,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288388000,
      "v": [
        4.837,
        4.765,
        4.843,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288383000,
      "v": [
        4.834,
        4.762,
        4.839,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288378000,
      "v": [
        4.838,
        4.764,
        4.843,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288373000,
      "v": [
        4.841,
        4.764,
        4.843,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288368000,
      "v": [
        4.839,
        4.767,
        4.847,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288363000,
      "v": [
        4.84,
        4.764,
        4.843,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288358000,
      "v": [
        4.843,
        4.766,
        4.846,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288353000,
      "v": [
        4.842,
        4.767,
        4.849,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288348000,
      "v": [
        4.844,
        4.766,
        4.845,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288343000,
      "v": [
        4.844,
        4.767,
        4.847,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288338000,
      "v": [
        4.839,
        4.765,
        4.845,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288334000,
      "v": [
        4.851,
        4.762,
        4.844,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288328000,
      "v": [
        4.839,
        4.765,
        4.844,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288323000,
      "v": [
        4.84,
        4.762,
        4.842,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288318000,
      "v": [
        4.838,
        4.763,
        4.841,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288313000,
      "v": [
        4.839,
        4.76,
        4.84,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288308000,
      "v": [
        4.839,
        4.761,
        4.841,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288303000,
      "v": [
        4.842,
        4.762,
        4.842,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288298000,
      "v": [
        4.841,
        4.76,
        4.841,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288293000,
      "v": [
        4.839,
        4.763,
        4.843,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288288000,
      "v": [
        4.837,
        4.762,
        4.844,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288283000,
      "v": [
        4.839,
        4.762,
        4.84,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288278000,
      "v": [
        4.838,
        4.759,
        4.839,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288274000,
      "v": [
        4.845,
        4.761,
        4.841,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288268000,
      "v": [
        4.838,
        4.76,
        4.842,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288263000,
      "v": [
        4.837,
        4.762,
        4.843,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288258000,
      "v": [
        4.835,
        4.759,
        4.839,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288253000,
      "v": [
        4.834,
        4.76,
        4.84,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288248000,
      "v": [
        4.833,
        4.758,
        4.84,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288243000,
      "v": [
        4.835,
        4.756,
        4.836,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288238000,
      "v": [
        4.837,
        4.758,
        4.837,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288233000,
      "v": [
        4.83,
        4.757,
        4.837,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288228000,
      "v": [
        4.832,
        4.756,
        4.836,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288223000,
      "v": [
        4.832,
        4.76,
        4.839,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288218000,
      "v": [
        4.836,
        4.757,
        4.838,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288213000,
      "v": [
        4.838,
        4.759,
        4.836,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288208000,
      "v": [
        4.834,
        4.758,
        4.836,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288203000,
      "v": [
        4.833,
        4.755,
        4.834,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288198000,
      "v": [
        4.834,
        4.755,
        4.835,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288193000,
      "v": [
        4.833,
        4.756,
        4.836,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288188000,
      "v": [
        4.833,
        4.757,
        4.836,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288183000,
      "v": [
        4.832,
        4.757,
        4.839,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288178000,
      "v": [
        4.831,
        4.758,
        4.838,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288173000,
      "v": [
        4.831,
        4.758,
        4.837,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288168000,
      "v": [
        4.835,
        4.756,
        4.835,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288163000,
      "v": [
        4.834,
        4.757,
        4.837,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288158000,
      "v": [
        4.83,
        4.758,
        4.839,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288154000,
      "v": [
        4.839,
        4.76,
        4.837,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288148000,
      "v": [
        4.833,
        4.759,
        4.842,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288143000,
      "v": [
        4.833,
        4.759,
        4.84,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288138000,
      "v": [
        4.835,
        4.757,
        4.838,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288133000,
      "v": [
        4.83,
        4.756,
        4.838,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288128000,
      "v": [
        4.835,
        4.759,
        4.838,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288123000,
      "v": [
        4.833,
        4.759,
        4.841,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288118000,
      "v": [
        4.833,
        4.758,
        4.836,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288113000,
      "v": [
        4.833,
        4.756,
        4.835,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288108000,
      "v": [
        4.833,
        4.756,
        4.837,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288103000,
      "v": [
        4.831,
        4.757,
        4.836,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288098000,
      "v": [
        4.837,
        4.748,
        4.839,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288093000,
      "v": [
        4.838,
        4.774,
        4.839,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288088000,
      "v": [
        4.837,
        4.759,
        4.839,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288083000,
      "v": [
        4.834,
        4.76,
        4.84,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288078000,
      "v": [
        4.833,
        4.76,
        4.84,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288073000,
      "v": [
        4.837,
        4.759,
        4.838,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288068000,
      "v": [
        4.835,
        4.761,
        4.84,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288063000,
      "v": [
        4.834,
        4.762,
        4.839,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288058000,
      "v": [
        4.835,
        4.762,
        4.841,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288053000,
      "v": [
        4.833,
        4.757,
        4.837,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288048000,
      "v": [
        4.834,
        4.755,
        4.835,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288043000,
      "v": [
        4.834,
        4.762,
        4.839,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288038000,
      "v": [
        4.833,
        4.758,
        4.838,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288034000,
      "v": [
        4.842,
        4.754,
        4.833,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288027000,
      "v": [
        4.833,
        4.754,
        4.833,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288022000,
      "v": [
        4.834,
        4.755,
        4.834,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288018000,
      "v": [
        4.833,
        4.755,
        4.835,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288013000,
      "v": [
        4.835,
        4.756,
        4.835,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577288008000,
      "v": [
        4.835,
        4.755,
        4.835,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577288003000,
      "v": [
        4.832,
        4.755,
        4.835,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287998000,
      "v": [
        4.833,
        4.758,
        4.835,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577287993000,
      "v": [
        4.835,
        4.756,
        4.835,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287988000,
      "v": [
        4.83,
        4.757,
        4.836,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287983000,
      "v": [
        4.829,
        4.755,
        4.835,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577287978000,
      "v": [
        4.83,
        4.757,
        4.838,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287974000,
      "v": [
        4.838,
        4.751,
        4.834,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287968000,
      "v": [
        4.831,
        4.755,
        4.833,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287963000,
      "v": [
        4.829,
        4.756,
        4.836,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577287958000,
      "v": [
        4.832,
        4.754,
        4.835,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577287953000,
      "v": [
        4.832,
        4.758,
        4.836,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287948000,
      "v": [
        4.833,
        4.755,
        4.834,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287943000,
      "v": [
        4.831,
        4.756,
        4.836,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287938000,
      "v": [
        4.828,
        4.754,
        4.836,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287933000,
      "v": [
        4.83,
        4.752,
        4.831,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287928000,
      "v": [
        4.83,
        4.752,
        4.831,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287923000,
      "v": [
        4.827,
        4.754,
        4.832,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287918000,
      "v": [
        4.832,
        4.754,
        4.833,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287914000,
      "v": [
        4.835,
        4.753,
        4.832,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287908000,
      "v": [
        4.826,
        4.753,
        4.831,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287903000,
      "v": [
        4.829,
        4.751,
        4.831,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287898000,
      "v": [
        4.828,
        4.757,
        4.838,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287893000,
      "v": [
        4.828,
        4.753,
        4.832,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287888000,
      "v": [
        4.827,
        4.751,
        4.83,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287883000,
      "v": [
        4.826,
        4.75,
        4.831,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577287877000,
      "v": [
        4.826,
        4.749,
        4.829,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287872000,
      "v": [
        4.828,
        4.749,
        4.83,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287867000,
      "v": [
        4.828,
        4.754,
        4.833,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287862000,
      "v": [
        4.829,
        4.75,
        4.828,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287857000,
      "v": [
        4.824,
        4.753,
        4.832,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287853000,
      "v": [
        4.834,
        4.752,
        4.834,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287848000,
      "v": [
        4.827,
        4.752,
        4.831,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287843000,
      "v": [
        4.828,
        4.751,
        4.83,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287838000,
      "v": [
        4.829,
        4.75,
        4.831,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287833000,
      "v": [
        4.828,
        4.753,
        4.831,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287828000,
      "v": [
        4.825,
        4.75,
        4.83,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287823000,
      "v": [
        4.825,
        4.751,
        4.832,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287818000,
      "v": [
        4.831,
        4.752,
        4.832,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287813000,
      "v": [
        4.829,
        4.75,
        4.83,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287808000,
      "v": [
        4.828,
        4.753,
        4.834,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287803000,
      "v": [
        4.827,
        4.749,
        4.828,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287798000,
      "v": [
        4.83,
        4.737,
        4.83,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287793000,
      "v": [
        4.831,
        4.764,
        4.83,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577287788000,
      "v": [
        4.828,
        4.753,
        4.831,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577287783000,
      "v": [
        4.826,
        4.751,
        4.831,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577287778000,
      "v": [
        4.829,
        4.751,
        4.828,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287773000,
      "v": [
        4.826,
        4.75,
        4.83,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287768000,
      "v": [
        4.828,
        4.75,
        4.829,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287763000,
      "v": [
        4.825,
        4.754,
        4.832,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287758000,
      "v": [
        4.826,
        4.751,
        4.831,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287753000,
      "v": [
        4.825,
        4.75,
        4.828,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287748000,
      "v": [
        4.826,
        4.751,
        4.83,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287743000,
      "v": [
        4.827,
        4.752,
        4.833,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287738000,
      "v": [
        4.828,
        4.752,
        4.832,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287734000,
      "v": [
        4.833,
        4.751,
        4.835,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287728000,
      "v": [
        4.826,
        4.751,
        4.831,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287723000,
      "v": [
        4.828,
        4.752,
        4.831,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287718000,
      "v": [
        4.827,
        4.753,
        4.834,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577287713000,
      "v": [
        4.829,
        4.755,
        4.835,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287708000,
      "v": [
        4.827,
        4.755,
        4.833,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287703000,
      "v": [
        4.826,
        4.751,
        4.829,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287698000,
      "v": [
        4.828,
        4.75,
        4.83,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287693000,
      "v": [
        4.828,
        4.751,
        4.831,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287688000,
      "v": [
        4.828,
        4.755,
        4.834,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287683000,
      "v": [
        4.825,
        4.753,
        4.832,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287677000,
      "v": [
        4.828,
        4.753,
        4.833,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287673000,
      "v": [
        4.836,
        4.757,
        4.838,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287667000,
      "v": [
        4.832,
        4.757,
        4.837,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577287662000,
      "v": [
        4.835,
        4.758,
        4.837,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577287657000,
      "v": [
        4.832,
        4.757,
        4.835,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287652000,
      "v": [
        4.837,
        4.758,
        4.837,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287647000,
      "v": [
        4.835,
        4.761,
        4.84,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287642000,
      "v": [
        4.833,
        4.758,
        4.84,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287637000,
      "v": [
        4.837,
        4.757,
        4.837,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287632000,
      "v": [
        4.832,
        4.755,
        4.838,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287627000,
      "v": [
        4.834,
        4.758,
        4.837,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287622000,
      "v": [
        4.833,
        4.757,
        4.836,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577287617000,
      "v": [
        4.833,
        4.76,
        4.841,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287613000,
      "v": [
        4.841,
        4.762,
        4.841,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577287608000,
      "v": [
        4.838,
        4.763,
        4.844,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577287603000,
      "v": [
        4.836,
        4.76,
        4.843,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577287598000,
      "v": [
        4.837,
        4.761,
        4.841,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287593000,
      "v": [
        4.835,
        4.757,
        4.838,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287588000,
      "v": [
        4.835,
        4.758,
        4.837,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577287583000,
      "v": [
        4.835,
        4.757,
        4.836,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287578000,
      "v": [
        4.834,
        4.761,
        4.839,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577287573000,
      "v": [
        4.833,
        4.758,
        4.84,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287568000,
      "v": [
        4.834,
        4.759,
        4.838,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287563000,
      "v": [
        4.833,
        4.76,
        4.84,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287558000,
      "v": [
        4.836,
        4.759,
        4.84,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287554000,
      "v": [
        4.843,
        4.757,
        4.838,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287548000,
      "v": [
        4.835,
        4.76,
        4.841,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287543000,
      "v": [
        4.834,
        4.762,
        4.837,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287538000,
      "v": [
        4.834,
        4.76,
        4.84,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287533000,
      "v": [
        4.832,
        4.758,
        4.839,
        0.028,
        0.0,
        null
      ]
    },
    {
      "t": 1577287528000,
      "v": [
        4.84,
        4.759,
        4.839,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287523000,
      "v": [
        4.832,
        4.756,
        4.837,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287518000,
      "v": [
        4.832,
        4.758,
        4.838,
        0.029,
        0.0,
        null
      ]
    },
    {
      "t": 1577287513000,
      "v": [
        4.829,
        4.757,
        4.835,
        0.029,
        0.0,
        null
      ]
    }
  ],
  "latest": {
    "t": null,
    "v": [
      4.835,
      4.758,
      4.837,
      0.028,
      0.0,
      null
    ]
  }
};

export const mockTrackEventList = [{
  id: 0,
  name: "Normal Track Event",
  ts: 1576771142202,
  te: 1576768501711,
  desc: "This is a normal track event",
  lvl: 1,
  opacity: "green"
},
{
  id: 1,
  name: "Error Track Event",
  ts: 1576768621303,
  te: 1576768708792,
  desc: "This is a Error track event",
  lvl: 2,
  opacity: "green"
}, {
  id: 2,
  name: "Warning Track Event",
  ts: 1576769312344,
  te: 1576769979215,
  desc: "This is a Warning track event",
  lvl: 3,
  opacity: "green"
}, {
  id: 3,
  name: "Normal Track Event",
  ts: 1576771220515,
  te: 1576772205208,
  desc: "This is a normal track event",
  lvl: 1,
  opacity: "green"
}, {
  id: 4,
  name: "Error Track Event",
  ts: 1576772959543,
  te: 1576773409766,
  desc: "This is a Error track event",
  lvl: 2,
  opacity: "green"
}, {
  id: 5,
  name: "Warning Track Event",
  ts: 1576773950476,
  te: 1576774584211,
  desc: "This is a Warning track event",
  lvl: 3,
  opacity: "green"
}, {
  id: 6,
  name: "Normal Track Event",
  ts: 1576775163327,
  te: 1576775686984,
  desc: "This is a normal track event",
  lvl: 1,
  opacity: "green"
}, {
  id: 7,
  name: "Error Track Event",
  ts: 1576776176427,
  te: 1576777056606,
  desc: "This is a error track event",
  lvl: 2,
  opacity: "green"
}, {
  id: 8,
  name: "Warning Track Event",
  ts: 1576777408527,
  te: 1576778040038,
  desc: "This is a Warning track event",
  lvl: 3,
  opacity: "green"
}];

export const mockSingleItemSidebarEntity = {
  "id": 99999999,
  "name": "Home",
  "description": "Dashboard home",
  "entityType": "SINGLE_ITEM_SIDEBAR_ENTITY",
  "entityLink": "/app/dashboard/",
  "status": 1,
  "entityIcon": null,
  "isHeader": true
};
export const mockLogicalEntities = [
  {
    "id": 4,
    "name": "TC1",
    "description": "Track Circuit 1 Mathura",
    "entityType": "TRACK_CIRCUIT_DC",
    "status": 1,
    "signals": [
      {
        "id": 1,
        "idx": 0,
        "name": "Vf"
      },
      {
        "id": 2,
        "idx": 1,
        "name": "Vr"
      },
      {
        "id": 3,
        "idx": 2,
        "name": "Vt"
      },
      {
        "id": 4,
        "idx": 3,
        "name": "If"
      },
      {
        "id": 5,
        "idx": 4,
        "name": "Ir"
      },
      {
        "id": 6,
        "idx": 5,
        "name": "Il"
      }
    ],
    "ts": null,
    "te": null,
    "v": null,
    "latest": {
      "t": null,
      "v": [
        null,
        null,
        null,
        null
      ]
    }
  },
  {
    "id": 2,
    "name": "TC2",
    "description": "Track Circuit 2 Mathura",
    "entityType": "TRACK_CIRCUIT_DC",
    "status": 1,
    "signals": [
      {
        "id": 1,
        "idx": 0,
        "name": "Vf"
      },
      {
        "id": 2,
        "idx": 1,
        "name": "Vr"
      },
      {
        "id": 3,
        "idx": 2,
        "name": "Vt"
      },
      {
        "id": 4,
        "idx": 3,
        "name": "If"
      },
      {
        "id": 5,
        "idx": 4,
        "name": "Ir"
      },
      {
        "id": 6,
        "idx": 5,
        "name": "Il"
      }],
    "ts": null,
    "te": null,
    "v": null,
    "latest": {
      "t": null,
      "v": [
        null,
        null,
        null,
        null
      ]
    }
  },

  {
    "id": 3,
    "name": "PM1",
    "description": "Point Machine 1 Mathura",
    "entityType": "POINT_MACHINE",
    "status": 1,
    "signals": [
      {
        "id": 1,
        "idx": 0,
        "name": "Vf"
      },
      {
        "id": 2,
        "idx": 1,
        "name": "If"
      },
      {
        "id": 3,
        "idx": 2,
        "name": "Vr"
      },
      {
        "id": 4,
        "idx": 3,
        "name": "Ir"
      }
    ],
    "ts": null,
    "te": null,
    "v": null,
    "latest": {
      "t": null,
      "v": [
        null,
        null,
        null,
        null
      ]
    }
  },

  {
    "id": 4,
    "name": "PM2",
    "description": "Point Machine 2 Mathura",
    "entityType": "POINT_MACHINE",
    "status": 1,
    "signals": [
      {
        "id": 1,
        "idx": 0,
        "name": "Vf"
      },
      {
        "id": 2,
        "idx": 1,
        "name": "If"
      },
      {
        "id": 3,
        "idx": 2,
        "name": "Vr"
      },
      {
        "id": 4,
        "idx": 3,
        "name": "Ir"
      }
    ],
    "ts": null,
    "te": null,
    "v": null,
    "latest": {
      "t": null,
      "v": [
        null,
        null,
        null,
        null
      ]
    }
  }
];

export const emptyMetaData = {
  "id": -1,
  "name": "_ _ _ _",
  "description": "_ _ _ _",
  "entityType": "_ _ _ _",
  "status": "_ _ _ _",
  "signals": [
    {
      "id": -1,
      "idx": -1,
      "name": "_ _ _ _",
      "dscr": "_ _ _ _"
    }
  ],
  "locationBoxes": []
};

export const emptyEventData = [{
  "id": -1,
  "name": "_ _ _ _",
  "details": "_ _ _ _",
  "severity": -1,
  "logicalSignalId": -1,
  "logicalTriggerId": -1,
  "ts": -1,
  "te": -1
}];

export const emptyTSData = {
  ts: 0,
  te: 0,
  tSeries: []
};

export const emptyLatestData = {
  "t": -1,
  "latest": []
};

export const mockEntityTsData = [{ t: 1576768207001, v: [8.23, 1.57, 4.13, 289, 253, 36] },
{ t: 1576768226758, v: [7.12, 1.74, 2.79, 326, 287, 39] },
{ t: 1576768238846, v: [5.11, 0.84, 3.6, 299, 289, 10] },
{ t: 1576768256413, v: [5.01, 0.28, 3.4, 293, 279, 14] },
{ t: 1576768269555, v: [6.99, 1.01, 1.72, 290, 256, 34] },
{ t: 1576768277691, v: [6.27, 1.1, 4.02, 345, 324, 21] },
{ t: 1576768294971, v: [5.94, 0.6, 3.64, 340, 304, 36] },
{ t: 1576768303726, v: [7.94, 1.42, 5.47, 251, 214, 37] },
{ t: 1576768321137, v: [7.58, 1.75, 4.11, 308, 287, 21] },
{ t: 1576768339213, v: [3.96, 1.1, 4.81, 251, 209, 42] },
{ t: 1576768345265, v: [6.3, 0.83, 4.19, 306, 293, 13] },
{ t: 1576768363528, v: [7.17, 0.37, 1.29, 279, 260, 19] },
{ t: 1576768378266, v: [5.1, 0.72, 2.21, 289, 275, 14] },
{ t: 1576768387549, v: [6.43, 1.39, 2.51, 309, 296, 13] },
{ t: 1576768396918, v: [4.88, 0.19, 2.38, 348, 338, 10] },
{ t: 1576768406887, v: [6.83, 0.6, 2.01, 338, 288, 50] },
{ t: 1576768420579, v: [3.1, 1.22, 2.22, 299, 254, 45] },
{ t: 1576768434556, v: [4.67, 0.36, 5.05, 290, 245, 45] },
{ t: 1576768440256, v: [5.18, 1.72, 3.98, 341, 306, 35] },
{ t: 1576768448176, v: [7.13, 0.57, 5.12, 345, 307, 38] },
{ t: 1576768453522, v: [5.71, 1.24, 2.06, 316, 273, 43] },
{ t: 1576768459374, v: [3.13, 1.41, 3.08, 345, 315, 30] },
{ t: 1576768474272, v: [7.93, 0.02, 4.09, 281, 263, 18] },
{ t: 1576768494221, v: [4.48, 1.9, 5.17, 266, 250, 16] },
{ t: 1576768501711, v: [5.07, 1.68, 2.06, 258, 216, 42] },
{ t: 1576768520267, v: [4.52, 1, 3.34, 275, 237, 38] },
{ t: 1576768527253, v: [5.67, 1.24, 3.61, 272, 259, 13] },
{ t: 1576768544462, v: [7.19, 1.5, 3.21, 333, 312, 21] },
{ t: 1576768563782, v: [4.7, 1.21, 5.29, 320, 277, 43] },
{ t: 1576768580589, v: [5.76, 1.04, 4.07, 275, 225, 50] },
{ t: 1576768586321, v: [7.41, 1.11, 2.04, 297, 247, 50] },
{ t: 1576768601852, v: [5.45, 0.38, 2.18, 257, 207, 50] },
{ t: 1576768610368, v: [6.9, 0.36, 3.11, 267, 243, 24] },
{ t: 1576768621303, v: [7.8, 0.17, 1.46, 305, 278, 27] },
{ t: 1576768636947, v: [4.37, 1.25, 4, 305, 264, 41] },
{ t: 1576768656511, v: [7.68, 1.93, 3.03, 290, 247, 43] },
{ t: 1576768672390, v: [4.36, 0.89, 1.29, 340, 308, 32] },
{ t: 1576768680207, v: [6.54, 1.88, 2.36, 302, 274, 28] },
{ t: 1576768692461, v: [3.46, 0.73, 2.35, 344, 311, 33] },
{ t: 1576768708792, v: [3, 1.3, 2.13, 286, 272, 14] },
{ t: 1576768715931, v: [4.6, 0.77, 3.82, 322, 312, 10] },
{ t: 1576768729815, v: [6.99, 1.45, 4.22, 304, 292, 12] },
{ t: 1576768747836, v: [7.91, 1.85, 4.28, 291, 258, 33] },
{ t: 1576768765267, v: [4.44, 1.51, 1.34, 288, 238, 50] },
{ t: 1576768784308, v: [7.73, 0.3, 3.11, 287, 239, 48] },
{ t: 1576768790985, v: [4.93, 1.78, 1.79, 338, 294, 44] },
{ t: 1576768809737, v: [7.1, 1.23, 2.29, 262, 250, 12] },
{ t: 1576768814886, v: [5.71, 0.46, 3.64, 345, 330, 15] },
{ t: 1576768821557, v: [7.88, 0.27, 1.7, 272, 255, 17] },
{ t: 1576768832612, v: [7.02, 0.43, 2.15, 255, 242, 13] },
{ t: 1576768849790, v: [3.25, 0.31, 3.3, 334, 303, 31] },
{ t: 1576768864246, v: [6.24, 1.17, 3.52, 302, 273, 29] },
{ t: 1576768878619, v: [6.68, 0.94, 1.28, 284, 272, 12] },
{ t: 1576768893914, v: [7.44, 0.48, 4.68, 252, 227, 25] },
{ t: 1576768901668, v: [6.83, 0.38, 3.85, 291, 253, 38] },
{ t: 1576768909825, v: [5.94, 1.53, 2.29, 284, 245, 39] },
{ t: 1576768919359, v: [4.68, 1.84, 1.16, 309, 299, 10] },
{ t: 1576768928408, v: [7.25, 1.53, 1.52, 316, 279, 37] },
{ t: 1576768945409, v: [3.04, 2, 5.11, 303, 281, 22] },
{ t: 1576768961547, v: [4.41, 0.16, 3.02, 334, 304, 30] },
{ t: 1576768969297, v: [4.65, 0.85, 2.12, 319, 282, 37] },
{ t: 1576768976468, v: [4.45, 1.56, 3.06, 260, 215, 45] },
{ t: 1576768989202, v: [3.86, 1.6, 5.36, 326, 296, 30] },
{ t: 1576769006574, v: [5.09, 1.91, 3.12, 346, 313, 33] },
{ t: 1576769018194, v: [3.77, 1.97, 3.58, 301, 251, 50] },
{ t: 1576769032947, v: [3.02, 1.92, 3.48, 322, 298, 24] },
{ t: 1576769050938, v: [6.07, 0.6, 4.46, 336, 300, 36] },
{ t: 1576769060169, v: [7.42, 0.38, 5.46, 280, 265, 15] },
{ t: 1576769065681, v: [5.31, 0.61, 4.16, 267, 227, 40] },
{ t: 1576769078148, v: [4.13, 1.24, 3.72, 296, 285, 11] },
{ t: 1576769096033, v: [7.1, 1.31, 4.12, 295, 269, 26] },
{ t: 1576769111293, v: [5.43, 1.07, 3.5, 350, 333, 17] },
{ t: 1576769122886, v: [4.87, 1.38, 3.56, 289, 264, 25] },
{ t: 1576769141118, v: [7.88, 0.07, 3.41, 324, 284, 40] },
{ t: 1576769159154, v: [5.69, 0.7, 2.68, 273, 229, 44] },
{ t: 1576769174453, v: [4.78, 0.95, 3.46, 251, 236, 15] },
{ t: 1576769193942, v: [7.04, 1.88, 5.12, 302, 261, 41] },
{ t: 1576769200551, v: [3.03, 0.08, 5.27, 269, 230, 39] },
{ t: 1576769217422, v: [3.58, 1.94, 4.72, 338, 320, 18] },
{ t: 1576769230163, v: [5.48, 1.3, 3.22, 305, 283, 22] },
{ t: 1576769249448, v: [7.8, 0.83, 1.14, 291, 243, 48] },
{ t: 1576769254958, v: [7, 0.4, 1.45, 303, 286, 17] },
{ t: 1576769273926, v: [4.05, 0.78, 2.55, 296, 260, 36] },
{ t: 1576769280436, v: [7.15, 1.57, 3, 349, 329, 20] },
{ t: 1576769292477, v: [7.45, 1.49, 1, 274, 231, 43] },
{ t: 1576769299235, v: [7.59, 1.95, 1.7, 298, 267, 31] },
{ t: 1576769305538, v: [4.38, 0.24, 2.76, 263, 222, 41] },
{ t: 1576769312344, v: [3.21, 1.56, 2.39, 315, 297, 18] },
{ t: 1576769327242, v: [4.95, 1.01, 3.06, 304, 287, 17] },
{ t: 1576769341122, v: [7.32, 1.16, 5.12, 259, 247, 12] },
{ t: 1576769349568, v: [7.4, 1.95, 3.44, 325, 303, 22] },
{ t: 1576769357456, v: [3.38, 1.83, 3.66, 298, 282, 16] },
{ t: 1576769372136, v: [5.31, 0.31, 3.02, 254, 238, 16] },
{ t: 1576769387996, v: [4.94, 1.85, 4.68, 299, 266, 33] },
{ t: 1576769401929, v: [3.47, 0.67, 3.09, 321, 306, 15] },
{ t: 1576769409413, v: [4.49, 1.66, 1.27, 273, 250, 23] },
{ t: 1576769417977, v: [3.44, 0.97, 4.52, 269, 244, 25] },
{ t: 1576769437751, v: [5.49, 1.69, 3.43, 270, 232, 38] },
{ t: 1576769448524, v: [6.58, 1.84, 2.34, 281, 261, 20] },
{ t: 1576769457468, v: [5.61, 1.78, 5.46, 269, 236, 33] },
{ t: 1576769475198, v: [3.63, 1.83, 4.44, 266, 226, 40] },
{ t: 1576769481027, v: [4.46, 1.13, 4.67, 324, 282, 42] },
{ t: 1576769489635, v: [3.56, 0.23, 2.41, 343, 311, 32] },
{ t: 1576769508112, v: [5.15, 1.7, 4.64, 314, 293, 21] },
{ t: 1576769524092, v: [6.66, 1.24, 3.37, 326, 279, 47] },
{ t: 1576769543508, v: [3.49, 1.26, 3.78, 316, 299, 17] },
{ t: 1576769551387, v: [3.04, 1.46, 3.85, 301, 281, 20] },
{ t: 1576769560003, v: [5.36, 1, 2.4, 320, 290, 30] },
{ t: 1576769572884, v: [7.5, 0.18, 2.47, 251, 214, 37] },
{ t: 1576769582357, v: [4.48, 0.11, 3.38, 262, 225, 37] },
{ t: 1576769589566, v: [5.35, 1.9, 4.1, 268, 226, 42] },
{ t: 1576769601855, v: [7.57, 0.19, 3.52, 322, 275, 47] },
{ t: 1576769609755, v: [3.19, 1.72, 4.06, 292, 265, 27] },
{ t: 1576769627952, v: [3.43, 0.5, 1.42, 332, 300, 32] },
{ t: 1576769635601, v: [4.2, 1.95, 4.04, 338, 313, 25] },
{ t: 1576769647937, v: [7.11, 0.8, 3.3, 301, 275, 26] },
{ t: 1576769658370, v: [7.66, 0.24, 2.14, 263, 252, 11] },
{ t: 1576769669837, v: [7.87, 1.05, 2.4, 331, 287, 44] },
{ t: 1576769685150, v: [6.8, 1.08, 5.48, 341, 331, 10] },
{ t: 1576769697735, v: [4.79, 1.21, 2.14, 291, 261, 30] },
{ t: 1576769712252, v: [5.5, 0.2, 2.99, 307, 273, 34] },
{ t: 1576769719139, v: [3.85, 1.17, 2.06, 321, 290, 31] },
{ t: 1576769725842, v: [6.09, 0.22, 3, 324, 281, 43] },
{ t: 1576769740811, v: [4.82, 2, 3.23, 311, 297, 14] },
{ t: 1576769756783, v: [5.36, 0.43, 2.14, 285, 263, 22] },
{ t: 1576769770645, v: [4.09, 0.15, 4.19, 307, 272, 35] },
{ t: 1576769777540, v: [4.38, 0.56, 4.9, 297, 283, 14] },
{ t: 1576769794230, v: [6.97, 1.05, 3.46, 334, 307, 27] },
{ t: 1576769805531, v: [3.84, 0.51, 2.85, 337, 303, 34] },
{ t: 1576769822205, v: [4, 0.64, 4.71, 263, 233, 30] },
{ t: 1576769833656, v: [3.28, 1.6, 3.97, 344, 305, 39] },
{ t: 1576769841607, v: [7.31, 0.9, 2.34, 292, 258, 34] },
{ t: 1576769851150, v: [4.42, 0.64, 4.2, 322, 304, 18] },
{ t: 1576769860276, v: [4.22, 0.28, 3.25, 342, 326, 16] },
{ t: 1576769871990, v: [4.65, 1.98, 1.13, 312, 290, 22] },
{ t: 1576769886133, v: [3.28, 0.95, 3.5, 330, 293, 37] },
{ t: 1576769905869, v: [4.37, 1.29, 2.39, 342, 298, 44] },
{ t: 1576769922778, v: [7.04, 0.77, 2.31, 297, 264, 33] },
{ t: 1576769939049, v: [5.77, 1.48, 1.04, 290, 255, 35] },
{ t: 1576769955715, v: [5.72, 0.18, 1.39, 251, 203, 48] },
{ t: 1576769962309, v: [6.25, 1.75, 2.46, 345, 330, 15] },
{ t: 1576769979215, v: [5, 0.68, 3.45, 316, 274, 42] },
{ t: 1576769986951, v: [6.46, 0.59, 3.29, 336, 309, 27] },
{ t: 1576769998750, v: [6.04, 0.44, 2.47, 341, 305, 36] },
{ t: 1576770014977, v: [5.72, 0.83, 3.62, 312, 287, 25] },
{ t: 1576770024627, v: [7.45, 1.05, 3.49, 311, 280, 31] },
{ t: 1576770041271, v: [5.81, 0.75, 4.6, 285, 271, 14] },
{ t: 1576770059545, v: [4.07, 1.03, 4.08, 285, 242, 43] },
{ t: 1576770076244, v: [5.4, 0.21, 4.42, 303, 267, 36] },
{ t: 1576770092121, v: [7.76, 1.39, 3.35, 310, 284, 26] },
{ t: 1576770108108, v: [4.34, 1.62, 3.3, 280, 265, 15] },
{ t: 1576770117898, v: [5.76, 0.16, 4.23, 348, 310, 38] },
{ t: 1576770124033, v: [5.37, 1.69, 5.33, 316, 279, 37] },
{ t: 1576770143068, v: [4.42, 1.15, 3.78, 311, 272, 39] },
{ t: 1576770148884, v: [3.82, 0.79, 5.04, 330, 314, 16] },
{ t: 1576770160639, v: [3.44, 1.25, 4.23, 264, 231, 33] },
{ t: 1576770166067, v: [7.56, 0.03, 2.35, 255, 232, 23] },
{ t: 1576770181278, v: [7.91, 0.69, 3.79, 258, 222, 36] },
{ t: 1576770196022, v: [3.84, 0.2, 4.2, 268, 249, 19] },
{ t: 1576770203637, v: [5.63, 0.77, 5, 254, 206, 48] },
{ t: 1576770213022, v: [5.12, 0.68, 2.09, 251, 224, 27] },
{ t: 1576770223279, v: [7.71, 0.54, 1.69, 267, 219, 48] },
{ t: 1576770232107, v: [6.45, 1.66, 4.94, 334, 286, 48] },
{ t: 1576770249064, v: [3.1, 1.46, 4.99, 327, 293, 34] },
{ t: 1576770257756, v: [3.44, 0.34, 1.81, 297, 249, 48] },
{ t: 1576770273124, v: [5.3, 0.51, 5.29, 307, 259, 48] },
{ t: 1576770291256, v: [5.76, 0.89, 4.38, 325, 308, 17] },
{ t: 1576770303738, v: [7.83, 1.08, 3.62, 335, 312, 23] },
{ t: 1576770319300, v: [4.78, 0.7, 3.41, 304, 259, 45] },
{ t: 1576770328397, v: [4.07, 1.11, 3.97, 320, 283, 37] },
{ t: 1576770346427, v: [4.05, 0.41, 2.21, 269, 241, 28] },
{ t: 1576770353286, v: [7.28, 1.02, 2.37, 268, 251, 17] },
{ t: 1576770359481, v: [4.54, 1.65, 1.44, 272, 252, 20] },
{ t: 1576770376772, v: [4.08, 1.01, 4.39, 340, 330, 10] },
{ t: 1576770388666, v: [3.79, 0.21, 4.13, 257, 209, 48] },
{ t: 1576770403376, v: [6.58, 1.83, 5.28, 328, 306, 22] },
{ t: 1576770409893, v: [5.85, 0.7, 3.54, 335, 311, 24] },
{ t: 1576770426875, v: [3.51, 1.85, 4.53, 250, 204, 46] },
{ t: 1576770433066, v: [8, 0.12, 3.49, 293, 262, 31] },
{ t: 1576770452010, v: [3.89, 0.56, 2.04, 340, 322, 18] },
{ t: 1576770465489, v: [5.14, 1.35, 3.45, 306, 273, 33] },
{ t: 1576770475186, v: [3.61, 1.54, 4.48, 251, 220, 31] },
{ t: 1576770494521, v: [5.67, 1.72, 2.47, 344, 329, 15] },
{ t: 1576770506614, v: [3.67, 1.92, 4.45, 287, 266, 21] },
{ t: 1576770524499, v: [7.61, 0.85, 4.46, 305, 293, 12] },
{ t: 1576770536200, v: [3.48, 0.13, 2.83, 334, 306, 28] },
{ t: 1576770542977, v: [4.73, 1.4, 3.06, 324, 283, 41] },
{ t: 1576770562679, v: [6.18, 1.05, 4.96, 251, 204, 47] },
{ t: 1576770568052, v: [5.79, 1.7, 3.33, 348, 305, 43] },
{ t: 1576770580708, v: [6.12, 0.12, 3.07, 286, 265, 21] },
{ t: 1576770600326, v: [5.78, 1.81, 1.9, 348, 324, 24] },
{ t: 1576770605781, v: [7.36, 0.44, 4.11, 340, 326, 14] },
{ t: 1576770616975, v: [6.18, 1.77, 3.4, 329, 300, 29] },
{ t: 1576770634583, v: [4.34, 0.59, 3.25, 350, 314, 36] },
{ t: 1576770651633, v: [4.24, 0.51, 1.91, 339, 328, 11] },
{ t: 1576770658918, v: [6.35, 0.7, 2.05, 347, 324, 23] },
{ t: 1576770678093, v: [4.46, 0.83, 2.05, 340, 324, 16] },
{ t: 1576770696087, v: [4.25, 1.75, 3.54, 260, 227, 33] },
{ t: 1576770712046, v: [5.23, 1.6, 1.28, 323, 304, 19] },
{ t: 1576770717391, v: [6.81, 0.29, 4.22, 278, 247, 31] },
{ t: 1576770734974, v: [5.95, 1.68, 1.9, 318, 274, 44] },
{ t: 1576770740476, v: [3.52, 0.23, 2.97, 258, 222, 36] },
{ t: 1576770750508, v: [5.72, 0.02, 4.2, 271, 254, 17] },
{ t: 1576770768075, v: [6.81, 0.51, 3.66, 340, 299, 41] },
{ t: 1576770779461, v: [5.37, 1.44, 4.45, 319, 301, 18] },
{ t: 1576770792164, v: [7.84, 0.87, 4.4, 294, 277, 17] },
{ t: 1576770810671, v: [4.2, 1.25, 4.16, 320, 299, 21] },
{ t: 1576770826937, v: [3.73, 1.09, 4.09, 289, 278, 11] },
{ t: 1576770842085, v: [6.11, 1.06, 5.44, 336, 325, 11] },
{ t: 1576770851364, v: [3.47, 1, 1.53, 265, 254, 11] },
{ t: 1576770857971, v: [3.14, 1.22, 2.9, 317, 268, 49] },
{ t: 1576770873363, v: [4.59, 0.47, 4.26, 346, 336, 10] },
{ t: 1576770890559, v: [3.94, 1.59, 1.08, 257, 222, 35] },
{ t: 1576770906292, v: [3.83, 0.92, 3.11, 264, 230, 34] },
{ t: 1576770917735, v: [4.95, 0.27, 2.1, 301, 269, 32] },
{ t: 1576770926781, v: [3.52, 0.69, 3.23, 336, 289, 47] },
{ t: 1576770943013, v: [3.98, 1.71, 1.87, 260, 248, 12] },
{ t: 1576770951689, v: [4.36, 0.96, 2.38, 337, 304, 33] },
{ t: 1576770961038, v: [7.96, 0.24, 4.53, 324, 299, 25] },
{ t: 1576770977221, v: [4.86, 1.7, 2.37, 326, 308, 18] },
{ t: 1576770982947, v: [6.1, 1.87, 5.11, 313, 265, 48] },
{ t: 1576770994002, v: [3.13, 0.87, 2.06, 293, 271, 22] },
{ t: 1576771000074, v: [7.15, 1.93, 3.01, 272, 261, 11] },
{ t: 1576771008936, v: [5.15, 1.57, 3.26, 313, 282, 31] },
{ t: 1576771025967, v: [3.78, 1.01, 4.37, 265, 244, 21] },
{ t: 1576771044917, v: [3.99, 1.2, 2.69, 316, 286, 30] },
{ t: 1576771052690, v: [4, 1.86, 4.96, 344, 303, 41] },
{ t: 1576771070604, v: [6.69, 0.05, 4.33, 281, 267, 14] },
{ t: 1576771079671, v: [6.69, 1.17, 5.2, 271, 230, 41] },
{ t: 1576771097294, v: [3.98, 1.18, 3.23, 307, 279, 28] },
{ t: 1576771114331, v: [4.54, 1.83, 4.46, 307, 259, 48] },
{ t: 1576771124576, v: [6.29, 1.07, 3.82, 344, 313, 31] },
{ t: 1576771130638, v: [5.52, 0.53, 4.93, 337, 310, 27] },
{ t: 1576771142202, v: [3.94, 0.72, 4.2, 310, 264, 46] },
{ t: 1576771148778, v: [3.76, 0.4, 5.49, 291, 254, 37] },
{ t: 1576771156190, v: [6.56, 1.86, 4.11, 278, 258, 20] },
{ t: 1576771165646, v: [5.08, 0.81, 1.55, 258, 212, 46] },
{ t: 1576771175848, v: [6.8, 0.82, 4.2, 292, 263, 29] },
{ t: 1576771181540, v: [3.83, 1.53, 4.85, 335, 319, 16] },
{ t: 1576771197170, v: [4.45, 1.88, 4.01, 270, 244, 26] },
{ t: 1576771212081, v: [7.45, 1.97, 2.5, 286, 238, 48] },
{ t: 1576771220515, v: [7.21, 0.47, 1.72, 263, 237, 26] },
{ t: 1576771236124, v: [4.54, 0.51, 2.42, 350, 314, 36] },
{ t: 1576771250933, v: [3.92, 1.52, 4.26, 301, 254, 47] },
{ t: 1576771266339, v: [6.91, 0.83, 2.08, 330, 320, 10] },
{ t: 1576771277228, v: [4.3, 1.12, 2.07, 339, 328, 11] },
{ t: 1576771290376, v: [7.44, 0.85, 1.78, 290, 247, 43] },
{ t: 1576771296191, v: [6.09, 1.44, 1.55, 339, 303, 36] },
{ t: 1576771305279, v: [3.9, 1.25, 3.73, 260, 237, 23] },
{ t: 1576771324557, v: [5.86, 1.7, 3.43, 257, 216, 41] },
{ t: 1576771338113, v: [6.74, 1.39, 1.91, 337, 288, 49] },
{ t: 1576771344021, v: [7.65, 1.4, 2.69, 276, 238, 38] },
{ t: 1576771361564, v: [5.38, 0.03, 2.03, 324, 314, 10] },
{ t: 1576771371082, v: [4.3, 1.74, 4.35, 314, 299, 15] },
{ t: 1576771383922, v: [6.78, 0.65, 2.13, 332, 286, 46] },
{ t: 1576771391051, v: [3.73, 1.25, 2.91, 324, 279, 45] },
{ t: 1576771410406, v: [4.64, 1.91, 1.5, 318, 308, 10] },
{ t: 1576771424024, v: [6.12, 1.12, 2.45, 278, 251, 27] },
{ t: 1576771434356, v: [3.51, 0.65, 4.6, 284, 234, 50] },
{ t: 1576771440163, v: [3.14, 1.08, 4.9, 334, 287, 47] },
{ t: 1576771455172, v: [6.79, 0.78, 5.25, 262, 248, 14] },
{ t: 1576771463857, v: [7.95, 1.28, 1.7, 334, 300, 34] },
{ t: 1576771482775, v: [3.05, 0.73, 4.25, 254, 215, 39] },
{ t: 1576771488020, v: [6.87, 0.93, 3.25, 250, 200, 50] },
{ t: 1576771507525, v: [6.96, 0.86, 4.53, 274, 263, 11] },
{ t: 1576771519670, v: [4.27, 1.75, 1.19, 347, 307, 40] },
{ t: 1576771535377, v: [6.34, 0.28, 4.46, 344, 324, 20] },
{ t: 1576771550047, v: [5.66, 0.53, 2.07, 280, 238, 42] },
{ t: 1576771569605, v: [6.59, 1.93, 3.21, 343, 301, 42] },
{ t: 1576771581995, v: [7.63, 1.74, 2.01, 306, 272, 34] },
{ t: 1576771597712, v: [3.3, 1.65, 4.76, 288, 249, 39] },
{ t: 1576771606259, v: [5.92, 1.83, 3.09, 291, 277, 14] },
{ t: 1576771618141, v: [3.55, 1.22, 4.76, 285, 244, 41] },
{ t: 1576771636435, v: [5.9, 0.99, 4.84, 255, 211, 44] },
{ t: 1576771656303, v: [6.12, 0.8, 1.54, 315, 267, 48] },
{ t: 1576771665786, v: [7.84, 0.58, 4.27, 311, 283, 28] },
{ t: 1576771682538, v: [7.67, 1.83, 4.52, 283, 234, 49] },
{ t: 1576771695426, v: [5.32, 1.6, 2.03, 302, 261, 41] },
{ t: 1576771706748, v: [3.21, 0.76, 2.57, 321, 279, 42] },
{ t: 1576771717654, v: [7.85, 1.26, 3.73, 349, 315, 34] },
{ t: 1576771724324, v: [5.45, 1.94, 2.85, 255, 212, 43] },
{ t: 1576771734271, v: [6.24, 0.82, 5.34, 253, 232, 21] },
{ t: 1576771741253, v: [3.54, 0.95, 5.4, 276, 226, 50] },
{ t: 1576771759243, v: [7.12, 0.82, 2.05, 252, 215, 37] },
{ t: 1576771778952, v: [3.57, 0.27, 4.94, 307, 275, 32] },
{ t: 1576771797294, v: [7.51, 0.1, 3.28, 297, 286, 11] },
{ t: 1576771804206, v: [6.85, 1.92, 3.18, 250, 239, 11] },
{ t: 1576771810513, v: [3.86, 0.63, 3.24, 284, 253, 31] },
{ t: 1576771823570, v: [3.21, 0.25, 3.51, 297, 259, 38] },
{ t: 1576771830671, v: [5.35, 1.28, 1.67, 273, 229, 44] },
{ t: 1576771841829, v: [5.9, 0.76, 2.4, 347, 337, 10] },
{ t: 1576771847770, v: [4.64, 0.05, 2.36, 326, 281, 45] },
{ t: 1576771861442, v: [3.86, 1.84, 2.75, 332, 292, 40] },
{ t: 1576771874491, v: [5.55, 1.23, 2.41, 272, 222, 50] },
{ t: 1576771883989, v: [3.76, 1.05, 2.22, 289, 239, 50] },
{ t: 1576771892763, v: [6.92, 0.81, 3.16, 346, 296, 50] },
{ t: 1576771906885, v: [5.73, 0.23, 1.73, 331, 299, 32] },
{ t: 1576771921402, v: [4.52, 0.14, 4.54, 256, 221, 35] },
{ t: 1576771938533, v: [5.25, 0.84, 1.11, 300, 259, 41] },
{ t: 1576771957184, v: [5.32, 0.36, 3.33, 340, 308, 32] },
{ t: 1576771970900, v: [5.64, 0.3, 2.21, 268, 256, 12] },
{ t: 1576771983416, v: [3.8, 1.36, 2.13, 348, 301, 47] },
{ t: 1576771992044, v: [3.8, 0.88, 2.49, 254, 236, 18] },
{ t: 1576772000798, v: [7.44, 1.68, 3.89, 302, 259, 43] },
{ t: 1576772010332, v: [4.81, 0.13, 3.17, 292, 247, 45] },
{ t: 1576772021573, v: [7.25, 1.74, 3.81, 272, 248, 24] },
{ t: 1576772041062, v: [5.58, 0.44, 2.31, 279, 245, 34] },
{ t: 1576772051262, v: [3.93, 0.77, 1.26, 276, 246, 30] },
{ t: 1576772061264, v: [6.84, 1.26, 2.18, 268, 231, 37] },
{ t: 1576772077698, v: [5.2, 0.62, 4.78, 336, 299, 37] },
{ t: 1576772083012, v: [7.74, 1.73, 1.12, 342, 314, 28] },
{ t: 1576772096501, v: [3.57, 0.06, 1.6, 340, 296, 44] },
{ t: 1576772111597, v: [6.1, 1.55, 2.17, 294, 260, 34] },
{ t: 1576772129932, v: [6.18, 0.84, 2.43, 289, 243, 46] },
{ t: 1576772142158, v: [5.57, 1.58, 4.02, 310, 261, 49] },
{ t: 1576772151397, v: [6.59, 1.43, 3.03, 255, 232, 23] },
{ t: 1576772158288, v: [6.46, 1.29, 3.18, 311, 270, 41] },
{ t: 1576772168030, v: [5.61, 0.23, 5.2, 343, 325, 18] },
{ t: 1576772177237, v: [3.59, 0.81, 4, 293, 273, 20] },
{ t: 1576772187874, v: [4.51, 0.34, 4.2, 317, 284, 33] },
{ t: 1576772196083, v: [7.66, 1.34, 3.91, 290, 266, 24] },
{ t: 1576772205208, v: [6.25, 1.56, 2.27, 257, 214, 43] },
{ t: 1576772224253, v: [5.04, 1.86, 1.93, 344, 310, 34] },
{ t: 1576772244125, v: [3.6, 0.73, 1.27, 255, 219, 36] },
{ t: 1576772252457, v: [5.52, 0.77, 4.49, 256, 246, 10] },
{ t: 1576772264276, v: [5.03, 1.75, 2.68, 289, 260, 29] },
{ t: 1576772274566, v: [3.8, 1.51, 1.2, 341, 306, 35] },
{ t: 1576772286604, v: [4.94, 1.23, 3.18, 327, 280, 47] },
{ t: 1576772302143, v: [3.4, 1.46, 4.11, 337, 311, 26] },
{ t: 1576772307550, v: [4.84, 0.68, 4.43, 312, 269, 43] },
{ t: 1576772321227, v: [7.69, 0.15, 3.36, 323, 283, 40] },
{ t: 1576772331515, v: [7.56, 1.99, 3, 310, 295, 15] },
{ t: 1576772345082, v: [7.31, 1.8, 1.55, 271, 250, 21] },
{ t: 1576772359209, v: [6.97, 0.82, 1.15, 315, 305, 10] },
{ t: 1576772372030, v: [6.41, 1.37, 2.27, 272, 250, 22] },
{ t: 1576772385776, v: [6.63, 0.74, 3.07, 296, 283, 13] },
{ t: 1576772397793, v: [4.57, 1.09, 3.41, 275, 239, 36] },
{ t: 1576772414660, v: [3.97, 0.21, 2.71, 313, 267, 46] },
{ t: 1576772431081, v: [3.4, 1.95, 2.47, 340, 302, 38] },
{ t: 1576772450841, v: [3.13, 1.97, 1.68, 312, 292, 20] },
{ t: 1576772466288, v: [3.62, 1.69, 1.97, 331, 315, 16] },
{ t: 1576772484922, v: [4.16, 0.43, 2.17, 344, 301, 43] },
{ t: 1576772501099, v: [4.16, 1.18, 3.36, 311, 274, 37] },
{ t: 1576772509500, v: [7.29, 1.29, 4.66, 340, 324, 16] },
{ t: 1576772519860, v: [3.06, 1.5, 1.78, 278, 259, 19] },
{ t: 1576772533616, v: [6.89, 0.98, 4.34, 313, 272, 41] },
{ t: 1576772546716, v: [3.65, 0.13, 3.57, 331, 311, 20] },
{ t: 1576772553141, v: [5.57, 0.65, 3.46, 320, 303, 17] },
{ t: 1576772560379, v: [3.43, 1.43, 2.43, 295, 276, 19] },
{ t: 1576772575881, v: [5.67, 1.03, 1.01, 317, 302, 15] },
{ t: 1576772590470, v: [3.28, 1.18, 1.08, 328, 311, 17] },
{ t: 1576772605268, v: [5.1, 1.01, 1.23, 320, 283, 37] },
{ t: 1576772624802, v: [5.95, 1.07, 1.56, 299, 250, 49] },
{ t: 1576772629845, v: [6.49, 1.28, 3.89, 261, 224, 37] },
{ t: 1576772644681, v: [4.73, 0.34, 3.85, 317, 295, 22] },
{ t: 1576772653200, v: [5, 2, 2.24, 348, 317, 31] },
{ t: 1576772661074, v: [4.5, 1.29, 5.26, 261, 236, 25] },
{ t: 1576772677996, v: [3.79, 1.02, 1.14, 271, 257, 14] },
{ t: 1576772697153, v: [3.47, 0.68, 3.71, 306, 292, 14] },
{ t: 1576772707528, v: [4.94, 1.84, 1.92, 316, 279, 37] },
{ t: 1576772718338, v: [4.99, 0.43, 2.2, 311, 300, 11] },
{ t: 1576772732494, v: [5.26, 0.57, 1.9, 347, 316, 31] },
{ t: 1576772747757, v: [6.64, 1.37, 4.76, 323, 286, 37] },
{ t: 1576772756520, v: [7.51, 1.76, 2.78, 321, 292, 29] },
{ t: 1576772771265, v: [3.63, 0.35, 1.34, 318, 293, 25] },
{ t: 1576772784083, v: [4.53, 0.17, 1.89, 265, 246, 19] },
{ t: 1576772795557, v: [3.39, 1.73, 4.23, 331, 319, 12] },
{ t: 1576772809369, v: [6.37, 1.62, 1.63, 350, 320, 30] },
{ t: 1576772825238, v: [4.23, 0.44, 4.53, 344, 311, 33] },
{ t: 1576772832798, v: [3.33, 1.19, 2.28, 319, 301, 18] },
{ t: 1576772846964, v: [5.16, 0.57, 1.26, 281, 265, 16] },
{ t: 1576772860471, v: [6.53, 1.46, 3.87, 348, 314, 34] },
{ t: 1576772871017, v: [5.74, 0.55, 3.9, 259, 222, 37] },
{ t: 1576772879011, v: [3.11, 0.21, 1.16, 296, 286, 10] },
{ t: 1576772896547, v: [7.38, 1.8, 2.08, 279, 240, 39] },
{ t: 1576772902746, v: [3.84, 1.04, 5.4, 266, 253, 13] },
{ t: 1576772913011, v: [3.99, 1.8, 1.53, 298, 275, 23] },
{ t: 1576772930536, v: [7.95, 0.3, 2.95, 321, 295, 26] },
{ t: 1576772940352, v: [6.86, 1.39, 1.26, 324, 304, 20] },
{ t: 1576772959543, v: [7.77, 1.72, 4.92, 271, 223, 48] },
{ t: 1576772968515, v: [7.79, 1.55, 4.78, 250, 234, 16] },
{ t: 1576772977628, v: [6.7, 1.45, 1.52, 314, 287, 27] },
{ t: 1576772987534, v: [3.95, 1.64, 2.31, 280, 235, 45] },
{ t: 1576772995743, v: [8, 1.15, 1.41, 296, 286, 10] },
{ t: 1576773013349, v: [3.32, 0.92, 3.17, 269, 244, 25] },
{ t: 1576773026530, v: [5.71, 0.37, 2.22, 291, 263, 28] },
{ t: 1576773038968, v: [5.86, 0.47, 3.84, 303, 263, 40] },
{ t: 1576773047160, v: [4.39, 0.2, 3.65, 298, 276, 22] },
{ t: 1576773054060, v: [6.66, 0.88, 3.34, 281, 257, 24] },
{ t: 1576773062702, v: [4.32, 0.89, 1.64, 313, 263, 50] },
{ t: 1576773070756, v: [7.68, 0.01, 2.23, 319, 279, 40] },
{ t: 1576773077792, v: [5.74, 1.01, 2.19, 253, 227, 26] },
{ t: 1576773083042, v: [4, 0.42, 4.22, 298, 254, 44] },
{ t: 1576773098713, v: [5.17, 0.08, 4.11, 346, 311, 35] },
{ t: 1576773105691, v: [3.75, 0.34, 4.54, 268, 252, 16] },
{ t: 1576773121441, v: [6.11, 0.88, 3.67, 255, 242, 13] },
{ t: 1576773140732, v: [3.95, 1.87, 2.74, 288, 262, 26] },
{ t: 1576773150758, v: [5.66, 1.79, 2.04, 257, 219, 38] },
{ t: 1576773161198, v: [4.82, 1.96, 3.29, 272, 234, 38] },
{ t: 1576773175958, v: [3.56, 0.4, 5.1, 347, 322, 25] },
{ t: 1576773189488, v: [6.45, 1.48, 4.4, 257, 234, 23] },
{ t: 1576773199361, v: [6.51, 0.06, 2.11, 349, 330, 19] },
{ t: 1576773215835, v: [6.13, 1.14, 2.77, 295, 273, 22] },
{ t: 1576773223495, v: [4.05, 0.02, 4.22, 310, 275, 35] },
{ t: 1576773243025, v: [5.98, 0.55, 3.17, 333, 313, 20] },
{ t: 1576773253243, v: [3.48, 0.07, 2.13, 309, 287, 22] },
{ t: 1576773259070, v: [7.26, 0.56, 4.02, 317, 295, 22] },
{ t: 1576773271064, v: [5.09, 1.37, 1.1, 279, 266, 13] },
{ t: 1576773276632, v: [4.15, 1.88, 4.35, 258, 239, 19] },
{ t: 1576773286176, v: [4.33, 1.99, 3.16, 344, 299, 45] },
{ t: 1576773296933, v: [5.28, 1.33, 2.61, 301, 285, 16] },
{ t: 1576773308097, v: [5.57, 1.55, 3.49, 320, 298, 22] },
{ t: 1576773324878, v: [5.36, 1.53, 4.33, 313, 274, 39] },
{ t: 1576773342483, v: [5.18, 0.65, 3.99, 281, 248, 33] },
{ t: 1576773356755, v: [4.81, 1.61, 3.84, 293, 269, 24] },
{ t: 1576773376574, v: [8, 1.21, 2.35, 299, 262, 37] },
{ t: 1576773390148, v: [7.29, 1.63, 3.53, 284, 255, 29] },
{ t: 1576773409766, v: [4.56, 0.38, 4.48, 292, 267, 25] },
{ t: 1576773426728, v: [3.32, 0.19, 2.94, 332, 299, 33] },
{ t: 1576773441940, v: [6.46, 0.26, 5.44, 281, 239, 42] },
{ t: 1576773460095, v: [3.24, 0.46, 1.89, 277, 265, 12] },
{ t: 1576773475231, v: [6.08, 1.51, 3.39, 269, 232, 37] },
{ t: 1576773490053, v: [7.91, 0.53, 3.66, 257, 217, 40] },
{ t: 1576773505871, v: [7.36, 0.94, 4.49, 302, 256, 46] },
{ t: 1576773517575, v: [3.01, 2, 1.71, 288, 249, 39] },
{ t: 1576773534020, v: [6.62, 1.57, 3.12, 258, 246, 12] },
{ t: 1576773548201, v: [3.82, 1, 1.18, 277, 234, 43] },
{ t: 1576773560986, v: [4.68, 0.79, 1.01, 328, 283, 45] },
{ t: 1576773578755, v: [5.72, 0.21, 4.13, 285, 269, 16] },
{ t: 1576773597543, v: [7.23, 0.74, 3.46, 313, 284, 29] },
{ t: 1576773606568, v: [5.16, 0.1, 2.85, 289, 272, 17] },
{ t: 1576773622890, v: [4.53, 0.41, 4.04, 257, 238, 19] },
{ t: 1576773632337, v: [3.05, 1.45, 2.04, 347, 329, 18] },
{ t: 1576773644428, v: [6.2, 0.1, 5.13, 254, 240, 14] },
{ t: 1576773650361, v: [3.09, 0.48, 3.92, 277, 267, 10] },
{ t: 1576773662776, v: [7.73, 2, 2.18, 280, 252, 28] },
{ t: 1576773675977, v: [3.27, 0.03, 2.6, 317, 307, 10] },
{ t: 1576773682922, v: [4.03, 0.9, 5.11, 262, 238, 24] },
{ t: 1576773691685, v: [4.71, 1, 4.97, 312, 270, 42] },
{ t: 1576773709586, v: [3.59, 0.95, 1.6, 253, 236, 17] },
{ t: 1576773717476, v: [6.51, 0.85, 5.14, 312, 296, 16] },
{ t: 1576773726595, v: [6.63, 1.8, 1.27, 269, 235, 34] },
{ t: 1576773738548, v: [5.77, 0.5, 1.87, 306, 292, 14] },
{ t: 1576773753429, v: [5.01, 0.92, 3.34, 331, 291, 40] },
{ t: 1576773758609, v: [5.14, 0.32, 1.27, 311, 294, 17] },
{ t: 1576773768714, v: [6.19, 1.95, 3.43, 291, 244, 47] },
{ t: 1576773783065, v: [7.2, 1.51, 4.59, 287, 277, 10] },
{ t: 1576773793166, v: [6.67, 1.78, 4.08, 321, 273, 48] },
{ t: 1576773801488, v: [5.82, 1.18, 1.84, 268, 225, 43] },
{ t: 1576773807841, v: [4.5, 1.74, 2.22, 290, 275, 15] },
{ t: 1576773815556, v: [3.14, 1.8, 4.6, 337, 289, 48] },
{ t: 1576773831715, v: [6.87, 1.83, 1.99, 255, 223, 32] },
{ t: 1576773851582, v: [4.94, 1.33, 4.43, 298, 251, 47] },
{ t: 1576773869715, v: [4.13, 1.39, 2.33, 254, 239, 15] },
{ t: 1576773881339, v: [4.99, 0.23, 1.57, 255, 243, 12] },
{ t: 1576773900195, v: [5.29, 0.47, 4.64, 344, 331, 13] },
{ t: 1576773919927, v: [7.06, 1.45, 3.17, 326, 285, 41] },
{ t: 1576773932850, v: [4.26, 0.12, 4.23, 308, 276, 32] },
{ t: 1576773940173, v: [6.65, 1.07, 3.38, 345, 319, 26] },
{ t: 1576773950476, v: [4.48, 0.7, 2.98, 257, 211, 46] },
{ t: 1576773963862, v: [4.27, 0.43, 4.17, 255, 225, 30] },
{ t: 1576773978902, v: [5.17, 1.39, 3.29, 318, 308, 10] },
{ t: 1576773985460, v: [4.13, 1.25, 4.13, 314, 296, 18] },
{ t: 1576773995310, v: [4.95, 0.38, 5.48, 284, 266, 18] },
{ t: 1576774003841, v: [5.15, 0.14, 1.23, 316, 284, 32] },
{ t: 1576774020202, v: [4.05, 0.49, 1.36, 302, 291, 11] },
{ t: 1576774038772, v: [4.61, 1.11, 4.31, 276, 254, 22] },
{ t: 1576774051042, v: [5.72, 0.1, 1.97, 266, 248, 18] },
{ t: 1576774059331, v: [6.23, 1.01, 3.15, 316, 277, 39] },
{ t: 1576774066921, v: [4.43, 1.58, 2.08, 330, 288, 42] },
{ t: 1576774072239, v: [6.85, 1.96, 1.87, 298, 275, 23] },
{ t: 1576774079223, v: [5.38, 0.71, 4.32, 336, 323, 13] },
{ t: 1576774098784, v: [3.5, 0.1, 2.27, 298, 268, 30] },
{ t: 1576774109971, v: [6.62, 0.5, 2.88, 311, 265, 46] },
{ t: 1576774129011, v: [7.39, 0.16, 4.2, 283, 264, 19] },
{ t: 1576774145540, v: [7.45, 0.72, 3, 289, 254, 35] },
{ t: 1576774163967, v: [3.2, 0.43, 4.34, 304, 284, 20] },
{ t: 1576774169866, v: [7.61, 1.46, 2.39, 314, 277, 37] },
{ t: 1576774188202, v: [3.66, 0.44, 1.66, 327, 298, 29] },
{ t: 1576774196468, v: [5.63, 1.31, 3.1, 257, 224, 33] },
{ t: 1576774214558, v: [4.97, 1.57, 2, 321, 288, 33] },
{ t: 1576774221557, v: [6.98, 0.55, 4.47, 279, 252, 27] },
{ t: 1576774240810, v: [3, 1.54, 2.5, 338, 298, 40] },
{ t: 1576774260335, v: [5, 0.93, 3.38, 310, 284, 26] },
{ t: 1576774266819, v: [7.97, 1.91, 1.4, 262, 218, 44] },
{ t: 1576774279699, v: [3.16, 1.16, 2.53, 283, 248, 35] },
{ t: 1576774295549, v: [5.55, 0.04, 1.28, 329, 288, 41] },
{ t: 1576774314769, v: [6.7, 0.6, 4.92, 342, 307, 35] },
{ t: 1576774321437, v: [4.3, 1.24, 2.24, 267, 255, 12] },
{ t: 1576774326734, v: [5.83, 1.28, 3.16, 346, 300, 46] },
{ t: 1576774334896, v: [7.03, 0.64, 4.27, 269, 254, 15] },
{ t: 1576774341257, v: [6.22, 0.44, 4.18, 312, 282, 30] },
{ t: 1576774347260, v: [4.91, 0.82, 3.46, 335, 303, 32] },
{ t: 1576774361266, v: [7.58, 0.23, 1.34, 255, 243, 12] },
{ t: 1576774371228, v: [7.12, 0.03, 3.98, 272, 234, 38] },
{ t: 1576774377349, v: [7.15, 0.03, 4.41, 333, 301, 32] },
{ t: 1576774394254, v: [4.35, 2, 3.38, 339, 312, 27] },
{ t: 1576774403766, v: [5.95, 1.76, 1.5, 341, 321, 20] },
{ t: 1576774421222, v: [4.18, 0.46, 1.52, 286, 239, 47] },
{ t: 1576774429671, v: [7.17, 1.1, 4.47, 316, 286, 30] },
{ t: 1576774438422, v: [3.28, 1.66, 5.03, 316, 293, 23] },
{ t: 1576774455719, v: [4.99, 0.66, 5.04, 350, 303, 47] },
{ t: 1576774461254, v: [7.76, 1.49, 3.36, 276, 264, 12] },
{ t: 1576774473672, v: [5.62, 1.33, 4.85, 281, 233, 48] },
{ t: 1576774490753, v: [4.5, 0.9, 1.16, 282, 259, 23] },
{ t: 1576774498083, v: [3.17, 0.68, 2.21, 293, 281, 12] },
{ t: 1576774514201, v: [5.65, 0.78, 2.02, 342, 311, 31] },
{ t: 1576774531783, v: [3.77, 0.45, 2.27, 343, 315, 28] },
{ t: 1576774546313, v: [4.2, 1.02, 1.81, 321, 277, 44] },
{ t: 1576774558183, v: [7.59, 1.98, 3.02, 343, 296, 47] },
{ t: 1576774569198, v: [5.5, 1.71, 1.26, 340, 319, 21] },
{ t: 1576774584211, v: [7.75, 0.86, 4.52, 311, 275, 36] },
{ t: 1576774594021, v: [3.08, 1.49, 1.33, 288, 270, 18] },
{ t: 1576774601319, v: [6.7, 1.26, 1.44, 262, 227, 35] },
{ t: 1576774615474, v: [5.82, 0.79, 1.5, 338, 297, 41] },
{ t: 1576774622553, v: [6.9, 0.49, 1.61, 313, 284, 29] },
{ t: 1576774628584, v: [7.09, 1.53, 5.49, 321, 291, 30] },
{ t: 1576774640029, v: [5.58, 0.09, 2.62, 341, 309, 32] },
{ t: 1576774651075, v: [4.52, 1.47, 4.18, 277, 258, 19] },
{ t: 1576774656663, v: [6.68, 0.93, 1.02, 301, 252, 49] },
{ t: 1576774669206, v: [3.32, 0.31, 2.21, 268, 252, 16] },
{ t: 1576774687589, v: [5.59, 0.09, 2.03, 340, 303, 37] },
{ t: 1576774693892, v: [5.43, 1.34, 2.16, 312, 266, 46] },
{ t: 1576774701765, v: [4.14, 0.63, 2.61, 324, 302, 22] },
{ t: 1576774719848, v: [6.28, 0.58, 3.59, 348, 302, 46] },
{ t: 1576774727799, v: [5.57, 1.41, 2.07, 343, 322, 21] },
{ t: 1576774738854, v: [5.55, 1.58, 4.25, 273, 224, 49] },
{ t: 1576774753116, v: [4.11, 0.7, 5.05, 334, 306, 28] },
{ t: 1576774763471, v: [6.13, 1.27, 4.36, 349, 332, 17] },
{ t: 1576774778725, v: [5.44, 0.19, 1.21, 258, 215, 43] },
{ t: 1576774793923, v: [5.65, 0.37, 1.76, 338, 311, 27] },
{ t: 1576774809176, v: [6.91, 1.73, 4.96, 278, 257, 21] },
{ t: 1576774819844, v: [5.76, 0.75, 4.06, 257, 219, 38] },
{ t: 1576774835957, v: [4.08, 0.57, 3.2, 284, 238, 46] },
{ t: 1576774842115, v: [7.63, 1.29, 2.01, 253, 227, 26] },
{ t: 1576774849761, v: [3.99, 1.24, 5.04, 286, 239, 47] },
{ t: 1576774862524, v: [3.5, 0.9, 4.39, 306, 292, 14] },
{ t: 1576774882183, v: [4.26, 1.23, 3.29, 262, 229, 33] },
{ t: 1576774887324, v: [3.09, 0.96, 1.6, 333, 291, 42] },
{ t: 1576774904033, v: [7.66, 0.72, 3.23, 263, 248, 15] },
{ t: 1576774919777, v: [3.94, 1.83, 2.06, 269, 242, 27] },
{ t: 1576774929303, v: [6.6, 0, 1.3, 289, 242, 47] },
{ t: 1576774937906, v: [5.95, 0.95, 4.18, 340, 295, 45] },
{ t: 1576774944650, v: [4.05, 1.22, 4.22, 304, 290, 14] },
{ t: 1576774961280, v: [6.09, 0.22, 5.21, 346, 331, 15] },
{ t: 1576774979275, v: [4.83, 0.02, 3.4, 345, 315, 30] },
{ t: 1576774990372, v: [3.35, 0.05, 2.32, 324, 313, 11] },
{ t: 1576774996852, v: [3.7, 0.48, 2.52, 250, 204, 46] },
{ t: 1576775002411, v: [7.2, 0.36, 1.39, 339, 328, 11] },
{ t: 1576775020252, v: [6.13, 0.36, 2.32, 281, 242, 39] },
{ t: 1576775030551, v: [3.54, 1.11, 3.91, 338, 296, 42] },
{ t: 1576775036841, v: [7.8, 1.54, 4.05, 344, 294, 50] },
{ t: 1576775046980, v: [4.02, 1.14, 4.24, 350, 321, 29] },
{ t: 1576775054332, v: [3.5, 1.54, 1.87, 346, 296, 50] },
{ t: 1576775072354, v: [5.75, 0.79, 4.65, 251, 206, 45] },
{ t: 1576775083038, v: [5.61, 0.82, 1.15, 286, 247, 39] },
{ t: 1576775096869, v: [3.58, 1.89, 1.26, 347, 336, 11] },
{ t: 1576775115850, v: [5.13, 0.06, 2.38, 257, 214, 43] },
{ t: 1576775134901, v: [5.26, 0.76, 1.28, 260, 228, 32] },
{ t: 1576775140660, v: [3.22, 1.88, 1.02, 251, 229, 22] },
{ t: 1576775153225, v: [4.89, 1.5, 4.01, 326, 309, 17] },
{ t: 1576775163327, v: [3.53, 0.76, 5.42, 278, 246, 32] },
{ t: 1576775171287, v: [6.74, 0.73, 4.02, 319, 280, 39] },
{ t: 1576775184148, v: [6.51, 1.02, 4.04, 337, 314, 23] },
{ t: 1576775193317, v: [3.34, 0.77, 3.76, 345, 319, 26] },
{ t: 1576775208178, v: [4.57, 1.12, 4.13, 261, 219, 42] },
{ t: 1576775226316, v: [5.04, 0.16, 4.14, 306, 284, 22] },
{ t: 1576775234139, v: [3.36, 0.11, 4.36, 309, 299, 10] },
{ t: 1576775245824, v: [3.59, 0.55, 2.48, 341, 293, 48] },
{ t: 1576775257997, v: [6.68, 1, 2.52, 336, 300, 36] },
{ t: 1576775275618, v: [7.69, 1.19, 2.79, 282, 238, 44] },
{ t: 1576775282239, v: [4.14, 1.51, 4.71, 279, 258, 21] },
{ t: 1576775288948, v: [5.74, 0.66, 2.29, 257, 226, 31] },
{ t: 1576775299233, v: [3.19, 1.25, 1.83, 315, 305, 10] },
{ t: 1576775311288, v: [4.85, 1.46, 1.71, 291, 270, 21] },
{ t: 1576775317648, v: [3.12, 1.58, 4.41, 296, 263, 33] },
{ t: 1576775326227, v: [6.73, 0.89, 3.41, 340, 317, 23] },
{ t: 1576775341908, v: [5.02, 1.02, 4.48, 252, 234, 18] },
{ t: 1576775360103, v: [5.37, 0.61, 3.03, 295, 261, 34] },
{ t: 1576775371586, v: [3.98, 0.53, 4.02, 289, 239, 50] },
{ t: 1576775382621, v: [3.63, 0.91, 4.45, 266, 241, 25] },
{ t: 1576775400853, v: [3.57, 0.72, 4.81, 264, 241, 23] },
{ t: 1576775410120, v: [5.67, 1.11, 4.16, 339, 324, 15] },
{ t: 1576775420168, v: [7.71, 0.55, 4.4, 350, 311, 39] },
{ t: 1576775431175, v: [7.84, 2, 5.27, 296, 248, 48] },
{ t: 1576775441178, v: [7.27, 1.22, 2.65, 253, 231, 22] },
{ t: 1576775459872, v: [7.29, 0.78, 5.05, 252, 237, 15] },
{ t: 1576775477991, v: [7.6, 0.7, 2.31, 304, 262, 42] },
{ t: 1576775495980, v: [3.01, 1.1, 5.17, 314, 297, 17] },
{ t: 1576775504456, v: [4.14, 0.04, 2.37, 324, 303, 21] },
{ t: 1576775523344, v: [6.89, 0.19, 4.07, 330, 316, 14] },
{ t: 1576775542180, v: [6.83, 0.35, 3.04, 349, 300, 49] },
{ t: 1576775558518, v: [6.82, 1.82, 4.73, 279, 252, 27] },
{ t: 1576775565598, v: [6.07, 0.15, 4.41, 256, 210, 46] },
{ t: 1576775572036, v: [4.5, 1.16, 2.2, 276, 237, 39] },
{ t: 1576775586182, v: [7.01, 0.79, 1.65, 332, 308, 24] },
{ t: 1576775602880, v: [7.63, 0.5, 3.81, 343, 332, 11] },
{ t: 1576775622543, v: [4.54, 1.37, 2.69, 313, 268, 45] },
{ t: 1576775628283, v: [6.42, 0.53, 2.31, 319, 272, 47] },
{ t: 1576775644694, v: [6.34, 0.58, 3.94, 283, 256, 27] },
{ t: 1576775661219, v: [4.45, 0.8, 3.93, 291, 256, 35] },
{ t: 1576775671386, v: [6.05, 0.68, 4.34, 323, 289, 34] },
{ t: 1576775677468, v: [4.71, 0.76, 2.47, 314, 270, 44] },
{ t: 1576775686984, v: [4.06, 1.36, 5.17, 334, 297, 37] },
{ t: 1576775698275, v: [3.94, 0.48, 4.24, 346, 312, 34] },
{ t: 1576775708598, v: [4.38, 1.29, 2.38, 282, 262, 20] },
{ t: 1576775720512, v: [6.13, 1.07, 5.18, 290, 259, 31] },
{ t: 1576775738532, v: [4.62, 1.33, 3.36, 315, 275, 40] },
{ t: 1576775751368, v: [3.73, 0.04, 4.8, 335, 305, 30] },
{ t: 1576775765767, v: [4.01, 1.14, 4.37, 258, 222, 36] },
{ t: 1576775771961, v: [5.64, 1.37, 4.33, 253, 228, 25] },
{ t: 1576775790860, v: [7.61, 0.64, 2.59, 335, 300, 35] },
{ t: 1576775802229, v: [7.72, 0.51, 1, 274, 237, 37] },
{ t: 1576775821455, v: [3.72, 1.81, 4.51, 269, 238, 31] },
{ t: 1576775830950, v: [3.63, 1.13, 4.69, 255, 210, 45] },
{ t: 1576775845479, v: [4.84, 0.65, 2.39, 305, 290, 15] },
{ t: 1576775854499, v: [3.42, 0.43, 4.34, 302, 282, 20] },
{ t: 1576775872652, v: [4.39, 1.26, 1.55, 310, 292, 18] },
{ t: 1576775881675, v: [6.48, 1.48, 1.27, 338, 299, 39] },
{ t: 1576775886779, v: [4.62, 1.81, 5.32, 301, 271, 30] },
{ t: 1576775899998, v: [4.62, 1.85, 4.11, 296, 278, 18] },
{ t: 1576775916920, v: [5.16, 0.4, 2.26, 314, 280, 34] },
{ t: 1576775935226, v: [5.68, 0.69, 1.9, 272, 234, 38] },
{ t: 1576775945061, v: [6.56, 0.19, 5.17, 324, 298, 26] },
{ t: 1576775952826, v: [3.26, 0.52, 3.17, 330, 282, 48] },
{ t: 1576775972709, v: [7.8, 1.96, 4.5, 330, 303, 27] },
{ t: 1576775990215, v: [4.4, 1.38, 2.48, 294, 251, 43] },
{ t: 1576776010079, v: [3.14, 0.39, 1.61, 255, 213, 42] },
{ t: 1576776016542, v: [7.29, 0.64, 3.79, 259, 221, 38] },
{ t: 1576776022442, v: [7.23, 1.38, 4.07, 288, 241, 47] },
{ t: 1576776041826, v: [6.38, 1.05, 2.12, 346, 324, 22] },
{ t: 1576776059267, v: [4.01, 0.46, 3.5, 296, 257, 39] },
{ t: 1576776077391, v: [5.05, 1.07, 4.95, 347, 331, 16] },
{ t: 1576776083969, v: [7.02, 1.36, 5.32, 325, 309, 16] },
{ t: 1576776089802, v: [4.84, 1.94, 4.59, 323, 311, 12] },
{ t: 1576776099126, v: [3.59, 1, 4.95, 295, 248, 47] },
{ t: 1576776109558, v: [7.4, 1.81, 2.01, 338, 297, 41] },
{ t: 1576776123581, v: [7.27, 0.52, 4.85, 337, 321, 16] },
{ t: 1576776135096, v: [4.99, 1.43, 1.49, 288, 255, 33] },
{ t: 1576776143913, v: [6.72, 1.7, 2.26, 329, 310, 19] },
{ t: 1576776161260, v: [4.86, 0.57, 3.87, 298, 261, 37] },
{ t: 1576776176427, v: [4.83, 0, 3.6, 344, 320, 24] },
{ t: 1576776185167, v: [6.72, 0.2, 3.42, 324, 299, 25] },
{ t: 1576776195956, v: [7.42, 0.33, 2.14, 302, 263, 39] },
{ t: 1576776215715, v: [5.25, 1.92, 2.07, 281, 238, 43] },
{ t: 1576776234097, v: [5.06, 0.37, 4.12, 335, 290, 45] },
{ t: 1576776244116, v: [7.23, 0.66, 2.79, 309, 263, 46] },
{ t: 1576776259383, v: [3.84, 1.9, 3.22, 344, 313, 31] },
{ t: 1576776274023, v: [3.04, 1.68, 3.19, 328, 285, 43] },
{ t: 1576776284934, v: [5.17, 0.65, 4.42, 326, 316, 10] },
{ t: 1576776297183, v: [6.79, 0.03, 3.4, 271, 258, 13] },
{ t: 1576776303378, v: [3.79, 0.65, 4.07, 316, 286, 30] },
{ t: 1576776308701, v: [5.93, 1.53, 4.5, 307, 276, 31] },
{ t: 1576776327644, v: [5.49, 1.83, 2.24, 335, 304, 31] },
{ t: 1576776338943, v: [7.51, 1.24, 1.09, 337, 298, 39] },
{ t: 1576776349618, v: [3.73, 0.18, 5.14, 269, 252, 17] },
{ t: 1576776362446, v: [3.75, 0.4, 2.01, 349, 308, 41] },
{ t: 1576776378181, v: [6.6, 0.24, 3.65, 263, 241, 22] },
{ t: 1576776387468, v: [4.66, 1.3, 3.14, 297, 286, 11] },
{ t: 1576776399250, v: [5.98, 1.66, 3.22, 258, 215, 43] },
{ t: 1576776406502, v: [5.31, 1.55, 5.1, 291, 253, 38] },
{ t: 1576776415602, v: [5.66, 1.09, 2.19, 348, 312, 36] },
{ t: 1576776432091, v: [4.93, 0.29, 3.98, 268, 233, 35] },
{ t: 1576776444034, v: [7.43, 1.28, 2.95, 347, 332, 15] },
{ t: 1576776451298, v: [7.31, 0.74, 1.77, 321, 306, 15] },
{ t: 1576776457610, v: [4.44, 0.37, 4.26, 305, 266, 39] },
{ t: 1576776463470, v: [7.98, 0.71, 4.06, 330, 295, 35] },
{ t: 1576776478975, v: [5.71, 1.91, 1.29, 267, 245, 22] },
{ t: 1576776494465, v: [5.73, 0.57, 4.97, 337, 303, 34] },
{ t: 1576776510643, v: [5.43, 0.05, 4.61, 291, 247, 44] },
{ t: 1576776516738, v: [7.77, 1.36, 2.89, 330, 320, 10] },
{ t: 1576776527847, v: [3.59, 0.56, 3.01, 273, 224, 49] },
{ t: 1576776534595, v: [6.07, 1.38, 4.07, 264, 243, 21] },
{ t: 1576776546023, v: [5.39, 0.13, 3.01, 323, 311, 12] },
{ t: 1576776564288, v: [4.45, 0.3, 1.34, 326, 283, 43] },
{ t: 1576776583305, v: [6, 1.42, 3.54, 345, 302, 43] },
{ t: 1576776597285, v: [3.52, 1.97, 2.21, 261, 233, 28] },
{ t: 1576776610186, v: [3.39, 0.12, 2.5, 331, 290, 41] },
{ t: 1576776616583, v: [7.91, 1.26, 4.14, 310, 267, 43] },
{ t: 1576776622193, v: [3.68, 0.42, 1.41, 345, 299, 46] },
{ t: 1576776633033, v: [5.42, 0.43, 4.24, 258, 238, 20] },
{ t: 1576776646283, v: [3.27, 0.6, 4.27, 273, 249, 24] },
{ t: 1576776662991, v: [7.21, 1.28, 4.36, 324, 276, 48] },
{ t: 1576776673055, v: [5.63, 0.41, 2.39, 316, 296, 20] },
{ t: 1576776687139, v: [3.9, 0.46, 1.68, 287, 251, 36] },
{ t: 1576776700051, v: [6.77, 1.62, 2.17, 316, 299, 17] },
{ t: 1576776711677, v: [7.33, 0.55, 2.01, 260, 226, 34] },
{ t: 1576776721257, v: [4.34, 0.44, 3.62, 265, 239, 26] },
{ t: 1576776736029, v: [5.06, 0.59, 4.14, 307, 291, 16] },
{ t: 1576776753382, v: [4.07, 1.08, 1.42, 297, 284, 13] },
{ t: 1576776764351, v: [3.49, 0.15, 3.91, 340, 300, 40] },
{ t: 1576776784169, v: [7.62, 0.67, 5.37, 261, 236, 25] },
{ t: 1576776803038, v: [4.83, 0.56, 5.45, 326, 285, 41] },
{ t: 1576776813994, v: [7.79, 0.6, 3.78, 319, 292, 27] },
{ t: 1576776831267, v: [3.34, 1.13, 2.23, 336, 303, 33] },
{ t: 1576776847965, v: [3.36, 0.98, 2.33, 332, 315, 17] },
{ t: 1576776865745, v: [3.65, 1.52, 3.64, 350, 310, 40] },
{ t: 1576776885419, v: [3.99, 0.37, 4.11, 264, 224, 40] },
{ t: 1576776899033, v: [4.37, 1.59, 3.96, 338, 301, 37] },
{ t: 1576776909148, v: [6.9, 1.08, 4.31, 261, 248, 13] },
{ t: 1576776927342, v: [5.05, 1.66, 2.32, 346, 305, 41] },
{ t: 1576776934938, v: [4.53, 1.78, 2.37, 327, 311, 16] },
{ t: 1576776945281, v: [4.16, 0.47, 2.04, 251, 202, 49] },
{ t: 1576776962908, v: [7.73, 0.32, 4.12, 331, 295, 36] },
{ t: 1576776969815, v: [7.12, 1.57, 1.01, 250, 205, 45] },
{ t: 1576776984638, v: [6.14, 1.89, 4.53, 305, 272, 33] },
{ t: 1576776997177, v: [5.6, 0.42, 2.17, 313, 283, 30] },
{ t: 1576777003916, v: [5.4, 1.72, 2.38, 284, 270, 14] },
{ t: 1576777018737, v: [4.29, 1.29, 4.53, 336, 297, 39] },
{ t: 1576777025444, v: [3.26, 1.09, 3.09, 271, 249, 22] },
{ t: 1576777039473, v: [3.53, 0.06, 1.92, 329, 300, 29] },
{ t: 1576777045767, v: [4.42, 0.24, 2.46, 306, 269, 37] },
{ t: 1576777056606, v: [5.11, 1.96, 3.99, 305, 279, 26] },
{ t: 1576777062484, v: [4.34, 1, 3.76, 268, 248, 20] },
{ t: 1576777067488, v: [5.64, 0.25, 4.3, 266, 235, 31] },
{ t: 1576777082150, v: [3.9, 1.87, 3.08, 251, 226, 25] },
{ t: 1576777095039, v: [4.33, 0.32, 2.73, 338, 298, 40] },
{ t: 1576777105767, v: [4.97, 1.52, 2.98, 310, 260, 50] },
{ t: 1576777118538, v: [7.55, 1.92, 1.81, 275, 249, 26] },
{ t: 1576777129634, v: [3.76, 1.93, 1.95, 350, 336, 14] },
{ t: 1576777148745, v: [4.56, 0.06, 5.05, 267, 247, 20] },
{ t: 1576777157977, v: [7.26, 0.79, 3.05, 293, 261, 32] },
{ t: 1576777164537, v: [3.59, 1.72, 4.68, 277, 233, 44] },
{ t: 1576777178985, v: [7.78, 0.09, 3.55, 262, 250, 12] },
{ t: 1576777195452, v: [3.2, 0.81, 4.05, 274, 253, 21] },
{ t: 1576777209344, v: [3.59, 0.85, 1.48, 315, 284, 31] },
{ t: 1576777219509, v: [5.68, 0.16, 4.52, 250, 208, 42] },
{ t: 1576777236556, v: [4.08, 0.18, 4.55, 285, 246, 39] },
{ t: 1576777255532, v: [4.82, 0.23, 4.23, 305, 259, 46] },
{ t: 1576777268232, v: [4.72, 1.66, 2.15, 275, 238, 37] },
{ t: 1576777274084, v: [7.91, 0.87, 2.09, 257, 217, 40] },
{ t: 1576777289443, v: [6.5, 2, 4.29, 251, 233, 18] },
{ t: 1576777306581, v: [5.53, 0.4, 2.36, 272, 229, 43] },
{ t: 1576777325932, v: [6.51, 1.85, 3.34, 261, 236, 25] },
{ t: 1576777333431, v: [3.03, 0.1, 5, 306, 259, 47] },
{ t: 1576777345361, v: [4.53, 1.19, 1.32, 332, 311, 21] },
{ t: 1576777352896, v: [3.1, 1.84, 1.71, 281, 269, 12] },
{ t: 1576777361731, v: [4.95, 0.38, 2.5, 296, 284, 12] },
{ t: 1576777368229, v: [4.96, 0.21, 3.39, 251, 234, 17] },
{ t: 1576777383258, v: [3.89, 1.07, 2.48, 290, 265, 25] },
{ t: 1576777389749, v: [4.96, 0.45, 1.01, 252, 202, 50] },
{ t: 1576777408527, v: [6.66, 1.12, 5.49, 283, 255, 28] },
{ t: 1576777424031, v: [7.42, 1.42, 2.09, 264, 233, 31] },
{ t: 1576777438101, v: [7.46, 1.93, 1.74, 332, 315, 17] },
{ t: 1576777452052, v: [5.58, 0.72, 2.02, 338, 309, 29] },
{ t: 1576777457286, v: [5.83, 1.95, 4.08, 312, 280, 32] },
{ t: 1576777471220, v: [4.84, 1.1, 3.7, 314, 266, 48] },
{ t: 1576777484272, v: [5.91, 2, 3.32, 309, 279, 30] },
{ t: 1576777497999, v: [3.07, 1.57, 1.88, 305, 275, 30] },
{ t: 1576777517858, v: [4.95, 0.35, 2.74, 331, 287, 44] },
{ t: 1576777535863, v: [5.08, 0.51, 4.06, 325, 284, 41] },
{ t: 1576777548551, v: [4.69, 1.93, 2.46, 256, 238, 18] },
{ t: 1576777554713, v: [5.67, 0.54, 5.03, 333, 289, 44] },
{ t: 1576777563236, v: [6.15, 0.44, 3.94, 346, 300, 46] },
{ t: 1576777572864, v: [3.87, 1.62, 4.84, 334, 324, 10] },
{ t: 1576777586370, v: [3.4, 1.85, 3.17, 324, 293, 31] },
{ t: 1576777604215, v: [4.92, 0.67, 4.32, 323, 299, 24] },
{ t: 1576777612532, v: [7.66, 0.66, 3.4, 268, 250, 18] },
{ t: 1576777619097, v: [7.43, 1.5, 4.94, 300, 287, 13] },
{ t: 1576777630278, v: [4.69, 0.98, 2.96, 273, 250, 23] },
{ t: 1576777647307, v: [5.59, 1.07, 4.42, 251, 201, 50] },
{ t: 1576777664283, v: [7.93, 0.5, 4.68, 280, 247, 33] },
{ t: 1576777671655, v: [3.54, 0.63, 4.78, 341, 327, 14] },
{ t: 1576777686592, v: [3.69, 0.1, 3.33, 335, 286, 49] },
{ t: 1576777698494, v: [3.56, 0.88, 3.25, 261, 218, 43] },
{ t: 1576777706795, v: [6.93, 1.62, 4.52, 294, 244, 50] },
{ t: 1576777715573, v: [7.66, 1.97, 4.11, 303, 270, 33] },
{ t: 1576777733412, v: [5.57, 1.89, 1.04, 322, 274, 48] },
{ t: 1576777749022, v: [7.33, 1.71, 2.27, 269, 249, 20] },
{ t: 1576777767749, v: [5.64, 0.11, 2.01, 274, 242, 32] },
{ t: 1576777778650, v: [7.91, 0.38, 3.2, 250, 239, 11] },
{ t: 1576777792224, v: [3.45, 0.16, 4.46, 331, 310, 21] },
{ t: 1576777807639, v: [5.16, 1.4, 1.67, 336, 300, 36] },
{ t: 1576777827030, v: [4.43, 1.92, 2.02, 314, 280, 34] },
{ t: 1576777837103, v: [3.21, 1.78, 2.75, 332, 289, 43] },
{ t: 1576777854134, v: [7.56, 0.9, 1.35, 313, 268, 45] },
{ t: 1576777871739, v: [3.7, 0.42, 3.88, 321, 301, 20] },
{ t: 1576777882631, v: [4.73, 0.43, 4.97, 339, 303, 36] },
{ t: 1576777894669, v: [7.72, 1.65, 3.34, 345, 296, 49] },
{ t: 1576777910959, v: [4.46, 1.81, 3.48, 284, 245, 39] },
{ t: 1576777919125, v: [3.04, 1.05, 1.84, 259, 224, 35] },
{ t: 1576777932514, v: [6.13, 0.82, 5.38, 266, 246, 20] },
{ t: 1576777941220, v: [3.02, 0.77, 3.12, 269, 219, 50] },
{ t: 1576777949404, v: [5.99, 0.35, 3.23, 273, 255, 18] },
{ t: 1576777965177, v: [3.03, 0.5, 5.45, 266, 251, 15] },
{ t: 1576777970956, v: [4.71, 1.31, 5.22, 333, 298, 35] },
{ t: 1576777984358, v: [6.11, 1.62, 4.94, 278, 267, 11] },
{ t: 1576777997711, v: [7.86, 1.34, 4.16, 291, 275, 16] },
{ t: 1576778005687, v: [6, 1.19, 3.36, 292, 268, 24] },
{ t: 1576778019285, v: [4.98, 0.13, 1.33, 310, 266, 44] },
{ t: 1576778031845, v: [5.95, 0.92, 1.51, 333, 291, 42] },
{ t: 1576778040038, v: [6.02, 0.87, 3.64, 250, 224, 26] },
{ t: 1576778046708, v: [4.51, 1.1, 3.78, 317, 278, 39] },
{ t: 1576778066196, v: [6.59, 0.61, 4.89, 348, 307, 41] },
{ t: 1576778083043, v: [7.35, 1.41, 3.77, 322, 312, 10] },
{ t: 1576778090916, v: [3.98, 1.41, 5.24, 261, 244, 17] },
{ t: 1576778105225, v: [5.29, 1.83, 1.47, 260, 233, 27] },
{ t: 1576778116294, v: [7.82, 1.11, 2.91, 293, 258, 35] },
{ t: 1576778132306, v: [5.32, 1.67, 2.66, 297, 268, 29] },
{ t: 1576778146376, v: [4.95, 1.84, 3.16, 279, 260, 19] },
{ t: 1576778165506, v: [7.64, 1, 2.61, 316, 268, 48] },
{ t: 1576778174506, v: [4.29, 0.26, 3.41, 287, 241, 46] },
{ t: 1576778180145, v: [5.08, 0.74, 2.31, 330, 303, 27] },
{ t: 1576778194417, v: [7.66, 1.7, 3.32, 300, 260, 40] },
{ t: 1576778205926, v: [4.63, 1.02, 3.03, 284, 239, 45] },
{ t: 1576778213897, v: [4.5, 1.4, 2.95, 277, 247, 30] },
{ t: 1576778219749, v: [6.01, 1.42, 4.45, 297, 284, 13] },
{ t: 1576778227726, v: [5.16, 1.45, 3.35, 327, 317, 10] },
{ t: 1576778240840, v: [6.2, 1.32, 3.47, 303, 277, 26] },
{ t: 1576778247233, v: [6.05, 1.98, 4, 281, 268, 13] },
{ t: 1576778265676, v: [5.21, 1.44, 2.13, 261, 215, 46] },
{ t: 1576778281855, v: [7.59, 1.08, 5.49, 273, 236, 37] },
{ t: 1576778296182, v: [7.81, 1.53, 1.25, 286, 270, 16] },
{ t: 1576778316054, v: [7.16, 1.79, 3.19, 250, 231, 19] },
{ t: 1576778324026, v: [3.53, 1.61, 3.26, 321, 310, 11] },
{ t: 1576778337478, v: [7.6, 1.19, 4.17, 345, 332, 13] },
{ t: 1576778354437, v: [6.56, 1.83, 1.42, 331, 315, 16] },
{ t: 1576778367103, v: [6.31, 0.79, 2.74, 271, 244, 27] },
{ t: 1576778372560, v: [5.87, 1.61, 2.42, 326, 290, 36] },
{ t: 1576778385512, v: [6.88, 0.07, 1.88, 297, 275, 22] },
{ t: 1576778403325, v: [5.84, 1.26, 1.79, 323, 311, 12] },
{ t: 1576778417374, v: [7.61, 1.43, 5.18, 257, 243, 14] },
{ t: 1576778425891, v: [7.5, 1.66, 2.27, 266, 225, 41] },
{ t: 1576778432652, v: [5.85, 1.09, 4.19, 284, 247, 37] },
{ t: 1576778442586, v: [4.38, 1.77, 3.21, 342, 324, 18] },
{ t: 1576778448465, v: [6.46, 1.57, 4.46, 268, 247, 21] },
{ t: 1576778456126, v: [3.6, 0.63, 1.62, 284, 256, 28] },
{ t: 1576778469628, v: [4.34, 0.63, 3.7, 284, 250, 34] },
{ t: 1576778485866, v: [3.79, 1.52, 2.17, 282, 266, 16] },
{ t: 1576778500816, v: [4.22, 0.15, 1.9, 323, 305, 18] },
{ t: 1576778507861, v: [3.55, 0.09, 3.3, 258, 231, 27] },
{ t: 1576778519461, v: [4.39, 1.67, 2.13, 308, 263, 45] },
{ t: 1576778538555, v: [5.63, 1.35, 2.57, 262, 241, 21] },
{ t: 1576778553019, v: [6.06, 1.58, 4.27, 288, 252, 36] },
{ t: 1576778570676, v: [5.35, 0.54, 5.09, 329, 315, 14] },
{ t: 1576778587453, v: [7.63, 0.78, 4.48, 293, 255, 38] },
{ t: 1576778597623, v: [7.08, 1.28, 2.44, 338, 307, 31] },
{ t: 1576778603664, v: [4.14, 0.64, 4.5, 349, 310, 39] },
{ t: 1576778623099, v: [4.27, 1.33, 3.3, 302, 263, 39] },
{ t: 1576778642045, v: [3.57, 1.36, 3, 338, 304, 34] },
{ t: 1576778655726, v: [5.72, 0.76, 4.02, 336, 322, 14] },
{ t: 1576778667509, v: [3.82, 0.19, 4.64, 317, 283, 34] },
{ t: 1576778673730, v: [6.3, 1.43, 4.14, 329, 285, 44] },
{ t: 1576778693715, v: [7.82, 0.46, 1.19, 294, 248, 46] },
{ t: 1576778706678, v: [4.54, 0.79, 2.06, 283, 263, 20] },
{ t: 1576778724154, v: [6.63, 0.85, 1.62, 346, 298, 48] },
{ t: 1576778733760, v: [3.31, 0.39, 3.08, 332, 285, 47] },
{ t: 1576778740462, v: [5.09, 0.88, 2.8, 326, 300, 26] },
{ t: 1576778750909, v: [6.43, 0.63, 1.94, 347, 313, 34] },
{ t: 1576778760786, v: [5.07, 0.03, 2.72, 297, 281, 16] },
{ t: 1576778780431, v: [4.54, 1.53, 3.39, 272, 245, 27] },
{ t: 1576778793969, v: [4.14, 1.72, 4.06, 291, 279, 12] },
{ t: 1576778805937, v: [6.53, 1.03, 4.12, 300, 272, 28] },
{ t: 1576778823958, v: [3.51, 1.69, 5.45, 261, 217, 44] },
{ t: 1576778831782, v: [3.89, 2, 4.08, 301, 256, 45] },
{ t: 1576778837085, v: [3.9, 0.02, 2.18, 312, 271, 41] },
{ t: 1576778851414, v: [7.16, 1.05, 2, 319, 271, 48] },
{ t: 1576778862215, v: [7.78, 0.83, 1.12, 250, 236, 14] },
{ t: 1576778877820, v: [4.6, 0.5, 4.31, 272, 244, 28] },
{ t: 1576778894672, v: [5.71, 1.27, 2.27, 304, 293, 11] },
{ t: 1576778909740, v: [7.83, 0.8, 2.11, 291, 260, 31] },
{ t: 1576778918870, v: [4.96, 1.68, 2.73, 287, 253, 34] },
{ t: 1576778931308, v: [7.55, 0.63, 3.92, 336, 325, 11] },
{ t: 1576778939463, v: [4.32, 0.71, 3.49, 259, 249, 10] },
{ t: 1576778948927, v: [3.1, 0.34, 2.26, 271, 230, 41] },
{ t: 1576778956584, v: [5.29, 0.29, 3.94, 282, 247, 35] },
{ t: 1576778971532, v: [7.71, 1.42, 2.21, 279, 251, 28] },
{ t: 1576778990318, v: [5.79, 0.56, 2.22, 286, 249, 37] },
{ t: 1576778999033, v: [4, 1.47, 1, 281, 231, 50] },
{ t: 1576779009448, v: [4.67, 1.36, 3.85, 272, 244, 28] },
{ t: 1576779020290, v: [4.52, 0.15, 2.08, 296, 286, 10] },
{ t: 1576779038272, v: [7.11, 0.14, 4.02, 304, 258, 46] },
{ t: 1576779056131, v: [3.91, 0.76, 3.46, 293, 271, 22] },
{ t: 1576779069852, v: [5.63, 0.17, 1.19, 329, 291, 38] },
{ t: 1576779075707, v: [7.46, 1.16, 3.42, 288, 256, 32] },
{ t: 1576779087879, v: [7.83, 0.13, 3.06, 329, 311, 18] },
{ t: 1576779097203, v: [7.09, 1.67, 5.03, 313, 278, 35] },
{ t: 1576779112345, v: [7.32, 1.65, 4.22, 270, 236, 34] },
{ t: 1576779128171, v: [3.04, 1.81, 2.91, 303, 285, 18] },
{ t: 1576779142085, v: [5.28, 1.04, 2.79, 255, 215, 40] },
{ t: 1576779156449, v: [3.21, 1.9, 1.64, 291, 247, 44] },
{ t: 1576779174051, v: [5.06, 0.93, 3.05, 257, 243, 14] },
{ t: 1576779185302, v: [7.45, 1.13, 2.13, 281, 245, 36] },
{ t: 1576779192710, v: [4.97, 1.63, 2.68, 332, 295, 37] },
{ t: 1576779202148, v: [4.53, 0.57, 5.4, 349, 299, 50] },
{ t: 1576779211789, v: [3.81, 1.14, 2.83, 329, 299, 30] },
{ t: 1576779217540, v: [5.65, 1.21, 3.65, 303, 290, 13] },
{ t: 1576779229767, v: [5.83, 1.95, 3.03, 312, 279, 33] },
{ t: 1576779239754, v: [6.24, 2, 3.45, 320, 306, 14] },
{ t: 1576779247763, v: [7.36, 0.73, 1.12, 285, 261, 24] },
{ t: 1576779252916, v: [4.38, 0.8, 5.31, 267, 246, 21] },
{ t: 1576779259497, v: [6.38, 1.03, 4.88, 280, 270, 10] },
{ t: 1576779272992, v: [4.41, 1.93, 5.12, 287, 275, 12] },
{ t: 1576779280702, v: [6.34, 0.09, 4.26, 267, 247, 20] },
{ t: 1576779295016, v: [7.86, 0.68, 3.37, 327, 297, 30] },
{ t: 1576779306075, v: [6.78, 1, 2.16, 318, 305, 13] },
{ t: 1576779323059, v: [4.42, 1.53, 1.96, 335, 308, 27] },
{ t: 1576779328153, v: [3.13, 1.16, 4.27, 287, 266, 21] },
{ t: 1576779334374, v: [6.59, 0.37, 5.43, 277, 262, 15] },
{ t: 1576779344634, v: [5.02, 0.36, 4.89, 316, 271, 45] },
{ t: 1576779355929, v: [5.54, 1.19, 5.42, 335, 316, 19] },
{ t: 1576779369226, v: [4.19, 1.25, 3.38, 262, 232, 30] },
{ t: 1576779388003, v: [4.31, 1.23, 1.09, 283, 253, 30] },
{ t: 1576779406069, v: [5.53, 0.21, 3.4, 284, 253, 31] },
{ t: 1576779416720, v: [4.23, 1.85, 2.21, 311, 273, 38] },
{ t: 1576779425165, v: [7.92, 0.91, 1.68, 283, 238, 45] },
{ t: 1576779442086, v: [4.95, 1.3, 2.97, 309, 277, 32] },
{ t: 1576779451427, v: [3.92, 1.88, 3.35, 281, 245, 36] },
{ t: 1576779463797, v: [7.53, 0.69, 3.46, 265, 243, 22] },
{ t: 1576779478987, v: [6.75, 1.21, 4.81, 256, 206, 50] },
{ t: 1576779485381, v: [3.33, 1.43, 4.88, 344, 311, 33] },
{ t: 1576779505016, v: [3.52, 1.67, 2.01, 294, 268, 26] },
{ t: 1576779514457, v: [4.81, 1.65, 5.29, 273, 227, 46] },
{ t: 1576779525805, v: [7.8, 0.82, 3.34, 322, 311, 11] },
{ t: 1576779530872, v: [3.8, 0.3, 1.43, 255, 239, 16] },
{ t: 1576779543089, v: [5.25, 0.75, 2.22, 296, 252, 44] },
{ t: 1576779562066, v: [6.02, 1.79, 3.06, 294, 257, 37] },
{ t: 1576779567928, v: [5.97, 1.81, 4.13, 332, 313, 19] },
{ t: 1576779583866, v: [6.3, 1.14, 5.5, 329, 280, 49] },
{ t: 1576779601001, v: [3.56, 0.35, 2.12, 345, 296, 49] },
{ t: 1576779609999, v: [6.86, 0.27, 5.28, 347, 315, 32] },
{ t: 1576779624325, v: [4.04, 1.52, 1.48, 260, 249, 11] },
{ t: 1576779643530, v: [4.34, 1.39, 3.48, 325, 297, 28] },
{ t: 1576779651092, v: [5.88, 1.22, 1.16, 295, 250, 45] },
{ t: 1576779664073, v: [4.11, 0.97, 1.4, 299, 278, 21] },
{ t: 1576779678118, v: [6.48, 0.63, 4.18, 264, 245, 19] },
{ t: 1576779697329, v: [3.51, 1.06, 4.99, 313, 263, 50] },
{ t: 1576779716598, v: [3.35, 0.56, 5.12, 317, 268, 49] },
{ t: 1576779728277, v: [4.69, 1.7, 2.07, 342, 312, 30] },
{ t: 1576779733279, v: [3.61, 1.53, 1.16, 314, 280, 34] },
{ t: 1576779752245, v: [5.52, 0.43, 1.73, 335, 324, 11] },
{ t: 1576779769081, v: [5.56, 0.84, 4.39, 305, 278, 27] },
{ t: 1576779778836, v: [5.64, 1.77, 4.03, 305, 272, 33] },
{ t: 1576779785741, v: [5.42, 1.38, 4.09, 275, 228, 47] },
{ t: 1576779803208, v: [6.08, 1.67, 4.07, 262, 222, 40] },
{ t: 1576779816834, v: [3.31, 0.46, 2.05, 313, 280, 33] },
{ t: 1576779826828, v: [5.58, 1.32, 1.92, 327, 280, 47] },
{ t: 1576779838632, v: [7.42, 1.77, 3.04, 337, 289, 48] },
{ t: 1576779844463, v: [5.14, 0.52, 2.3, 307, 259, 48] },
{ t: 1576779858289, v: [6.52, 0.09, 5.3, 254, 233, 21] },
{ t: 1576779872893, v: [7.7, 1.43, 5.49, 268, 250, 18] },
{ t: 1576779884432, v: [5.16, 1.27, 5.31, 343, 300, 43] },
{ t: 1576779894123, v: [5.55, 1.83, 5.08, 277, 255, 22] },
{ t: 1576779906454, v: [7.71, 0.83, 2.37, 281, 238, 43] },
{ t: 1576779921541, v: [5.04, 1.86, 2.91, 286, 259, 27] },
{ t: 1576779931806, v: [5.87, 0.68, 4.13, 339, 318, 21] },
{ t: 1576779949096, v: [4.64, 1.64, 3.04, 310, 296, 14] },
{ t: 1576779957206, v: [5.8, 1.2, 4.64, 318, 291, 27] },
{ t: 1576779966073, v: [7.94, 0.58, 5.14, 329, 290, 39] },
{ t: 1576779980493, v: [5.81, 1.39, 3.1, 278, 231, 47] },
{ t: 1576779992999, v: [7.18, 1.06, 2.18, 348, 336, 12] },
{ t: 1576779999212, v: [7.04, 0.52, 4.45, 282, 241, 41] },
{ t: 1576780009037, v: [6.51, 0.28, 3.32, 261, 236, 25] },
{ t: 1576780016844, v: [4.7, 0.25, 3.6, 276, 243, 33] },
{ t: 1576780023016, v: [7.65, 1.95, 2.15, 287, 243, 44] },
{ t: 1576780040746, v: [5.03, 1.1, 4.08, 280, 261, 19] },
{ t: 1576780059332, v: [5.94, 1.68, 4.22, 279, 255, 24] },
{ t: 1576780066812, v: [7.38, 1.56, 4.26, 321, 291, 30] },
{ t: 1576780078733, v: [3.77, 1.08, 4.97, 329, 314, 15] },
{ t: 1576780094924, v: [4.43, 0.15, 3.21, 349, 310, 39] },
{ t: 1576780114735, v: [7.95, 1.64, 3.34, 271, 226, 45] },
{ t: 1576780133072, v: [6.42, 1.46, 3.36, 285, 244, 41] },
{ t: 1576780143638, v: [6.38, 0.59, 1.88, 284, 236, 48] },
{ t: 1576780155934, v: [3.82, 1.13, 2.27, 256, 225, 31] },
{ t: 1576780164364, v: [7.48, 0.63, 2.19, 319, 290, 29] },
{ t: 1576780177040, v: [6.62, 0.99, 3.25, 311, 264, 47] },
{ t: 1576780186401, v: [6.52, 0.02, 1.18, 257, 235, 22] },
{ t: 1576780194665, v: [6.92, 1.1, 4.29, 311, 286, 25] },
{ t: 1576780211544, v: [3.36, 1.64, 4.26, 311, 295, 16] },
{ t: 1576780218599, v: [4.46, 0.07, 2.32, 287, 258, 29] },
{ t: 1576780223803, v: [3.71, 0.98, 5.09, 302, 278, 24] },
{ t: 1576780234824, v: [3.1, 1.23, 3.42, 327, 313, 14] },
{ t: 1576780240442, v: [4.37, 0.17, 2.25, 336, 321, 15] },
{ t: 1576780246200, v: [5.09, 0.68, 1.06, 257, 247, 10] },
{ t: 1576780261162, v: [3.86, 0.66, 3.6, 288, 251, 37] },
{ t: 1576780266663, v: [5.16, 0.6, 2.63, 335, 315, 20] },
{ t: 1576780281697, v: [7.14, 0.63, 2.27, 290, 271, 19] },
{ t: 1576780293481, v: [7.23, 0.5, 4.95, 348, 333, 15] },
{ t: 1576780311132, v: [3.16, 0.63, 1.35, 289, 269, 20] },
{ t: 1576780328204, v: [5.51, 0.78, 3.8, 336, 307, 29] },
{ t: 1576780347268, v: [6.11, 0.88, 2.88, 315, 274, 41] },
{ t: 1576780366134, v: [5.49, 0.82, 3.09, 350, 324, 26] },
{ t: 1576780374561, v: [5.73, 1.26, 2.07, 347, 308, 39] },
{ t: 1576780393621, v: [4.97, 1.58, 1.82, 264, 226, 38] },
{ t: 1576780409604, v: [3.85, 0.06, 2.87, 281, 267, 14] },
{ t: 1576780415691, v: [5.26, 0.76, 3.48, 298, 262, 36] },
{ t: 1576780421171, v: [5.42, 0.72, 4.23, 278, 228, 50] },
{ t: 1576780438994, v: [6.24, 0.82, 1.48, 267, 234, 33] },
{ t: 1576780448048, v: [6.16, 0.78, 2.72, 278, 236, 42] },
{ t: 1576780464636, v: [6.36, 1.62, 3.1, 280, 267, 13] },
{ t: 1576780480034, v: [6.99, 1.85, 3.07, 280, 258, 22] },
{ t: 1576780496464, v: [4.17, 0.57, 4.77, 342, 302, 40] },
{ t: 1576780511049, v: [3.08, 0.87, 1.77, 284, 266, 18] },
{ t: 1576780518257, v: [7.31, 0.67, 4.45, 275, 257, 18] },
{ t: 1576780535931, v: [3.12, 1.75, 4.14, 295, 268, 27] },
{ t: 1576780550966, v: [6.48, 1.48, 4.52, 290, 279, 11] },
{ t: 1576780562708, v: [7.19, 0.17, 2.14, 304, 291, 13] },
{ t: 1576780572432, v: [4.17, 0.25, 5.36, 338, 315, 23] },
{ t: 1576780581265, v: [6.11, 1.44, 5.39, 250, 216, 34] },
{ t: 1576780597077, v: [6.6, 1.2, 7.3, 289, 259, 30] }];

export const mockSensorConfig = [{
  "id": 1,
  "idx": 0,
  "calDataJson": null,
  "name": "V1",
  "isVisible": true
},
{
  "id": 2,
  "idx": 1,
  "calDataJson": null,
  "name": "V2",
  "isVisible": true
},
{
  "id": 3,
  "idx": 2,
  "calDataJson": null,
  "name": "V3",
  "isVisible": true
},
{
  "id": 4,
  "idx": 3,
  "calDataJson": null,
  "name": "V4",
  "isVisible": true
},
{
  "id": 5,
  "idx": 4,
  "calDataJson": null,
  "name": "V5",
  "isVisible": true
},
{
  "id": 6,
  "idx": 5,
  "calDataJson": null,
  "name": "V6",
  "isVisible": true
},
{
  "id": 7,
  "idx": 6,
  "calDataJson": null,
  "name": "V7",
  "isVisible": true
},
{
  "id": 8,
  "idx": 7,
  "calDataJson": null,
  "name": "V8",
  "isVisible": true
},
{
  "id": 9,
  "idx": 8,
  "calDataJson": null,
  "name": "I1",
  "isVisible": true
},
{
  "id": 10,
  "idx": 9,
  "calDataJson": null,
  "name": "I2",
  "isVisible": true
},
{
  "id": 11,
  "idx": 10,
  "calDataJson": null,
  "name": "I3",
  "isVisible": true
},
{
  "id": 12,
  "idx": 11,
  "calDataJson": null,
  "name": "I4",
  "isVisible": true
},
{
  "id": 13,
  "idx": 12,
  "calDataJson": null,
  "name": "Temp",
  "isVisible": true
},
{
  "id": 14,
  "idx": 13,
  "calDataJson": null,
  "name": "Humidity",
  "isVisible": true
},
{
  "id": 15,
  "idx": 14,
  "calDataJson": null,
  "name": "AdcVref",
  "isVisible": false
},
{
  "id": 16,
  "idx": 15,
  "calDataJson": null,
  "name": "AdcVdd",
  "isVisible": false
},
{
  "id": 17,
  "idx": 16,
  "calDataJson": null,
  "name": "AdcErr",
  "isVisible": false
},
{
  "id": 18,
  "idx": 17,
  "calDataJson": null,
  "name": "AdcOffset",
  "isVisible": false
}];
export const mockLatestsSensorReadings = {
  "t": null,
  "v": [
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0
  ]
};
export const mockTsData = {
  "ts": 0,
  "te": 0,
  "v": [
    {
      "t": 0,
      "v": [
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0
      ]
    }
  ]
};
export const mockRawEntityPacket = [{
  "id": "1111",
  "timestamp": "_ _ _",
  "packet": "_ _ _",
  "topic": "_ _ _",
  "isIncoming": true,
  "physid": 1
}, {
  "id": "2222",
  "timestamp": "_ _ _",
  "packet": "_ _ _",
  "topic": "_ _ _",
  "isIncoming": true,
  "physid": 1
}, {
  "id": "3333",
  "timestamp": "_ _ _",
  "packet": "_ _ _",
  "topic": "_ _ _",
  "isIncoming": true,
  "physid": 1
}, {
  "id": "4444",
  "timestamp": "_ _ _",
  "packet": "_ _ _",
  "topic": "_ _ _",
  "isIncoming": true,
  "physid": 1
}, {
  "id": "5555",
  "timestamp": "_ _ _",
  "packet": "_ _ _",
  "topic": "_ _ _",
  "isIncoming": true,
  "physid": 1
}];

export const mockPhysicalEntity = {
  "id": 1,
  "name": "_ _ _ _",
  "loc": "_ _ _ _",
  "seenOn": "_ _ _ _",
  "cof": "_ _ _ _",
  "mNo": "_ _ _ _",
  "lat": 27.479118,
  "lng": 77.67333138,
  "hdop": "_ _ _ _",
  "sats": "_ _ _ _",
};
export const mockTrackCircuitTsData = [{ t: 1576768207001, "FEndV": 8.23, "FResV": 1.57, "REndV": 4.13, "FEndmA": 289, "REndmA": 253, "LeakmA": 36 },
{ t: 1576768226758, "FEndV": 7.12, "FResV": 1.74, "REndV": 2.79, "FEndmA": 326, "REndmA": 287, "LeakmA": 39 },
{ t: 1576768238846, "FEndV": 5.11, "FResV": 0.84, "REndV": 3.6, "FEndmA": 299, "REndmA": 289, "LeakmA": 10 },
{ t: 1576768256413, "FEndV": 5.01, "FResV": 0.28, "REndV": 3.4, "FEndmA": 293, "REndmA": 279, "LeakmA": 14 },
{ t: 1576768269555, "FEndV": 6.99, "FResV": 1.01, "REndV": 1.72, "FEndmA": 290, "REndmA": 256, "LeakmA": 34 },
{ t: 1576768277691, "FEndV": 6.27, "FResV": 1.1, "REndV": 4.02, "FEndmA": 345, "REndmA": 324, "LeakmA": 21 },
{ t: 1576768294971, "FEndV": 5.94, "FResV": 0.6, "REndV": 3.64, "FEndmA": 340, "REndmA": 304, "LeakmA": 36 },
{ t: 1576768303726, "FEndV": 7.94, "FResV": 1.42, "REndV": 5.47, "FEndmA": 251, "REndmA": 214, "LeakmA": 37 },
{ t: 1576768321137, "FEndV": 7.58, "FResV": 1.75, "REndV": 4.11, "FEndmA": 308, "REndmA": 287, "LeakmA": 21 },
{ t: 1576768339213, "FEndV": 3.96, "FResV": 1.1, "REndV": 4.81, "FEndmA": 251, "REndmA": 209, "LeakmA": 42 },
{ t: 1576768345265, "FEndV": 6.3, "FResV": 0.83, "REndV": 4.19, "FEndmA": 306, "REndmA": 293, "LeakmA": 13 },
{ t: 1576768363528, "FEndV": 7.17, "FResV": 0.37, "REndV": 1.29, "FEndmA": 279, "REndmA": 260, "LeakmA": 19 },
{ t: 1576768378266, "FEndV": 5.1, "FResV": 0.72, "REndV": 2.21, "FEndmA": 289, "REndmA": 275, "LeakmA": 14 },
{ t: 1576768387549, "FEndV": 6.43, "FResV": 1.39, "REndV": 2.51, "FEndmA": 309, "REndmA": 296, "LeakmA": 13 },
{ t: 1576768396918, "FEndV": 4.88, "FResV": 0.19, "REndV": 2.38, "FEndmA": 348, "REndmA": 338, "LeakmA": 10 },
{ t: 1576768406887, "FEndV": 6.83, "FResV": 0.6, "REndV": 2.01, "FEndmA": 338, "REndmA": 288, "LeakmA": 50 },
{ t: 1576768420579, "FEndV": 3.1, "FResV": 1.22, "REndV": 2.22, "FEndmA": 299, "REndmA": 254, "LeakmA": 45 },
{ t: 1576768434556, "FEndV": 4.67, "FResV": 0.36, "REndV": 5.05, "FEndmA": 290, "REndmA": 245, "LeakmA": 45 },
{ t: 1576768440256, "FEndV": 5.18, "FResV": 1.72, "REndV": 3.98, "FEndmA": 341, "REndmA": 306, "LeakmA": 35 },
{ t: 1576768448176, "FEndV": 7.13, "FResV": 0.57, "REndV": 5.12, "FEndmA": 345, "REndmA": 307, "LeakmA": 38 },
{ t: 1576768453522, "FEndV": 5.71, "FResV": 1.24, "REndV": 2.06, "FEndmA": 316, "REndmA": 273, "LeakmA": 43 },
{ t: 1576768459374, "FEndV": 3.13, "FResV": 1.41, "REndV": 3.08, "FEndmA": 345, "REndmA": 315, "LeakmA": 30 },
{ t: 1576768474272, "FEndV": 7.93, "FResV": 0.02, "REndV": 4.09, "FEndmA": 281, "REndmA": 263, "LeakmA": 18 },
{ t: 1576768494221, "FEndV": 4.48, "FResV": 1.9, "REndV": 5.17, "FEndmA": 266, "REndmA": 250, "LeakmA": 16 },
{ t: 1576768501711, "FEndV": 5.07, "FResV": 1.68, "REndV": 2.06, "FEndmA": 258, "REndmA": 216, "LeakmA": 42 },
{ t: 1576768520267, "FEndV": 4.52, "FResV": 1, "REndV": 3.34, "FEndmA": 275, "REndmA": 237, "LeakmA": 38 },
{ t: 1576768527253, "FEndV": 5.67, "FResV": 1.24, "REndV": 3.61, "FEndmA": 272, "REndmA": 259, "LeakmA": 13 },
{ t: 1576768544462, "FEndV": 7.19, "FResV": 1.5, "REndV": 3.21, "FEndmA": 333, "REndmA": 312, "LeakmA": 21 },
{ t: 1576768563782, "FEndV": 4.7, "FResV": 1.21, "REndV": 5.29, "FEndmA": 320, "REndmA": 277, "LeakmA": 43 },
{ t: 1576768580589, "FEndV": 5.76, "FResV": 1.04, "REndV": 4.07, "FEndmA": 275, "REndmA": 225, "LeakmA": 50 },
{ t: 1576768586321, "FEndV": 7.41, "FResV": 1.11, "REndV": 2.04, "FEndmA": 297, "REndmA": 247, "LeakmA": 50 },
{ t: 1576768601852, "FEndV": 5.45, "FResV": 0.38, "REndV": 2.18, "FEndmA": 257, "REndmA": 207, "LeakmA": 50 },
{ t: 1576768610368, "FEndV": 6.9, "FResV": 0.36, "REndV": 3.11, "FEndmA": 267, "REndmA": 243, "LeakmA": 24 },
{ t: 1576768621303, "FEndV": 7.8, "FResV": 0.17, "REndV": 1.46, "FEndmA": 305, "REndmA": 278, "LeakmA": 27 },
{ t: 1576768636947, "FEndV": 4.37, "FResV": 1.25, "REndV": 4, "FEndmA": 305, "REndmA": 264, "LeakmA": 41 },
{ t: 1576768656511, "FEndV": 7.68, "FResV": 1.93, "REndV": 3.03, "FEndmA": 290, "REndmA": 247, "LeakmA": 43 },
{ t: 1576768672390, "FEndV": 4.36, "FResV": 0.89, "REndV": 1.29, "FEndmA": 340, "REndmA": 308, "LeakmA": 32 },
{ t: 1576768680207, "FEndV": 6.54, "FResV": 1.88, "REndV": 2.36, "FEndmA": 302, "REndmA": 274, "LeakmA": 28 },
{ t: 1576768692461, "FEndV": 3.46, "FResV": 0.73, "REndV": 2.35, "FEndmA": 344, "REndmA": 311, "LeakmA": 33 },
{ t: 1576768708792, "FEndV": 3, "FResV": 1.3, "REndV": 2.13, "FEndmA": 286, "REndmA": 272, "LeakmA": 14 },
{ t: 1576768715931, "FEndV": 4.6, "FResV": 0.77, "REndV": 3.82, "FEndmA": 322, "REndmA": 312, "LeakmA": 10 },
{ t: 1576768729815, "FEndV": 6.99, "FResV": 1.45, "REndV": 4.22, "FEndmA": 304, "REndmA": 292, "LeakmA": 12 },
{ t: 1576768747836, "FEndV": 7.91, "FResV": 1.85, "REndV": 4.28, "FEndmA": 291, "REndmA": 258, "LeakmA": 33 },
{ t: 1576768765267, "FEndV": 4.44, "FResV": 1.51, "REndV": 1.34, "FEndmA": 288, "REndmA": 238, "LeakmA": 50 },
{ t: 1576768784308, "FEndV": 7.73, "FResV": 0.3, "REndV": 3.11, "FEndmA": 287, "REndmA": 239, "LeakmA": 48 },
{ t: 1576768790985, "FEndV": 4.93, "FResV": 1.78, "REndV": 1.79, "FEndmA": 338, "REndmA": 294, "LeakmA": 44 },
{ t: 1576768809737, "FEndV": 7.1, "FResV": 1.23, "REndV": 2.29, "FEndmA": 262, "REndmA": 250, "LeakmA": 12 },
{ t: 1576768814886, "FEndV": 5.71, "FResV": 0.46, "REndV": 3.64, "FEndmA": 345, "REndmA": 330, "LeakmA": 15 },
{ t: 1576768821557, "FEndV": 7.88, "FResV": 0.27, "REndV": 1.7, "FEndmA": 272, "REndmA": 255, "LeakmA": 17 },
{ t: 1576768832612, "FEndV": 7.02, "FResV": 0.43, "REndV": 2.15, "FEndmA": 255, "REndmA": 242, "LeakmA": 13 },
{ t: 1576768849790, "FEndV": 3.25, "FResV": 0.31, "REndV": 3.3, "FEndmA": 334, "REndmA": 303, "LeakmA": 31 },
{ t: 1576768864246, "FEndV": 6.24, "FResV": 1.17, "REndV": 3.52, "FEndmA": 302, "REndmA": 273, "LeakmA": 29 },
{ t: 1576768878619, "FEndV": 6.68, "FResV": 0.94, "REndV": 1.28, "FEndmA": 284, "REndmA": 272, "LeakmA": 12 },
{ t: 1576768893914, "FEndV": 7.44, "FResV": 0.48, "REndV": 4.68, "FEndmA": 252, "REndmA": 227, "LeakmA": 25 },
{ t: 1576768901668, "FEndV": 6.83, "FResV": 0.38, "REndV": 3.85, "FEndmA": 291, "REndmA": 253, "LeakmA": 38 },
{ t: 1576768909825, "FEndV": 5.94, "FResV": 1.53, "REndV": 2.29, "FEndmA": 284, "REndmA": 245, "LeakmA": 39 },
{ t: 1576768919359, "FEndV": 4.68, "FResV": 1.84, "REndV": 1.16, "FEndmA": 309, "REndmA": 299, "LeakmA": 10 },
{ t: 1576768928408, "FEndV": 7.25, "FResV": 1.53, "REndV": 1.52, "FEndmA": 316, "REndmA": 279, "LeakmA": 37 },
{ t: 1576768945409, "FEndV": 3.04, "FResV": 2, "REndV": 5.11, "FEndmA": 303, "REndmA": 281, "LeakmA": 22 },
{ t: 1576768961547, "FEndV": 4.41, "FResV": 0.16, "REndV": 3.02, "FEndmA": 334, "REndmA": 304, "LeakmA": 30 },
{ t: 1576768969297, "FEndV": 4.65, "FResV": 0.85, "REndV": 2.12, "FEndmA": 319, "REndmA": 282, "LeakmA": 37 },
{ t: 1576768976468, "FEndV": 4.45, "FResV": 1.56, "REndV": 3.06, "FEndmA": 260, "REndmA": 215, "LeakmA": 45 },
{ t: 1576768989202, "FEndV": 3.86, "FResV": 1.6, "REndV": 5.36, "FEndmA": 326, "REndmA": 296, "LeakmA": 30 },
{ t: 1576769006574, "FEndV": 5.09, "FResV": 1.91, "REndV": 3.12, "FEndmA": 346, "REndmA": 313, "LeakmA": 33 },
{ t: 1576769018194, "FEndV": 3.77, "FResV": 1.97, "REndV": 3.58, "FEndmA": 301, "REndmA": 251, "LeakmA": 50 },
{ t: 1576769032947, "FEndV": 3.02, "FResV": 1.92, "REndV": 3.48, "FEndmA": 322, "REndmA": 298, "LeakmA": 24 },
{ t: 1576769050938, "FEndV": 6.07, "FResV": 0.6, "REndV": 4.46, "FEndmA": 336, "REndmA": 300, "LeakmA": 36 },
{ t: 1576769060169, "FEndV": 7.42, "FResV": 0.38, "REndV": 5.46, "FEndmA": 280, "REndmA": 265, "LeakmA": 15 },
{ t: 1576769065681, "FEndV": 5.31, "FResV": 0.61, "REndV": 4.16, "FEndmA": 267, "REndmA": 227, "LeakmA": 40 },
{ t: 1576769078148, "FEndV": 4.13, "FResV": 1.24, "REndV": 3.72, "FEndmA": 296, "REndmA": 285, "LeakmA": 11 },
{ t: 1576769096033, "FEndV": 7.1, "FResV": 1.31, "REndV": 4.12, "FEndmA": 295, "REndmA": 269, "LeakmA": 26 },
{ t: 1576769111293, "FEndV": 5.43, "FResV": 1.07, "REndV": 3.5, "FEndmA": 350, "REndmA": 333, "LeakmA": 17 },
{ t: 1576769122886, "FEndV": 4.87, "FResV": 1.38, "REndV": 3.56, "FEndmA": 289, "REndmA": 264, "LeakmA": 25 },
{ t: 1576769141118, "FEndV": 7.88, "FResV": 0.07, "REndV": 3.41, "FEndmA": 324, "REndmA": 284, "LeakmA": 40 },
{ t: 1576769159154, "FEndV": 5.69, "FResV": 0.7, "REndV": 2.68, "FEndmA": 273, "REndmA": 229, "LeakmA": 44 },
{ t: 1576769174453, "FEndV": 4.78, "FResV": 0.95, "REndV": 3.46, "FEndmA": 251, "REndmA": 236, "LeakmA": 15 },
{ t: 1576769193942, "FEndV": 7.04, "FResV": 1.88, "REndV": 5.12, "FEndmA": 302, "REndmA": 261, "LeakmA": 41 },
{ t: 1576769200551, "FEndV": 3.03, "FResV": 0.08, "REndV": 5.27, "FEndmA": 269, "REndmA": 230, "LeakmA": 39 },
{ t: 1576769217422, "FEndV": 3.58, "FResV": 1.94, "REndV": 4.72, "FEndmA": 338, "REndmA": 320, "LeakmA": 18 },
{ t: 1576769230163, "FEndV": 5.48, "FResV": 1.3, "REndV": 3.22, "FEndmA": 305, "REndmA": 283, "LeakmA": 22 },
{ t: 1576769249448, "FEndV": 7.8, "FResV": 0.83, "REndV": 1.14, "FEndmA": 291, "REndmA": 243, "LeakmA": 48 },
{ t: 1576769254958, "FEndV": 7, "FResV": 0.4, "REndV": 1.45, "FEndmA": 303, "REndmA": 286, "LeakmA": 17 },
{ t: 1576769273926, "FEndV": 4.05, "FResV": 0.78, "REndV": 2.55, "FEndmA": 296, "REndmA": 260, "LeakmA": 36 },
{ t: 1576769280436, "FEndV": 7.15, "FResV": 1.57, "REndV": 3, "FEndmA": 349, "REndmA": 329, "LeakmA": 20 },
{ t: 1576769292477, "FEndV": 7.45, "FResV": 1.49, "REndV": 1, "FEndmA": 274, "REndmA": 231, "LeakmA": 43 },
{ t: 1576769299235, "FEndV": 7.59, "FResV": 1.95, "REndV": 1.7, "FEndmA": 298, "REndmA": 267, "LeakmA": 31 },
{ t: 1576769305538, "FEndV": 4.38, "FResV": 0.24, "REndV": 2.76, "FEndmA": 263, "REndmA": 222, "LeakmA": 41 },
{ t: 1576769312344, "FEndV": 3.21, "FResV": 1.56, "REndV": 2.39, "FEndmA": 315, "REndmA": 297, "LeakmA": 18 },
{ t: 1576769327242, "FEndV": 4.95, "FResV": 1.01, "REndV": 3.06, "FEndmA": 304, "REndmA": 287, "LeakmA": 17 },
{ t: 1576769341122, "FEndV": 7.32, "FResV": 1.16, "REndV": 5.12, "FEndmA": 259, "REndmA": 247, "LeakmA": 12 },
{ t: 1576769349568, "FEndV": 7.4, "FResV": 1.95, "REndV": 3.44, "FEndmA": 325, "REndmA": 303, "LeakmA": 22 },
{ t: 1576769357456, "FEndV": 3.38, "FResV": 1.83, "REndV": 3.66, "FEndmA": 298, "REndmA": 282, "LeakmA": 16 },
{ t: 1576769372136, "FEndV": 5.31, "FResV": 0.31, "REndV": 3.02, "FEndmA": 254, "REndmA": 238, "LeakmA": 16 },
{ t: 1576769387996, "FEndV": 4.94, "FResV": 1.85, "REndV": 4.68, "FEndmA": 299, "REndmA": 266, "LeakmA": 33 },
{ t: 1576769401929, "FEndV": 3.47, "FResV": 0.67, "REndV": 3.09, "FEndmA": 321, "REndmA": 306, "LeakmA": 15 },
{ t: 1576769409413, "FEndV": 4.49, "FResV": 1.66, "REndV": 1.27, "FEndmA": 273, "REndmA": 250, "LeakmA": 23 },
{ t: 1576769417977, "FEndV": 3.44, "FResV": 0.97, "REndV": 4.52, "FEndmA": 269, "REndmA": 244, "LeakmA": 25 },
{ t: 1576769437751, "FEndV": 5.49, "FResV": 1.69, "REndV": 3.43, "FEndmA": 270, "REndmA": 232, "LeakmA": 38 },
{ t: 1576769448524, "FEndV": 6.58, "FResV": 1.84, "REndV": 2.34, "FEndmA": 281, "REndmA": 261, "LeakmA": 20 },
{ t: 1576769457468, "FEndV": 5.61, "FResV": 1.78, "REndV": 5.46, "FEndmA": 269, "REndmA": 236, "LeakmA": 33 },
{ t: 1576769475198, "FEndV": 3.63, "FResV": 1.83, "REndV": 4.44, "FEndmA": 266, "REndmA": 226, "LeakmA": 40 },
{ t: 1576769481027, "FEndV": 4.46, "FResV": 1.13, "REndV": 4.67, "FEndmA": 324, "REndmA": 282, "LeakmA": 42 },
{ t: 1576769489635, "FEndV": 3.56, "FResV": 0.23, "REndV": 2.41, "FEndmA": 343, "REndmA": 311, "LeakmA": 32 },
{ t: 1576769508112, "FEndV": 5.15, "FResV": 1.7, "REndV": 4.64, "FEndmA": 314, "REndmA": 293, "LeakmA": 21 },
{ t: 1576769524092, "FEndV": 6.66, "FResV": 1.24, "REndV": 3.37, "FEndmA": 326, "REndmA": 279, "LeakmA": 47 },
{ t: 1576769543508, "FEndV": 3.49, "FResV": 1.26, "REndV": 3.78, "FEndmA": 316, "REndmA": 299, "LeakmA": 17 },
{ t: 1576769551387, "FEndV": 3.04, "FResV": 1.46, "REndV": 3.85, "FEndmA": 301, "REndmA": 281, "LeakmA": 20 },
{ t: 1576769560003, "FEndV": 5.36, "FResV": 1, "REndV": 2.4, "FEndmA": 320, "REndmA": 290, "LeakmA": 30 },
{ t: 1576769572884, "FEndV": 7.5, "FResV": 0.18, "REndV": 2.47, "FEndmA": 251, "REndmA": 214, "LeakmA": 37 },
{ t: 1576769582357, "FEndV": 4.48, "FResV": 0.11, "REndV": 3.38, "FEndmA": 262, "REndmA": 225, "LeakmA": 37 },
{ t: 1576769589566, "FEndV": 5.35, "FResV": 1.9, "REndV": 4.1, "FEndmA": 268, "REndmA": 226, "LeakmA": 42 },
{ t: 1576769601855, "FEndV": 7.57, "FResV": 0.19, "REndV": 3.52, "FEndmA": 322, "REndmA": 275, "LeakmA": 47 },
{ t: 1576769609755, "FEndV": 3.19, "FResV": 1.72, "REndV": 4.06, "FEndmA": 292, "REndmA": 265, "LeakmA": 27 },
{ t: 1576769627952, "FEndV": 3.43, "FResV": 0.5, "REndV": 1.42, "FEndmA": 332, "REndmA": 300, "LeakmA": 32 },
{ t: 1576769635601, "FEndV": 4.2, "FResV": 1.95, "REndV": 4.04, "FEndmA": 338, "REndmA": 313, "LeakmA": 25 },
{ t: 1576769647937, "FEndV": 7.11, "FResV": 0.8, "REndV": 3.3, "FEndmA": 301, "REndmA": 275, "LeakmA": 26 },
{ t: 1576769658370, "FEndV": 7.66, "FResV": 0.24, "REndV": 2.14, "FEndmA": 263, "REndmA": 252, "LeakmA": 11 },
{ t: 1576769669837, "FEndV": 7.87, "FResV": 1.05, "REndV": 2.4, "FEndmA": 331, "REndmA": 287, "LeakmA": 44 },
{ t: 1576769685150, "FEndV": 6.8, "FResV": 1.08, "REndV": 5.48, "FEndmA": 341, "REndmA": 331, "LeakmA": 10 },
{ t: 1576769697735, "FEndV": 4.79, "FResV": 1.21, "REndV": 2.14, "FEndmA": 291, "REndmA": 261, "LeakmA": 30 },
{ t: 1576769712252, "FEndV": 5.5, "FResV": 0.2, "REndV": 2.99, "FEndmA": 307, "REndmA": 273, "LeakmA": 34 },
{ t: 1576769719139, "FEndV": 3.85, "FResV": 1.17, "REndV": 2.06, "FEndmA": 321, "REndmA": 290, "LeakmA": 31 },
{ t: 1576769725842, "FEndV": 6.09, "FResV": 0.22, "REndV": 3, "FEndmA": 324, "REndmA": 281, "LeakmA": 43 },
{ t: 1576769740811, "FEndV": 4.82, "FResV": 2, "REndV": 3.23, "FEndmA": 311, "REndmA": 297, "LeakmA": 14 },
{ t: 1576769756783, "FEndV": 5.36, "FResV": 0.43, "REndV": 2.14, "FEndmA": 285, "REndmA": 263, "LeakmA": 22 },
{ t: 1576769770645, "FEndV": 4.09, "FResV": 0.15, "REndV": 4.19, "FEndmA": 307, "REndmA": 272, "LeakmA": 35 },
{ t: 1576769777540, "FEndV": 4.38, "FResV": 0.56, "REndV": 4.9, "FEndmA": 297, "REndmA": 283, "LeakmA": 14 },
{ t: 1576769794230, "FEndV": 6.97, "FResV": 1.05, "REndV": 3.46, "FEndmA": 334, "REndmA": 307, "LeakmA": 27 },
{ t: 1576769805531, "FEndV": 3.84, "FResV": 0.51, "REndV": 2.85, "FEndmA": 337, "REndmA": 303, "LeakmA": 34 },
{ t: 1576769822205, "FEndV": 4, "FResV": 0.64, "REndV": 4.71, "FEndmA": 263, "REndmA": 233, "LeakmA": 30 },
{ t: 1576769833656, "FEndV": 3.28, "FResV": 1.6, "REndV": 3.97, "FEndmA": 344, "REndmA": 305, "LeakmA": 39 },
{ t: 1576769841607, "FEndV": 7.31, "FResV": 0.9, "REndV": 2.34, "FEndmA": 292, "REndmA": 258, "LeakmA": 34 },
{ t: 1576769851150, "FEndV": 4.42, "FResV": 0.64, "REndV": 4.2, "FEndmA": 322, "REndmA": 304, "LeakmA": 18 },
{ t: 1576769860276, "FEndV": 4.22, "FResV": 0.28, "REndV": 3.25, "FEndmA": 342, "REndmA": 326, "LeakmA": 16 },
{ t: 1576769871990, "FEndV": 4.65, "FResV": 1.98, "REndV": 1.13, "FEndmA": 312, "REndmA": 290, "LeakmA": 22 },
{ t: 1576769886133, "FEndV": 3.28, "FResV": 0.95, "REndV": 3.5, "FEndmA": 330, "REndmA": 293, "LeakmA": 37 },
{ t: 1576769905869, "FEndV": 4.37, "FResV": 1.29, "REndV": 2.39, "FEndmA": 342, "REndmA": 298, "LeakmA": 44 },
{ t: 1576769922778, "FEndV": 7.04, "FResV": 0.77, "REndV": 2.31, "FEndmA": 297, "REndmA": 264, "LeakmA": 33 },
{ t: 1576769939049, "FEndV": 5.77, "FResV": 1.48, "REndV": 1.04, "FEndmA": 290, "REndmA": 255, "LeakmA": 35 },
{ t: 1576769955715, "FEndV": 5.72, "FResV": 0.18, "REndV": 1.39, "FEndmA": 251, "REndmA": 203, "LeakmA": 48 },
{ t: 1576769962309, "FEndV": 6.25, "FResV": 1.75, "REndV": 2.46, "FEndmA": 345, "REndmA": 330, "LeakmA": 15 },
{ t: 1576769979215, "FEndV": 5, "FResV": 0.68, "REndV": 3.45, "FEndmA": 316, "REndmA": 274, "LeakmA": 42 },
{ t: 1576769986951, "FEndV": 6.46, "FResV": 0.59, "REndV": 3.29, "FEndmA": 336, "REndmA": 309, "LeakmA": 27 },
{ t: 1576769998750, "FEndV": 6.04, "FResV": 0.44, "REndV": 2.47, "FEndmA": 341, "REndmA": 305, "LeakmA": 36 },
{ t: 1576770014977, "FEndV": 5.72, "FResV": 0.83, "REndV": 3.62, "FEndmA": 312, "REndmA": 287, "LeakmA": 25 },
{ t: 1576770024627, "FEndV": 7.45, "FResV": 1.05, "REndV": 3.49, "FEndmA": 311, "REndmA": 280, "LeakmA": 31 },
{ t: 1576770041271, "FEndV": 5.81, "FResV": 0.75, "REndV": 4.6, "FEndmA": 285, "REndmA": 271, "LeakmA": 14 },
{ t: 1576770059545, "FEndV": 4.07, "FResV": 1.03, "REndV": 4.08, "FEndmA": 285, "REndmA": 242, "LeakmA": 43 },
{ t: 1576770076244, "FEndV": 5.4, "FResV": 0.21, "REndV": 4.42, "FEndmA": 303, "REndmA": 267, "LeakmA": 36 },
{ t: 1576770092121, "FEndV": 7.76, "FResV": 1.39, "REndV": 3.35, "FEndmA": 310, "REndmA": 284, "LeakmA": 26 },
{ t: 1576770108108, "FEndV": 4.34, "FResV": 1.62, "REndV": 3.3, "FEndmA": 280, "REndmA": 265, "LeakmA": 15 },
{ t: 1576770117898, "FEndV": 5.76, "FResV": 0.16, "REndV": 4.23, "FEndmA": 348, "REndmA": 310, "LeakmA": 38 },
{ t: 1576770124033, "FEndV": 5.37, "FResV": 1.69, "REndV": 5.33, "FEndmA": 316, "REndmA": 279, "LeakmA": 37 },
{ t: 1576770143068, "FEndV": 4.42, "FResV": 1.15, "REndV": 3.78, "FEndmA": 311, "REndmA": 272, "LeakmA": 39 },
{ t: 1576770148884, "FEndV": 3.82, "FResV": 0.79, "REndV": 5.04, "FEndmA": 330, "REndmA": 314, "LeakmA": 16 },
{ t: 1576770160639, "FEndV": 3.44, "FResV": 1.25, "REndV": 4.23, "FEndmA": 264, "REndmA": 231, "LeakmA": 33 },
{ t: 1576770166067, "FEndV": 7.56, "FResV": 0.03, "REndV": 2.35, "FEndmA": 255, "REndmA": 232, "LeakmA": 23 },
{ t: 1576770181278, "FEndV": 7.91, "FResV": 0.69, "REndV": 3.79, "FEndmA": 258, "REndmA": 222, "LeakmA": 36 },
{ t: 1576770196022, "FEndV": 3.84, "FResV": 0.2, "REndV": 4.2, "FEndmA": 268, "REndmA": 249, "LeakmA": 19 },
{ t: 1576770203637, "FEndV": 5.63, "FResV": 0.77, "REndV": 5, "FEndmA": 254, "REndmA": 206, "LeakmA": 48 },
{ t: 1576770213022, "FEndV": 5.12, "FResV": 0.68, "REndV": 2.09, "FEndmA": 251, "REndmA": 224, "LeakmA": 27 },
{ t: 1576770223279, "FEndV": 7.71, "FResV": 0.54, "REndV": 1.69, "FEndmA": 267, "REndmA": 219, "LeakmA": 48 },
{ t: 1576770232107, "FEndV": 6.45, "FResV": 1.66, "REndV": 4.94, "FEndmA": 334, "REndmA": 286, "LeakmA": 48 },
{ t: 1576770249064, "FEndV": 3.1, "FResV": 1.46, "REndV": 4.99, "FEndmA": 327, "REndmA": 293, "LeakmA": 34 },
{ t: 1576770257756, "FEndV": 3.44, "FResV": 0.34, "REndV": 1.81, "FEndmA": 297, "REndmA": 249, "LeakmA": 48 },
{ t: 1576770273124, "FEndV": 5.3, "FResV": 0.51, "REndV": 5.29, "FEndmA": 307, "REndmA": 259, "LeakmA": 48 },
{ t: 1576770291256, "FEndV": 5.76, "FResV": 0.89, "REndV": 4.38, "FEndmA": 325, "REndmA": 308, "LeakmA": 17 },
{ t: 1576770303738, "FEndV": 7.83, "FResV": 1.08, "REndV": 3.62, "FEndmA": 335, "REndmA": 312, "LeakmA": 23 },
{ t: 1576770319300, "FEndV": 4.78, "FResV": 0.7, "REndV": 3.41, "FEndmA": 304, "REndmA": 259, "LeakmA": 45 },
{ t: 1576770328397, "FEndV": 4.07, "FResV": 1.11, "REndV": 3.97, "FEndmA": 320, "REndmA": 283, "LeakmA": 37 },
{ t: 1576770346427, "FEndV": 4.05, "FResV": 0.41, "REndV": 2.21, "FEndmA": 269, "REndmA": 241, "LeakmA": 28 },
{ t: 1576770353286, "FEndV": 7.28, "FResV": 1.02, "REndV": 2.37, "FEndmA": 268, "REndmA": 251, "LeakmA": 17 },
{ t: 1576770359481, "FEndV": 4.54, "FResV": 1.65, "REndV": 1.44, "FEndmA": 272, "REndmA": 252, "LeakmA": 20 },
{ t: 1576770376772, "FEndV": 4.08, "FResV": 1.01, "REndV": 4.39, "FEndmA": 340, "REndmA": 330, "LeakmA": 10 },
{ t: 1576770388666, "FEndV": 3.79, "FResV": 0.21, "REndV": 4.13, "FEndmA": 257, "REndmA": 209, "LeakmA": 48 },
{ t: 1576770403376, "FEndV": 6.58, "FResV": 1.83, "REndV": 5.28, "FEndmA": 328, "REndmA": 306, "LeakmA": 22 },
{ t: 1576770409893, "FEndV": 5.85, "FResV": 0.7, "REndV": 3.54, "FEndmA": 335, "REndmA": 311, "LeakmA": 24 },
{ t: 1576770426875, "FEndV": 3.51, "FResV": 1.85, "REndV": 4.53, "FEndmA": 250, "REndmA": 204, "LeakmA": 46 },
{ t: 1576770433066, "FEndV": 8, "FResV": 0.12, "REndV": 3.49, "FEndmA": 293, "REndmA": 262, "LeakmA": 31 },
{ t: 1576770452010, "FEndV": 3.89, "FResV": 0.56, "REndV": 2.04, "FEndmA": 340, "REndmA": 322, "LeakmA": 18 },
{ t: 1576770465489, "FEndV": 5.14, "FResV": 1.35, "REndV": 3.45, "FEndmA": 306, "REndmA": 273, "LeakmA": 33 },
{ t: 1576770475186, "FEndV": 3.61, "FResV": 1.54, "REndV": 4.48, "FEndmA": 251, "REndmA": 220, "LeakmA": 31 },
{ t: 1576770494521, "FEndV": 5.67, "FResV": 1.72, "REndV": 2.47, "FEndmA": 344, "REndmA": 329, "LeakmA": 15 },
{ t: 1576770506614, "FEndV": 3.67, "FResV": 1.92, "REndV": 4.45, "FEndmA": 287, "REndmA": 266, "LeakmA": 21 },
{ t: 1576770524499, "FEndV": 7.61, "FResV": 0.85, "REndV": 4.46, "FEndmA": 305, "REndmA": 293, "LeakmA": 12 },
{ t: 1576770536200, "FEndV": 3.48, "FResV": 0.13, "REndV": 2.83, "FEndmA": 334, "REndmA": 306, "LeakmA": 28 },
{ t: 1576770542977, "FEndV": 4.73, "FResV": 1.4, "REndV": 3.06, "FEndmA": 324, "REndmA": 283, "LeakmA": 41 },
{ t: 1576770562679, "FEndV": 6.18, "FResV": 1.05, "REndV": 4.96, "FEndmA": 251, "REndmA": 204, "LeakmA": 47 },
{ t: 1576770568052, "FEndV": 5.79, "FResV": 1.7, "REndV": 3.33, "FEndmA": 348, "REndmA": 305, "LeakmA": 43 },
{ t: 1576770580708, "FEndV": 6.12, "FResV": 0.12, "REndV": 3.07, "FEndmA": 286, "REndmA": 265, "LeakmA": 21 },
{ t: 1576770600326, "FEndV": 5.78, "FResV": 1.81, "REndV": 1.9, "FEndmA": 348, "REndmA": 324, "LeakmA": 24 },
{ t: 1576770605781, "FEndV": 7.36, "FResV": 0.44, "REndV": 4.11, "FEndmA": 340, "REndmA": 326, "LeakmA": 14 },
{ t: 1576770616975, "FEndV": 6.18, "FResV": 1.77, "REndV": 3.4, "FEndmA": 329, "REndmA": 300, "LeakmA": 29 },
{ t: 1576770634583, "FEndV": 4.34, "FResV": 0.59, "REndV": 3.25, "FEndmA": 350, "REndmA": 314, "LeakmA": 36 },
{ t: 1576770651633, "FEndV": 4.24, "FResV": 0.51, "REndV": 1.91, "FEndmA": 339, "REndmA": 328, "LeakmA": 11 },
{ t: 1576770658918, "FEndV": 6.35, "FResV": 0.7, "REndV": 2.05, "FEndmA": 347, "REndmA": 324, "LeakmA": 23 },
{ t: 1576770678093, "FEndV": 4.46, "FResV": 0.83, "REndV": 2.05, "FEndmA": 340, "REndmA": 324, "LeakmA": 16 },
{ t: 1576770696087, "FEndV": 4.25, "FResV": 1.75, "REndV": 3.54, "FEndmA": 260, "REndmA": 227, "LeakmA": 33 },
{ t: 1576770712046, "FEndV": 5.23, "FResV": 1.6, "REndV": 1.28, "FEndmA": 323, "REndmA": 304, "LeakmA": 19 },
{ t: 1576770717391, "FEndV": 6.81, "FResV": 0.29, "REndV": 4.22, "FEndmA": 278, "REndmA": 247, "LeakmA": 31 },
{ t: 1576770734974, "FEndV": 5.95, "FResV": 1.68, "REndV": 1.9, "FEndmA": 318, "REndmA": 274, "LeakmA": 44 },
{ t: 1576770740476, "FEndV": 3.52, "FResV": 0.23, "REndV": 2.97, "FEndmA": 258, "REndmA": 222, "LeakmA": 36 },
{ t: 1576770750508, "FEndV": 5.72, "FResV": 0.02, "REndV": 4.2, "FEndmA": 271, "REndmA": 254, "LeakmA": 17 },
{ t: 1576770768075, "FEndV": 6.81, "FResV": 0.51, "REndV": 3.66, "FEndmA": 340, "REndmA": 299, "LeakmA": 41 },
{ t: 1576770779461, "FEndV": 5.37, "FResV": 1.44, "REndV": 4.45, "FEndmA": 319, "REndmA": 301, "LeakmA": 18 },
{ t: 1576770792164, "FEndV": 7.84, "FResV": 0.87, "REndV": 4.4, "FEndmA": 294, "REndmA": 277, "LeakmA": 17 },
{ t: 1576770810671, "FEndV": 4.2, "FResV": 1.25, "REndV": 4.16, "FEndmA": 320, "REndmA": 299, "LeakmA": 21 },
{ t: 1576770826937, "FEndV": 3.73, "FResV": 1.09, "REndV": 4.09, "FEndmA": 289, "REndmA": 278, "LeakmA": 11 },
{ t: 1576770842085, "FEndV": 6.11, "FResV": 1.06, "REndV": 5.44, "FEndmA": 336, "REndmA": 325, "LeakmA": 11 },
{ t: 1576770851364, "FEndV": 3.47, "FResV": 1, "REndV": 1.53, "FEndmA": 265, "REndmA": 254, "LeakmA": 11 },
{ t: 1576770857971, "FEndV": 3.14, "FResV": 1.22, "REndV": 2.9, "FEndmA": 317, "REndmA": 268, "LeakmA": 49 },
{ t: 1576770873363, "FEndV": 4.59, "FResV": 0.47, "REndV": 4.26, "FEndmA": 346, "REndmA": 336, "LeakmA": 10 },
{ t: 1576770890559, "FEndV": 3.94, "FResV": 1.59, "REndV": 1.08, "FEndmA": 257, "REndmA": 222, "LeakmA": 35 },
{ t: 1576770906292, "FEndV": 3.83, "FResV": 0.92, "REndV": 3.11, "FEndmA": 264, "REndmA": 230, "LeakmA": 34 },
{ t: 1576770917735, "FEndV": 4.95, "FResV": 0.27, "REndV": 2.1, "FEndmA": 301, "REndmA": 269, "LeakmA": 32 },
{ t: 1576770926781, "FEndV": 3.52, "FResV": 0.69, "REndV": 3.23, "FEndmA": 336, "REndmA": 289, "LeakmA": 47 },
{ t: 1576770943013, "FEndV": 3.98, "FResV": 1.71, "REndV": 1.87, "FEndmA": 260, "REndmA": 248, "LeakmA": 12 },
{ t: 1576770951689, "FEndV": 4.36, "FResV": 0.96, "REndV": 2.38, "FEndmA": 337, "REndmA": 304, "LeakmA": 33 },
{ t: 1576770961038, "FEndV": 7.96, "FResV": 0.24, "REndV": 4.53, "FEndmA": 324, "REndmA": 299, "LeakmA": 25 },
{ t: 1576770977221, "FEndV": 4.86, "FResV": 1.7, "REndV": 2.37, "FEndmA": 326, "REndmA": 308, "LeakmA": 18 },
{ t: 1576770982947, "FEndV": 6.1, "FResV": 1.87, "REndV": 5.11, "FEndmA": 313, "REndmA": 265, "LeakmA": 48 },
{ t: 1576770994002, "FEndV": 3.13, "FResV": 0.87, "REndV": 2.06, "FEndmA": 293, "REndmA": 271, "LeakmA": 22 },
{ t: 1576771000074, "FEndV": 7.15, "FResV": 1.93, "REndV": 3.01, "FEndmA": 272, "REndmA": 261, "LeakmA": 11 },
{ t: 1576771008936, "FEndV": 5.15, "FResV": 1.57, "REndV": 3.26, "FEndmA": 313, "REndmA": 282, "LeakmA": 31 },
{ t: 1576771025967, "FEndV": 3.78, "FResV": 1.01, "REndV": 4.37, "FEndmA": 265, "REndmA": 244, "LeakmA": 21 },
{ t: 1576771044917, "FEndV": 3.99, "FResV": 1.2, "REndV": 2.69, "FEndmA": 316, "REndmA": 286, "LeakmA": 30 },
{ t: 1576771052690, "FEndV": 4, "FResV": 1.86, "REndV": 4.96, "FEndmA": 344, "REndmA": 303, "LeakmA": 41 },
{ t: 1576771070604, "FEndV": 6.69, "FResV": 0.05, "REndV": 4.33, "FEndmA": 281, "REndmA": 267, "LeakmA": 14 },
{ t: 1576771079671, "FEndV": 6.69, "FResV": 1.17, "REndV": 5.2, "FEndmA": 271, "REndmA": 230, "LeakmA": 41 },
{ t: 1576771097294, "FEndV": 3.98, "FResV": 1.18, "REndV": 3.23, "FEndmA": 307, "REndmA": 279, "LeakmA": 28 },
{ t: 1576771114331, "FEndV": 4.54, "FResV": 1.83, "REndV": 4.46, "FEndmA": 307, "REndmA": 259, "LeakmA": 48 },
{ t: 1576771124576, "FEndV": 6.29, "FResV": 1.07, "REndV": 3.82, "FEndmA": 344, "REndmA": 313, "LeakmA": 31 },
{ t: 1576771130638, "FEndV": 5.52, "FResV": 0.53, "REndV": 4.93, "FEndmA": 337, "REndmA": 310, "LeakmA": 27 },
{ t: 1576771142202, "FEndV": 3.94, "FResV": 0.72, "REndV": 4.2, "FEndmA": 310, "REndmA": 264, "LeakmA": 46 },
{ t: 1576771148778, "FEndV": 3.76, "FResV": 0.4, "REndV": 5.49, "FEndmA": 291, "REndmA": 254, "LeakmA": 37 },
{ t: 1576771156190, "FEndV": 6.56, "FResV": 1.86, "REndV": 4.11, "FEndmA": 278, "REndmA": 258, "LeakmA": 20 },
{ t: 1576771165646, "FEndV": 5.08, "FResV": 0.81, "REndV": 1.55, "FEndmA": 258, "REndmA": 212, "LeakmA": 46 },
{ t: 1576771175848, "FEndV": 6.8, "FResV": 0.82, "REndV": 4.2, "FEndmA": 292, "REndmA": 263, "LeakmA": 29 },
{ t: 1576771181540, "FEndV": 3.83, "FResV": 1.53, "REndV": 4.85, "FEndmA": 335, "REndmA": 319, "LeakmA": 16 },
{ t: 1576771197170, "FEndV": 4.45, "FResV": 1.88, "REndV": 4.01, "FEndmA": 270, "REndmA": 244, "LeakmA": 26 },
{ t: 1576771212081, "FEndV": 7.45, "FResV": 1.97, "REndV": 2.5, "FEndmA": 286, "REndmA": 238, "LeakmA": 48 },
{ t: 1576771220515, "FEndV": 7.21, "FResV": 0.47, "REndV": 1.72, "FEndmA": 263, "REndmA": 237, "LeakmA": 26 },
{ t: 1576771236124, "FEndV": 4.54, "FResV": 0.51, "REndV": 2.42, "FEndmA": 350, "REndmA": 314, "LeakmA": 36 },
{ t: 1576771250933, "FEndV": 3.92, "FResV": 1.52, "REndV": 4.26, "FEndmA": 301, "REndmA": 254, "LeakmA": 47 },
{ t: 1576771266339, "FEndV": 6.91, "FResV": 0.83, "REndV": 2.08, "FEndmA": 330, "REndmA": 320, "LeakmA": 10 },
{ t: 1576771277228, "FEndV": 4.3, "FResV": 1.12, "REndV": 2.07, "FEndmA": 339, "REndmA": 328, "LeakmA": 11 },
{ t: 1576771290376, "FEndV": 7.44, "FResV": 0.85, "REndV": 1.78, "FEndmA": 290, "REndmA": 247, "LeakmA": 43 },
{ t: 1576771296191, "FEndV": 6.09, "FResV": 1.44, "REndV": 1.55, "FEndmA": 339, "REndmA": 303, "LeakmA": 36 },
{ t: 1576771305279, "FEndV": 3.9, "FResV": 1.25, "REndV": 3.73, "FEndmA": 260, "REndmA": 237, "LeakmA": 23 },
{ t: 1576771324557, "FEndV": 5.86, "FResV": 1.7, "REndV": 3.43, "FEndmA": 257, "REndmA": 216, "LeakmA": 41 },
{ t: 1576771338113, "FEndV": 6.74, "FResV": 1.39, "REndV": 1.91, "FEndmA": 337, "REndmA": 288, "LeakmA": 49 },
{ t: 1576771344021, "FEndV": 7.65, "FResV": 1.4, "REndV": 2.69, "FEndmA": 276, "REndmA": 238, "LeakmA": 38 },
{ t: 1576771361564, "FEndV": 5.38, "FResV": 0.03, "REndV": 2.03, "FEndmA": 324, "REndmA": 314, "LeakmA": 10 },
{ t: 1576771371082, "FEndV": 4.3, "FResV": 1.74, "REndV": 4.35, "FEndmA": 314, "REndmA": 299, "LeakmA": 15 },
{ t: 1576771383922, "FEndV": 6.78, "FResV": 0.65, "REndV": 2.13, "FEndmA": 332, "REndmA": 286, "LeakmA": 46 },
{ t: 1576771391051, "FEndV": 3.73, "FResV": 1.25, "REndV": 2.91, "FEndmA": 324, "REndmA": 279, "LeakmA": 45 },
{ t: 1576771410406, "FEndV": 4.64, "FResV": 1.91, "REndV": 1.5, "FEndmA": 318, "REndmA": 308, "LeakmA": 10 },
{ t: 1576771424024, "FEndV": 6.12, "FResV": 1.12, "REndV": 2.45, "FEndmA": 278, "REndmA": 251, "LeakmA": 27 },
{ t: 1576771434356, "FEndV": 3.51, "FResV": 0.65, "REndV": 4.6, "FEndmA": 284, "REndmA": 234, "LeakmA": 50 },
{ t: 1576771440163, "FEndV": 3.14, "FResV": 1.08, "REndV": 4.9, "FEndmA": 334, "REndmA": 287, "LeakmA": 47 },
{ t: 1576771455172, "FEndV": 6.79, "FResV": 0.78, "REndV": 5.25, "FEndmA": 262, "REndmA": 248, "LeakmA": 14 },
{ t: 1576771463857, "FEndV": 7.95, "FResV": 1.28, "REndV": 1.7, "FEndmA": 334, "REndmA": 300, "LeakmA": 34 },
{ t: 1576771482775, "FEndV": 3.05, "FResV": 0.73, "REndV": 4.25, "FEndmA": 254, "REndmA": 215, "LeakmA": 39 },
{ t: 1576771488020, "FEndV": 6.87, "FResV": 0.93, "REndV": 3.25, "FEndmA": 250, "REndmA": 200, "LeakmA": 50 },
{ t: 1576771507525, "FEndV": 6.96, "FResV": 0.86, "REndV": 4.53, "FEndmA": 274, "REndmA": 263, "LeakmA": 11 },
{ t: 1576771519670, "FEndV": 4.27, "FResV": 1.75, "REndV": 1.19, "FEndmA": 347, "REndmA": 307, "LeakmA": 40 },
{ t: 1576771535377, "FEndV": 6.34, "FResV": 0.28, "REndV": 4.46, "FEndmA": 344, "REndmA": 324, "LeakmA": 20 },
{ t: 1576771550047, "FEndV": 5.66, "FResV": 0.53, "REndV": 2.07, "FEndmA": 280, "REndmA": 238, "LeakmA": 42 },
{ t: 1576771569605, "FEndV": 6.59, "FResV": 1.93, "REndV": 3.21, "FEndmA": 343, "REndmA": 301, "LeakmA": 42 },
{ t: 1576771581995, "FEndV": 7.63, "FResV": 1.74, "REndV": 2.01, "FEndmA": 306, "REndmA": 272, "LeakmA": 34 },
{ t: 1576771597712, "FEndV": 3.3, "FResV": 1.65, "REndV": 4.76, "FEndmA": 288, "REndmA": 249, "LeakmA": 39 },
{ t: 1576771606259, "FEndV": 5.92, "FResV": 1.83, "REndV": 3.09, "FEndmA": 291, "REndmA": 277, "LeakmA": 14 },
{ t: 1576771618141, "FEndV": 3.55, "FResV": 1.22, "REndV": 4.76, "FEndmA": 285, "REndmA": 244, "LeakmA": 41 },
{ t: 1576771636435, "FEndV": 5.9, "FResV": 0.99, "REndV": 4.84, "FEndmA": 255, "REndmA": 211, "LeakmA": 44 },
{ t: 1576771656303, "FEndV": 6.12, "FResV": 0.8, "REndV": 1.54, "FEndmA": 315, "REndmA": 267, "LeakmA": 48 },
{ t: 1576771665786, "FEndV": 7.84, "FResV": 0.58, "REndV": 4.27, "FEndmA": 311, "REndmA": 283, "LeakmA": 28 },
{ t: 1576771682538, "FEndV": 7.67, "FResV": 1.83, "REndV": 4.52, "FEndmA": 283, "REndmA": 234, "LeakmA": 49 },
{ t: 1576771695426, "FEndV": 5.32, "FResV": 1.6, "REndV": 2.03, "FEndmA": 302, "REndmA": 261, "LeakmA": 41 },
{ t: 1576771706748, "FEndV": 3.21, "FResV": 0.76, "REndV": 2.57, "FEndmA": 321, "REndmA": 279, "LeakmA": 42 },
{ t: 1576771717654, "FEndV": 7.85, "FResV": 1.26, "REndV": 3.73, "FEndmA": 349, "REndmA": 315, "LeakmA": 34 },
{ t: 1576771724324, "FEndV": 5.45, "FResV": 1.94, "REndV": 2.85, "FEndmA": 255, "REndmA": 212, "LeakmA": 43 },
{ t: 1576771734271, "FEndV": 6.24, "FResV": 0.82, "REndV": 5.34, "FEndmA": 253, "REndmA": 232, "LeakmA": 21 },
{ t: 1576771741253, "FEndV": 3.54, "FResV": 0.95, "REndV": 5.4, "FEndmA": 276, "REndmA": 226, "LeakmA": 50 },
{ t: 1576771759243, "FEndV": 7.12, "FResV": 0.82, "REndV": 2.05, "FEndmA": 252, "REndmA": 215, "LeakmA": 37 },
{ t: 1576771778952, "FEndV": 3.57, "FResV": 0.27, "REndV": 4.94, "FEndmA": 307, "REndmA": 275, "LeakmA": 32 },
{ t: 1576771797294, "FEndV": 7.51, "FResV": 0.1, "REndV": 3.28, "FEndmA": 297, "REndmA": 286, "LeakmA": 11 },
{ t: 1576771804206, "FEndV": 6.85, "FResV": 1.92, "REndV": 3.18, "FEndmA": 250, "REndmA": 239, "LeakmA": 11 },
{ t: 1576771810513, "FEndV": 3.86, "FResV": 0.63, "REndV": 3.24, "FEndmA": 284, "REndmA": 253, "LeakmA": 31 },
{ t: 1576771823570, "FEndV": 3.21, "FResV": 0.25, "REndV": 3.51, "FEndmA": 297, "REndmA": 259, "LeakmA": 38 },
{ t: 1576771830671, "FEndV": 5.35, "FResV": 1.28, "REndV": 1.67, "FEndmA": 273, "REndmA": 229, "LeakmA": 44 },
{ t: 1576771841829, "FEndV": 5.9, "FResV": 0.76, "REndV": 2.4, "FEndmA": 347, "REndmA": 337, "LeakmA": 10 },
{ t: 1576771847770, "FEndV": 4.64, "FResV": 0.05, "REndV": 2.36, "FEndmA": 326, "REndmA": 281, "LeakmA": 45 },
{ t: 1576771861442, "FEndV": 3.86, "FResV": 1.84, "REndV": 2.75, "FEndmA": 332, "REndmA": 292, "LeakmA": 40 },
{ t: 1576771874491, "FEndV": 5.55, "FResV": 1.23, "REndV": 2.41, "FEndmA": 272, "REndmA": 222, "LeakmA": 50 },
{ t: 1576771883989, "FEndV": 3.76, "FResV": 1.05, "REndV": 2.22, "FEndmA": 289, "REndmA": 239, "LeakmA": 50 },
{ t: 1576771892763, "FEndV": 6.92, "FResV": 0.81, "REndV": 3.16, "FEndmA": 346, "REndmA": 296, "LeakmA": 50 },
{ t: 1576771906885, "FEndV": 5.73, "FResV": 0.23, "REndV": 1.73, "FEndmA": 331, "REndmA": 299, "LeakmA": 32 },
{ t: 1576771921402, "FEndV": 4.52, "FResV": 0.14, "REndV": 4.54, "FEndmA": 256, "REndmA": 221, "LeakmA": 35 },
{ t: 1576771938533, "FEndV": 5.25, "FResV": 0.84, "REndV": 1.11, "FEndmA": 300, "REndmA": 259, "LeakmA": 41 },
{ t: 1576771957184, "FEndV": 5.32, "FResV": 0.36, "REndV": 3.33, "FEndmA": 340, "REndmA": 308, "LeakmA": 32 },
{ t: 1576771970900, "FEndV": 5.64, "FResV": 0.3, "REndV": 2.21, "FEndmA": 268, "REndmA": 256, "LeakmA": 12 },
{ t: 1576771983416, "FEndV": 3.8, "FResV": 1.36, "REndV": 2.13, "FEndmA": 348, "REndmA": 301, "LeakmA": 47 },
{ t: 1576771992044, "FEndV": 3.8, "FResV": 0.88, "REndV": 2.49, "FEndmA": 254, "REndmA": 236, "LeakmA": 18 },
{ t: 1576772000798, "FEndV": 7.44, "FResV": 1.68, "REndV": 3.89, "FEndmA": 302, "REndmA": 259, "LeakmA": 43 },
{ t: 1576772010332, "FEndV": 4.81, "FResV": 0.13, "REndV": 3.17, "FEndmA": 292, "REndmA": 247, "LeakmA": 45 },
{ t: 1576772021573, "FEndV": 7.25, "FResV": 1.74, "REndV": 3.81, "FEndmA": 272, "REndmA": 248, "LeakmA": 24 },
{ t: 1576772041062, "FEndV": 5.58, "FResV": 0.44, "REndV": 2.31, "FEndmA": 279, "REndmA": 245, "LeakmA": 34 },
{ t: 1576772051262, "FEndV": 3.93, "FResV": 0.77, "REndV": 1.26, "FEndmA": 276, "REndmA": 246, "LeakmA": 30 },
{ t: 1576772061264, "FEndV": 6.84, "FResV": 1.26, "REndV": 2.18, "FEndmA": 268, "REndmA": 231, "LeakmA": 37 },
{ t: 1576772077698, "FEndV": 5.2, "FResV": 0.62, "REndV": 4.78, "FEndmA": 336, "REndmA": 299, "LeakmA": 37 },
{ t: 1576772083012, "FEndV": 7.74, "FResV": 1.73, "REndV": 1.12, "FEndmA": 342, "REndmA": 314, "LeakmA": 28 },
{ t: 1576772096501, "FEndV": 3.57, "FResV": 0.06, "REndV": 1.6, "FEndmA": 340, "REndmA": 296, "LeakmA": 44 },
{ t: 1576772111597, "FEndV": 6.1, "FResV": 1.55, "REndV": 2.17, "FEndmA": 294, "REndmA": 260, "LeakmA": 34 },
{ t: 1576772129932, "FEndV": 6.18, "FResV": 0.84, "REndV": 2.43, "FEndmA": 289, "REndmA": 243, "LeakmA": 46 },
{ t: 1576772142158, "FEndV": 5.57, "FResV": 1.58, "REndV": 4.02, "FEndmA": 310, "REndmA": 261, "LeakmA": 49 },
{ t: 1576772151397, "FEndV": 6.59, "FResV": 1.43, "REndV": 3.03, "FEndmA": 255, "REndmA": 232, "LeakmA": 23 },
{ t: 1576772158288, "FEndV": 6.46, "FResV": 1.29, "REndV": 3.18, "FEndmA": 311, "REndmA": 270, "LeakmA": 41 },
{ t: 1576772168030, "FEndV": 5.61, "FResV": 0.23, "REndV": 5.2, "FEndmA": 343, "REndmA": 325, "LeakmA": 18 },
{ t: 1576772177237, "FEndV": 3.59, "FResV": 0.81, "REndV": 4, "FEndmA": 293, "REndmA": 273, "LeakmA": 20 },
{ t: 1576772187874, "FEndV": 4.51, "FResV": 0.34, "REndV": 4.2, "FEndmA": 317, "REndmA": 284, "LeakmA": 33 },
{ t: 1576772196083, "FEndV": 7.66, "FResV": 1.34, "REndV": 3.91, "FEndmA": 290, "REndmA": 266, "LeakmA": 24 },
{ t: 1576772205208, "FEndV": 6.25, "FResV": 1.56, "REndV": 2.27, "FEndmA": 257, "REndmA": 214, "LeakmA": 43 },
{ t: 1576772224253, "FEndV": 5.04, "FResV": 1.86, "REndV": 1.93, "FEndmA": 344, "REndmA": 310, "LeakmA": 34 },
{ t: 1576772244125, "FEndV": 3.6, "FResV": 0.73, "REndV": 1.27, "FEndmA": 255, "REndmA": 219, "LeakmA": 36 },
{ t: 1576772252457, "FEndV": 5.52, "FResV": 0.77, "REndV": 4.49, "FEndmA": 256, "REndmA": 246, "LeakmA": 10 },
{ t: 1576772264276, "FEndV": 5.03, "FResV": 1.75, "REndV": 2.68, "FEndmA": 289, "REndmA": 260, "LeakmA": 29 },
{ t: 1576772274566, "FEndV": 3.8, "FResV": 1.51, "REndV": 1.2, "FEndmA": 341, "REndmA": 306, "LeakmA": 35 },
{ t: 1576772286604, "FEndV": 4.94, "FResV": 1.23, "REndV": 3.18, "FEndmA": 327, "REndmA": 280, "LeakmA": 47 },
{ t: 1576772302143, "FEndV": 3.4, "FResV": 1.46, "REndV": 4.11, "FEndmA": 337, "REndmA": 311, "LeakmA": 26 },
{ t: 1576772307550, "FEndV": 4.84, "FResV": 0.68, "REndV": 4.43, "FEndmA": 312, "REndmA": 269, "LeakmA": 43 },
{ t: 1576772321227, "FEndV": 7.69, "FResV": 0.15, "REndV": 3.36, "FEndmA": 323, "REndmA": 283, "LeakmA": 40 },
{ t: 1576772331515, "FEndV": 7.56, "FResV": 1.99, "REndV": 3, "FEndmA": 310, "REndmA": 295, "LeakmA": 15 },
{ t: 1576772345082, "FEndV": 7.31, "FResV": 1.8, "REndV": 1.55, "FEndmA": 271, "REndmA": 250, "LeakmA": 21 },
{ t: 1576772359209, "FEndV": 6.97, "FResV": 0.82, "REndV": 1.15, "FEndmA": 315, "REndmA": 305, "LeakmA": 10 },
{ t: 1576772372030, "FEndV": 6.41, "FResV": 1.37, "REndV": 2.27, "FEndmA": 272, "REndmA": 250, "LeakmA": 22 },
{ t: 1576772385776, "FEndV": 6.63, "FResV": 0.74, "REndV": 3.07, "FEndmA": 296, "REndmA": 283, "LeakmA": 13 },
{ t: 1576772397793, "FEndV": 4.57, "FResV": 1.09, "REndV": 3.41, "FEndmA": 275, "REndmA": 239, "LeakmA": 36 },
{ t: 1576772414660, "FEndV": 3.97, "FResV": 0.21, "REndV": 2.71, "FEndmA": 313, "REndmA": 267, "LeakmA": 46 },
{ t: 1576772431081, "FEndV": 3.4, "FResV": 1.95, "REndV": 2.47, "FEndmA": 340, "REndmA": 302, "LeakmA": 38 },
{ t: 1576772450841, "FEndV": 3.13, "FResV": 1.97, "REndV": 1.68, "FEndmA": 312, "REndmA": 292, "LeakmA": 20 },
{ t: 1576772466288, "FEndV": 3.62, "FResV": 1.69, "REndV": 1.97, "FEndmA": 331, "REndmA": 315, "LeakmA": 16 },
{ t: 1576772484922, "FEndV": 4.16, "FResV": 0.43, "REndV": 2.17, "FEndmA": 344, "REndmA": 301, "LeakmA": 43 },
{ t: 1576772501099, "FEndV": 4.16, "FResV": 1.18, "REndV": 3.36, "FEndmA": 311, "REndmA": 274, "LeakmA": 37 },
{ t: 1576772509500, "FEndV": 7.29, "FResV": 1.29, "REndV": 4.66, "FEndmA": 340, "REndmA": 324, "LeakmA": 16 },
{ t: 1576772519860, "FEndV": 3.06, "FResV": 1.5, "REndV": 1.78, "FEndmA": 278, "REndmA": 259, "LeakmA": 19 },
{ t: 1576772533616, "FEndV": 6.89, "FResV": 0.98, "REndV": 4.34, "FEndmA": 313, "REndmA": 272, "LeakmA": 41 },
{ t: 1576772546716, "FEndV": 3.65, "FResV": 0.13, "REndV": 3.57, "FEndmA": 331, "REndmA": 311, "LeakmA": 20 },
{ t: 1576772553141, "FEndV": 5.57, "FResV": 0.65, "REndV": 3.46, "FEndmA": 320, "REndmA": 303, "LeakmA": 17 },
{ t: 1576772560379, "FEndV": 3.43, "FResV": 1.43, "REndV": 2.43, "FEndmA": 295, "REndmA": 276, "LeakmA": 19 },
{ t: 1576772575881, "FEndV": 5.67, "FResV": 1.03, "REndV": 1.01, "FEndmA": 317, "REndmA": 302, "LeakmA": 15 },
{ t: 1576772590470, "FEndV": 3.28, "FResV": 1.18, "REndV": 1.08, "FEndmA": 328, "REndmA": 311, "LeakmA": 17 },
{ t: 1576772605268, "FEndV": 5.1, "FResV": 1.01, "REndV": 1.23, "FEndmA": 320, "REndmA": 283, "LeakmA": 37 },
{ t: 1576772624802, "FEndV": 5.95, "FResV": 1.07, "REndV": 1.56, "FEndmA": 299, "REndmA": 250, "LeakmA": 49 },
{ t: 1576772629845, "FEndV": 6.49, "FResV": 1.28, "REndV": 3.89, "FEndmA": 261, "REndmA": 224, "LeakmA": 37 },
{ t: 1576772644681, "FEndV": 4.73, "FResV": 0.34, "REndV": 3.85, "FEndmA": 317, "REndmA": 295, "LeakmA": 22 },
{ t: 1576772653200, "FEndV": 5, "FResV": 2, "REndV": 2.24, "FEndmA": 348, "REndmA": 317, "LeakmA": 31 },
{ t: 1576772661074, "FEndV": 4.5, "FResV": 1.29, "REndV": 5.26, "FEndmA": 261, "REndmA": 236, "LeakmA": 25 },
{ t: 1576772677996, "FEndV": 3.79, "FResV": 1.02, "REndV": 1.14, "FEndmA": 271, "REndmA": 257, "LeakmA": 14 },
{ t: 1576772697153, "FEndV": 3.47, "FResV": 0.68, "REndV": 3.71, "FEndmA": 306, "REndmA": 292, "LeakmA": 14 },
{ t: 1576772707528, "FEndV": 4.94, "FResV": 1.84, "REndV": 1.92, "FEndmA": 316, "REndmA": 279, "LeakmA": 37 },
{ t: 1576772718338, "FEndV": 4.99, "FResV": 0.43, "REndV": 2.2, "FEndmA": 311, "REndmA": 300, "LeakmA": 11 },
{ t: 1576772732494, "FEndV": 5.26, "FResV": 0.57, "REndV": 1.9, "FEndmA": 347, "REndmA": 316, "LeakmA": 31 },
{ t: 1576772747757, "FEndV": 6.64, "FResV": 1.37, "REndV": 4.76, "FEndmA": 323, "REndmA": 286, "LeakmA": 37 },
{ t: 1576772756520, "FEndV": 7.51, "FResV": 1.76, "REndV": 2.78, "FEndmA": 321, "REndmA": 292, "LeakmA": 29 },
{ t: 1576772771265, "FEndV": 3.63, "FResV": 0.35, "REndV": 1.34, "FEndmA": 318, "REndmA": 293, "LeakmA": 25 },
{ t: 1576772784083, "FEndV": 4.53, "FResV": 0.17, "REndV": 1.89, "FEndmA": 265, "REndmA": 246, "LeakmA": 19 },
{ t: 1576772795557, "FEndV": 3.39, "FResV": 1.73, "REndV": 4.23, "FEndmA": 331, "REndmA": 319, "LeakmA": 12 },
{ t: 1576772809369, "FEndV": 6.37, "FResV": 1.62, "REndV": 1.63, "FEndmA": 350, "REndmA": 320, "LeakmA": 30 },
{ t: 1576772825238, "FEndV": 4.23, "FResV": 0.44, "REndV": 4.53, "FEndmA": 344, "REndmA": 311, "LeakmA": 33 },
{ t: 1576772832798, "FEndV": 3.33, "FResV": 1.19, "REndV": 2.28, "FEndmA": 319, "REndmA": 301, "LeakmA": 18 },
{ t: 1576772846964, "FEndV": 5.16, "FResV": 0.57, "REndV": 1.26, "FEndmA": 281, "REndmA": 265, "LeakmA": 16 },
{ t: 1576772860471, "FEndV": 6.53, "FResV": 1.46, "REndV": 3.87, "FEndmA": 348, "REndmA": 314, "LeakmA": 34 },
{ t: 1576772871017, "FEndV": 5.74, "FResV": 0.55, "REndV": 3.9, "FEndmA": 259, "REndmA": 222, "LeakmA": 37 },
{ t: 1576772879011, "FEndV": 3.11, "FResV": 0.21, "REndV": 1.16, "FEndmA": 296, "REndmA": 286, "LeakmA": 10 },
{ t: 1576772896547, "FEndV": 7.38, "FResV": 1.8, "REndV": 2.08, "FEndmA": 279, "REndmA": 240, "LeakmA": 39 },
{ t: 1576772902746, "FEndV": 3.84, "FResV": 1.04, "REndV": 5.4, "FEndmA": 266, "REndmA": 253, "LeakmA": 13 },
{ t: 1576772913011, "FEndV": 3.99, "FResV": 1.8, "REndV": 1.53, "FEndmA": 298, "REndmA": 275, "LeakmA": 23 },
{ t: 1576772930536, "FEndV": 7.95, "FResV": 0.3, "REndV": 2.95, "FEndmA": 321, "REndmA": 295, "LeakmA": 26 },
{ t: 1576772940352, "FEndV": 6.86, "FResV": 1.39, "REndV": 1.26, "FEndmA": 324, "REndmA": 304, "LeakmA": 20 },
{ t: 1576772959543, "FEndV": 7.77, "FResV": 1.72, "REndV": 4.92, "FEndmA": 271, "REndmA": 223, "LeakmA": 48 },
{ t: 1576772968515, "FEndV": 7.79, "FResV": 1.55, "REndV": 4.78, "FEndmA": 250, "REndmA": 234, "LeakmA": 16 },
{ t: 1576772977628, "FEndV": 6.7, "FResV": 1.45, "REndV": 1.52, "FEndmA": 314, "REndmA": 287, "LeakmA": 27 },
{ t: 1576772987534, "FEndV": 3.95, "FResV": 1.64, "REndV": 2.31, "FEndmA": 280, "REndmA": 235, "LeakmA": 45 },
{ t: 1576772995743, "FEndV": 8, "FResV": 1.15, "REndV": 1.41, "FEndmA": 296, "REndmA": 286, "LeakmA": 10 },
{ t: 1576773013349, "FEndV": 3.32, "FResV": 0.92, "REndV": 3.17, "FEndmA": 269, "REndmA": 244, "LeakmA": 25 },
{ t: 1576773026530, "FEndV": 5.71, "FResV": 0.37, "REndV": 2.22, "FEndmA": 291, "REndmA": 263, "LeakmA": 28 },
{ t: 1576773038968, "FEndV": 5.86, "FResV": 0.47, "REndV": 3.84, "FEndmA": 303, "REndmA": 263, "LeakmA": 40 },
{ t: 1576773047160, "FEndV": 4.39, "FResV": 0.2, "REndV": 3.65, "FEndmA": 298, "REndmA": 276, "LeakmA": 22 },
{ t: 1576773054060, "FEndV": 6.66, "FResV": 0.88, "REndV": 3.34, "FEndmA": 281, "REndmA": 257, "LeakmA": 24 },
{ t: 1576773062702, "FEndV": 4.32, "FResV": 0.89, "REndV": 1.64, "FEndmA": 313, "REndmA": 263, "LeakmA": 50 },
{ t: 1576773070756, "FEndV": 7.68, "FResV": 0.01, "REndV": 2.23, "FEndmA": 319, "REndmA": 279, "LeakmA": 40 },
{ t: 1576773077792, "FEndV": 5.74, "FResV": 1.01, "REndV": 2.19, "FEndmA": 253, "REndmA": 227, "LeakmA": 26 },
{ t: 1576773083042, "FEndV": 4, "FResV": 0.42, "REndV": 4.22, "FEndmA": 298, "REndmA": 254, "LeakmA": 44 },
{ t: 1576773098713, "FEndV": 5.17, "FResV": 0.08, "REndV": 4.11, "FEndmA": 346, "REndmA": 311, "LeakmA": 35 },
{ t: 1576773105691, "FEndV": 3.75, "FResV": 0.34, "REndV": 4.54, "FEndmA": 268, "REndmA": 252, "LeakmA": 16 },
{ t: 1576773121441, "FEndV": 6.11, "FResV": 0.88, "REndV": 3.67, "FEndmA": 255, "REndmA": 242, "LeakmA": 13 },
{ t: 1576773140732, "FEndV": 3.95, "FResV": 1.87, "REndV": 2.74, "FEndmA": 288, "REndmA": 262, "LeakmA": 26 },
{ t: 1576773150758, "FEndV": 5.66, "FResV": 1.79, "REndV": 2.04, "FEndmA": 257, "REndmA": 219, "LeakmA": 38 },
{ t: 1576773161198, "FEndV": 4.82, "FResV": 1.96, "REndV": 3.29, "FEndmA": 272, "REndmA": 234, "LeakmA": 38 },
{ t: 1576773175958, "FEndV": 3.56, "FResV": 0.4, "REndV": 5.1, "FEndmA": 347, "REndmA": 322, "LeakmA": 25 },
{ t: 1576773189488, "FEndV": 6.45, "FResV": 1.48, "REndV": 4.4, "FEndmA": 257, "REndmA": 234, "LeakmA": 23 },
{ t: 1576773199361, "FEndV": 6.51, "FResV": 0.06, "REndV": 2.11, "FEndmA": 349, "REndmA": 330, "LeakmA": 19 },
{ t: 1576773215835, "FEndV": 6.13, "FResV": 1.14, "REndV": 2.77, "FEndmA": 295, "REndmA": 273, "LeakmA": 22 },
{ t: 1576773223495, "FEndV": 4.05, "FResV": 0.02, "REndV": 4.22, "FEndmA": 310, "REndmA": 275, "LeakmA": 35 },
{ t: 1576773243025, "FEndV": 5.98, "FResV": 0.55, "REndV": 3.17, "FEndmA": 333, "REndmA": 313, "LeakmA": 20 },
{ t: 1576773253243, "FEndV": 3.48, "FResV": 0.07, "REndV": 2.13, "FEndmA": 309, "REndmA": 287, "LeakmA": 22 },
{ t: 1576773259070, "FEndV": 7.26, "FResV": 0.56, "REndV": 4.02, "FEndmA": 317, "REndmA": 295, "LeakmA": 22 },
{ t: 1576773271064, "FEndV": 5.09, "FResV": 1.37, "REndV": 1.1, "FEndmA": 279, "REndmA": 266, "LeakmA": 13 },
{ t: 1576773276632, "FEndV": 4.15, "FResV": 1.88, "REndV": 4.35, "FEndmA": 258, "REndmA": 239, "LeakmA": 19 },
{ t: 1576773286176, "FEndV": 4.33, "FResV": 1.99, "REndV": 3.16, "FEndmA": 344, "REndmA": 299, "LeakmA": 45 },
{ t: 1576773296933, "FEndV": 5.28, "FResV": 1.33, "REndV": 2.61, "FEndmA": 301, "REndmA": 285, "LeakmA": 16 },
{ t: 1576773308097, "FEndV": 5.57, "FResV": 1.55, "REndV": 3.49, "FEndmA": 320, "REndmA": 298, "LeakmA": 22 },
{ t: 1576773324878, "FEndV": 5.36, "FResV": 1.53, "REndV": 4.33, "FEndmA": 313, "REndmA": 274, "LeakmA": 39 },
{ t: 1576773342483, "FEndV": 5.18, "FResV": 0.65, "REndV": 3.99, "FEndmA": 281, "REndmA": 248, "LeakmA": 33 },
{ t: 1576773356755, "FEndV": 4.81, "FResV": 1.61, "REndV": 3.84, "FEndmA": 293, "REndmA": 269, "LeakmA": 24 },
{ t: 1576773376574, "FEndV": 8, "FResV": 1.21, "REndV": 2.35, "FEndmA": 299, "REndmA": 262, "LeakmA": 37 },
{ t: 1576773390148, "FEndV": 7.29, "FResV": 1.63, "REndV": 3.53, "FEndmA": 284, "REndmA": 255, "LeakmA": 29 },
{ t: 1576773409766, "FEndV": 4.56, "FResV": 0.38, "REndV": 4.48, "FEndmA": 292, "REndmA": 267, "LeakmA": 25 },
{ t: 1576773426728, "FEndV": 3.32, "FResV": 0.19, "REndV": 2.94, "FEndmA": 332, "REndmA": 299, "LeakmA": 33 },
{ t: 1576773441940, "FEndV": 6.46, "FResV": 0.26, "REndV": 5.44, "FEndmA": 281, "REndmA": 239, "LeakmA": 42 },
{ t: 1576773460095, "FEndV": 3.24, "FResV": 0.46, "REndV": 1.89, "FEndmA": 277, "REndmA": 265, "LeakmA": 12 },
{ t: 1576773475231, "FEndV": 6.08, "FResV": 1.51, "REndV": 3.39, "FEndmA": 269, "REndmA": 232, "LeakmA": 37 },
{ t: 1576773490053, "FEndV": 7.91, "FResV": 0.53, "REndV": 3.66, "FEndmA": 257, "REndmA": 217, "LeakmA": 40 },
{ t: 1576773505871, "FEndV": 7.36, "FResV": 0.94, "REndV": 4.49, "FEndmA": 302, "REndmA": 256, "LeakmA": 46 },
{ t: 1576773517575, "FEndV": 3.01, "FResV": 2, "REndV": 1.71, "FEndmA": 288, "REndmA": 249, "LeakmA": 39 },
{ t: 1576773534020, "FEndV": 6.62, "FResV": 1.57, "REndV": 3.12, "FEndmA": 258, "REndmA": 246, "LeakmA": 12 },
{ t: 1576773548201, "FEndV": 3.82, "FResV": 1, "REndV": 1.18, "FEndmA": 277, "REndmA": 234, "LeakmA": 43 },
{ t: 1576773560986, "FEndV": 4.68, "FResV": 0.79, "REndV": 1.01, "FEndmA": 328, "REndmA": 283, "LeakmA": 45 },
{ t: 1576773578755, "FEndV": 5.72, "FResV": 0.21, "REndV": 4.13, "FEndmA": 285, "REndmA": 269, "LeakmA": 16 },
{ t: 1576773597543, "FEndV": 7.23, "FResV": 0.74, "REndV": 3.46, "FEndmA": 313, "REndmA": 284, "LeakmA": 29 },
{ t: 1576773606568, "FEndV": 5.16, "FResV": 0.1, "REndV": 2.85, "FEndmA": 289, "REndmA": 272, "LeakmA": 17 },
{ t: 1576773622890, "FEndV": 4.53, "FResV": 0.41, "REndV": 4.04, "FEndmA": 257, "REndmA": 238, "LeakmA": 19 },
{ t: 1576773632337, "FEndV": 3.05, "FResV": 1.45, "REndV": 2.04, "FEndmA": 347, "REndmA": 329, "LeakmA": 18 },
{ t: 1576773644428, "FEndV": 6.2, "FResV": 0.1, "REndV": 5.13, "FEndmA": 254, "REndmA": 240, "LeakmA": 14 },
{ t: 1576773650361, "FEndV": 3.09, "FResV": 0.48, "REndV": 3.92, "FEndmA": 277, "REndmA": 267, "LeakmA": 10 },
{ t: 1576773662776, "FEndV": 7.73, "FResV": 2, "REndV": 2.18, "FEndmA": 280, "REndmA": 252, "LeakmA": 28 },
{ t: 1576773675977, "FEndV": 3.27, "FResV": 0.03, "REndV": 2.6, "FEndmA": 317, "REndmA": 307, "LeakmA": 10 },
{ t: 1576773682922, "FEndV": 4.03, "FResV": 0.9, "REndV": 5.11, "FEndmA": 262, "REndmA": 238, "LeakmA": 24 },
{ t: 1576773691685, "FEndV": 4.71, "FResV": 1, "REndV": 4.97, "FEndmA": 312, "REndmA": 270, "LeakmA": 42 },
{ t: 1576773709586, "FEndV": 3.59, "FResV": 0.95, "REndV": 1.6, "FEndmA": 253, "REndmA": 236, "LeakmA": 17 },
{ t: 1576773717476, "FEndV": 6.51, "FResV": 0.85, "REndV": 5.14, "FEndmA": 312, "REndmA": 296, "LeakmA": 16 },
{ t: 1576773726595, "FEndV": 6.63, "FResV": 1.8, "REndV": 1.27, "FEndmA": 269, "REndmA": 235, "LeakmA": 34 },
{ t: 1576773738548, "FEndV": 5.77, "FResV": 0.5, "REndV": 1.87, "FEndmA": 306, "REndmA": 292, "LeakmA": 14 },
{ t: 1576773753429, "FEndV": 5.01, "FResV": 0.92, "REndV": 3.34, "FEndmA": 331, "REndmA": 291, "LeakmA": 40 },
{ t: 1576773758609, "FEndV": 5.14, "FResV": 0.32, "REndV": 1.27, "FEndmA": 311, "REndmA": 294, "LeakmA": 17 },
{ t: 1576773768714, "FEndV": 6.19, "FResV": 1.95, "REndV": 3.43, "FEndmA": 291, "REndmA": 244, "LeakmA": 47 },
{ t: 1576773783065, "FEndV": 7.2, "FResV": 1.51, "REndV": 4.59, "FEndmA": 287, "REndmA": 277, "LeakmA": 10 },
{ t: 1576773793166, "FEndV": 6.67, "FResV": 1.78, "REndV": 4.08, "FEndmA": 321, "REndmA": 273, "LeakmA": 48 },
{ t: 1576773801488, "FEndV": 5.82, "FResV": 1.18, "REndV": 1.84, "FEndmA": 268, "REndmA": 225, "LeakmA": 43 },
{ t: 1576773807841, "FEndV": 4.5, "FResV": 1.74, "REndV": 2.22, "FEndmA": 290, "REndmA": 275, "LeakmA": 15 },
{ t: 1576773815556, "FEndV": 3.14, "FResV": 1.8, "REndV": 4.6, "FEndmA": 337, "REndmA": 289, "LeakmA": 48 },
{ t: 1576773831715, "FEndV": 6.87, "FResV": 1.83, "REndV": 1.99, "FEndmA": 255, "REndmA": 223, "LeakmA": 32 },
{ t: 1576773851582, "FEndV": 4.94, "FResV": 1.33, "REndV": 4.43, "FEndmA": 298, "REndmA": 251, "LeakmA": 47 },
{ t: 1576773869715, "FEndV": 4.13, "FResV": 1.39, "REndV": 2.33, "FEndmA": 254, "REndmA": 239, "LeakmA": 15 },
{ t: 1576773881339, "FEndV": 4.99, "FResV": 0.23, "REndV": 1.57, "FEndmA": 255, "REndmA": 243, "LeakmA": 12 },
{ t: 1576773900195, "FEndV": 5.29, "FResV": 0.47, "REndV": 4.64, "FEndmA": 344, "REndmA": 331, "LeakmA": 13 },
{ t: 1576773919927, "FEndV": 7.06, "FResV": 1.45, "REndV": 3.17, "FEndmA": 326, "REndmA": 285, "LeakmA": 41 },
{ t: 1576773932850, "FEndV": 4.26, "FResV": 0.12, "REndV": 4.23, "FEndmA": 308, "REndmA": 276, "LeakmA": 32 },
{ t: 1576773940173, "FEndV": 6.65, "FResV": 1.07, "REndV": 3.38, "FEndmA": 345, "REndmA": 319, "LeakmA": 26 },
{ t: 1576773950476, "FEndV": 4.48, "FResV": 0.7, "REndV": 2.98, "FEndmA": 257, "REndmA": 211, "LeakmA": 46 },
{ t: 1576773963862, "FEndV": 4.27, "FResV": 0.43, "REndV": 4.17, "FEndmA": 255, "REndmA": 225, "LeakmA": 30 },
{ t: 1576773978902, "FEndV": 5.17, "FResV": 1.39, "REndV": 3.29, "FEndmA": 318, "REndmA": 308, "LeakmA": 10 },
{ t: 1576773985460, "FEndV": 4.13, "FResV": 1.25, "REndV": 4.13, "FEndmA": 314, "REndmA": 296, "LeakmA": 18 },
{ t: 1576773995310, "FEndV": 4.95, "FResV": 0.38, "REndV": 5.48, "FEndmA": 284, "REndmA": 266, "LeakmA": 18 },
{ t: 1576774003841, "FEndV": 5.15, "FResV": 0.14, "REndV": 1.23, "FEndmA": 316, "REndmA": 284, "LeakmA": 32 },
{ t: 1576774020202, "FEndV": 4.05, "FResV": 0.49, "REndV": 1.36, "FEndmA": 302, "REndmA": 291, "LeakmA": 11 },
{ t: 1576774038772, "FEndV": 4.61, "FResV": 1.11, "REndV": 4.31, "FEndmA": 276, "REndmA": 254, "LeakmA": 22 },
{ t: 1576774051042, "FEndV": 5.72, "FResV": 0.1, "REndV": 1.97, "FEndmA": 266, "REndmA": 248, "LeakmA": 18 },
{ t: 1576774059331, "FEndV": 6.23, "FResV": 1.01, "REndV": 3.15, "FEndmA": 316, "REndmA": 277, "LeakmA": 39 },
{ t: 1576774066921, "FEndV": 4.43, "FResV": 1.58, "REndV": 2.08, "FEndmA": 330, "REndmA": 288, "LeakmA": 42 },
{ t: 1576774072239, "FEndV": 6.85, "FResV": 1.96, "REndV": 1.87, "FEndmA": 298, "REndmA": 275, "LeakmA": 23 },
{ t: 1576774079223, "FEndV": 5.38, "FResV": 0.71, "REndV": 4.32, "FEndmA": 336, "REndmA": 323, "LeakmA": 13 },
{ t: 1576774098784, "FEndV": 3.5, "FResV": 0.1, "REndV": 2.27, "FEndmA": 298, "REndmA": 268, "LeakmA": 30 },
{ t: 1576774109971, "FEndV": 6.62, "FResV": 0.5, "REndV": 2.88, "FEndmA": 311, "REndmA": 265, "LeakmA": 46 },
{ t: 1576774129011, "FEndV": 7.39, "FResV": 0.16, "REndV": 4.2, "FEndmA": 283, "REndmA": 264, "LeakmA": 19 },
{ t: 1576774145540, "FEndV": 7.45, "FResV": 0.72, "REndV": 3, "FEndmA": 289, "REndmA": 254, "LeakmA": 35 },
{ t: 1576774163967, "FEndV": 3.2, "FResV": 0.43, "REndV": 4.34, "FEndmA": 304, "REndmA": 284, "LeakmA": 20 },
{ t: 1576774169866, "FEndV": 7.61, "FResV": 1.46, "REndV": 2.39, "FEndmA": 314, "REndmA": 277, "LeakmA": 37 },
{ t: 1576774188202, "FEndV": 3.66, "FResV": 0.44, "REndV": 1.66, "FEndmA": 327, "REndmA": 298, "LeakmA": 29 },
{ t: 1576774196468, "FEndV": 5.63, "FResV": 1.31, "REndV": 3.1, "FEndmA": 257, "REndmA": 224, "LeakmA": 33 },
{ t: 1576774214558, "FEndV": 4.97, "FResV": 1.57, "REndV": 2, "FEndmA": 321, "REndmA": 288, "LeakmA": 33 },
{ t: 1576774221557, "FEndV": 6.98, "FResV": 0.55, "REndV": 4.47, "FEndmA": 279, "REndmA": 252, "LeakmA": 27 },
{ t: 1576774240810, "FEndV": 3, "FResV": 1.54, "REndV": 2.5, "FEndmA": 338, "REndmA": 298, "LeakmA": 40 },
{ t: 1576774260335, "FEndV": 5, "FResV": 0.93, "REndV": 3.38, "FEndmA": 310, "REndmA": 284, "LeakmA": 26 },
{ t: 1576774266819, "FEndV": 7.97, "FResV": 1.91, "REndV": 1.4, "FEndmA": 262, "REndmA": 218, "LeakmA": 44 },
{ t: 1576774279699, "FEndV": 3.16, "FResV": 1.16, "REndV": 2.53, "FEndmA": 283, "REndmA": 248, "LeakmA": 35 },
{ t: 1576774295549, "FEndV": 5.55, "FResV": 0.04, "REndV": 1.28, "FEndmA": 329, "REndmA": 288, "LeakmA": 41 },
{ t: 1576774314769, "FEndV": 6.7, "FResV": 0.6, "REndV": 4.92, "FEndmA": 342, "REndmA": 307, "LeakmA": 35 },
{ t: 1576774321437, "FEndV": 4.3, "FResV": 1.24, "REndV": 2.24, "FEndmA": 267, "REndmA": 255, "LeakmA": 12 },
{ t: 1576774326734, "FEndV": 5.83, "FResV": 1.28, "REndV": 3.16, "FEndmA": 346, "REndmA": 300, "LeakmA": 46 },
{ t: 1576774334896, "FEndV": 7.03, "FResV": 0.64, "REndV": 4.27, "FEndmA": 269, "REndmA": 254, "LeakmA": 15 },
{ t: 1576774341257, "FEndV": 6.22, "FResV": 0.44, "REndV": 4.18, "FEndmA": 312, "REndmA": 282, "LeakmA": 30 },
{ t: 1576774347260, "FEndV": 4.91, "FResV": 0.82, "REndV": 3.46, "FEndmA": 335, "REndmA": 303, "LeakmA": 32 },
{ t: 1576774361266, "FEndV": 7.58, "FResV": 0.23, "REndV": 1.34, "FEndmA": 255, "REndmA": 243, "LeakmA": 12 },
{ t: 1576774371228, "FEndV": 7.12, "FResV": 0.03, "REndV": 3.98, "FEndmA": 272, "REndmA": 234, "LeakmA": 38 },
{ t: 1576774377349, "FEndV": 7.15, "FResV": 0.03, "REndV": 4.41, "FEndmA": 333, "REndmA": 301, "LeakmA": 32 },
{ t: 1576774394254, "FEndV": 4.35, "FResV": 2, "REndV": 3.38, "FEndmA": 339, "REndmA": 312, "LeakmA": 27 },
{ t: 1576774403766, "FEndV": 5.95, "FResV": 1.76, "REndV": 1.5, "FEndmA": 341, "REndmA": 321, "LeakmA": 20 },
{ t: 1576774421222, "FEndV": 4.18, "FResV": 0.46, "REndV": 1.52, "FEndmA": 286, "REndmA": 239, "LeakmA": 47 },
{ t: 1576774429671, "FEndV": 7.17, "FResV": 1.1, "REndV": 4.47, "FEndmA": 316, "REndmA": 286, "LeakmA": 30 },
{ t: 1576774438422, "FEndV": 3.28, "FResV": 1.66, "REndV": 5.03, "FEndmA": 316, "REndmA": 293, "LeakmA": 23 },
{ t: 1576774455719, "FEndV": 4.99, "FResV": 0.66, "REndV": 5.04, "FEndmA": 350, "REndmA": 303, "LeakmA": 47 },
{ t: 1576774461254, "FEndV": 7.76, "FResV": 1.49, "REndV": 3.36, "FEndmA": 276, "REndmA": 264, "LeakmA": 12 },
{ t: 1576774473672, "FEndV": 5.62, "FResV": 1.33, "REndV": 4.85, "FEndmA": 281, "REndmA": 233, "LeakmA": 48 },
{ t: 1576774490753, "FEndV": 4.5, "FResV": 0.9, "REndV": 1.16, "FEndmA": 282, "REndmA": 259, "LeakmA": 23 },
{ t: 1576774498083, "FEndV": 3.17, "FResV": 0.68, "REndV": 2.21, "FEndmA": 293, "REndmA": 281, "LeakmA": 12 },
{ t: 1576774514201, "FEndV": 5.65, "FResV": 0.78, "REndV": 2.02, "FEndmA": 342, "REndmA": 311, "LeakmA": 31 },
{ t: 1576774531783, "FEndV": 3.77, "FResV": 0.45, "REndV": 2.27, "FEndmA": 343, "REndmA": 315, "LeakmA": 28 },
{ t: 1576774546313, "FEndV": 4.2, "FResV": 1.02, "REndV": 1.81, "FEndmA": 321, "REndmA": 277, "LeakmA": 44 },
{ t: 1576774558183, "FEndV": 7.59, "FResV": 1.98, "REndV": 3.02, "FEndmA": 343, "REndmA": 296, "LeakmA": 47 },
{ t: 1576774569198, "FEndV": 5.5, "FResV": 1.71, "REndV": 1.26, "FEndmA": 340, "REndmA": 319, "LeakmA": 21 },
{ t: 1576774584211, "FEndV": 7.75, "FResV": 0.86, "REndV": 4.52, "FEndmA": 311, "REndmA": 275, "LeakmA": 36 },
{ t: 1576774594021, "FEndV": 3.08, "FResV": 1.49, "REndV": 1.33, "FEndmA": 288, "REndmA": 270, "LeakmA": 18 },
{ t: 1576774601319, "FEndV": 6.7, "FResV": 1.26, "REndV": 1.44, "FEndmA": 262, "REndmA": 227, "LeakmA": 35 },
{ t: 1576774615474, "FEndV": 5.82, "FResV": 0.79, "REndV": 1.5, "FEndmA": 338, "REndmA": 297, "LeakmA": 41 },
{ t: 1576774622553, "FEndV": 6.9, "FResV": 0.49, "REndV": 1.61, "FEndmA": 313, "REndmA": 284, "LeakmA": 29 },
{ t: 1576774628584, "FEndV": 7.09, "FResV": 1.53, "REndV": 5.49, "FEndmA": 321, "REndmA": 291, "LeakmA": 30 },
{ t: 1576774640029, "FEndV": 5.58, "FResV": 0.09, "REndV": 2.62, "FEndmA": 341, "REndmA": 309, "LeakmA": 32 },
{ t: 1576774651075, "FEndV": 4.52, "FResV": 1.47, "REndV": 4.18, "FEndmA": 277, "REndmA": 258, "LeakmA": 19 },
{ t: 1576774656663, "FEndV": 6.68, "FResV": 0.93, "REndV": 1.02, "FEndmA": 301, "REndmA": 252, "LeakmA": 49 },
{ t: 1576774669206, "FEndV": 3.32, "FResV": 0.31, "REndV": 2.21, "FEndmA": 268, "REndmA": 252, "LeakmA": 16 },
{ t: 1576774687589, "FEndV": 5.59, "FResV": 0.09, "REndV": 2.03, "FEndmA": 340, "REndmA": 303, "LeakmA": 37 },
{ t: 1576774693892, "FEndV": 5.43, "FResV": 1.34, "REndV": 2.16, "FEndmA": 312, "REndmA": 266, "LeakmA": 46 },
{ t: 1576774701765, "FEndV": 4.14, "FResV": 0.63, "REndV": 2.61, "FEndmA": 324, "REndmA": 302, "LeakmA": 22 },
{ t: 1576774719848, "FEndV": 6.28, "FResV": 0.58, "REndV": 3.59, "FEndmA": 348, "REndmA": 302, "LeakmA": 46 },
{ t: 1576774727799, "FEndV": 5.57, "FResV": 1.41, "REndV": 2.07, "FEndmA": 343, "REndmA": 322, "LeakmA": 21 },
{ t: 1576774738854, "FEndV": 5.55, "FResV": 1.58, "REndV": 4.25, "FEndmA": 273, "REndmA": 224, "LeakmA": 49 },
{ t: 1576774753116, "FEndV": 4.11, "FResV": 0.7, "REndV": 5.05, "FEndmA": 334, "REndmA": 306, "LeakmA": 28 },
{ t: 1576774763471, "FEndV": 6.13, "FResV": 1.27, "REndV": 4.36, "FEndmA": 349, "REndmA": 332, "LeakmA": 17 },
{ t: 1576774778725, "FEndV": 5.44, "FResV": 0.19, "REndV": 1.21, "FEndmA": 258, "REndmA": 215, "LeakmA": 43 },
{ t: 1576774793923, "FEndV": 5.65, "FResV": 0.37, "REndV": 1.76, "FEndmA": 338, "REndmA": 311, "LeakmA": 27 },
{ t: 1576774809176, "FEndV": 6.91, "FResV": 1.73, "REndV": 4.96, "FEndmA": 278, "REndmA": 257, "LeakmA": 21 },
{ t: 1576774819844, "FEndV": 5.76, "FResV": 0.75, "REndV": 4.06, "FEndmA": 257, "REndmA": 219, "LeakmA": 38 },
{ t: 1576774835957, "FEndV": 4.08, "FResV": 0.57, "REndV": 3.2, "FEndmA": 284, "REndmA": 238, "LeakmA": 46 },
{ t: 1576774842115, "FEndV": 7.63, "FResV": 1.29, "REndV": 2.01, "FEndmA": 253, "REndmA": 227, "LeakmA": 26 },
{ t: 1576774849761, "FEndV": 3.99, "FResV": 1.24, "REndV": 5.04, "FEndmA": 286, "REndmA": 239, "LeakmA": 47 },
{ t: 1576774862524, "FEndV": 3.5, "FResV": 0.9, "REndV": 4.39, "FEndmA": 306, "REndmA": 292, "LeakmA": 14 },
{ t: 1576774882183, "FEndV": 4.26, "FResV": 1.23, "REndV": 3.29, "FEndmA": 262, "REndmA": 229, "LeakmA": 33 },
{ t: 1576774887324, "FEndV": 3.09, "FResV": 0.96, "REndV": 1.6, "FEndmA": 333, "REndmA": 291, "LeakmA": 42 },
{ t: 1576774904033, "FEndV": 7.66, "FResV": 0.72, "REndV": 3.23, "FEndmA": 263, "REndmA": 248, "LeakmA": 15 },
{ t: 1576774919777, "FEndV": 3.94, "FResV": 1.83, "REndV": 2.06, "FEndmA": 269, "REndmA": 242, "LeakmA": 27 },
{ t: 1576774929303, "FEndV": 6.6, "FResV": 0, "REndV": 1.3, "FEndmA": 289, "REndmA": 242, "LeakmA": 47 },
{ t: 1576774937906, "FEndV": 5.95, "FResV": 0.95, "REndV": 4.18, "FEndmA": 340, "REndmA": 295, "LeakmA": 45 },
{ t: 1576774944650, "FEndV": 4.05, "FResV": 1.22, "REndV": 4.22, "FEndmA": 304, "REndmA": 290, "LeakmA": 14 },
{ t: 1576774961280, "FEndV": 6.09, "FResV": 0.22, "REndV": 5.21, "FEndmA": 346, "REndmA": 331, "LeakmA": 15 },
{ t: 1576774979275, "FEndV": 4.83, "FResV": 0.02, "REndV": 3.4, "FEndmA": 345, "REndmA": 315, "LeakmA": 30 },
{ t: 1576774990372, "FEndV": 3.35, "FResV": 0.05, "REndV": 2.32, "FEndmA": 324, "REndmA": 313, "LeakmA": 11 },
{ t: 1576774996852, "FEndV": 3.7, "FResV": 0.48, "REndV": 2.52, "FEndmA": 250, "REndmA": 204, "LeakmA": 46 },
{ t: 1576775002411, "FEndV": 7.2, "FResV": 0.36, "REndV": 1.39, "FEndmA": 339, "REndmA": 328, "LeakmA": 11 },
{ t: 1576775020252, "FEndV": 6.13, "FResV": 0.36, "REndV": 2.32, "FEndmA": 281, "REndmA": 242, "LeakmA": 39 },
{ t: 1576775030551, "FEndV": 3.54, "FResV": 1.11, "REndV": 3.91, "FEndmA": 338, "REndmA": 296, "LeakmA": 42 },
{ t: 1576775036841, "FEndV": 7.8, "FResV": 1.54, "REndV": 4.05, "FEndmA": 344, "REndmA": 294, "LeakmA": 50 },
{ t: 1576775046980, "FEndV": 4.02, "FResV": 1.14, "REndV": 4.24, "FEndmA": 350, "REndmA": 321, "LeakmA": 29 },
{ t: 1576775054332, "FEndV": 3.5, "FResV": 1.54, "REndV": 1.87, "FEndmA": 346, "REndmA": 296, "LeakmA": 50 },
{ t: 1576775072354, "FEndV": 5.75, "FResV": 0.79, "REndV": 4.65, "FEndmA": 251, "REndmA": 206, "LeakmA": 45 },
{ t: 1576775083038, "FEndV": 5.61, "FResV": 0.82, "REndV": 1.15, "FEndmA": 286, "REndmA": 247, "LeakmA": 39 },
{ t: 1576775096869, "FEndV": 3.58, "FResV": 1.89, "REndV": 1.26, "FEndmA": 347, "REndmA": 336, "LeakmA": 11 },
{ t: 1576775115850, "FEndV": 5.13, "FResV": 0.06, "REndV": 2.38, "FEndmA": 257, "REndmA": 214, "LeakmA": 43 },
{ t: 1576775134901, "FEndV": 5.26, "FResV": 0.76, "REndV": 1.28, "FEndmA": 260, "REndmA": 228, "LeakmA": 32 },
{ t: 1576775140660, "FEndV": 3.22, "FResV": 1.88, "REndV": 1.02, "FEndmA": 251, "REndmA": 229, "LeakmA": 22 },
{ t: 1576775153225, "FEndV": 4.89, "FResV": 1.5, "REndV": 4.01, "FEndmA": 326, "REndmA": 309, "LeakmA": 17 },
{ t: 1576775163327, "FEndV": 3.53, "FResV": 0.76, "REndV": 5.42, "FEndmA": 278, "REndmA": 246, "LeakmA": 32 },
{ t: 1576775171287, "FEndV": 6.74, "FResV": 0.73, "REndV": 4.02, "FEndmA": 319, "REndmA": 280, "LeakmA": 39 },
{ t: 1576775184148, "FEndV": 6.51, "FResV": 1.02, "REndV": 4.04, "FEndmA": 337, "REndmA": 314, "LeakmA": 23 },
{ t: 1576775193317, "FEndV": 3.34, "FResV": 0.77, "REndV": 3.76, "FEndmA": 345, "REndmA": 319, "LeakmA": 26 },
{ t: 1576775208178, "FEndV": 4.57, "FResV": 1.12, "REndV": 4.13, "FEndmA": 261, "REndmA": 219, "LeakmA": 42 },
{ t: 1576775226316, "FEndV": 5.04, "FResV": 0.16, "REndV": 4.14, "FEndmA": 306, "REndmA": 284, "LeakmA": 22 },
{ t: 1576775234139, "FEndV": 3.36, "FResV": 0.11, "REndV": 4.36, "FEndmA": 309, "REndmA": 299, "LeakmA": 10 },
{ t: 1576775245824, "FEndV": 3.59, "FResV": 0.55, "REndV": 2.48, "FEndmA": 341, "REndmA": 293, "LeakmA": 48 },
{ t: 1576775257997, "FEndV": 6.68, "FResV": 1, "REndV": 2.52, "FEndmA": 336, "REndmA": 300, "LeakmA": 36 },
{ t: 1576775275618, "FEndV": 7.69, "FResV": 1.19, "REndV": 2.79, "FEndmA": 282, "REndmA": 238, "LeakmA": 44 },
{ t: 1576775282239, "FEndV": 4.14, "FResV": 1.51, "REndV": 4.71, "FEndmA": 279, "REndmA": 258, "LeakmA": 21 },
{ t: 1576775288948, "FEndV": 5.74, "FResV": 0.66, "REndV": 2.29, "FEndmA": 257, "REndmA": 226, "LeakmA": 31 },
{ t: 1576775299233, "FEndV": 3.19, "FResV": 1.25, "REndV": 1.83, "FEndmA": 315, "REndmA": 305, "LeakmA": 10 },
{ t: 1576775311288, "FEndV": 4.85, "FResV": 1.46, "REndV": 1.71, "FEndmA": 291, "REndmA": 270, "LeakmA": 21 },
{ t: 1576775317648, "FEndV": 3.12, "FResV": 1.58, "REndV": 4.41, "FEndmA": 296, "REndmA": 263, "LeakmA": 33 },
{ t: 1576775326227, "FEndV": 6.73, "FResV": 0.89, "REndV": 3.41, "FEndmA": 340, "REndmA": 317, "LeakmA": 23 },
{ t: 1576775341908, "FEndV": 5.02, "FResV": 1.02, "REndV": 4.48, "FEndmA": 252, "REndmA": 234, "LeakmA": 18 },
{ t: 1576775360103, "FEndV": 5.37, "FResV": 0.61, "REndV": 3.03, "FEndmA": 295, "REndmA": 261, "LeakmA": 34 },
{ t: 1576775371586, "FEndV": 3.98, "FResV": 0.53, "REndV": 4.02, "FEndmA": 289, "REndmA": 239, "LeakmA": 50 },
{ t: 1576775382621, "FEndV": 3.63, "FResV": 0.91, "REndV": 4.45, "FEndmA": 266, "REndmA": 241, "LeakmA": 25 },
{ t: 1576775400853, "FEndV": 3.57, "FResV": 0.72, "REndV": 4.81, "FEndmA": 264, "REndmA": 241, "LeakmA": 23 },
{ t: 1576775410120, "FEndV": 5.67, "FResV": 1.11, "REndV": 4.16, "FEndmA": 339, "REndmA": 324, "LeakmA": 15 },
{ t: 1576775420168, "FEndV": 7.71, "FResV": 0.55, "REndV": 4.4, "FEndmA": 350, "REndmA": 311, "LeakmA": 39 },
{ t: 1576775431175, "FEndV": 7.84, "FResV": 2, "REndV": 5.27, "FEndmA": 296, "REndmA": 248, "LeakmA": 48 },
{ t: 1576775441178, "FEndV": 7.27, "FResV": 1.22, "REndV": 2.65, "FEndmA": 253, "REndmA": 231, "LeakmA": 22 },
{ t: 1576775459872, "FEndV": 7.29, "FResV": 0.78, "REndV": 5.05, "FEndmA": 252, "REndmA": 237, "LeakmA": 15 },
{ t: 1576775477991, "FEndV": 7.6, "FResV": 0.7, "REndV": 2.31, "FEndmA": 304, "REndmA": 262, "LeakmA": 42 },
{ t: 1576775495980, "FEndV": 3.01, "FResV": 1.1, "REndV": 5.17, "FEndmA": 314, "REndmA": 297, "LeakmA": 17 },
{ t: 1576775504456, "FEndV": 4.14, "FResV": 0.04, "REndV": 2.37, "FEndmA": 324, "REndmA": 303, "LeakmA": 21 },
{ t: 1576775523344, "FEndV": 6.89, "FResV": 0.19, "REndV": 4.07, "FEndmA": 330, "REndmA": 316, "LeakmA": 14 },
{ t: 1576775542180, "FEndV": 6.83, "FResV": 0.35, "REndV": 3.04, "FEndmA": 349, "REndmA": 300, "LeakmA": 49 },
{ t: 1576775558518, "FEndV": 6.82, "FResV": 1.82, "REndV": 4.73, "FEndmA": 279, "REndmA": 252, "LeakmA": 27 },
{ t: 1576775565598, "FEndV": 6.07, "FResV": 0.15, "REndV": 4.41, "FEndmA": 256, "REndmA": 210, "LeakmA": 46 },
{ t: 1576775572036, "FEndV": 4.5, "FResV": 1.16, "REndV": 2.2, "FEndmA": 276, "REndmA": 237, "LeakmA": 39 },
{ t: 1576775586182, "FEndV": 7.01, "FResV": 0.79, "REndV": 1.65, "FEndmA": 332, "REndmA": 308, "LeakmA": 24 },
{ t: 1576775602880, "FEndV": 7.63, "FResV": 0.5, "REndV": 3.81, "FEndmA": 343, "REndmA": 332, "LeakmA": 11 },
{ t: 1576775622543, "FEndV": 4.54, "FResV": 1.37, "REndV": 2.69, "FEndmA": 313, "REndmA": 268, "LeakmA": 45 },
{ t: 1576775628283, "FEndV": 6.42, "FResV": 0.53, "REndV": 2.31, "FEndmA": 319, "REndmA": 272, "LeakmA": 47 },
{ t: 1576775644694, "FEndV": 6.34, "FResV": 0.58, "REndV": 3.94, "FEndmA": 283, "REndmA": 256, "LeakmA": 27 },
{ t: 1576775661219, "FEndV": 4.45, "FResV": 0.8, "REndV": 3.93, "FEndmA": 291, "REndmA": 256, "LeakmA": 35 },
{ t: 1576775671386, "FEndV": 6.05, "FResV": 0.68, "REndV": 4.34, "FEndmA": 323, "REndmA": 289, "LeakmA": 34 },
{ t: 1576775677468, "FEndV": 4.71, "FResV": 0.76, "REndV": 2.47, "FEndmA": 314, "REndmA": 270, "LeakmA": 44 },
{ t: 1576775686984, "FEndV": 4.06, "FResV": 1.36, "REndV": 5.17, "FEndmA": 334, "REndmA": 297, "LeakmA": 37 },
{ t: 1576775698275, "FEndV": 3.94, "FResV": 0.48, "REndV": 4.24, "FEndmA": 346, "REndmA": 312, "LeakmA": 34 },
{ t: 1576775708598, "FEndV": 4.38, "FResV": 1.29, "REndV": 2.38, "FEndmA": 282, "REndmA": 262, "LeakmA": 20 },
{ t: 1576775720512, "FEndV": 6.13, "FResV": 1.07, "REndV": 5.18, "FEndmA": 290, "REndmA": 259, "LeakmA": 31 },
{ t: 1576775738532, "FEndV": 4.62, "FResV": 1.33, "REndV": 3.36, "FEndmA": 315, "REndmA": 275, "LeakmA": 40 },
{ t: 1576775751368, "FEndV": 3.73, "FResV": 0.04, "REndV": 4.8, "FEndmA": 335, "REndmA": 305, "LeakmA": 30 },
{ t: 1576775765767, "FEndV": 4.01, "FResV": 1.14, "REndV": 4.37, "FEndmA": 258, "REndmA": 222, "LeakmA": 36 },
{ t: 1576775771961, "FEndV": 5.64, "FResV": 1.37, "REndV": 4.33, "FEndmA": 253, "REndmA": 228, "LeakmA": 25 },
{ t: 1576775790860, "FEndV": 7.61, "FResV": 0.64, "REndV": 2.59, "FEndmA": 335, "REndmA": 300, "LeakmA": 35 },
{ t: 1576775802229, "FEndV": 7.72, "FResV": 0.51, "REndV": 1, "FEndmA": 274, "REndmA": 237, "LeakmA": 37 },
{ t: 1576775821455, "FEndV": 3.72, "FResV": 1.81, "REndV": 4.51, "FEndmA": 269, "REndmA": 238, "LeakmA": 31 },
{ t: 1576775830950, "FEndV": 3.63, "FResV": 1.13, "REndV": 4.69, "FEndmA": 255, "REndmA": 210, "LeakmA": 45 },
{ t: 1576775845479, "FEndV": 4.84, "FResV": 0.65, "REndV": 2.39, "FEndmA": 305, "REndmA": 290, "LeakmA": 15 },
{ t: 1576775854499, "FEndV": 3.42, "FResV": 0.43, "REndV": 4.34, "FEndmA": 302, "REndmA": 282, "LeakmA": 20 },
{ t: 1576775872652, "FEndV": 4.39, "FResV": 1.26, "REndV": 1.55, "FEndmA": 310, "REndmA": 292, "LeakmA": 18 },
{ t: 1576775881675, "FEndV": 6.48, "FResV": 1.48, "REndV": 1.27, "FEndmA": 338, "REndmA": 299, "LeakmA": 39 },
{ t: 1576775886779, "FEndV": 4.62, "FResV": 1.81, "REndV": 5.32, "FEndmA": 301, "REndmA": 271, "LeakmA": 30 },
{ t: 1576775899998, "FEndV": 4.62, "FResV": 1.85, "REndV": 4.11, "FEndmA": 296, "REndmA": 278, "LeakmA": 18 },
{ t: 1576775916920, "FEndV": 5.16, "FResV": 0.4, "REndV": 2.26, "FEndmA": 314, "REndmA": 280, "LeakmA": 34 },
{ t: 1576775935226, "FEndV": 5.68, "FResV": 0.69, "REndV": 1.9, "FEndmA": 272, "REndmA": 234, "LeakmA": 38 },
{ t: 1576775945061, "FEndV": 6.56, "FResV": 0.19, "REndV": 5.17, "FEndmA": 324, "REndmA": 298, "LeakmA": 26 },
{ t: 1576775952826, "FEndV": 3.26, "FResV": 0.52, "REndV": 3.17, "FEndmA": 330, "REndmA": 282, "LeakmA": 48 },
{ t: 1576775972709, "FEndV": 7.8, "FResV": 1.96, "REndV": 4.5, "FEndmA": 330, "REndmA": 303, "LeakmA": 27 },
{ t: 1576775990215, "FEndV": 4.4, "FResV": 1.38, "REndV": 2.48, "FEndmA": 294, "REndmA": 251, "LeakmA": 43 },
{ t: 1576776010079, "FEndV": 3.14, "FResV": 0.39, "REndV": 1.61, "FEndmA": 255, "REndmA": 213, "LeakmA": 42 },
{ t: 1576776016542, "FEndV": 7.29, "FResV": 0.64, "REndV": 3.79, "FEndmA": 259, "REndmA": 221, "LeakmA": 38 },
{ t: 1576776022442, "FEndV": 7.23, "FResV": 1.38, "REndV": 4.07, "FEndmA": 288, "REndmA": 241, "LeakmA": 47 },
{ t: 1576776041826, "FEndV": 6.38, "FResV": 1.05, "REndV": 2.12, "FEndmA": 346, "REndmA": 324, "LeakmA": 22 },
{ t: 1576776059267, "FEndV": 4.01, "FResV": 0.46, "REndV": 3.5, "FEndmA": 296, "REndmA": 257, "LeakmA": 39 },
{ t: 1576776077391, "FEndV": 5.05, "FResV": 1.07, "REndV": 4.95, "FEndmA": 347, "REndmA": 331, "LeakmA": 16 },
{ t: 1576776083969, "FEndV": 7.02, "FResV": 1.36, "REndV": 5.32, "FEndmA": 325, "REndmA": 309, "LeakmA": 16 },
{ t: 1576776089802, "FEndV": 4.84, "FResV": 1.94, "REndV": 4.59, "FEndmA": 323, "REndmA": 311, "LeakmA": 12 },
{ t: 1576776099126, "FEndV": 3.59, "FResV": 1, "REndV": 4.95, "FEndmA": 295, "REndmA": 248, "LeakmA": 47 },
{ t: 1576776109558, "FEndV": 7.4, "FResV": 1.81, "REndV": 2.01, "FEndmA": 338, "REndmA": 297, "LeakmA": 41 },
{ t: 1576776123581, "FEndV": 7.27, "FResV": 0.52, "REndV": 4.85, "FEndmA": 337, "REndmA": 321, "LeakmA": 16 },
{ t: 1576776135096, "FEndV": 4.99, "FResV": 1.43, "REndV": 1.49, "FEndmA": 288, "REndmA": 255, "LeakmA": 33 },
{ t: 1576776143913, "FEndV": 6.72, "FResV": 1.7, "REndV": 2.26, "FEndmA": 329, "REndmA": 310, "LeakmA": 19 },
{ t: 1576776161260, "FEndV": 4.86, "FResV": 0.57, "REndV": 3.87, "FEndmA": 298, "REndmA": 261, "LeakmA": 37 },
{ t: 1576776176427, "FEndV": 4.83, "FResV": 0, "REndV": 3.6, "FEndmA": 344, "REndmA": 320, "LeakmA": 24 },
{ t: 1576776185167, "FEndV": 6.72, "FResV": 0.2, "REndV": 3.42, "FEndmA": 324, "REndmA": 299, "LeakmA": 25 },
{ t: 1576776195956, "FEndV": 7.42, "FResV": 0.33, "REndV": 2.14, "FEndmA": 302, "REndmA": 263, "LeakmA": 39 },
{ t: 1576776215715, "FEndV": 5.25, "FResV": 1.92, "REndV": 2.07, "FEndmA": 281, "REndmA": 238, "LeakmA": 43 },
{ t: 1576776234097, "FEndV": 5.06, "FResV": 0.37, "REndV": 4.12, "FEndmA": 335, "REndmA": 290, "LeakmA": 45 },
{ t: 1576776244116, "FEndV": 7.23, "FResV": 0.66, "REndV": 2.79, "FEndmA": 309, "REndmA": 263, "LeakmA": 46 },
{ t: 1576776259383, "FEndV": 3.84, "FResV": 1.9, "REndV": 3.22, "FEndmA": 344, "REndmA": 313, "LeakmA": 31 },
{ t: 1576776274023, "FEndV": 3.04, "FResV": 1.68, "REndV": 3.19, "FEndmA": 328, "REndmA": 285, "LeakmA": 43 },
{ t: 1576776284934, "FEndV": 5.17, "FResV": 0.65, "REndV": 4.42, "FEndmA": 326, "REndmA": 316, "LeakmA": 10 },
{ t: 1576776297183, "FEndV": 6.79, "FResV": 0.03, "REndV": 3.4, "FEndmA": 271, "REndmA": 258, "LeakmA": 13 },
{ t: 1576776303378, "FEndV": 3.79, "FResV": 0.65, "REndV": 4.07, "FEndmA": 316, "REndmA": 286, "LeakmA": 30 },
{ t: 1576776308701, "FEndV": 5.93, "FResV": 1.53, "REndV": 4.5, "FEndmA": 307, "REndmA": 276, "LeakmA": 31 },
{ t: 1576776327644, "FEndV": 5.49, "FResV": 1.83, "REndV": 2.24, "FEndmA": 335, "REndmA": 304, "LeakmA": 31 },
{ t: 1576776338943, "FEndV": 7.51, "FResV": 1.24, "REndV": 1.09, "FEndmA": 337, "REndmA": 298, "LeakmA": 39 },
{ t: 1576776349618, "FEndV": 3.73, "FResV": 0.18, "REndV": 5.14, "FEndmA": 269, "REndmA": 252, "LeakmA": 17 },
{ t: 1576776362446, "FEndV": 3.75, "FResV": 0.4, "REndV": 2.01, "FEndmA": 349, "REndmA": 308, "LeakmA": 41 },
{ t: 1576776378181, "FEndV": 6.6, "FResV": 0.24, "REndV": 3.65, "FEndmA": 263, "REndmA": 241, "LeakmA": 22 },
{ t: 1576776387468, "FEndV": 4.66, "FResV": 1.3, "REndV": 3.14, "FEndmA": 297, "REndmA": 286, "LeakmA": 11 },
{ t: 1576776399250, "FEndV": 5.98, "FResV": 1.66, "REndV": 3.22, "FEndmA": 258, "REndmA": 215, "LeakmA": 43 },
{ t: 1576776406502, "FEndV": 5.31, "FResV": 1.55, "REndV": 5.1, "FEndmA": 291, "REndmA": 253, "LeakmA": 38 },
{ t: 1576776415602, "FEndV": 5.66, "FResV": 1.09, "REndV": 2.19, "FEndmA": 348, "REndmA": 312, "LeakmA": 36 },
{ t: 1576776432091, "FEndV": 4.93, "FResV": 0.29, "REndV": 3.98, "FEndmA": 268, "REndmA": 233, "LeakmA": 35 },
{ t: 1576776444034, "FEndV": 7.43, "FResV": 1.28, "REndV": 2.95, "FEndmA": 347, "REndmA": 332, "LeakmA": 15 },
{ t: 1576776451298, "FEndV": 7.31, "FResV": 0.74, "REndV": 1.77, "FEndmA": 321, "REndmA": 306, "LeakmA": 15 },
{ t: 1576776457610, "FEndV": 4.44, "FResV": 0.37, "REndV": 4.26, "FEndmA": 305, "REndmA": 266, "LeakmA": 39 },
{ t: 1576776463470, "FEndV": 7.98, "FResV": 0.71, "REndV": 4.06, "FEndmA": 330, "REndmA": 295, "LeakmA": 35 },
{ t: 1576776478975, "FEndV": 5.71, "FResV": 1.91, "REndV": 1.29, "FEndmA": 267, "REndmA": 245, "LeakmA": 22 },
{ t: 1576776494465, "FEndV": 5.73, "FResV": 0.57, "REndV": 4.97, "FEndmA": 337, "REndmA": 303, "LeakmA": 34 },
{ t: 1576776510643, "FEndV": 5.43, "FResV": 0.05, "REndV": 4.61, "FEndmA": 291, "REndmA": 247, "LeakmA": 44 },
{ t: 1576776516738, "FEndV": 7.77, "FResV": 1.36, "REndV": 2.89, "FEndmA": 330, "REndmA": 320, "LeakmA": 10 },
{ t: 1576776527847, "FEndV": 3.59, "FResV": 0.56, "REndV": 3.01, "FEndmA": 273, "REndmA": 224, "LeakmA": 49 },
{ t: 1576776534595, "FEndV": 6.07, "FResV": 1.38, "REndV": 4.07, "FEndmA": 264, "REndmA": 243, "LeakmA": 21 },
{ t: 1576776546023, "FEndV": 5.39, "FResV": 0.13, "REndV": 3.01, "FEndmA": 323, "REndmA": 311, "LeakmA": 12 },
{ t: 1576776564288, "FEndV": 4.45, "FResV": 0.3, "REndV": 1.34, "FEndmA": 326, "REndmA": 283, "LeakmA": 43 },
{ t: 1576776583305, "FEndV": 6, "FResV": 1.42, "REndV": 3.54, "FEndmA": 345, "REndmA": 302, "LeakmA": 43 },
{ t: 1576776597285, "FEndV": 3.52, "FResV": 1.97, "REndV": 2.21, "FEndmA": 261, "REndmA": 233, "LeakmA": 28 },
{ t: 1576776610186, "FEndV": 3.39, "FResV": 0.12, "REndV": 2.5, "FEndmA": 331, "REndmA": 290, "LeakmA": 41 },
{ t: 1576776616583, "FEndV": 7.91, "FResV": 1.26, "REndV": 4.14, "FEndmA": 310, "REndmA": 267, "LeakmA": 43 },
{ t: 1576776622193, "FEndV": 3.68, "FResV": 0.42, "REndV": 1.41, "FEndmA": 345, "REndmA": 299, "LeakmA": 46 },
{ t: 1576776633033, "FEndV": 5.42, "FResV": 0.43, "REndV": 4.24, "FEndmA": 258, "REndmA": 238, "LeakmA": 20 },
{ t: 1576776646283, "FEndV": 3.27, "FResV": 0.6, "REndV": 4.27, "FEndmA": 273, "REndmA": 249, "LeakmA": 24 },
{ t: 1576776662991, "FEndV": 7.21, "FResV": 1.28, "REndV": 4.36, "FEndmA": 324, "REndmA": 276, "LeakmA": 48 },
{ t: 1576776673055, "FEndV": 5.63, "FResV": 0.41, "REndV": 2.39, "FEndmA": 316, "REndmA": 296, "LeakmA": 20 },
{ t: 1576776687139, "FEndV": 3.9, "FResV": 0.46, "REndV": 1.68, "FEndmA": 287, "REndmA": 251, "LeakmA": 36 },
{ t: 1576776700051, "FEndV": 6.77, "FResV": 1.62, "REndV": 2.17, "FEndmA": 316, "REndmA": 299, "LeakmA": 17 },
{ t: 1576776711677, "FEndV": 7.33, "FResV": 0.55, "REndV": 2.01, "FEndmA": 260, "REndmA": 226, "LeakmA": 34 },
{ t: 1576776721257, "FEndV": 4.34, "FResV": 0.44, "REndV": 3.62, "FEndmA": 265, "REndmA": 239, "LeakmA": 26 },
{ t: 1576776736029, "FEndV": 5.06, "FResV": 0.59, "REndV": 4.14, "FEndmA": 307, "REndmA": 291, "LeakmA": 16 },
{ t: 1576776753382, "FEndV": 4.07, "FResV": 1.08, "REndV": 1.42, "FEndmA": 297, "REndmA": 284, "LeakmA": 13 },
{ t: 1576776764351, "FEndV": 3.49, "FResV": 0.15, "REndV": 3.91, "FEndmA": 340, "REndmA": 300, "LeakmA": 40 },
{ t: 1576776784169, "FEndV": 7.62, "FResV": 0.67, "REndV": 5.37, "FEndmA": 261, "REndmA": 236, "LeakmA": 25 },
{ t: 1576776803038, "FEndV": 4.83, "FResV": 0.56, "REndV": 5.45, "FEndmA": 326, "REndmA": 285, "LeakmA": 41 },
{ t: 1576776813994, "FEndV": 7.79, "FResV": 0.6, "REndV": 3.78, "FEndmA": 319, "REndmA": 292, "LeakmA": 27 },
{ t: 1576776831267, "FEndV": 3.34, "FResV": 1.13, "REndV": 2.23, "FEndmA": 336, "REndmA": 303, "LeakmA": 33 },
{ t: 1576776847965, "FEndV": 3.36, "FResV": 0.98, "REndV": 2.33, "FEndmA": 332, "REndmA": 315, "LeakmA": 17 },
{ t: 1576776865745, "FEndV": 3.65, "FResV": 1.52, "REndV": 3.64, "FEndmA": 350, "REndmA": 310, "LeakmA": 40 },
{ t: 1576776885419, "FEndV": 3.99, "FResV": 0.37, "REndV": 4.11, "FEndmA": 264, "REndmA": 224, "LeakmA": 40 },
{ t: 1576776899033, "FEndV": 4.37, "FResV": 1.59, "REndV": 3.96, "FEndmA": 338, "REndmA": 301, "LeakmA": 37 },
{ t: 1576776909148, "FEndV": 6.9, "FResV": 1.08, "REndV": 4.31, "FEndmA": 261, "REndmA": 248, "LeakmA": 13 },
{ t: 1576776927342, "FEndV": 5.05, "FResV": 1.66, "REndV": 2.32, "FEndmA": 346, "REndmA": 305, "LeakmA": 41 },
{ t: 1576776934938, "FEndV": 4.53, "FResV": 1.78, "REndV": 2.37, "FEndmA": 327, "REndmA": 311, "LeakmA": 16 },
{ t: 1576776945281, "FEndV": 4.16, "FResV": 0.47, "REndV": 2.04, "FEndmA": 251, "REndmA": 202, "LeakmA": 49 },
{ t: 1576776962908, "FEndV": 7.73, "FResV": 0.32, "REndV": 4.12, "FEndmA": 331, "REndmA": 295, "LeakmA": 36 },
{ t: 1576776969815, "FEndV": 7.12, "FResV": 1.57, "REndV": 1.01, "FEndmA": 250, "REndmA": 205, "LeakmA": 45 },
{ t: 1576776984638, "FEndV": 6.14, "FResV": 1.89, "REndV": 4.53, "FEndmA": 305, "REndmA": 272, "LeakmA": 33 },
{ t: 1576776997177, "FEndV": 5.6, "FResV": 0.42, "REndV": 2.17, "FEndmA": 313, "REndmA": 283, "LeakmA": 30 },
{ t: 1576777003916, "FEndV": 5.4, "FResV": 1.72, "REndV": 2.38, "FEndmA": 284, "REndmA": 270, "LeakmA": 14 },
{ t: 1576777018737, "FEndV": 4.29, "FResV": 1.29, "REndV": 4.53, "FEndmA": 336, "REndmA": 297, "LeakmA": 39 },
{ t: 1576777025444, "FEndV": 3.26, "FResV": 1.09, "REndV": 3.09, "FEndmA": 271, "REndmA": 249, "LeakmA": 22 },
{ t: 1576777039473, "FEndV": 3.53, "FResV": 0.06, "REndV": 1.92, "FEndmA": 329, "REndmA": 300, "LeakmA": 29 },
{ t: 1576777045767, "FEndV": 4.42, "FResV": 0.24, "REndV": 2.46, "FEndmA": 306, "REndmA": 269, "LeakmA": 37 },
{ t: 1576777056606, "FEndV": 5.11, "FResV": 1.96, "REndV": 3.99, "FEndmA": 305, "REndmA": 279, "LeakmA": 26 },
{ t: 1576777062484, "FEndV": 4.34, "FResV": 1, "REndV": 3.76, "FEndmA": 268, "REndmA": 248, "LeakmA": 20 },
{ t: 1576777067488, "FEndV": 5.64, "FResV": 0.25, "REndV": 4.3, "FEndmA": 266, "REndmA": 235, "LeakmA": 31 },
{ t: 1576777082150, "FEndV": 3.9, "FResV": 1.87, "REndV": 3.08, "FEndmA": 251, "REndmA": 226, "LeakmA": 25 },
{ t: 1576777095039, "FEndV": 4.33, "FResV": 0.32, "REndV": 2.73, "FEndmA": 338, "REndmA": 298, "LeakmA": 40 },
{ t: 1576777105767, "FEndV": 4.97, "FResV": 1.52, "REndV": 2.98, "FEndmA": 310, "REndmA": 260, "LeakmA": 50 },
{ t: 1576777118538, "FEndV": 7.55, "FResV": 1.92, "REndV": 1.81, "FEndmA": 275, "REndmA": 249, "LeakmA": 26 },
{ t: 1576777129634, "FEndV": 3.76, "FResV": 1.93, "REndV": 1.95, "FEndmA": 350, "REndmA": 336, "LeakmA": 14 },
{ t: 1576777148745, "FEndV": 4.56, "FResV": 0.06, "REndV": 5.05, "FEndmA": 267, "REndmA": 247, "LeakmA": 20 },
{ t: 1576777157977, "FEndV": 7.26, "FResV": 0.79, "REndV": 3.05, "FEndmA": 293, "REndmA": 261, "LeakmA": 32 },
{ t: 1576777164537, "FEndV": 3.59, "FResV": 1.72, "REndV": 4.68, "FEndmA": 277, "REndmA": 233, "LeakmA": 44 },
{ t: 1576777178985, "FEndV": 7.78, "FResV": 0.09, "REndV": 3.55, "FEndmA": 262, "REndmA": 250, "LeakmA": 12 },
{ t: 1576777195452, "FEndV": 3.2, "FResV": 0.81, "REndV": 4.05, "FEndmA": 274, "REndmA": 253, "LeakmA": 21 },
{ t: 1576777209344, "FEndV": 3.59, "FResV": 0.85, "REndV": 1.48, "FEndmA": 315, "REndmA": 284, "LeakmA": 31 },
{ t: 1576777219509, "FEndV": 5.68, "FResV": 0.16, "REndV": 4.52, "FEndmA": 250, "REndmA": 208, "LeakmA": 42 },
{ t: 1576777236556, "FEndV": 4.08, "FResV": 0.18, "REndV": 4.55, "FEndmA": 285, "REndmA": 246, "LeakmA": 39 },
{ t: 1576777255532, "FEndV": 4.82, "FResV": 0.23, "REndV": 4.23, "FEndmA": 305, "REndmA": 259, "LeakmA": 46 },
{ t: 1576777268232, "FEndV": 4.72, "FResV": 1.66, "REndV": 2.15, "FEndmA": 275, "REndmA": 238, "LeakmA": 37 },
{ t: 1576777274084, "FEndV": 7.91, "FResV": 0.87, "REndV": 2.09, "FEndmA": 257, "REndmA": 217, "LeakmA": 40 },
{ t: 1576777289443, "FEndV": 6.5, "FResV": 2, "REndV": 4.29, "FEndmA": 251, "REndmA": 233, "LeakmA": 18 },
{ t: 1576777306581, "FEndV": 5.53, "FResV": 0.4, "REndV": 2.36, "FEndmA": 272, "REndmA": 229, "LeakmA": 43 },
{ t: 1576777325932, "FEndV": 6.51, "FResV": 1.85, "REndV": 3.34, "FEndmA": 261, "REndmA": 236, "LeakmA": 25 },
{ t: 1576777333431, "FEndV": 3.03, "FResV": 0.1, "REndV": 5, "FEndmA": 306, "REndmA": 259, "LeakmA": 47 },
{ t: 1576777345361, "FEndV": 4.53, "FResV": 1.19, "REndV": 1.32, "FEndmA": 332, "REndmA": 311, "LeakmA": 21 },
{ t: 1576777352896, "FEndV": 3.1, "FResV": 1.84, "REndV": 1.71, "FEndmA": 281, "REndmA": 269, "LeakmA": 12 },
{ t: 1576777361731, "FEndV": 4.95, "FResV": 0.38, "REndV": 2.5, "FEndmA": 296, "REndmA": 284, "LeakmA": 12 },
{ t: 1576777368229, "FEndV": 4.96, "FResV": 0.21, "REndV": 3.39, "FEndmA": 251, "REndmA": 234, "LeakmA": 17 },
{ t: 1576777383258, "FEndV": 3.89, "FResV": 1.07, "REndV": 2.48, "FEndmA": 290, "REndmA": 265, "LeakmA": 25 },
{ t: 1576777389749, "FEndV": 4.96, "FResV": 0.45, "REndV": 1.01, "FEndmA": 252, "REndmA": 202, "LeakmA": 50 },
{ t: 1576777408527, "FEndV": 6.66, "FResV": 1.12, "REndV": 5.49, "FEndmA": 283, "REndmA": 255, "LeakmA": 28 },
{ t: 1576777424031, "FEndV": 7.42, "FResV": 1.42, "REndV": 2.09, "FEndmA": 264, "REndmA": 233, "LeakmA": 31 },
{ t: 1576777438101, "FEndV": 7.46, "FResV": 1.93, "REndV": 1.74, "FEndmA": 332, "REndmA": 315, "LeakmA": 17 },
{ t: 1576777452052, "FEndV": 5.58, "FResV": 0.72, "REndV": 2.02, "FEndmA": 338, "REndmA": 309, "LeakmA": 29 },
{ t: 1576777457286, "FEndV": 5.83, "FResV": 1.95, "REndV": 4.08, "FEndmA": 312, "REndmA": 280, "LeakmA": 32 },
{ t: 1576777471220, "FEndV": 4.84, "FResV": 1.1, "REndV": 3.7, "FEndmA": 314, "REndmA": 266, "LeakmA": 48 },
{ t: 1576777484272, "FEndV": 5.91, "FResV": 2, "REndV": 3.32, "FEndmA": 309, "REndmA": 279, "LeakmA": 30 },
{ t: 1576777497999, "FEndV": 3.07, "FResV": 1.57, "REndV": 1.88, "FEndmA": 305, "REndmA": 275, "LeakmA": 30 },
{ t: 1576777517858, "FEndV": 4.95, "FResV": 0.35, "REndV": 2.74, "FEndmA": 331, "REndmA": 287, "LeakmA": 44 },
{ t: 1576777535863, "FEndV": 5.08, "FResV": 0.51, "REndV": 4.06, "FEndmA": 325, "REndmA": 284, "LeakmA": 41 },
{ t: 1576777548551, "FEndV": 4.69, "FResV": 1.93, "REndV": 2.46, "FEndmA": 256, "REndmA": 238, "LeakmA": 18 },
{ t: 1576777554713, "FEndV": 5.67, "FResV": 0.54, "REndV": 5.03, "FEndmA": 333, "REndmA": 289, "LeakmA": 44 },
{ t: 1576777563236, "FEndV": 6.15, "FResV": 0.44, "REndV": 3.94, "FEndmA": 346, "REndmA": 300, "LeakmA": 46 },
{ t: 1576777572864, "FEndV": 3.87, "FResV": 1.62, "REndV": 4.84, "FEndmA": 334, "REndmA": 324, "LeakmA": 10 },
{ t: 1576777586370, "FEndV": 3.4, "FResV": 1.85, "REndV": 3.17, "FEndmA": 324, "REndmA": 293, "LeakmA": 31 },
{ t: 1576777604215, "FEndV": 4.92, "FResV": 0.67, "REndV": 4.32, "FEndmA": 323, "REndmA": 299, "LeakmA": 24 },
{ t: 1576777612532, "FEndV": 7.66, "FResV": 0.66, "REndV": 3.4, "FEndmA": 268, "REndmA": 250, "LeakmA": 18 },
{ t: 1576777619097, "FEndV": 7.43, "FResV": 1.5, "REndV": 4.94, "FEndmA": 300, "REndmA": 287, "LeakmA": 13 },
{ t: 1576777630278, "FEndV": 4.69, "FResV": 0.98, "REndV": 2.96, "FEndmA": 273, "REndmA": 250, "LeakmA": 23 },
{ t: 1576777647307, "FEndV": 5.59, "FResV": 1.07, "REndV": 4.42, "FEndmA": 251, "REndmA": 201, "LeakmA": 50 },
{ t: 1576777664283, "FEndV": 7.93, "FResV": 0.5, "REndV": 4.68, "FEndmA": 280, "REndmA": 247, "LeakmA": 33 },
{ t: 1576777671655, "FEndV": 3.54, "FResV": 0.63, "REndV": 4.78, "FEndmA": 341, "REndmA": 327, "LeakmA": 14 },
{ t: 1576777686592, "FEndV": 3.69, "FResV": 0.1, "REndV": 3.33, "FEndmA": 335, "REndmA": 286, "LeakmA": 49 },
{ t: 1576777698494, "FEndV": 3.56, "FResV": 0.88, "REndV": 3.25, "FEndmA": 261, "REndmA": 218, "LeakmA": 43 },
{ t: 1576777706795, "FEndV": 6.93, "FResV": 1.62, "REndV": 4.52, "FEndmA": 294, "REndmA": 244, "LeakmA": 50 },
{ t: 1576777715573, "FEndV": 7.66, "FResV": 1.97, "REndV": 4.11, "FEndmA": 303, "REndmA": 270, "LeakmA": 33 },
{ t: 1576777733412, "FEndV": 5.57, "FResV": 1.89, "REndV": 1.04, "FEndmA": 322, "REndmA": 274, "LeakmA": 48 },
{ t: 1576777749022, "FEndV": 7.33, "FResV": 1.71, "REndV": 2.27, "FEndmA": 269, "REndmA": 249, "LeakmA": 20 },
{ t: 1576777767749, "FEndV": 5.64, "FResV": 0.11, "REndV": 2.01, "FEndmA": 274, "REndmA": 242, "LeakmA": 32 },
{ t: 1576777778650, "FEndV": 7.91, "FResV": 0.38, "REndV": 3.2, "FEndmA": 250, "REndmA": 239, "LeakmA": 11 },
{ t: 1576777792224, "FEndV": 3.45, "FResV": 0.16, "REndV": 4.46, "FEndmA": 331, "REndmA": 310, "LeakmA": 21 },
{ t: 1576777807639, "FEndV": 5.16, "FResV": 1.4, "REndV": 1.67, "FEndmA": 336, "REndmA": 300, "LeakmA": 36 },
{ t: 1576777827030, "FEndV": 4.43, "FResV": 1.92, "REndV": 2.02, "FEndmA": 314, "REndmA": 280, "LeakmA": 34 },
{ t: 1576777837103, "FEndV": 3.21, "FResV": 1.78, "REndV": 2.75, "FEndmA": 332, "REndmA": 289, "LeakmA": 43 },
{ t: 1576777854134, "FEndV": 7.56, "FResV": 0.9, "REndV": 1.35, "FEndmA": 313, "REndmA": 268, "LeakmA": 45 },
{ t: 1576777871739, "FEndV": 3.7, "FResV": 0.42, "REndV": 3.88, "FEndmA": 321, "REndmA": 301, "LeakmA": 20 },
{ t: 1576777882631, "FEndV": 4.73, "FResV": 0.43, "REndV": 4.97, "FEndmA": 339, "REndmA": 303, "LeakmA": 36 },
{ t: 1576777894669, "FEndV": 7.72, "FResV": 1.65, "REndV": 3.34, "FEndmA": 345, "REndmA": 296, "LeakmA": 49 },
{ t: 1576777910959, "FEndV": 4.46, "FResV": 1.81, "REndV": 3.48, "FEndmA": 284, "REndmA": 245, "LeakmA": 39 },
{ t: 1576777919125, "FEndV": 3.04, "FResV": 1.05, "REndV": 1.84, "FEndmA": 259, "REndmA": 224, "LeakmA": 35 },
{ t: 1576777932514, "FEndV": 6.13, "FResV": 0.82, "REndV": 5.38, "FEndmA": 266, "REndmA": 246, "LeakmA": 20 },
{ t: 1576777941220, "FEndV": 3.02, "FResV": 0.77, "REndV": 3.12, "FEndmA": 269, "REndmA": 219, "LeakmA": 50 },
{ t: 1576777949404, "FEndV": 5.99, "FResV": 0.35, "REndV": 3.23, "FEndmA": 273, "REndmA": 255, "LeakmA": 18 },
{ t: 1576777965177, "FEndV": 3.03, "FResV": 0.5, "REndV": 5.45, "FEndmA": 266, "REndmA": 251, "LeakmA": 15 },
{ t: 1576777970956, "FEndV": 4.71, "FResV": 1.31, "REndV": 5.22, "FEndmA": 333, "REndmA": 298, "LeakmA": 35 },
{ t: 1576777984358, "FEndV": 6.11, "FResV": 1.62, "REndV": 4.94, "FEndmA": 278, "REndmA": 267, "LeakmA": 11 },
{ t: 1576777997711, "FEndV": 7.86, "FResV": 1.34, "REndV": 4.16, "FEndmA": 291, "REndmA": 275, "LeakmA": 16 },
{ t: 1576778005687, "FEndV": 6, "FResV": 1.19, "REndV": 3.36, "FEndmA": 292, "REndmA": 268, "LeakmA": 24 },
{ t: 1576778019285, "FEndV": 4.98, "FResV": 0.13, "REndV": 1.33, "FEndmA": 310, "REndmA": 266, "LeakmA": 44 },
{ t: 1576778031845, "FEndV": 5.95, "FResV": 0.92, "REndV": 1.51, "FEndmA": 333, "REndmA": 291, "LeakmA": 42 },
{ t: 1576778040038, "FEndV": 6.02, "FResV": 0.87, "REndV": 3.64, "FEndmA": 250, "REndmA": 224, "LeakmA": 26 },
{ t: 1576778046708, "FEndV": 4.51, "FResV": 1.1, "REndV": 3.78, "FEndmA": 317, "REndmA": 278, "LeakmA": 39 },
{ t: 1576778066196, "FEndV": 6.59, "FResV": 0.61, "REndV": 4.89, "FEndmA": 348, "REndmA": 307, "LeakmA": 41 },
{ t: 1576778083043, "FEndV": 7.35, "FResV": 1.41, "REndV": 3.77, "FEndmA": 322, "REndmA": 312, "LeakmA": 10 },
{ t: 1576778090916, "FEndV": 3.98, "FResV": 1.41, "REndV": 5.24, "FEndmA": 261, "REndmA": 244, "LeakmA": 17 },
{ t: 1576778105225, "FEndV": 5.29, "FResV": 1.83, "REndV": 1.47, "FEndmA": 260, "REndmA": 233, "LeakmA": 27 },
{ t: 1576778116294, "FEndV": 7.82, "FResV": 1.11, "REndV": 2.91, "FEndmA": 293, "REndmA": 258, "LeakmA": 35 },
{ t: 1576778132306, "FEndV": 5.32, "FResV": 1.67, "REndV": 2.66, "FEndmA": 297, "REndmA": 268, "LeakmA": 29 },
{ t: 1576778146376, "FEndV": 4.95, "FResV": 1.84, "REndV": 3.16, "FEndmA": 279, "REndmA": 260, "LeakmA": 19 },
{ t: 1576778165506, "FEndV": 7.64, "FResV": 1, "REndV": 2.61, "FEndmA": 316, "REndmA": 268, "LeakmA": 48 },
{ t: 1576778174506, "FEndV": 4.29, "FResV": 0.26, "REndV": 3.41, "FEndmA": 287, "REndmA": 241, "LeakmA": 46 },
{ t: 1576778180145, "FEndV": 5.08, "FResV": 0.74, "REndV": 2.31, "FEndmA": 330, "REndmA": 303, "LeakmA": 27 },
{ t: 1576778194417, "FEndV": 7.66, "FResV": 1.7, "REndV": 3.32, "FEndmA": 300, "REndmA": 260, "LeakmA": 40 },
{ t: 1576778205926, "FEndV": 4.63, "FResV": 1.02, "REndV": 3.03, "FEndmA": 284, "REndmA": 239, "LeakmA": 45 },
{ t: 1576778213897, "FEndV": 4.5, "FResV": 1.4, "REndV": 2.95, "FEndmA": 277, "REndmA": 247, "LeakmA": 30 },
{ t: 1576778219749, "FEndV": 6.01, "FResV": 1.42, "REndV": 4.45, "FEndmA": 297, "REndmA": 284, "LeakmA": 13 },
{ t: 1576778227726, "FEndV": 5.16, "FResV": 1.45, "REndV": 3.35, "FEndmA": 327, "REndmA": 317, "LeakmA": 10 },
{ t: 1576778240840, "FEndV": 6.2, "FResV": 1.32, "REndV": 3.47, "FEndmA": 303, "REndmA": 277, "LeakmA": 26 },
{ t: 1576778247233, "FEndV": 6.05, "FResV": 1.98, "REndV": 4, "FEndmA": 281, "REndmA": 268, "LeakmA": 13 },
{ t: 1576778265676, "FEndV": 5.21, "FResV": 1.44, "REndV": 2.13, "FEndmA": 261, "REndmA": 215, "LeakmA": 46 },
{ t: 1576778281855, "FEndV": 7.59, "FResV": 1.08, "REndV": 5.49, "FEndmA": 273, "REndmA": 236, "LeakmA": 37 },
{ t: 1576778296182, "FEndV": 7.81, "FResV": 1.53, "REndV": 1.25, "FEndmA": 286, "REndmA": 270, "LeakmA": 16 },
{ t: 1576778316054, "FEndV": 7.16, "FResV": 1.79, "REndV": 3.19, "FEndmA": 250, "REndmA": 231, "LeakmA": 19 },
{ t: 1576778324026, "FEndV": 3.53, "FResV": 1.61, "REndV": 3.26, "FEndmA": 321, "REndmA": 310, "LeakmA": 11 },
{ t: 1576778337478, "FEndV": 7.6, "FResV": 1.19, "REndV": 4.17, "FEndmA": 345, "REndmA": 332, "LeakmA": 13 },
{ t: 1576778354437, "FEndV": 6.56, "FResV": 1.83, "REndV": 1.42, "FEndmA": 331, "REndmA": 315, "LeakmA": 16 },
{ t: 1576778367103, "FEndV": 6.31, "FResV": 0.79, "REndV": 2.74, "FEndmA": 271, "REndmA": 244, "LeakmA": 27 },
{ t: 1576778372560, "FEndV": 5.87, "FResV": 1.61, "REndV": 2.42, "FEndmA": 326, "REndmA": 290, "LeakmA": 36 },
{ t: 1576778385512, "FEndV": 6.88, "FResV": 0.07, "REndV": 1.88, "FEndmA": 297, "REndmA": 275, "LeakmA": 22 },
{ t: 1576778403325, "FEndV": 5.84, "FResV": 1.26, "REndV": 1.79, "FEndmA": 323, "REndmA": 311, "LeakmA": 12 },
{ t: 1576778417374, "FEndV": 7.61, "FResV": 1.43, "REndV": 5.18, "FEndmA": 257, "REndmA": 243, "LeakmA": 14 },
{ t: 1576778425891, "FEndV": 7.5, "FResV": 1.66, "REndV": 2.27, "FEndmA": 266, "REndmA": 225, "LeakmA": 41 },
{ t: 1576778432652, "FEndV": 5.85, "FResV": 1.09, "REndV": 4.19, "FEndmA": 284, "REndmA": 247, "LeakmA": 37 },
{ t: 1576778442586, "FEndV": 4.38, "FResV": 1.77, "REndV": 3.21, "FEndmA": 342, "REndmA": 324, "LeakmA": 18 },
{ t: 1576778448465, "FEndV": 6.46, "FResV": 1.57, "REndV": 4.46, "FEndmA": 268, "REndmA": 247, "LeakmA": 21 },
{ t: 1576778456126, "FEndV": 3.6, "FResV": 0.63, "REndV": 1.62, "FEndmA": 284, "REndmA": 256, "LeakmA": 28 },
{ t: 1576778469628, "FEndV": 4.34, "FResV": 0.63, "REndV": 3.7, "FEndmA": 284, "REndmA": 250, "LeakmA": 34 },
{ t: 1576778485866, "FEndV": 3.79, "FResV": 1.52, "REndV": 2.17, "FEndmA": 282, "REndmA": 266, "LeakmA": 16 },
{ t: 1576778500816, "FEndV": 4.22, "FResV": 0.15, "REndV": 1.9, "FEndmA": 323, "REndmA": 305, "LeakmA": 18 },
{ t: 1576778507861, "FEndV": 3.55, "FResV": 0.09, "REndV": 3.3, "FEndmA": 258, "REndmA": 231, "LeakmA": 27 },
{ t: 1576778519461, "FEndV": 4.39, "FResV": 1.67, "REndV": 2.13, "FEndmA": 308, "REndmA": 263, "LeakmA": 45 },
{ t: 1576778538555, "FEndV": 5.63, "FResV": 1.35, "REndV": 2.57, "FEndmA": 262, "REndmA": 241, "LeakmA": 21 },
{ t: 1576778553019, "FEndV": 6.06, "FResV": 1.58, "REndV": 4.27, "FEndmA": 288, "REndmA": 252, "LeakmA": 36 },
{ t: 1576778570676, "FEndV": 5.35, "FResV": 0.54, "REndV": 5.09, "FEndmA": 329, "REndmA": 315, "LeakmA": 14 },
{ t: 1576778587453, "FEndV": 7.63, "FResV": 0.78, "REndV": 4.48, "FEndmA": 293, "REndmA": 255, "LeakmA": 38 },
{ t: 1576778597623, "FEndV": 7.08, "FResV": 1.28, "REndV": 2.44, "FEndmA": 338, "REndmA": 307, "LeakmA": 31 },
{ t: 1576778603664, "FEndV": 4.14, "FResV": 0.64, "REndV": 4.5, "FEndmA": 349, "REndmA": 310, "LeakmA": 39 },
{ t: 1576778623099, "FEndV": 4.27, "FResV": 1.33, "REndV": 3.3, "FEndmA": 302, "REndmA": 263, "LeakmA": 39 },
{ t: 1576778642045, "FEndV": 3.57, "FResV": 1.36, "REndV": 3, "FEndmA": 338, "REndmA": 304, "LeakmA": 34 },
{ t: 1576778655726, "FEndV": 5.72, "FResV": 0.76, "REndV": 4.02, "FEndmA": 336, "REndmA": 322, "LeakmA": 14 },
{ t: 1576778667509, "FEndV": 3.82, "FResV": 0.19, "REndV": 4.64, "FEndmA": 317, "REndmA": 283, "LeakmA": 34 },
{ t: 1576778673730, "FEndV": 6.3, "FResV": 1.43, "REndV": 4.14, "FEndmA": 329, "REndmA": 285, "LeakmA": 44 },
{ t: 1576778693715, "FEndV": 7.82, "FResV": 0.46, "REndV": 1.19, "FEndmA": 294, "REndmA": 248, "LeakmA": 46 },
{ t: 1576778706678, "FEndV": 4.54, "FResV": 0.79, "REndV": 2.06, "FEndmA": 283, "REndmA": 263, "LeakmA": 20 },
{ t: 1576778724154, "FEndV": 6.63, "FResV": 0.85, "REndV": 1.62, "FEndmA": 346, "REndmA": 298, "LeakmA": 48 },
{ t: 1576778733760, "FEndV": 3.31, "FResV": 0.39, "REndV": 3.08, "FEndmA": 332, "REndmA": 285, "LeakmA": 47 },
{ t: 1576778740462, "FEndV": 5.09, "FResV": 0.88, "REndV": 2.8, "FEndmA": 326, "REndmA": 300, "LeakmA": 26 },
{ t: 1576778750909, "FEndV": 6.43, "FResV": 0.63, "REndV": 1.94, "FEndmA": 347, "REndmA": 313, "LeakmA": 34 },
{ t: 1576778760786, "FEndV": 5.07, "FResV": 0.03, "REndV": 2.72, "FEndmA": 297, "REndmA": 281, "LeakmA": 16 },
{ t: 1576778780431, "FEndV": 4.54, "FResV": 1.53, "REndV": 3.39, "FEndmA": 272, "REndmA": 245, "LeakmA": 27 },
{ t: 1576778793969, "FEndV": 4.14, "FResV": 1.72, "REndV": 4.06, "FEndmA": 291, "REndmA": 279, "LeakmA": 12 },
{ t: 1576778805937, "FEndV": 6.53, "FResV": 1.03, "REndV": 4.12, "FEndmA": 300, "REndmA": 272, "LeakmA": 28 },
{ t: 1576778823958, "FEndV": 3.51, "FResV": 1.69, "REndV": 5.45, "FEndmA": 261, "REndmA": 217, "LeakmA": 44 },
{ t: 1576778831782, "FEndV": 3.89, "FResV": 2, "REndV": 4.08, "FEndmA": 301, "REndmA": 256, "LeakmA": 45 },
{ t: 1576778837085, "FEndV": 3.9, "FResV": 0.02, "REndV": 2.18, "FEndmA": 312, "REndmA": 271, "LeakmA": 41 },
{ t: 1576778851414, "FEndV": 7.16, "FResV": 1.05, "REndV": 2, "FEndmA": 319, "REndmA": 271, "LeakmA": 48 },
{ t: 1576778862215, "FEndV": 7.78, "FResV": 0.83, "REndV": 1.12, "FEndmA": 250, "REndmA": 236, "LeakmA": 14 },
{ t: 1576778877820, "FEndV": 4.6, "FResV": 0.5, "REndV": 4.31, "FEndmA": 272, "REndmA": 244, "LeakmA": 28 },
{ t: 1576778894672, "FEndV": 5.71, "FResV": 1.27, "REndV": 2.27, "FEndmA": 304, "REndmA": 293, "LeakmA": 11 },
{ t: 1576778909740, "FEndV": 7.83, "FResV": 0.8, "REndV": 2.11, "FEndmA": 291, "REndmA": 260, "LeakmA": 31 },
{ t: 1576778918870, "FEndV": 4.96, "FResV": 1.68, "REndV": 2.73, "FEndmA": 287, "REndmA": 253, "LeakmA": 34 },
{ t: 1576778931308, "FEndV": 7.55, "FResV": 0.63, "REndV": 3.92, "FEndmA": 336, "REndmA": 325, "LeakmA": 11 },
{ t: 1576778939463, "FEndV": 4.32, "FResV": 0.71, "REndV": 3.49, "FEndmA": 259, "REndmA": 249, "LeakmA": 10 },
{ t: 1576778948927, "FEndV": 3.1, "FResV": 0.34, "REndV": 2.26, "FEndmA": 271, "REndmA": 230, "LeakmA": 41 },
{ t: 1576778956584, "FEndV": 5.29, "FResV": 0.29, "REndV": 3.94, "FEndmA": 282, "REndmA": 247, "LeakmA": 35 },
{ t: 1576778971532, "FEndV": 7.71, "FResV": 1.42, "REndV": 2.21, "FEndmA": 279, "REndmA": 251, "LeakmA": 28 },
{ t: 1576778990318, "FEndV": 5.79, "FResV": 0.56, "REndV": 2.22, "FEndmA": 286, "REndmA": 249, "LeakmA": 37 },
{ t: 1576778999033, "FEndV": 4, "FResV": 1.47, "REndV": 1, "FEndmA": 281, "REndmA": 231, "LeakmA": 50 },
{ t: 1576779009448, "FEndV": 4.67, "FResV": 1.36, "REndV": 3.85, "FEndmA": 272, "REndmA": 244, "LeakmA": 28 },
{ t: 1576779020290, "FEndV": 4.52, "FResV": 0.15, "REndV": 2.08, "FEndmA": 296, "REndmA": 286, "LeakmA": 10 },
{ t: 1576779038272, "FEndV": 7.11, "FResV": 0.14, "REndV": 4.02, "FEndmA": 304, "REndmA": 258, "LeakmA": 46 },
{ t: 1576779056131, "FEndV": 3.91, "FResV": 0.76, "REndV": 3.46, "FEndmA": 293, "REndmA": 271, "LeakmA": 22 },
{ t: 1576779069852, "FEndV": 5.63, "FResV": 0.17, "REndV": 1.19, "FEndmA": 329, "REndmA": 291, "LeakmA": 38 },
{ t: 1576779075707, "FEndV": 7.46, "FResV": 1.16, "REndV": 3.42, "FEndmA": 288, "REndmA": 256, "LeakmA": 32 },
{ t: 1576779087879, "FEndV": 7.83, "FResV": 0.13, "REndV": 3.06, "FEndmA": 329, "REndmA": 311, "LeakmA": 18 },
{ t: 1576779097203, "FEndV": 7.09, "FResV": 1.67, "REndV": 5.03, "FEndmA": 313, "REndmA": 278, "LeakmA": 35 },
{ t: 1576779112345, "FEndV": 7.32, "FResV": 1.65, "REndV": 4.22, "FEndmA": 270, "REndmA": 236, "LeakmA": 34 },
{ t: 1576779128171, "FEndV": 3.04, "FResV": 1.81, "REndV": 2.91, "FEndmA": 303, "REndmA": 285, "LeakmA": 18 },
{ t: 1576779142085, "FEndV": 5.28, "FResV": 1.04, "REndV": 2.79, "FEndmA": 255, "REndmA": 215, "LeakmA": 40 },
{ t: 1576779156449, "FEndV": 3.21, "FResV": 1.9, "REndV": 1.64, "FEndmA": 291, "REndmA": 247, "LeakmA": 44 },
{ t: 1576779174051, "FEndV": 5.06, "FResV": 0.93, "REndV": 3.05, "FEndmA": 257, "REndmA": 243, "LeakmA": 14 },
{ t: 1576779185302, "FEndV": 7.45, "FResV": 1.13, "REndV": 2.13, "FEndmA": 281, "REndmA": 245, "LeakmA": 36 },
{ t: 1576779192710, "FEndV": 4.97, "FResV": 1.63, "REndV": 2.68, "FEndmA": 332, "REndmA": 295, "LeakmA": 37 },
{ t: 1576779202148, "FEndV": 4.53, "FResV": 0.57, "REndV": 5.4, "FEndmA": 349, "REndmA": 299, "LeakmA": 50 },
{ t: 1576779211789, "FEndV": 3.81, "FResV": 1.14, "REndV": 2.83, "FEndmA": 329, "REndmA": 299, "LeakmA": 30 },
{ t: 1576779217540, "FEndV": 5.65, "FResV": 1.21, "REndV": 3.65, "FEndmA": 303, "REndmA": 290, "LeakmA": 13 },
{ t: 1576779229767, "FEndV": 5.83, "FResV": 1.95, "REndV": 3.03, "FEndmA": 312, "REndmA": 279, "LeakmA": 33 },
{ t: 1576779239754, "FEndV": 6.24, "FResV": 2, "REndV": 3.45, "FEndmA": 320, "REndmA": 306, "LeakmA": 14 },
{ t: 1576779247763, "FEndV": 7.36, "FResV": 0.73, "REndV": 1.12, "FEndmA": 285, "REndmA": 261, "LeakmA": 24 },
{ t: 1576779252916, "FEndV": 4.38, "FResV": 0.8, "REndV": 5.31, "FEndmA": 267, "REndmA": 246, "LeakmA": 21 },
{ t: 1576779259497, "FEndV": 6.38, "FResV": 1.03, "REndV": 4.88, "FEndmA": 280, "REndmA": 270, "LeakmA": 10 },
{ t: 1576779272992, "FEndV": 4.41, "FResV": 1.93, "REndV": 5.12, "FEndmA": 287, "REndmA": 275, "LeakmA": 12 },
{ t: 1576779280702, "FEndV": 6.34, "FResV": 0.09, "REndV": 4.26, "FEndmA": 267, "REndmA": 247, "LeakmA": 20 },
{ t: 1576779295016, "FEndV": 7.86, "FResV": 0.68, "REndV": 3.37, "FEndmA": 327, "REndmA": 297, "LeakmA": 30 },
{ t: 1576779306075, "FEndV": 6.78, "FResV": 1, "REndV": 2.16, "FEndmA": 318, "REndmA": 305, "LeakmA": 13 },
{ t: 1576779323059, "FEndV": 4.42, "FResV": 1.53, "REndV": 1.96, "FEndmA": 335, "REndmA": 308, "LeakmA": 27 },
{ t: 1576779328153, "FEndV": 3.13, "FResV": 1.16, "REndV": 4.27, "FEndmA": 287, "REndmA": 266, "LeakmA": 21 },
{ t: 1576779334374, "FEndV": 6.59, "FResV": 0.37, "REndV": 5.43, "FEndmA": 277, "REndmA": 262, "LeakmA": 15 },
{ t: 1576779344634, "FEndV": 5.02, "FResV": 0.36, "REndV": 4.89, "FEndmA": 316, "REndmA": 271, "LeakmA": 45 },
{ t: 1576779355929, "FEndV": 5.54, "FResV": 1.19, "REndV": 5.42, "FEndmA": 335, "REndmA": 316, "LeakmA": 19 },
{ t: 1576779369226, "FEndV": 4.19, "FResV": 1.25, "REndV": 3.38, "FEndmA": 262, "REndmA": 232, "LeakmA": 30 },
{ t: 1576779388003, "FEndV": 4.31, "FResV": 1.23, "REndV": 1.09, "FEndmA": 283, "REndmA": 253, "LeakmA": 30 },
{ t: 1576779406069, "FEndV": 5.53, "FResV": 0.21, "REndV": 3.4, "FEndmA": 284, "REndmA": 253, "LeakmA": 31 },
{ t: 1576779416720, "FEndV": 4.23, "FResV": 1.85, "REndV": 2.21, "FEndmA": 311, "REndmA": 273, "LeakmA": 38 },
{ t: 1576779425165, "FEndV": 7.92, "FResV": 0.91, "REndV": 1.68, "FEndmA": 283, "REndmA": 238, "LeakmA": 45 },
{ t: 1576779442086, "FEndV": 4.95, "FResV": 1.3, "REndV": 2.97, "FEndmA": 309, "REndmA": 277, "LeakmA": 32 },
{ t: 1576779451427, "FEndV": 3.92, "FResV": 1.88, "REndV": 3.35, "FEndmA": 281, "REndmA": 245, "LeakmA": 36 },
{ t: 1576779463797, "FEndV": 7.53, "FResV": 0.69, "REndV": 3.46, "FEndmA": 265, "REndmA": 243, "LeakmA": 22 },
{ t: 1576779478987, "FEndV": 6.75, "FResV": 1.21, "REndV": 4.81, "FEndmA": 256, "REndmA": 206, "LeakmA": 50 },
{ t: 1576779485381, "FEndV": 3.33, "FResV": 1.43, "REndV": 4.88, "FEndmA": 344, "REndmA": 311, "LeakmA": 33 },
{ t: 1576779505016, "FEndV": 3.52, "FResV": 1.67, "REndV": 2.01, "FEndmA": 294, "REndmA": 268, "LeakmA": 26 },
{ t: 1576779514457, "FEndV": 4.81, "FResV": 1.65, "REndV": 5.29, "FEndmA": 273, "REndmA": 227, "LeakmA": 46 },
{ t: 1576779525805, "FEndV": 7.8, "FResV": 0.82, "REndV": 3.34, "FEndmA": 322, "REndmA": 311, "LeakmA": 11 },
{ t: 1576779530872, "FEndV": 3.8, "FResV": 0.3, "REndV": 1.43, "FEndmA": 255, "REndmA": 239, "LeakmA": 16 },
{ t: 1576779543089, "FEndV": 5.25, "FResV": 0.75, "REndV": 2.22, "FEndmA": 296, "REndmA": 252, "LeakmA": 44 },
{ t: 1576779562066, "FEndV": 6.02, "FResV": 1.79, "REndV": 3.06, "FEndmA": 294, "REndmA": 257, "LeakmA": 37 },
{ t: 1576779567928, "FEndV": 5.97, "FResV": 1.81, "REndV": 4.13, "FEndmA": 332, "REndmA": 313, "LeakmA": 19 },
{ t: 1576779583866, "FEndV": 6.3, "FResV": 1.14, "REndV": 5.5, "FEndmA": 329, "REndmA": 280, "LeakmA": 49 },
{ t: 1576779601001, "FEndV": 3.56, "FResV": 0.35, "REndV": 2.12, "FEndmA": 345, "REndmA": 296, "LeakmA": 49 },
{ t: 1576779609999, "FEndV": 6.86, "FResV": 0.27, "REndV": 5.28, "FEndmA": 347, "REndmA": 315, "LeakmA": 32 },
{ t: 1576779624325, "FEndV": 4.04, "FResV": 1.52, "REndV": 1.48, "FEndmA": 260, "REndmA": 249, "LeakmA": 11 },
{ t: 1576779643530, "FEndV": 4.34, "FResV": 1.39, "REndV": 3.48, "FEndmA": 325, "REndmA": 297, "LeakmA": 28 },
{ t: 1576779651092, "FEndV": 5.88, "FResV": 1.22, "REndV": 1.16, "FEndmA": 295, "REndmA": 250, "LeakmA": 45 },
{ t: 1576779664073, "FEndV": 4.11, "FResV": 0.97, "REndV": 1.4, "FEndmA": 299, "REndmA": 278, "LeakmA": 21 },
{ t: 1576779678118, "FEndV": 6.48, "FResV": 0.63, "REndV": 4.18, "FEndmA": 264, "REndmA": 245, "LeakmA": 19 },
{ t: 1576779697329, "FEndV": 3.51, "FResV": 1.06, "REndV": 4.99, "FEndmA": 313, "REndmA": 263, "LeakmA": 50 },
{ t: 1576779716598, "FEndV": 3.35, "FResV": 0.56, "REndV": 5.12, "FEndmA": 317, "REndmA": 268, "LeakmA": 49 },
{ t: 1576779728277, "FEndV": 4.69, "FResV": 1.7, "REndV": 2.07, "FEndmA": 342, "REndmA": 312, "LeakmA": 30 },
{ t: 1576779733279, "FEndV": 3.61, "FResV": 1.53, "REndV": 1.16, "FEndmA": 314, "REndmA": 280, "LeakmA": 34 },
{ t: 1576779752245, "FEndV": 5.52, "FResV": 0.43, "REndV": 1.73, "FEndmA": 335, "REndmA": 324, "LeakmA": 11 },
{ t: 1576779769081, "FEndV": 5.56, "FResV": 0.84, "REndV": 4.39, "FEndmA": 305, "REndmA": 278, "LeakmA": 27 },
{ t: 1576779778836, "FEndV": 5.64, "FResV": 1.77, "REndV": 4.03, "FEndmA": 305, "REndmA": 272, "LeakmA": 33 },
{ t: 1576779785741, "FEndV": 5.42, "FResV": 1.38, "REndV": 4.09, "FEndmA": 275, "REndmA": 228, "LeakmA": 47 },
{ t: 1576779803208, "FEndV": 6.08, "FResV": 1.67, "REndV": 4.07, "FEndmA": 262, "REndmA": 222, "LeakmA": 40 },
{ t: 1576779816834, "FEndV": 3.31, "FResV": 0.46, "REndV": 2.05, "FEndmA": 313, "REndmA": 280, "LeakmA": 33 },
{ t: 1576779826828, "FEndV": 5.58, "FResV": 1.32, "REndV": 1.92, "FEndmA": 327, "REndmA": 280, "LeakmA": 47 },
{ t: 1576779838632, "FEndV": 7.42, "FResV": 1.77, "REndV": 3.04, "FEndmA": 337, "REndmA": 289, "LeakmA": 48 },
{ t: 1576779844463, "FEndV": 5.14, "FResV": 0.52, "REndV": 2.3, "FEndmA": 307, "REndmA": 259, "LeakmA": 48 },
{ t: 1576779858289, "FEndV": 6.52, "FResV": 0.09, "REndV": 5.3, "FEndmA": 254, "REndmA": 233, "LeakmA": 21 },
{ t: 1576779872893, "FEndV": 7.7, "FResV": 1.43, "REndV": 5.49, "FEndmA": 268, "REndmA": 250, "LeakmA": 18 },
{ t: 1576779884432, "FEndV": 5.16, "FResV": 1.27, "REndV": 5.31, "FEndmA": 343, "REndmA": 300, "LeakmA": 43 },
{ t: 1576779894123, "FEndV": 5.55, "FResV": 1.83, "REndV": 5.08, "FEndmA": 277, "REndmA": 255, "LeakmA": 22 },
{ t: 1576779906454, "FEndV": 7.71, "FResV": 0.83, "REndV": 2.37, "FEndmA": 281, "REndmA": 238, "LeakmA": 43 },
{ t: 1576779921541, "FEndV": 5.04, "FResV": 1.86, "REndV": 2.91, "FEndmA": 286, "REndmA": 259, "LeakmA": 27 },
{ t: 1576779931806, "FEndV": 5.87, "FResV": 0.68, "REndV": 4.13, "FEndmA": 339, "REndmA": 318, "LeakmA": 21 },
{ t: 1576779949096, "FEndV": 4.64, "FResV": 1.64, "REndV": 3.04, "FEndmA": 310, "REndmA": 296, "LeakmA": 14 },
{ t: 1576779957206, "FEndV": 5.8, "FResV": 1.2, "REndV": 4.64, "FEndmA": 318, "REndmA": 291, "LeakmA": 27 },
{ t: 1576779966073, "FEndV": 7.94, "FResV": 0.58, "REndV": 5.14, "FEndmA": 329, "REndmA": 290, "LeakmA": 39 },
{ t: 1576779980493, "FEndV": 5.81, "FResV": 1.39, "REndV": 3.1, "FEndmA": 278, "REndmA": 231, "LeakmA": 47 },
{ t: 1576779992999, "FEndV": 7.18, "FResV": 1.06, "REndV": 2.18, "FEndmA": 348, "REndmA": 336, "LeakmA": 12 },
{ t: 1576779999212, "FEndV": 7.04, "FResV": 0.52, "REndV": 4.45, "FEndmA": 282, "REndmA": 241, "LeakmA": 41 },
{ t: 1576780009037, "FEndV": 6.51, "FResV": 0.28, "REndV": 3.32, "FEndmA": 261, "REndmA": 236, "LeakmA": 25 },
{ t: 1576780016844, "FEndV": 4.7, "FResV": 0.25, "REndV": 3.6, "FEndmA": 276, "REndmA": 243, "LeakmA": 33 },
{ t: 1576780023016, "FEndV": 7.65, "FResV": 1.95, "REndV": 2.15, "FEndmA": 287, "REndmA": 243, "LeakmA": 44 },
{ t: 1576780040746, "FEndV": 5.03, "FResV": 1.1, "REndV": 4.08, "FEndmA": 280, "REndmA": 261, "LeakmA": 19 },
{ t: 1576780059332, "FEndV": 5.94, "FResV": 1.68, "REndV": 4.22, "FEndmA": 279, "REndmA": 255, "LeakmA": 24 },
{ t: 1576780066812, "FEndV": 7.38, "FResV": 1.56, "REndV": 4.26, "FEndmA": 321, "REndmA": 291, "LeakmA": 30 },
{ t: 1576780078733, "FEndV": 3.77, "FResV": 1.08, "REndV": 4.97, "FEndmA": 329, "REndmA": 314, "LeakmA": 15 },
{ t: 1576780094924, "FEndV": 4.43, "FResV": 0.15, "REndV": 3.21, "FEndmA": 349, "REndmA": 310, "LeakmA": 39 },
{ t: 1576780114735, "FEndV": 7.95, "FResV": 1.64, "REndV": 3.34, "FEndmA": 271, "REndmA": 226, "LeakmA": 45 },
{ t: 1576780133072, "FEndV": 6.42, "FResV": 1.46, "REndV": 3.36, "FEndmA": 285, "REndmA": 244, "LeakmA": 41 },
{ t: 1576780143638, "FEndV": 6.38, "FResV": 0.59, "REndV": 1.88, "FEndmA": 284, "REndmA": 236, "LeakmA": 48 },
{ t: 1576780155934, "FEndV": 3.82, "FResV": 1.13, "REndV": 2.27, "FEndmA": 256, "REndmA": 225, "LeakmA": 31 },
{ t: 1576780164364, "FEndV": 7.48, "FResV": 0.63, "REndV": 2.19, "FEndmA": 319, "REndmA": 290, "LeakmA": 29 },
{ t: 1576780177040, "FEndV": 6.62, "FResV": 0.99, "REndV": 3.25, "FEndmA": 311, "REndmA": 264, "LeakmA": 47 },
{ t: 1576780186401, "FEndV": 6.52, "FResV": 0.02, "REndV": 1.18, "FEndmA": 257, "REndmA": 235, "LeakmA": 22 },
{ t: 1576780194665, "FEndV": 6.92, "FResV": 1.1, "REndV": 4.29, "FEndmA": 311, "REndmA": 286, "LeakmA": 25 },
{ t: 1576780211544, "FEndV": 3.36, "FResV": 1.64, "REndV": 4.26, "FEndmA": 311, "REndmA": 295, "LeakmA": 16 },
{ t: 1576780218599, "FEndV": 4.46, "FResV": 0.07, "REndV": 2.32, "FEndmA": 287, "REndmA": 258, "LeakmA": 29 },
{ t: 1576780223803, "FEndV": 3.71, "FResV": 0.98, "REndV": 5.09, "FEndmA": 302, "REndmA": 278, "LeakmA": 24 },
{ t: 1576780234824, "FEndV": 3.1, "FResV": 1.23, "REndV": 3.42, "FEndmA": 327, "REndmA": 313, "LeakmA": 14 },
{ t: 1576780240442, "FEndV": 4.37, "FResV": 0.17, "REndV": 2.25, "FEndmA": 336, "REndmA": 321, "LeakmA": 15 },
{ t: 1576780246200, "FEndV": 5.09, "FResV": 0.68, "REndV": 1.06, "FEndmA": 257, "REndmA": 247, "LeakmA": 10 },
{ t: 1576780261162, "FEndV": 3.86, "FResV": 0.66, "REndV": 3.6, "FEndmA": 288, "REndmA": 251, "LeakmA": 37 },
{ t: 1576780266663, "FEndV": 5.16, "FResV": 0.6, "REndV": 2.63, "FEndmA": 335, "REndmA": 315, "LeakmA": 20 },
{ t: 1576780281697, "FEndV": 7.14, "FResV": 0.63, "REndV": 2.27, "FEndmA": 290, "REndmA": 271, "LeakmA": 19 },
{ t: 1576780293481, "FEndV": 7.23, "FResV": 0.5, "REndV": 4.95, "FEndmA": 348, "REndmA": 333, "LeakmA": 15 },
{ t: 1576780311132, "FEndV": 3.16, "FResV": 0.63, "REndV": 1.35, "FEndmA": 289, "REndmA": 269, "LeakmA": 20 },
{ t: 1576780328204, "FEndV": 5.51, "FResV": 0.78, "REndV": 3.8, "FEndmA": 336, "REndmA": 307, "LeakmA": 29 },
{ t: 1576780347268, "FEndV": 6.11, "FResV": 0.88, "REndV": 2.88, "FEndmA": 315, "REndmA": 274, "LeakmA": 41 },
{ t: 1576780366134, "FEndV": 5.49, "FResV": 0.82, "REndV": 3.09, "FEndmA": 350, "REndmA": 324, "LeakmA": 26 },
{ t: 1576780374561, "FEndV": 5.73, "FResV": 1.26, "REndV": 2.07, "FEndmA": 347, "REndmA": 308, "LeakmA": 39 },
{ t: 1576780393621, "FEndV": 4.97, "FResV": 1.58, "REndV": 1.82, "FEndmA": 264, "REndmA": 226, "LeakmA": 38 },
{ t: 1576780409604, "FEndV": 3.85, "FResV": 0.06, "REndV": 2.87, "FEndmA": 281, "REndmA": 267, "LeakmA": 14 },
{ t: 1576780415691, "FEndV": 5.26, "FResV": 0.76, "REndV": 3.48, "FEndmA": 298, "REndmA": 262, "LeakmA": 36 },
{ t: 1576780421171, "FEndV": 5.42, "FResV": 0.72, "REndV": 4.23, "FEndmA": 278, "REndmA": 228, "LeakmA": 50 },
{ t: 1576780438994, "FEndV": 6.24, "FResV": 0.82, "REndV": 1.48, "FEndmA": 267, "REndmA": 234, "LeakmA": 33 },
{ t: 1576780448048, "FEndV": 6.16, "FResV": 0.78, "REndV": 2.72, "FEndmA": 278, "REndmA": 236, "LeakmA": 42 },
{ t: 1576780464636, "FEndV": 6.36, "FResV": 1.62, "REndV": 3.1, "FEndmA": 280, "REndmA": 267, "LeakmA": 13 },
{ t: 1576780480034, "FEndV": 6.99, "FResV": 1.85, "REndV": 3.07, "FEndmA": 280, "REndmA": 258, "LeakmA": 22 },
{ t: 1576780496464, "FEndV": 4.17, "FResV": 0.57, "REndV": 4.77, "FEndmA": 342, "REndmA": 302, "LeakmA": 40 },
{ t: 1576780511049, "FEndV": 3.08, "FResV": 0.87, "REndV": 1.77, "FEndmA": 284, "REndmA": 266, "LeakmA": 18 },
{ t: 1576780518257, "FEndV": 7.31, "FResV": 0.67, "REndV": 4.45, "FEndmA": 275, "REndmA": 257, "LeakmA": 18 },
{ t: 1576780535931, "FEndV": 3.12, "FResV": 1.75, "REndV": 4.14, "FEndmA": 295, "REndmA": 268, "LeakmA": 27 },
{ t: 1576780550966, "FEndV": 6.48, "FResV": 1.48, "REndV": 4.52, "FEndmA": 290, "REndmA": 279, "LeakmA": 11 },
{ t: 1576780562708, "FEndV": 7.19, "FResV": 0.17, "REndV": 2.14, "FEndmA": 304, "REndmA": 291, "LeakmA": 13 },
{ t: 1576780572432, "FEndV": 4.17, "FResV": 0.25, "REndV": 5.36, "FEndmA": 338, "REndmA": 315, "LeakmA": 23 },
{ t: 1576780581265, "FEndV": 6.11, "FResV": 1.44, "REndV": 5.39, "FEndmA": 250, "REndmA": 216, "LeakmA": 34 },
{ t: 1576780597077, "FEndV": 6.6, "FResV": 1.2, "REndV": 7.3, "FEndmA": 289, "REndmA": 259, "LeakmA": 30 }];
export const mockTsPhysicalData = {
  "id": 1,
  "name": "Loading...",
  "loc": "Loading...",
  "seenOn": "Loading...",
  "cof": "Loading...",
  "mNo": "Loading...",
  "lat": 28.591689,
  "lng": 77.372788,
  "hdop": 1.5,
  "sats": 3,
  "sensors": [
    {
      "id": 1,
      "idx": 0,
      "calDataJson": null,
      "name": "V1",
      "isVisible": true
    },
    {
      "id": 2,
      "idx": 1,
      "calDataJson": null,
      "name": "V2",
      "isVisible": true
    },
    {
      "id": 3,
      "idx": 2,
      "calDataJson": null,
      "name": "V3",
      "isVisible": true
    },
    {
      "id": 4,
      "idx": 3,
      "calDataJson": null,
      "name": "V4",
      "isVisible": true
    },
    {
      "id": 5,
      "idx": 4,
      "calDataJson": null,
      "name": "V5",
      "isVisible": true
    },
    {
      "id": 6,
      "idx": 5,
      "calDataJson": null,
      "name": "V6",
      "isVisible": true
    },
    {
      "id": 7,
      "idx": 6,
      "calDataJson": null,
      "name": "V7",
      "isVisible": true
    },
    {
      "id": 8,
      "idx": 7,
      "calDataJson": null,
      "name": "V8",
      "isVisible": true
    },
    {
      "id": 9,
      "idx": 8,
      "calDataJson": null,
      "name": "I1",
      "isVisible": true
    },
    {
      "id": 10,
      "idx": 9,
      "calDataJson": null,
      "name": "I2",
      "isVisible": true
    },
    {
      "id": 11,
      "idx": 10,
      "calDataJson": null,
      "name": "I3",
      "isVisible": true
    },
    {
      "id": 12,
      "idx": 11,
      "calDataJson": null,
      "name": "I4",
      "isVisible": true
    },
    {
      "id": 13,
      "idx": 12,
      "calDataJson": null,
      "name": "Temp",
      "isVisible": true
    },
    {
      "id": 14,
      "idx": 13,
      "calDataJson": null,
      "name": "Humidity",
      "isVisible": true
    },
    {
      "id": 15,
      "idx": 14,
      "calDataJson": null,
      "name": "AdcVref",
      "isVisible": false
    },
    {
      "id": 16,
      "idx": 15,
      "calDataJson": null,
      "name": "AdcVdd",
      "isVisible": false
    },
    {
      "id": 17,
      "idx": 16,
      "calDataJson": null,
      "name": "AdcErr",
      "isVisible": false
    },
    {
      "id": 18,
      "idx": 17,
      "calDataJson": null,
      "name": "AdcOffset",
      "isVisible": false
    }
  ],
  "ts": 0,
  "te": 0,
  "v": [{
    "t": 0,
    "v": []
  }],
  "latest": {
    "t": null,
    "v": [
      0.0,
      0.0,
      0.0,
      0.0,
      0.0,
      0.0,
      0.0,
      0.0,
      0.0,
      0.0,
      0.0,
      0.0,
      0.0,
      0.0,
      0.0,
      0.0,
      0.0,
      0.0
    ]
  }
};
