import { Drawer, IconButton } from "@material-ui/core";
import List from '@material-ui/core/List';
import { Home as HomeIcon, ArrowBack as ArrowBackIcon, FilterNone as UIElementsIcon } from "@material-ui/icons";
import axios from 'axios';
import classNames from "classnames";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import * as ApiGlobal from '../../api/ApiGlobal';
import SidebarEntity from "./components/SidebarEntity/SidebarEntity";
import { LOG_TAG, GEAR_TYPE } from "../../GlobalConstants";

// import {
//   useLayoutState,
//   useLayoutDispatch,
//   toggleSidebar,
// } from "../../context/LayoutContext";

let initialTrackCircuitList = [
  {
    id: 1, name: "Track Circuits", entityLink: "", entityIcon: <UIElementsIcon />,
    entityChildren: [],
    isHeader: true
  }
];
let initialPointMachineList = [
  {
    id: 1, name: "Point machines", entityLink: "", entityIcon: <UIElementsIcon />,
    entityChildren: [],
    isHeader: true
  }
];
let initialSignalList = [
  {
    id: 1, name: "Signals", entityLink: "", entityIcon: <UIElementsIcon />,
    entityChildren: [],
    isHeader: true
  }
];
let initialLBoxList = [
  {
    id: 1, name: "Location Boxes", entityLink: "", entityIcon: <UIElementsIcon />,
    entityChildren: [],
    isHeader: true
  }
];

// local
class Sidebar extends Component {
  constructor(props) {
    super(props);
    // console.log(props);

    this.startPolling = this.startPolling.bind(this);
    this.stopPolling = this.stopPolling.bind(this);
    // this.extractLogicalEntities = this.extractLogicalEntities.bind(this);
    this.handleWindowWidthChange = this.handleWindowWidthChange.bind(this);
    this.handleApiError = this.handleApiError.bind(this);
    this.handleApiSuccess = this.handleApiSuccess.bind(this);
    this.parseApiResponse = this.parseApiResponse.bind(this);
    this.apiCall = this.apiCall.bind(this);

    this.dashboardHomeEntity = ApiGlobal.mockSingleItemSidebarEntity;
    this.dashboardHomeEntity.entityIcon = <HomeIcon />;
    this.state = {
      isPermanent: true,
      apiTokenSource: {},
      CancelToken: axios.CancelToken,
      physicalEntities: [],
      logicalEntities: ApiGlobal.mockLogicalEntities,
      trackCircuitEntityList: initialTrackCircuitList,
      pointMachineEntityList: initialPointMachineList,
      signalEntityList: initialSignalList,
      lBoxEntityList: initialLBoxList,
    };
  }

  parseApiResponse(newApiResponseObj) {
    try {
      let tempTrackCircuitEntities = [];
      let tempPointMachineEntities = [];
      let tempSignalEntities = [];
      let tempLBoxEntities = [];
      for (let entityCtr = 0; entityCtr < newApiResponseObj.length; entityCtr++) {
        if (newApiResponseObj[entityCtr].entityType === GEAR_TYPE.TRCK) {
          let tempObj = { ...newApiResponseObj[entityCtr], entityLink: "/app/trck/" + newApiResponseObj[entityCtr].id }
          tempTrackCircuitEntities.push(tempObj);
        } else if (newApiResponseObj[entityCtr].entityType === GEAR_TYPE.PTMC) {
          let tempObj = { ...newApiResponseObj[entityCtr], entityLink: "/app/ptmc/" + newApiResponseObj[entityCtr].id }
          tempPointMachineEntities.push(tempObj);
        } else if (newApiResponseObj[entityCtr].entityType === GEAR_TYPE.SGNL) {
          let tempObj = { ...newApiResponseObj[entityCtr], entityLink: "/app/sgnl/" + newApiResponseObj[entityCtr].id }
          tempSignalEntities.push(tempObj);
        } else if (newApiResponseObj[entityCtr].entityType === GEAR_TYPE.LBOX) {
          let tempObj = { ...newApiResponseObj[entityCtr], entityLink: "/app/lbox/" + newApiResponseObj[entityCtr].id }
          tempLBoxEntities.push(tempObj);
        }
      }

      let tempTrackCircuitList = this.state.trackCircuitEntityList;
      tempTrackCircuitList[0].entityChildren.splice(0, 0, ...tempTrackCircuitEntities);

      let tempPointMachineList = this.state.pointMachineEntityList;
      tempPointMachineList[0].entityChildren.splice(0, 0, ...tempPointMachineEntities);

      // let tempSignalList = this.state.signalEntityList;
      // tempSignalList[0].entityChildren.splice(0, 0, ...tempSignalEntities);

      let tempLBoxList = this.state.lBoxEntityList;
      tempLBoxList[0].entityChildren.splice(0, 0, ...tempLBoxEntities);

      this.setState({
        logicalEntities: newApiResponseObj,
        trackCircuitEntityList: tempTrackCircuitList,
        pointMachineEntityList: tempPointMachineList,
        // signalEntityList: tempSignalList
        lBoxEntityList: tempLBoxList
      });

    } catch (err) {
      console.log(err.message);
    }
  }
  startPolling() {
  }

  stopPolling() {
  }

  handleApiSuccess(response) {
    // console.log(response.data)
    this.parseApiResponse(response.data);
  }

  handleApiError(error) {
    if (axios.isCancel(error)) {
      console.log('sidebar apiRequest cancelled!');
    }
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }
    console.log(error.config);
  }

  apiCall() {
    console.log(LOG_TAG.INFO,"Sidebar apiCall");
    const apiRequest = axios({
      method: `${ApiGlobal.AXIOS_METHOD_TYPE_GET}`,
      url: `${ApiGlobal.GET_LOGICAL_ENTITIES}`,
      baseURL: `${ApiGlobal.API_BASE_URL}`,
      headers: {
        'Content-Type': 'application/json'
      },
      canceltoken: this.state.apiTokenSource.token,
    });
    return apiRequest;
  }

  componentDidMount() {
    console.log(LOG_TAG.LIFE_CYCLE,'DidMount SidebarClass')
    this.setState({ apiTokenSource: axios.CancelToken.source() })
    try {
      window.addEventListener("resize", this.handleWindowWidthChange);
      this.handleWindowWidthChange();
      this.startPolling();
      const request = this.apiCall()
      request.then(response => this.handleApiSuccess(response))
        .catch(error => this.handleApiError(error));
      // this.extractLogicalEntities(this.state.logicalEntities);
    } catch (err) {
      console.log(err.message)
    }
  }

  componentWillUnmount() {
    try {
      window.removeEventListener("resize", this.handleWindowWidthChange);
      this.stopPolling();
      this.state.apiTokenSource.cancel('apiRequest cancelled: unmounted!');
    } catch (err) {
      console.log(err.message)
    }
  }
  render() {
    return (
      <Drawer
        variant={this.state.isPermanent ? "permanent" : "temporary"}
        className={classNames(this.props.classes.drawer, {
          [this.props.classes.drawerOpen]: this.props.isSidebarOpened,
          [this.props.classes.drawerClose]: !this.props.isSidebarOpened,
        })}
        classes={{
          paper: classNames({
            [this.props.classes.drawerOpen]: this.props.isSidebarOpened,
            [this.props.classes.drawerClose]: !this.props.isSidebarOpened,
          }),
        }}
        open={this.props.isSidebarOpened}
      >
        <div className={this.props.classes.toolbar} />
        <div className={this.props.classes.mobileBackButton}>
          <IconButton onClick={() => this.props.toggleSidebar(this.props.layoutDispatch)}>
            <ArrowBackIcon
              classes={{
                root: classNames(this.props.classes.headerIcon, this.props.classes.headerIconCollapse),
              }}
            />
          </IconButton>
        </div>

        <List className={this.props.classes.sidebarList}>
          <SidebarEntity
            key={this.dashboardHomeEntity.id}
            location={this.props.location}
            isSidebarOpened={this.props.isSidebarOpened}
            entityItem={this.dashboardHomeEntity}
          />
        </List>
        <List className={this.props.classes.sidebarList}>
          {this.state.trackCircuitEntityList.map(entityItem => (
            <SidebarEntity
              key={entityItem.id}
              location={this.props.location}
              isSidebarOpened={this.props.isSidebarOpened}
              entityItem={entityItem}
            />
          ))}
        </List>
        <List className={this.props.classes.sidebarList}>
          {this.state.pointMachineEntityList.map(entityItem => (
            <SidebarEntity
              key={entityItem.id}
              location={this.props.location}
              isSidebarOpened={this.props.isSidebarOpened}
              entityItem={entityItem}
            />
          ))}
        </List>
        <List className={this.props.classes.sidebarList}>
          {this.state.signalEntityList.map(entityItem => (
            <SidebarEntity
              key={entityItem.id}
              location={this.props.location}
              isSidebarOpened={this.props.isSidebarOpened}
              entityItem={entityItem}
            />
          ))}
        </List>
        <List className={this.props.classes.sidebarList}>
          {this.state.lBoxEntityList.map(entityItem => (
            <SidebarEntity
              key={entityItem.id}
              location={this.props.location}
              isSidebarOpened={this.props.isSidebarOpened}
              entityItem={entityItem}
            />
          ))}
        </List>
      </Drawer>
    );

  }

  handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = this.props.theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && this.state.isPermanent) {
      this.setState({
        isPermanent: false
      })
    } else if (!isSmallScreen && !this.state.isPermanent) {
      this.setState({
        isPermanent: true
      })
    }
  }
}

export default withRouter(Sidebar);