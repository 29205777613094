import { useGutterBorderedGridStyles } from '@mui-treasury/styles/grid/gutterBordered';
import { useFadedShadowStyles } from '@mui-treasury/styles/shadow/faded';
import React from "react";
import { SEVERITY } from '../../../GlobalConstants';
import './styles.scss';

function EventListItem({ logicalEvent, styles }) {
  const shadowStyles = useFadedShadowStyles();
  const borderedGridStyles = useGutterBorderedGridStyles({
    borderColor: 'rgba(0, 0, 0, 0.08)',
    height: '60%',
  });

  let name = "Wait...";
  let details = "Wait...";
  let time = "Wait...";
  let date = "Wait...";
  let duration = "Wait...";
  let severity = 1;
  let colour='stats is-blue';

  let eventId = -1;
  if (logicalEvent !== undefined) {
    eventId = logicalEvent.id;
    name = logicalEvent.name;
    details = logicalEvent.description;
    time = logicalEvent.time;
    date = logicalEvent.date;
    duration = logicalEvent.duration;
    severity = logicalEvent.severity;
    
    if (eventId === undefined) {
      eventId = -1;
    }
    if (name === undefined) {
      name = "- - - ";
    }
    if (details === undefined) {
      details = "- - - ";
    }
    // if (time === undefined) {
    //   time = "- - - ";
    // }
    if (date === undefined) {
      date = "- - - ";
    }
    if (duration === undefined) {
      duration = "- - - ";
    }
    if (severity === undefined) {
      severity = 1;
    }

    switch (severity) {
      case  SEVERITY.NORMAL:
          colour='stats is-green'
        break;
      case  SEVERITY.WARNING:
          colour='stats is-orange'
        break;
      case  SEVERITY.ERROR:
          colour='stats is-cherry'
        break;
      default:
        break;
    }
  }

  return (

    <div className="card-stats">
      <div className={colour}>
      <h6>{date} ({duration})</h6>
        <h3>{name}</h3>
        <div className="stats-bottom">
          <h6>{details}</h6>
        </div>
      </div>
    </div>

  );
}
export default (EventListItem);