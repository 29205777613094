import React from "react";
import { useGutterBorderedGridStyles } from '@mui-treasury/styles/grid/gutterBordered';
import { useFadedShadowStyles } from '@mui-treasury/styles/shadow/faded';
import { GEAR_TYPE } from '../../../../GlobalConstants';
import './styles.scss'
import { BorderAll } from "@material-ui/icons";

function EntityListItem({ entity, styles, isEntityHidden }) {
  const shadowStyles = useFadedShadowStyles();
  const borderedGridStyles = useGutterBorderedGridStyles({
    borderColor: 'rgba(0, 0, 0, 0.08)',
    height: '60%',
  });

  let name = "- - -";
  let health = "- - -";
  let station = "- - -";
  let feedEnd = "- - -";
  let relayEnd = "- - -";
  let colourClass, statsRowClass;
  if (entity !== undefined) {
    if (entity.name !== undefined) name = entity.name;
    if (entity.health !== undefined) health = entity.health;
    if (entity.station !== undefined) station = entity.station;

    if (entity.locationBoxes !== undefined && entity.locationBoxes.length > 0) {
      for (let i = 0; i < entity.locationBoxes.length; i++) {
        if (entity.locationBoxes[i].role === 'Feed') {
          feedEnd = entity.locationBoxes[i].name;
        }
        if (entity.locationBoxes[i].role === 'Relay') {
          relayEnd = entity.locationBoxes[i].name;
        }
      }
    }

    if (entity.health > 75) {
      colourClass = 'stats is-green';
      statsRowClass = 'stats-row is-green';
    } else if (entity.health < 50) {
      colourClass = 'stats is-cherry';
      statsRowClass = 'stats-row is-cherry';
    } else {
      colourClass = 'stats is-yellow';
      statsRowClass = 'stats-row is-yellow';
    }

  }
  let rootclassName;
  if (isEntityHidden) {
    rootclassName = "card-stats-hidden";
  } else {
    rootclassName = "card-stats";
  }

  let row1, row2;
  switch (entity.entityType) {
    case GEAR_TYPE.TRCK:
      row1 = <h3>{name}</h3>
      row2 = <div className={statsRowClass}>
        <h4>{health}%</h4>
        <h4>{feedEnd}</h4>
        <h4>{"-->"}</h4>
        <h4>{relayEnd}</h4>
      </div>

      break;
    case GEAR_TYPE.PTMC:
      row1 = <h3>{name}</h3>
      row2 = <div className={statsRowClass}>
        <h3>{health}%</h3>
      </div>

      break;
    case GEAR_TYPE.SGNL:
      row1 = <h3>{name}</h3>

      row2 = <div className={statsRowClass}>
        <h3>{health}%</h3>
      </div>

      break;
    case GEAR_TYPE.LBOX:
      row1 = <h3>{name}</h3>
      row2 = <div className={statsRowClass}>
        <h3>{health}%</h3>
      </div>
      break;
    default:
      break;
  }
  return (

    <div className={rootclassName}>
      <div className={colourClass}>
        {row1}
        {row2}

      </div>
    </div>
  );
}
export default (EntityListItem);