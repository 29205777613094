import React from 'react';
import logo from './3p3_logo.svg';
import './UnderConstruction.css';

function UnderConstruction() {
  return (
    <div className="UnderConstruction">
      <header className="UnderConstruction-header">
        <img src={logo} className="UnderConstruction-logo" alt="logo" />
        <p>
          This page is under construction. Please watch this space for updates in future.
        </p>
        <a
          className="UnderConstruction-link"
          href="/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Home
        </a>
      </header>
    </div>
  );
}

export default UnderConstruction;
