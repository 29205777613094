import { makeStyles } from "@material-ui/styles";
import * as GlobalConstants from '../../GlobalConstants'
export default makeStyles(theme => ({
  card: {
    borderRadius: 12,
    minWidth: 150,
    textAlign: 'left',
    margin: 5
  },
  heading: {
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: '0.5px',
    marginTop: 4,
    marginBottom: 0,
  },
  headingKey: {
    fontSize: 16,
    color: theme.palette.grey[500],
    fontWeight: 500,
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    margin: 0,
  },
  subheader: {
    fontSize: 14,
    color: theme.palette.grey[500],
    marginBottom: '0.875em',
  },
  statLabel: {
    fontSize: 12,
    color: theme.palette.grey[500],
    fontWeight: 500,
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    margin: 0,
  },
  statValue: {
    fontSize: 20,
    fontWeight: 'bold',
    letterSpacing: '1px',
  },
  // card: {
  //   minHeight: "100%",
  //   display: "flex",
  //   flexDirection: "column",
  // },
  cardGreen: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: GlobalConstants.GREEN
  },
  cardYellow: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: GlobalConstants.YELLOW
  },
  cardOrange: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: GlobalConstants.ORANGE
  },
  cardRed: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: GlobalConstants.RED
  },
  dashboardGeneralInfo: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    paddingBottom: theme.spacing(1),
  },
  progressSection: {
    marginBottom: theme.spacing(1),
  },
  progressTitle: {
    marginBottom: theme.spacing(2),
  },
  progress: {
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  pieChartLegendWrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    marginRight: theme.spacing(1),
  },
  legendItemContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  fullHeightBody: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "space-between",
  },

  tableWidget: {
    overflowX: "auto",
  },
  progressBar: {
    backgroundColor: theme.palette.warning.main,
  },
  performanceLegendWrapper: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  legendElement: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
  },
  legendElementText: {
    marginLeft: theme.spacing(1),
  },
  serverOverviewElement: {
    display: "flex",
    alignItems: "center",
    maxWidth: "100%",
  },
  serverOverviewElementText: {
    minWidth: 145,
    paddingRight: theme.spacing(2),
  },
  serverOverviewElementChartWrapper: {
    width: "100%",
  },
  mainChartBody: {
    overflowX: "auto",
  },
  mainChartHeader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.only("xs")]: {
      flexWrap: "wrap",
    },
  },
  mainChartHeaderLabels: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.only("xs")]: {
      order: 3,
      width: "100%",
      justifyContent: "center",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
  },

  divPlaceHolderNoMargins: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.only("xs")]: {
      order: 3,
      width: "100%",
      justifyContent: "center",
    },
  },
  mainChartHeaderLabel: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(3),
  },
  mainChartSelectRoot: {
    borderColor: theme.palette.text.hint + "80 !important",
  },
  mainChartSelect: {
    padding: 10,
    paddingRight: 25,
  },
  mainChartLegentElement: {
    fontSize: "18px !important",
    marginLeft: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(2)
  },
  dividerBlue: {
    backgroundColor: '#424572',
  },
  dividerRed: {
    backgroundColor: '#b70003',
  },
  listRoot: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  typographyInline: {
    display: 'inline',
  },

  pageTitle: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  pageHeading: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
    // marginTop: theme.spacing(1),
  },
  headingTextBig: {
    fontSize: 24,
    // fontWeight: 'bold',
    letterSpacing: '0.5px',
    // marginTop: 4,
    marginBottom: 0,
  },
  headingTextSmall: {
    fontSize: 18,
    // fontWeight: 'bold',
    letterSpacing: '0.5px',
    // marginTop: 4,
    marginBottom: 0,
  },
  pageTitleSubtext: {
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: theme.spacing(1),
    // marginTop: theme.spacing(1),
  },
  pageHeadingSubtext: {
    // display: "",
    justifyContent: "flex-start",
    marginBottom: theme.spacing(1),
    // marginTop: theme.spacing(1),
  },
  typo: {
    color: theme.palette.text.text,
    marginBottom: theme.spacing(1),
  },
  typoSubtext: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  button: {
    boxShadow: theme.customShadows.widget,
    textTransform: "none",
    "&:active": {
      boxShadow: theme.customShadows.widgetWide,
    },
  },


  cardTitle: {
    display: "flex",
    justifyContent: "space-between",
    // marginBottom: theme.spacing(1),
  },

  eventList: {
    boxShadow: theme.customShadows.widget,
    marginLeft: theme.spacing(.2),
    marginTop: theme.spacing(.2),
    marginBottom: theme.spacing(.2),
    marginRight: theme.spacing(.5),
    borderRadius: "3em",
    backgroundColor: '#0288D1' // or whatever color you want this to be
  },

  eventList1: [{
    paddingBottom: theme.spacing(1),
    borderRadius: "1em",
    backgroundColor: '#303F9F' // or whatever color you want this to be
  }, {
    paddingBottom: theme.spacing(1),
    borderRadius: "1em",
    backgroundColor: '#388E3C' // or whatever color you want this to be
  }, {
    paddingBottom: theme.spacing(1),
    borderRadius: "1em",
    backgroundColor: '#FFA000' // or whatever color you want this to be
  }, {
    paddingBottom: theme.spacing(1),
    borderRadius: "1em",
    backgroundColor: '#E64A19' // or whatever color you want this to be
  }, {
    paddingBottom: theme.spacing(1),
    borderRadius: "1em",
    backgroundColor: '##D32F2F' // or whatever color you want this to be
  }],
  // #responsiveContainer {
  //   -webkit-box-sizing: border-box;
  //   -moz-box-sizing: border-box;
  //   box-sizing: border-box;
  //   padding: 10px;
  //   width: 100%;
  //   height: 400px;
  //   background-color: #fff;
  // }

}));
