import { Divider } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { useGutterBorderedGridStyles } from '@mui-treasury/styles/grid/gutterBordered';
import React from "react";
import useStyles from "../components/styles";
function CardTitle({ trck }) {
    return <>
        <div style={{ backgroundColor: "red" }}>
        </div>
        {trck.name}
    </>
}
function EntityEventSummary({ summary }) {
    let classes = useStyles();
    const borderedGridStyles = useGutterBorderedGridStyles({
        borderColor: 'rgba(0, 0, 0, 0.08)',
        height: '50%',
    });
    let normal = "- - -";
    let warnings = "- - -";
    let errors = "- - - ";
    let name = "- - - ";
    if (summary !== undefined) {
        if(summary.n!==undefined) normal = summary.n;
        if(summary.w!==undefined) warnings = summary.w;
        if(summary.e!==undefined) errors = summary.e;
        if(summary.name!==undefined) name = summary.name;
    }
    return (
        <Card className={classes.card}>
            <CardContent>
                <h3 className={classes.heading}>{name}</h3>
                <span className={classes.subheader}>Live Summary</span>
            </CardContent>
            <Divider light />
            <Box display={'flex'}>
                <Box p={2} flex={'auto'} className={borderedGridStyles.item}>
                    <p className={classes.statLabel}>Normal</p>
                    <p className={classes.statValue}>{normal}</p>
                </Box>
                <Box p={2} flex={'auto'} className={borderedGridStyles.item}>
                    <p className={classes.statLabel}>Warning</p>
                    <p className={classes.statValue}>{warnings}</p>
                </Box>
                <Box p={2} flex={'auto'} className={borderedGridStyles.item}>
                    <p className={classes.statLabel}>Errors</p>
                    <p className={classes.statValue}>{errors}</p>
                </Box>
            </Box>
        </Card>
    )
}
export default (EntityEventSummary);