import { Grid } from "@material-ui/core";
import axios from 'axios';
import 'rc-checkbox/assets/index.css';
import React from 'react';
import { withRouter } from "react-router-dom";
import { ApiHelper } from './api/ApiHelper';
// components
import * as Constants from './Constants';
import EventsWeekly from './events/EventsWeekly';
import EventTabs from './events/EventTabs';
import EntityGrid from './entities/EntityGrid';
import EntityEventSummary from './entities/EntityEventSummary';
import EnvSummary from './entities/EnvSummary';
import { GEAR_TYPE } from "../../GlobalConstants";


var selfRef
class DashboardClass extends React.Component {
  constructor(props) {
    super(props);
    this.onApiErrorCb = this.onApiErrorCb.bind(this);
    this.onApiSuccessCb = this.onApiSuccessCb.bind(this);
    this.getDashboard = this.getDashboard.bind(this);
    this._apiTokenSource = axios.CancelToken.source();
    this._ApiHelper = new ApiHelper();

    this.state = {
      tabIndex: 0,
      entityTabIndex: 0,
      ongoingEventTabIndex: 0,
      resolvedEventTabIndex: 0,
      trckList: [],
      ptmcList: [],
      sgnlList: [],
      ongoingNormalList: [],
      ongoingWarningList: [],
      ongoingErrorList: [],
      resolvedNormalList: [],
      resolvedWarningList: [],
      resolvedErrorList: [],
      eventsWeekly: [],
      summary: {}
    };
    selfRef = this;
  }

  getDashboard() {
    console.log("getDashboard");
    let tempTe = (new Date()).getTime();
    let tempTs = tempTe - selfRef._historyViewWindowSize;
    selfRef._ApiHelper.prepReq(selfRef._apiTokenSource, Constants.API_REQ_TYPE_IDs.GET_DASHBOARD)
      .sendReq(selfRef.onApiSuccessCb, selfRef.onApiErrorCb);
  }

  onApiSuccessCb(tempTrckList, tempPtmcList, tempSgnlList,
    tempOngoingNormal, tempOngoingWarning, tempOngoingError,
    tempResolvedNormal, tempResolvedWarning, tempResolvedError,
    eventsWeekly, summary, reqType) {
    switch (reqType) {
      case Constants.API_REQ_TYPE_IDs.GET_DASHBOARD:
        selfRef.setState({
          trckList: tempTrckList,
          ptmcList: tempPtmcList,
          sgnlList: tempSgnlList,
          ongoingNormalList: tempOngoingNormal,
          ongoingWarningList: tempOngoingWarning,
          ongoingErrorList: tempOngoingError,
          resolvedNormalList: tempResolvedNormal,
          resolvedWarningList: tempResolvedWarning,
          resolvedErrorList: tempResolvedError,
          eventsWeekly: eventsWeekly,
          summary: summary
        });
        break;
    }
  }
  onApiErrorCb(error, reqType) {
    //TODO update history resp error to state
    switch (reqType) {
      case Constants.API_REQ_TYPE_IDs.GET_DASHBOARD:
        console.log("Dashboard API failure![" + error + "]")
        break;
    }
  }

  componentDidMount() {
    try {
      selfRef.getDashboard();
    } catch (err) {
      console.log(err.message)
    }
  }

  componentDidUpdate(nextProps) {
    console.log("DidUpdate Dashboard")
    try {

    } catch (err) {
    }
  }

  componentWillUnmount() {
    try {
      selfRef._apiTokenSource.cancel('apiRequest cancelled: unmounted!');
    } catch (err) {
      console.log(err.message)
    }
  }

  render() {
    console.log("Dashboard render!");
    return (<>
      <div className={selfRef.props.classes.pageHeading}>
        <div className={selfRef.props.classes.pageTitleSubtext}>
          <h3 className={selfRef.props.classes.headingTextBig}> {"Dashboard"}{'-'}</h3>
          <h3 className={selfRef.props.classes.headingTextSmall}>{"Live"}</h3>
        </div>
      </div>
      <Grid container spacing={1} style={{ overflow: 'hidden  ' }}>
        <Grid item sm={3} xs={12}>
          <EntityEventSummary summary={this.state.summary[GEAR_TYPE.TRCK]} />
        </Grid>
        <Grid item sm={3} xs={12}>
          <EntityEventSummary summary={this.state.summary[GEAR_TYPE.PTMC]} />
        </Grid>
        <Grid item sm={3} xs={12}>
          <EntityEventSummary summary={this.state.summary[GEAR_TYPE.SGNL]} />
        </Grid>
        <Grid item sm={3} xs={12}>
          <EnvSummary summary={this.state.summary[GEAR_TYPE.ENV]} />
        </Grid>

        <Grid item sm={4} xs={12}>
          <EntityGrid entities={selfRef.state.trckList} entityType={Constants.TRCK} columnCount={2} />
        </Grid>
        <Grid item sm={4} xs={12}>
          <EntityGrid entities={selfRef.state.ptmcList} entityType={Constants.PTMC} columnCount={2} />
        </Grid>
        <Grid item sm={4} xs={12}>
          <EntityGrid entities={selfRef.state.sgnlList} entityType={Constants.SGNL} columnCount={2} />
        </Grid>

        <Grid item sm={6} xs={12}>
          <EventTabs classes={selfRef.props.classes} muiTabsStyles={selfRef.props.muiTabsStyles} muitTabsItemStyles={selfRef.props.muitTabsItemStyles}
            resolvedNormalList={this.state.resolvedNormalList}
            resolvedWarningList={selfRef.state.resolvedWarningList}
            resolvedErrorList={selfRef.state.resolvedErrorList} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <EventsWeekly classes={selfRef.props.classes} eventsWeekly={selfRef.state.eventsWeekly} />
        </Grid>
      </Grid>
    </>);
  }
}
export default withRouter(DashboardClass);