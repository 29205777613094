import { Divider } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/styles';
import { useGutterBorderedGridStyles } from '@mui-treasury/styles/grid/gutterBordered';
import { useFadedShadowStyles } from '@mui-treasury/styles/shadow/faded';
import React from "react";

const useStyles = makeStyles(({ palette }) => ({
  card: {
    borderRadius: 12,
    minWidth: 150,
    textAlign: 'left',
    margin: 5
  },
  heading: {
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: '0.5px',
    marginTop: 4,
    marginBottom: 0,
  },
  headingKey: {
    fontSize: 16,
    color: palette.grey[500],
    fontWeight: 500,
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    margin: 0,
  },
  subheader: {
    fontSize: 14,
    color: palette.grey[500],
    marginBottom: '0.875em',
  },
  statLabel: {
    fontSize: 12,
    color: palette.grey[500],
    fontWeight: 500,
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    margin: 0,
  },
  statValue: {
    fontSize: 20,
    fontWeight: 'bold',
    letterSpacing: '1px',
  },
}));
function LocationSummary({ locationBoxes }) {
  const styles = useStyles();
  const shadowStyles = useFadedShadowStyles();
  const borderedGridStyles = useGutterBorderedGridStyles({
    borderColor: 'rgba(0, 0, 0, 0.08)',
    height: '30%',
  });

  let feedEnd = "- - -";
  let feedTemp = "- - -";
  let feedHumi = "- - -";
  let relayEnd = "- - -";
  let relayTemp = "- - -";
  let relayHumi = "- - -";
  if (locationBoxes !== undefined && locationBoxes.length > 0) {
    for (let i = 0; i < locationBoxes.length; i++) {
      if (locationBoxes[i].role === 'Feed') {
        feedEnd = locationBoxes[i].name;
        feedTemp = locationBoxes[i].temp+" °C";
        feedHumi = locationBoxes[i].hum+ " %";
      }
      if (locationBoxes[i].role === 'Relay') {
        relayEnd = locationBoxes[i].name;
        relayTemp = locationBoxes[i].temp+" °C";
        relayHumi = locationBoxes[i].hum+" %";
      }
    }
  }

  return (
    <Card className={styles.card}>
      <CardContent>
        <h3 className={styles.heading}>Location</h3>
      </CardContent>
      <Divider light />
      {/* <Maps lat={"28"} lng={"77"} name={"Feed and relay"}></Maps> */}

      <Box display={'flex'}>

        <Box p={2} flex={'auto'} className={borderedGridStyles.item}>
          <p className={styles.statLabel}>Feed</p>
          <p className={styles.statValue}>{feedEnd}</p>
        </Box>
        <Box p={2} flex={'auto'} className={borderedGridStyles.item}>
          <p className={styles.statLabel}>Temperature</p>
          <p className={styles.statValue}>{feedTemp}</p>
        </Box>

        <Box p={2} flex={'auto'} className={borderedGridStyles.item}>
          <p className={styles.statLabel}>Humidity</p>
          <p className={styles.statValue}>{feedHumi}</p>
        </Box>
      </Box>
      <Box display={'flex'}>
        <Box p={2} flex={'auto'} className={borderedGridStyles.item}>
          <p className={styles.statLabel}>Relay</p>
          <p className={styles.statValue}>{relayEnd}</p>
        </Box>
        <Box p={2} flex={'auto'} className={borderedGridStyles.item}>
          <p className={styles.statLabel}>Temperature</p>
          <p className={styles.statValue}>{relayTemp}</p>
        </Box>
        <Box p={2} flex={'auto'} className={borderedGridStyles.item}>
          <p className={styles.statLabel}>Humidity</p>
          <p className={styles.statValue}>{relayHumi}</p>
        </Box>
      </Box>
    </Card>
  )
}
export default (LocationSummary);