import moment from 'moment';
import React from 'react';
import { shortDateTime } from '../../../commonjs/CustomUtils';
import { LOG_TAG } from '../../../GlobalConstants';
import './styles.scss';
function GraphSummary({ selectedDateAndHour,summary, styles }) {

  // let dateAndHour = <Skeleton duration={2} height={20} width={"100%"}/>;
  let from = "From";///<Skeleton duration={2} height={20} width={"100%"}/>;
  let to = "To"//<Skeleton duration={2} height={20} width={"100%"}/>;
  let duration = "Duration"//<Skeleton duration={2} height={20} width={"100%"}/>;
  let events = "Count"
  if (summary !== undefined) {
    //showing[" + dateCompact(selectedDateAndHour) + "] 
    console.log(LOG_TAG.INFO, "graphSummary:[" + shortDateTime(summary.from) + " => " +
      shortDateTime(summary.to) + "] eventCount[" + summary.events + "]")
    // if (selectedDateAndHour !== undefined) dateAndHour = dateCompact(selectedDateAndHour);
    if (summary.from !== undefined) from = shortDateTime(summary.from);
    if (summary.to !== undefined) to = shortDateTime(summary.to);
    if (summary.to !== undefined) duration=moment.duration(summary.to - summary.from).humanize();
    if (summary.events !== undefined) events = summary.events;
  }
  return (
    <div className="card-stats">
      <div className='stats-row is-blue'>
        <h3>{from} - {to}  ( {duration})</h3>
        <h3>Events : {events}</h3>
      </div>
    </div>
  );
}

export default GraphSummary;
