import { useTheme } from "@material-ui/styles";
import React from 'react';
import { useParams } from "react-router-dom";
// styles
import useStyles from "./styles";
// components
import LBoxClass from "./LBoxClass";


export default function LBoxFunctional({location }) {
  var classes = useStyles();
  var theme = useTheme();
  var {id,ts,te}= useParams();
  console.log('LBox Functional /'+id+"/"+ts+"/"+te);


  return (
    <>
      <LBoxClass classes={classes}
        theme={theme}
        location={location} entityId={id} ts={ts} te={te}></LBoxClass>
    </>
  );

}

// #######################################################################
