import { makeStyles, useTheme } from "@material-ui/styles";
import React from 'react';
// components
import DashboardClass from "./DashboardClass";
// styles
import useStyles from "./styles";
import { tabItemStyles, tabsStyles } from './TabsStyles';

const containedTabsStylesHook = {
  useTabs: makeStyles(tabsStyles),
  useTabItem: makeStyles(tabItemStyles)
};

export default function DashboardFunctional({ location }) {
  var classes = useStyles();
  var theme = useTheme();
  const tabsStyles = containedTabsStylesHook.useTabs();
  const tabItemStyles = containedTabsStylesHook.useTabItem();
  // console.log('Dashboard Functional');
  return (
    <>
      <DashboardClass classes={classes} muiTabsStyles={tabsStyles} muitTabsItemStyles={tabItemStyles}
        theme={theme}
        location={location} ></DashboardClass>
    </>
  );

}

// #######################################################################
