import { Grid } from "@material-ui/core";
import React, { Component } from "react";
import { Label, Legend, Line, LineChart, ReferenceArea as LogicalEvent, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { getClosestDisplayableTick, shortDateTime, timestampToLocaleHhmmss } from '../../../commonjs/CustomUtils';
import { LOG_TAG, TIME_WINDOWS_IN_MILLIS } from "../../../GlobalConstants";
import * as Constants from '../Constants';
import EventList from './EventList';
import './styles.scss';
let inputData = {
  xkey: "xtime",
  yAxisLabelGraph1: "Voltage [ V ]",
  yAxisColorGraph1: "#b70003",
  yAxisColorGraph2: "#424572",
  oyLabelRight: "Current [ A ]",
  xAxisLabelGraph1: "Time [hh:mm:ss am/pm]",
};
let graphLabels = [
  { key: "Vf", keyCopy: "Vf ", color: "#ff073a", onColor: "#ff073a", offColor: "#CCCCCC" },
  { key: "Vr", keyCopy: "Vr ", color: "#28a745", onColor: "#28a745", offColor: "#CCCCCC" },
  { key: "Vt", keyCopy: "Vt ", color: "#fd7e14", onColor: "#fd7e14", offColor: "#CCCCCC" },
  { key: "If", keyCopy: "If ", color: "#ff073a", onColor: "#ff073a", offColor: "#CCCCCC" },
  { key: "Ir", keyCopy: "Ir ", color: "#28a745", onColor: "#28a745", offColor: "#CCCCCC" },
  { key: "Il", keyCopy: "Il ", color: "#fd7e14", onColor: "#fd7e14", offColor: "#CCCCCC" },
];
let selfRef
class TrckGraph extends Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.onClickEventListItem = this.onClickEventListItem.bind(this);
    this.onClickShowAllEvents = this.onClickShowAllEvents.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.toggleLineVisibilityGraph1 = this.toggleLineVisibilityGraph1.bind(this);
    this.toggleLineVisibilityGraph2 = this.toggleLineVisibilityGraph2.bind(this);
    this.state = {
      title: Constants.GRAPHICAL,
      hangedLineVisibilityGraph1: true,
      hangedLineVisibilityGraph2: true,
      xkey: "t",
      xAxisLabelGraph1: inputData.xAxisLabelGraph1,
      yAxisLabelGraph1: inputData.yAxisLabelGraph1,
      xAxisLabelGraph2: inputData.xAxisLabelGraph2,
      yAxisLabelGraph2: inputData.yAxisLabelGraph2,
      yAxisColorGraph1: inputData.yAxisColorGraph1,
      yAxisColorGraph2: inputData.yAxisColorGraph2,
      yAxisTickCountGraph1: 4,
      yAxisTickCountGraph2: 10,
      labelsGraph1: [],
      labelsGraph2: [],
      xAxisGridDivisions: 16,
      eventTimePaddingMinutes: 2,
      graphxGridSizeFullRangeMinutes: 5,
      graphxGridSizeEventRangeMinutes: 2,
      tsData: this.props.tsData,
      eventData: this.props.eventData,
      metaData: this.props.metaData,
      renderedTsData: [],
      renderedEventData: [],
      isGraphAnimationActive: false,
      isFullGraphView: false,
      isEventFocusView: false,
      trckGraphState: Constants.TRCK_GRAPH_STATE_IDs.FULL_RANGE
    };
    this.state.renderedTsData = this.props.tsData.slice();
    this.state.renderedEventData = this.props.eventData.slice();
    selfRef = this;

  }
  //TODO http://recharts.org/en-US/examples/HighlightAndZoomLineChart
  //TODO handle events where the endTs is not there
  onClickEventListItem(clickEvent, logicalEvent) {
    // console.log("Focus on a Logical Event!")
    this.setState({
      isFullGraphView: false,
      isEventFocusView: true,
      trckGraphState: Constants.TRCK_GRAPH_STATE_IDs.EVENT_RANGE
    });
    if (logicalEvent.ts === undefined || logicalEvent.ts > this.props.tsData[this.props.tsData.length - 1].t) {
      console.log("Invalid event!")
      //show the message on UI
      return;
    }

    if (logicalEvent.ts <= this.props.tsData[0].t) {
      logicalEvent.ts = this.props.tsData[0].t
    }
    if (logicalEvent.te === undefined && logicalEvent.te > this.props.tsData[this.props.tsData.length - 1].t) {
      logicalEvent.te = this.props.tsData[this.props.tsData.length - 1].t
    }

    //find the start and end timstamp according to the event and set the data accordingnly
    let eventStartTStamp = logicalEvent.ts - Constants.EVENT_TIME_PADDING_IN_MILLIS;
    let eventEndTStamp = logicalEvent.te + Constants.EVENT_TIME_PADDING_IN_MILLIS;
    let eventStartIndex = 0, eventEndIndex = this.props.tsData.length - 1;

    if (eventStartTStamp < this.props.tsData[0].t) {
      eventStartIndex = 0;
    } else {
      for (let ctr1 = this.props.tsData.length - 1; ctr1 >= 0; ctr1--) {
        if (eventStartTStamp >= this.props.tsData[ctr1].t) {
          eventStartIndex = ctr1;
          break;
        }
      }
    }

    if (eventEndTStamp > this.props.tsData[this.props.tsData.length - 1].t) {
      eventEndIndex = this.props.tsData.length - 1;
    } else {
      for (let ctr = 0; ctr < this.props.tsData.length - 1; ctr++) {
        if (eventEndTStamp <= this.props.tsData[ctr].t) {
          eventEndIndex = ctr;
          break;
        }
      }
    }

    if (eventStartIndex > eventEndIndex) {
      console.log("Invalid Logical event!")
      //show the message on UI
      return;
    }
    console.log("eventStart[" + timestampToLocaleHhmmss(this.props.tsData[eventStartIndex].t) + "] eventEnd["
      + timestampToLocaleHhmmss(this.props.tsData[eventEndIndex].t) + "]")
    this.setState({
      renderedTsData: this.props.tsData.slice(eventStartIndex, eventEndIndex),
      isGraphAnimationActive: true
    });
  }

  onClickShowAllEvents() {
    switch (this.state.trckGraphState) {
      case Constants.TRCK_GRAPH_STATE_IDs.FULL_RANGE:
        this.setState({
          renderedTsData: this.props.tsData.slice(),
          renderedEventData: selfRef.props.eventData.slice(),
          trckGraphState: Constants.TRCK_GRAPH_STATE_IDs.FULL_RANGE,
          isGraphAnimationActive: true
        })
        break;
      case Constants.TRCK_GRAPH_STATE_IDs.EVENT_RANGE:
        this.setState({
          renderedTsData: this.props.tsData.slice(),
          renderedEventData: selfRef.props.eventData.slice(),
          trckGraphState: Constants.TRCK_GRAPH_STATE_IDs.FULL_RANGE,
          isGraphAnimationActive: true
        })
        break;
      default:
        break;
    }
  }

  handleUiClick(e, genericObj) {
    console.log("onClick button: " + e.currentTarget.id);
    let clickableId = +(e.currentTarget.id);
    switch (clickableId) {
      case Constants.TRCK_GRAPH_UI_INTERACTIVE_IDs.BT_SHOW_ALL_EVENTS:
        selfRef.onClickShowAllEvents(e);
        break;
      case Constants.TRCK_GRAPH_UI_INTERACTIVE_IDs.BT_EVENT_LIST_ITEM:
        selfRef.onClickEventListItem(e, genericObj);
        break;
      default:
        break;
    }
  }

  // need to use state.eventData instead of this.prop.eventData
  onMouseEnter(e, logicalEvent) {
    // if (this.state.isFullGraphView === false) {
    if (logicalEvent === undefined) return;
    try {
      // console.log("Highlight Logical Event[" + logicalEvent.id + " " + logicalEvent.name + "]")
      let tempFullLogicalEventList = selfRef.props.eventData;
      tempFullLogicalEventList[tempFullLogicalEventList.indexOf(logicalEvent)].fillOpacity = .5;
      this.setState({
        eventData: tempFullLogicalEventList
      });
    } catch (err) {
      console.log("Highlight Logical Event exception![" + logicalEvent.id + " " + logicalEvent.name + "]")
    }
  }
  onMouseLeave(e, logicalEvent) {
    //if (this.state.isFullGraphView === false) {
    // console.log("Dehighlight Logical Event[" + logicalEvent.id + " " + logicalEvent.name + "]")
    if (logicalEvent === undefined) return;
    try {
      // console.log("Dehighlight Logical Event[" + logicalEvent.id + " " + logicalEvent.name + "]")
      let tempFullLogicalEventList = selfRef.props.eventData;
      tempFullLogicalEventList[tempFullLogicalEventList.indexOf(logicalEvent)].fillOpacity = .4;
      this.setState({
        eventData: tempFullLogicalEventList
      });
    } catch (err) {
      console.log("dehighlight Logical Event exception![" + logicalEvent.id + " " + logicalEvent.name + "]")
    }
  }

  componentDidMount() {
    // console.log("DidMount Graph");
    if (this.state.renderedTsData !== undefined) {
      // console.log("tSeries[" + this.state.renderedTsData.length + "]");
    }
    if (this.state.renderedEventData !== undefined) {
      // console.log("eventData[" + this.state.renderedEventData.length + "]");
    }
  }

  componentDidUpdate(nextProps) {
    // console.log("DidUpdate Graph");
    if (this.state.renderedTsData !== undefined) {
      // console.log("tSeries[" + this.state.renderedTsData.length + "]");
    }
    if (this.state.renderedEventData !== undefined) {
      // console.log("eventData[" + this.state.renderedEventData.length + "]");
    }
    try {
      if (this.props.tsData !== nextProps.tsData) {
        this.setState({
          renderedTsData: this.props.tsData.slice(),
          renderedEventData: this.props.eventData.slice(),
          isGraphAnimationActive: true,
          trckGraphState: Constants.TRCK_GRAPH_STATE_IDs.FULL_RANGE
        });
      }

    } catch (err) {
    }
  }

  toggleLineVisibilityGraph1(event) {
    for (let j = 0; j < graphLabels.length; j++) {
      if (graphLabels[j].key === event.dataKey) {
        if (graphLabels[j].key !== graphLabels[j].keyCopy) {//make Visible
          graphLabels[j].key = graphLabels[j].key + " ";
          graphLabels[j].color = graphLabels[j].offColor;
          selfRef.setState({ changeLineVisibilityGraph1: true })
        } else {//make Disappear
          graphLabels[j].key = graphLabels[j].key.trim();
          graphLabels[j].color = graphLabels[j].onColor;
          selfRef.setState({ changeLineVisibilityGraph1: false })
        }
      }
    }
  }

  toggleLineVisibilityGraph2(event) {
    for (let j = 0; j < graphLabels.length; j++) {
      if (graphLabels[j].key === event.dataKey) {
        if (graphLabels[j].key !== graphLabels[j].keyCopy) {//make Visible
          graphLabels[j].key = graphLabels[j].key + " ";
          graphLabels[j].color = graphLabels[j].offColor;
          selfRef.setState({ changeLineVisibilityGraph2: true })
        } else {//make Disappear
          graphLabels[j].key = graphLabels[j].key.trim();
          graphLabels[j].color = graphLabels[j].onColor;
          selfRef.setState({ changeLineVisibilityGraph2: false })
        }
      }
    }
  }

  render() {
    // console.log("Trck Circuit Graph render!");
    let maxTimestamp
    let minTimestamp
    let totalTimeSpan
    let xAxisDivisions
    let renderedTicks = [];
    let graphxGridSizeInMillis
    let eventListPlaceHolder
    let isEventRange = false

    const TooltipGraph1 = ({ active, payload, label }) => {
      if (active) {
        if (label !== null && label !== undefined && payload !== null && payload !== undefined) {
          let row1 = <h4>{`${shortDateTime(label)}`}</h4>
          let row2, row3, row4;
          if (payload[0] !== undefined) {
            row2 = <h4>{`${payload[0].name}: ${payload[0].value} V`} </h4>
          } else {
            row2 = <h4>{`-- : -- V`} </h4>
          }
          if (payload[1] !== undefined) {
            row3 = <h4>{`${payload[1].name}: ${payload[1].value} V`} </h4>
          } else {
            row3 = <h4>{`-- : -- V`} </h4>
          }
          if (payload[2] !== undefined) {
            row4 = <h4>{`${payload[2].name}: ${payload[2].value} V`} </h4>
          } else {
            row4 = <h4>{`-- : -- V`} </h4>
          }
          return (

            <div className='card-stats'>
              <div className='stats-row is-blue'>
                {row1}
                <h3>{`|`}</h3>
                {row2}
                <h3>{`|`}</h3>
                {row3}
                <h3>{`|`}</h3>
                {row4}
              </div>
            </div>
          );
        } else return <></>
      } else return <></>
    }

    const TooltipGraph2 = ({ active, payload, label }) => {
      if (active) {
        if (label !== null && label !== undefined && payload !== null && payload !== undefined) {
          let row1 = <h4>{`${shortDateTime(label)}`}</h4>
          let row2, row3, row4;
          if (payload[0] !== undefined) {
            row2 = <h4>{`${payload[0].name}: ${payload[0].value} mA`} </h4>
          } else {
            row2 = <h4>{`-- : -- mA`} </h4>
          }
          if (payload[1] !== undefined) {
            row3 = <h4>{`${payload[1].name}: ${payload[1].value} mA`} </h4>
          } else {
            row3 = <h4>{`-- : -- mA`} </h4>
          }
          if (payload[2] !== undefined) {
            row4 = <h4>{`${payload[2].name}: ${payload[2].value} mA`} </h4>
          } else {
            row4 = <h4>{`-- : -- mA`} </h4>
          }
          return (

            <div className='card-stats'>
              <div className='stats-row is-blue'>
                {row1}
                <h3>{`|`}</h3>
                {row2}
                <h3>{`|`}</h3>
                {row3}
                <h3>{`|`}</h3>
                {row4}
              </div>
            </div>
          );
        } else return <></>
      } else return <></>
    }

    const LogicalEventLabel = ({ logicalEvent }) => {
      if (logicalEvent !== undefined) {
        return (
          <div className='card-stats'>
            <div className='stats-row is-blue'>
              <h4>{`${logicalEvent.name}`}</h4>
            </div>
            {/* <div className='stats-row is-gray'>
              <h4>{`${shortDateTime(logicalEvent.tx)}`}</h4>
              <h3>{`|`}</h3>
              <h4>{`${[logicalEvent.duration]}`} </h4>
            </div> */}
          </div>
        );
      } else return <></>
    }

    if (this.state.renderedTsData !== undefined) {
      console.log(LOG_TAG.INFO, "Graph render tSeries[" + this.state.renderedTsData.length + "] events[" + this.state.renderedEventData.length + "]");
    }

    if (this.state.renderedTsData.length > 0) {
      maxTimestamp = this.state.renderedTsData[this.state.renderedTsData.length - 1].t || 0;
      minTimestamp = this.state.renderedTsData[0].t || 0;
      switch (this.state.trckGraphState) {
        case Constants.TRCK_GRAPH_STATE_IDs.FULL_RANGE:
          minTimestamp = getClosestDisplayableTick(false, minTimestamp, TIME_WINDOWS_IN_MILLIS.FOUR_HOURS);
          maxTimestamp = getClosestDisplayableTick(false, maxTimestamp, TIME_WINDOWS_IN_MILLIS.FOUR_HOURS);
          graphxGridSizeInMillis = (TIME_WINDOWS_IN_MILLIS.FOUR_HOURS);
          isEventRange = false;
          break;
        case Constants.TRCK_GRAPH_STATE_IDs.EVENT_RANGE:
          minTimestamp = getClosestDisplayableTick(false, minTimestamp, TIME_WINDOWS_IN_MILLIS.TWO_MIN);
          maxTimestamp = getClosestDisplayableTick(false, maxTimestamp, TIME_WINDOWS_IN_MILLIS.TWO_MIN);
          graphxGridSizeInMillis = (TIME_WINDOWS_IN_MILLIS.TWO_MIN);
          isEventRange = true;
          break;
        default:
          break;
      }
    }
    totalTimeSpan = maxTimestamp - minTimestamp;
    xAxisDivisions = totalTimeSpan / graphxGridSizeInMillis;
    renderedTicks.push(minTimestamp);
    let tempVal = minTimestamp;
    for (let ctr = 0; ctr < xAxisDivisions; ctr++) {
      tempVal = tempVal + graphxGridSizeInMillis;
      renderedTicks.push(tempVal);
    }
    xAxisDivisions = xAxisDivisions + 1;
    eventListPlaceHolder = <EventList renderedEventData={selfRef.state.renderedEventData}
      handleUiClick={selfRef.handleUiClick}
      onMouseEnter={selfRef.onMouseEnter}
      onMouseLeave={selfRef.onMouseLeave} />



    return (
      <>
        <div style={{ marginTop: (selfRef.props.theme.spacing(1)), marginBottom: (selfRef.props.theme.spacing(1)), marginLeft: (selfRef.props.theme.spacing(1)) }}>
          <Grid container spacing={1}>
            <Grid item sm={10} xs={12}>
              <div style={{ marginTop: (selfRef.props.theme.spacing(4)), marginBottom: (selfRef.props.theme.spacing(1)) }}>
                <ResponsiveContainer width="100%" minWidth={300} height={280}>
                  <LineChart
                    data={selfRef.state.renderedTsData.slice()}
                    margin={{ top: 0, right: 40, left: 2, bottom: 1 }}
                    syncId="trckGraph"
                  >
                    {/* <CartesianGrid strokeDasharray="3 3" /> */}
                    <Tooltip
                      position={{ x: 45, y: -25 }}
                      content={<TooltipGraph1 />}
                    />
                    <Legend onClick={selfRef.toggleLineVisibilityGraph1} verticalAlign="top" align="right" iconSize={30} />
                    <XAxis dataKey={selfRef.state.xkey} dy={5}
                      height={60}
                      angle={-30}
                      textAnchor="end"
                      allowDecimals={true}
                      axisLine={true}
                      tick={{ fontSize: 12 }}
                      type='number'
                      scale='auto'
                      domain={[{ minTimestamp }, { maxTimestamp }]}
                      interval="preserveStartEnd"
                      tickFormatter={timeStr => shortDateTime(timeStr)}
                      ticks={renderedTicks}
                      tickCount={xAxisDivisions}
                    // tick={false}
                    >
                      <Label value={selfRef.state.xAxisLabelGraph1} position="insideBottom" dy={40} dx={0} />
                    </XAxis>
                    <YAxis
                      type="number"
                      domain={[-2, 8]}
                      allowDataOverflow={true}
                      allowDecimals={false}
                      stroke={"black"}
                      strokeWidth={1}
                      tick={{ fontSize: 12 }}
                      ticks={[-2, 0, 2, 4, 6, 8]}
                      tickCount={selfRef.state.yAxisTickCountGraph1}
                      interval={0} >
                      <Label position="insideLeft" angle={-90} dy={0} dx={0} style={{ textAnchor: 'middle', fontSize: 16 }}>{"Voltages ( V )"} </Label>
                    </YAxis>
                    <Line key={0}
                      dataKey={graphLabels[0].key}
                      stroke={graphLabels[0].color}
                      stackId={selfRef.state.xkey}
                      dot={false} 
                      activeDot={{ r: 3 }}
                      strokeWidth={1}
                      type="monotone"
                      isAnimationActive={selfRef.state.isGraphAnimationActive}
                      animationEasing={'ease-in-out'}
                      animationDuration={200}
                    />
                    <Line key={1}
                      dataKey={graphLabels[1].key}
                      stroke={graphLabels[1].color}
                      stackId={selfRef.state.xkey}
                      dot={false} 
                      activeDot={{ r: 3 }}
                      strokeWidth={1}
                      type="monotone"
                      isAnimationActive={selfRef.state.isGraphAnimationActive}
                      animationEasing={'ease-in-out'}
                      animationDuration={200}
                    />
                    <Line key={2}
                      dataKey={graphLabels[2].key}
                      stroke={graphLabels[2].color}
                      stackId={selfRef.state.xkey}
                      dot={false} 
                      activeDot={{ r: 3 }}
                      strokeWidth={1}
                      type="monotone"
                      isAnimationActive={selfRef.state.isGraphAnimationActive}
                      animationEasing={'ease-in-out'}
                      animationDuration={600}
                    />
                    {selfRef.props.eventData.slice().map(logicalEvent => (
                      <LogicalEvent
                        key={logicalEvent.id}
                        x1={logicalEvent.ts}
                        x2={logicalEvent.te}
                        strokeOpacity={.3}
                        // label={logicalEvent.name}
                        fill={logicalEvent.fillColour}
                        fillOpacity={logicalEvent.fillOpacity}
                        label={isEventRange && <LogicalEventLabel logicalEvent={logicalEvent} />}>
                      </LogicalEvent>
                    ))}
                  </LineChart>
                </ResponsiveContainer>
                <div style={{ marginTop: (selfRef.props.theme.spacing(8)) }}>
                  <ResponsiveContainer width="100%" minWidth={300} height={280}>
                    <LineChart
                      data={selfRef.state.renderedTsData.slice()}
                      margin={{ top: -20, right: 40, left: 2, bottom: 1 }}
                      syncId="trckGraph"
                    >
                      {/* <CartesianGrid strokeDasharray="3 3" /> */}
                      <Tooltip
                        position={{ x: 45, y: -45 }}
                        content={<TooltipGraph2 />}
                      />
                      <Legend onClick={selfRef.toggleLineVisibilityGraph2} verticalAlign="top" align="right" iconSize={30} />
                      <XAxis dataKey={selfRef.state.xkey} 
		      dy={5}
                        height={60}
                        angle={-30}
                        textAnchor="end"
                        allowDecimals={true}
                        axisLine={true}
                        tick={{ fontSize: 12 }}
                        type='number'
                        scale='auto'
                        interval="preserveStartEnd"
                        domain={[{ minTimestamp }, { maxTimestamp }]}
                        tickFormatter={timeStr => shortDateTime(timeStr)}
                        ticks={renderedTicks}
                        tickCount={xAxisDivisions}
                      >
                        {/* <Label
                      value={selfRef.state.xAxisLabelGraph1}
                      position="insideBottom"
                      dy={10} dx={0} /> */}
                        {/* https://github.com/recharts/recharts/issues/661 */}
                      </XAxis>
                      <YAxis
                        type="number"
                        domain={[-100, 1100]}
                        allowDataOverflow={true}
                        allowDecimals={false}
                        stroke={"black"}
                        strokeWidth={1}
                        tick={{ fontSize: 12 }}
                        ticks={[-100, 0, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100]}
                        tickCount={selfRef.state.yAxisTickCountGraph2}
                        interval={0}>
                        <Label position="insideLeft" angle={-90} dy={0} dx={0} style={{ textAnchor: 'middle', fontSize: 16 }} > {"Currents ( mA )"}</Label>
                      </YAxis>
                      <Line key={0}
                        dataKey={graphLabels[3].key}
                        stroke={graphLabels[3].color}
                        stackId={selfRef.state.xkey}
                        dot={false} 
                        activeDot={{ r: 3 }}
                        strokeWidth={1}
                        type="monotone"
                        isAnimationActive={selfRef.state.isGraphAnimationActive}
                        animationEasing={'ease-in-out'}
                        animationDuration={200}
                      />
                      <Line key={1}
                        dataKey={graphLabels[4].key}
                        stroke={graphLabels[4].color}
                        stackId={selfRef.state.xkey}
                        dot={false} 
                        activeDot={{ r: 3 }}
                        strokeWidth={1}
                        type="monotone"
                        isAnimationActive={selfRef.state.isGraphAnimationActive}
                        animationEasing={'ease-in-out'}
                        animationDuration={400}
                      />
                      <Line key={2}
                        dataKey={graphLabels[5].key}
                        stroke={graphLabels[5].color}
                        stackId={selfRef.state.xkey}
                        dot={false} 
                        activeDot={{ r: 3 }}
                        strokeWidth={1}
                        type="monotone"
                        isAnimationActive={selfRef.state.isGraphAnimationActive}
                        animationEasing={'ease-in-out'}
                        animationDuration={600}
                      />

                      {selfRef.state.renderedEventData.slice().map(logicalEvent => (
                        <LogicalEvent
                          key={logicalEvent.id}
                          x1={logicalEvent.ts}
                          x2={logicalEvent.te}
                          strokeOpacity={.3}
                          // label={logicalEvent.name}
                          fill={logicalEvent.fillColour}
                          fillOpacity={logicalEvent.fillOpacity}>
                          {/* {isEventRange &&
                            <Label position="top" dy={40}>
                              {logicalEvent.name}
                            </Label>} */}
                        </LogicalEvent>
                      ))}
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </Grid>
            <Grid item sm={2} xs={12}>
              <div style={{ marginTop: (selfRef.props.theme.spacing(1)), marginBottom: (selfRef.props.theme.spacing(1)) }}>
                {eventListPlaceHolder}
              </div>
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}

export default TrckGraph;
