import React, { useState } from "react";
import { Collapse, Divider, List, ListItem, ListItemIcon, Typography, } from "@material-ui/core";
import { Inbox as InboxIcon } from "@material-ui/icons";
import { Link as ReactLink } from "react-router-dom";
import classnames from "classnames";
// styles
import useStyles from "./styles";

import EntityListItem from "./EntityListItem";
import EntityHeaderItem from "./EntityHeaderItem";

export default function SidebarEntity({ isSidebarOpened, location, nested, type, entityItem }) {
  var classes = useStyles();

  var [isOpen, setIsOpen] = useState(false);

  let entityLink = entityItem.entityLink;
  let entityIcon = entityItem.entityIcon ? entityItem.entityIcon : <InboxIcon />
  let entityChildren = entityItem.entityChildren;

  let isLinkActive = entityLink &&
    (location.pathname === entityLink || location.pathname.indexOf(entityLink) !== -1);
  let iconClass = classnames(classes.linkIcon, { [classes.linkIconActive]: isLinkActive, })
  let isEntityHidden = (isLinkActive && (!isSidebarOpened));
  let entityView;
  if (entityItem.isHeader === true) {
    // console.log("header item["+entityItem.name+"]")
    entityView = <EntityHeaderItem entity={entityItem} styles={classes} isEntityHidden={isEntityHidden} />
  } else {
    // console.log("List item["+entityItem.name+"]")
    entityView = <EntityListItem entity={entityItem} styles={classes} isEntityHidden={isEntityHidden} />
  }
  if (entityChildren)
    return (
      <>
        <ListItem button component={entityLink && ReactLink} onClick={toggleCollapse}
          className={classes.link} to={entityLink} >
          <ListItemIcon className={iconClass}> {entityIcon} </ListItemIcon>
          {entityView}
        </ListItem>

        {entityChildren && (
          <Collapse in={isOpen && isSidebarOpened}
            timeout="auto" unmountOnExit className={classes.nestedList}
          >
            <List component="div" dense={true} disablePadding>
              {entityChildren.map(entityChild => (
                <SidebarEntity
                  key={entityChild.id}
                  location={location}
                  isSidebarOpened={isSidebarOpened}
                  classes={classes}
                  nested
                  entityItem={entityChild}
                />
              ))}
            </List>
          </Collapse>
        )}
      </>
    );

  if (type === "title")
    return (
      <Typography
        className={classnames(classes.linkText, classes.sectionTitle, {
          [classes.linkTextHidden]: !isSidebarOpened,
        })}
      >
        {entityItem.name}
      </Typography>
    );

  if (type === "divider") return <Divider className={classes.divider} />;

  return (
    <ListItem button component={entityLink && ReactLink} to={entityLink} className={classes.link}>
      {entityView}
    </ListItem>
  );



  // ###########################################################

  function toggleCollapse(e) {
    if (isSidebarOpened) {
      e.preventDefault();
      setIsOpen(!isOpen);
    }
  }
}
