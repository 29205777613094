import { makeStyles } from "@material-ui/styles";

import grey from "@material-ui/core/colors/grey";
const primary800 = grey["400"];

export default makeStyles(theme => ({
  card: {
    borderRadius: 12,
    width: '100%',
    textAlign: 'left',
    marginLeft: 1
  },
  cardHidden: {
    opacity: 0
  },
  cardHovered: {
    borderRadius: 12,
    minWidth: 150,
    textAlign: 'left',
    margin: 5,
    backgroundColor: primary800
  },

  heading: {
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: '0.5px',
    marginBottom: -5,
    marginTop: -1,
    marginLeft: 4,
  },
  headingKey: {
    fontSize: 16,
    color: theme.palette.grey[500],
    fontWeight: 500,
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    margin: 0,
  },
  subheader: {
    fontSize: 14,
    color: theme.palette.grey[500],
    marginBottom: -3,
    marginTop: -3,
    marginLeft: 4,
  },
  boxStyle: {
    marginTop: -3,
  },
  boxStyleRow: {
    flex: "auto",
    flexDirection: "row"
  },
  eventCard: {
    borderRadius: 12,
    width: '100%',
    textAlign: 'left',
    marginLeft: 1
  },

  liHeading: {
    fontSize: 14,
  },
  liSubheader: {
    fontSize: 12,
  },
  liStatLabel: {
    fontSize: 14,
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  },
  liStatValue: {
    fontSize: 14,
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  },
  statLabel: {
    fontSize: 12,
    color: theme.palette.grey[500],
    fontWeight: 500,
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    marginBottom: -5,
    marginTop: -5,
  },
  statValue: {
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: '1px',
  },
  link: {
    textDecoration: "none",
    "&:hover, &:focus": {
      backgroundColor: "#EEF5DB"//theme.palette.background.light,
    },
  },
  linkActive: {
    backgroundColor: theme.palette.background.dark,
  },
  linkNested: {
    paddingLeft: 0,
    "&:hover, &:focus": {
      backgroundColor: "#EEF5DB",
    },
  },
  linkIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary + "99",
    transition: theme.transitions.create("color"),
    width: 24,
    display: "flex",
    justifyContent: "center",
  },
  linkIconActive: {
    color: theme.palette.primary.main,
  },
  linkText: {
    padding: 0,
    color: theme.palette.text.secondary + "CC",
    transition: theme.transitions.create(["opacity", "color"]),
    fontSize: 16,
  },
  linkTextActive: {
    color: theme.palette.text.primary,
  },
  linkTextHidden: {
    opacity: 0,
  },
  nestedList: {
    paddingLeft: theme.spacing(1) + 5,
    "&:hover, &:focus": {
      backgroundColor: "#FFFFFF",
    },
    "&:select, &:select": {
      backgroundColor: "#FFFFFF",
    },
  },
  sectionTitle: {
    marginLeft: theme.spacing(4.5),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    height: 1,
    backgroundColor: "#D8D8D880",
  },
}));
