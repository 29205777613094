import { Divider, Grid } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import 'rc-checkbox/assets/index.css';
import React from 'react';
// components
import { GEAR_TYPE } from '../../../GlobalConstants';
import DoubleBarChart from './DoubleBarChart';

var selfRef

//const normalColors https://www.schemecolor.com/sample?getcolor=238823
//const warningColors https://www.schemecolor.com/sample?getcolor=ffbf00
//const errorColors  https://www.schemecolor.com/sample?getcolor=d2222d
const dataKeysWarning = { "xKey": "day", "col1": "wo", "col2": "wc", "col1Label": "Ongoing", "col2Label": "Closed", "xLabel": "Last Week", "yLabel": "Warning", "colors": ["#ede59a", "#ffcc00"] };
const dataKeysError = { "xKey": "day", "col1": "eo", "col2": "ec", "col1Label": "Ongoing", "col2Label": "Closed", "xLabel": "Last Week", "yLabel": "Error", "colors": ["#ff4d00", "#7c0a02"] };
const dataKeysNormal = { "xKey": "day", "col1": "no", "col2": "nc", "col1Label": "Ongoing", "col2Label": "Closed", "xLabel": "Last Week", "yLabel": "Normal", "colors": ["#a7e9af", "#238823"] };

export default class EventsWeekly extends React.Component {
    constructor(props) {
        super(props);
        selfRef = this;
    }
    render() {
        return (
            <>
                <Card className={selfRef.props.classes.card}>
                    <CardContent>
                        <h3 className={selfRef.props.classes.heading}>{"Gear Health - Weekly"}</h3>
                    </CardContent>
                    <Divider light />
                    <CardContent>
                        <Grid container spacing={5} direction={"row"} justify={"space-around"}>
                            <Grid item sm={12} xs={12}>
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <DoubleBarChart height={250} dataKeys={dataKeysWarning} data={selfRef.props.eventsWeekly[GEAR_TYPE.ALL]} />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <DoubleBarChart height={250} dataKeys={dataKeysError} data={selfRef.props.eventsWeekly[GEAR_TYPE.ALL]} />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </>
        );
    }
}
