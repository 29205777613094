import { Divider } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/styles';
import { useGutterBorderedGridStyles } from '@mui-treasury/styles/grid/gutterBordered';
import { useFadedShadowStyles } from '@mui-treasury/styles/shadow/faded';
import React from "react";
import { getTime } from '../../../commonjs/CustomUtils';

const useStyles = makeStyles(({ palette }) => ({
  card: {
    borderRadius: 12,
    minWidth: 150,
    textAlign: 'left',
    margin: 5
  },
  heading: {
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: '0.5px',
    marginTop: 4,
    marginBottom: 0,
  },
  headingKey: {
    fontSize: 16,
    color: palette.grey[500],
    fontWeight: 500,
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    margin: 0,
  },
  subheader: {
    fontSize: 14,
    color: palette.grey[500],
    marginBottom: '0.875em',
  },
  statLabel: {
    fontSize: 12,
    color: palette.grey[500],
    fontWeight: 500,
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    margin: 0,
  },
  statValue: {
    fontSize: 20,
    fontWeight: 'bold',
    letterSpacing: '1px',
  },
}));
function EventSummary({ summary }) {
  const styles = useStyles();
  const shadowStyles = useFadedShadowStyles();
  const borderedGridStyles = useGutterBorderedGridStyles({
    borderColor: 'rgba(0, 0, 0, 0.08)',
    height: '30%',
  });

  let lastEventTime = "- - -";
  let lastUpdateTime = "- - -";
  let allEventCount = "- - -";
  let normalEventCount = "- - -";
  let warningEventCount = "- - -";
  let errorEventCount = "- - -";
  
  if (summary !== undefined) {
    if (summary.lastEvent !== undefined) lastEventTime = getTime(summary.lastEvent);
    if (summary.allEvents !== undefined) allEventCount = summary.allEvents;
    if (summary.normal !== undefined) normalEventCount = summary.normal;
    if (summary.warning !== undefined) warningEventCount = summary.warning;
    if (summary.error !== undefined) errorEventCount = summary.error;
  }

  return (
    <Card className={styles.card}>
      <CardContent>
        <h3 className={styles.heading}>Events Summary</h3>
      </CardContent>
      <Divider light />
      <Box display={'flex'}>
        <Box p={2} flex={'auto'} className={borderedGridStyles.item}>
          <p className={styles.statLabel}>Last Event</p>
          <p className={styles.statValue}>{lastEventTime}</p>
        </Box>
        <Box p={2} flex={'auto'} className={borderedGridStyles.item}>
          <p className={styles.statLabel}>Count</p>
          <p className={styles.statValue}>{allEventCount}</p>
        </Box>
      </Box>
      <Box display={'flex'}>
        <Box p={2} flex={'auto'} className={borderedGridStyles.item}>
          <p className={styles.statLabel}>Normal</p>
          <p className={styles.statValue}>{normalEventCount}</p>
        </Box>
        <Box p={2} flex={'auto'} className={borderedGridStyles.item}>
          <p className={styles.statLabel}>Warning</p>
          <p className={styles.statValue}>{warningEventCount}</p>
        </Box>
        <Box p={2} flex={'auto'} className={borderedGridStyles.item}>
          <p className={styles.statLabel}>Error</p>
          <p className={styles.statValue}>{errorEventCount}</p>
        </Box>
      </Box>
    </Card>
  )
}
export default (EventSummary);