import { Divider } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/styles';
import { useGutterBorderedGridStyles } from '@mui-treasury/styles/grid/gutterBordered';
import { useFadedShadowStyles } from '@mui-treasury/styles/shadow/faded';
import React from "react";
import { getTime } from '../../../commonjs/CustomUtils';
const useStyles = makeStyles(({ palette }) => ({
  card: {
    borderRadius: 12,
    minWidth: 150,
    textAlign: 'left',
    margin: 5
  },
  heading: {
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: '0.5px',
    marginTop: 4,
    marginBottom: 0,
  },
  headingKey: {
    fontSize: 16,
    color: palette.grey[500],
    fontWeight: 500,
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    margin: 0,
  },
  subheader: {
    fontSize: 14,
    color: palette.grey[500],
    marginBottom: '0.875em',
  },
  statLabel: {
    fontSize: 12,
    color: palette.grey[500],
    fontWeight: 500,
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    margin: 0,
  },
  statValue: {
    fontSize: 20,
    fontWeight: 'bold',
    letterSpacing: '1px',
  },
}));
function HealthSummary({ summary }) {
  const styles = useStyles();
  const shadowStyles = useFadedShadowStyles();
  const borderedGridStyles = useGutterBorderedGridStyles({
    borderColor: 'rgba(0, 0, 0, 0.08)',
    height: '30%',
  });

  let health = "- - -";
  let leakage = "- - -";
  let ballastRes = "- - -";
  let lastUpdateTime = "- - -";
  let stn = "- - -";
  if (summary !== undefined) {
    if (summary.lastUpdate !== undefined) lastUpdateTime = getTime(summary.lastUpdate);
    if (summary.health !== undefined) health = summary.health;
    if (summary.leakage !== undefined) leakage = summary.leakage;
    if (summary.ballastRes !== undefined) ballastRes = summary.ballastRes;
    if (summary.stn !== undefined) stn= summary.stn;
    

  }
  return (
    <Card className={styles.card}>
      <CardContent>
        <h3 className={styles.heading}>Health</h3>
      </CardContent>
      <Divider light />
      <Box display={'flex'} >
        <Box p={2} flex={'auto'} className={borderedGridStyles.item}>
          <p className={styles.statLabel}>Station</p>
          <p className={styles.statValue}>{stn}</p>
        </Box>
        <Box p={2} flex={'auto'} className={borderedGridStyles.item}>
          <p className={styles.statLabel}>Last Update</p>
          <p className={styles.statValue}>{lastUpdateTime}</p>
        </Box>
      </Box>
      <Box display={'flex'}>
        <Box p={2} flex={'auto'} className={borderedGridStyles.item}>
          <p className={styles.statLabel}>Health</p>
          <p className={styles.statValue}>{health} %</p>
        </Box>
        <Box p={2} flex={'auto'} className={borderedGridStyles.item}>
          <p className={styles.statLabel}>Leakage</p>
          <p className={styles.statValue}>{leakage}</p>
        </Box>
        <Box p={2} flex={'auto'} className={borderedGridStyles.item}>
          <p className={styles.statLabel}>Ballast Resistance</p>
          <p className={styles.statValue}>{ballastRes}</p>
        </Box>
      </Box>
    </Card>
  )
}
export default (HealthSummary);